import { format } from 'date-fns'
import { commonFormat } from '../../../utils'
import { convertSecondsToHMS } from '../../../utils/track-utils'
import ExternalLink from '../../../components/ExternalLink'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Slide,
  Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    position: 'fixed',
    left:   theme.spacing(5),
    top:    theme.spacing(8),
    zIndex: 1500,
    margin: theme.spacing(2),
  },
  card: {
    minWidth:   theme.spacing(32),
    maxWidth:   theme.spacing(50),
    background: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  close: {
    cursor: 'pointer',
    color: 'white',
  },
})

function DemoCard({ classes, data, showCard, onClose }) {
  if (!data)
    return null
  return (
    <Slide
      direction='right'
      in={showCard}
      mountOnEnter
      unmountOnExit>
      <div className={classes.container}>
        <Card className={classes.card}>
          <TrackerCard classes={classes} data={data} onClose={onClose} />
        </Card>
      </div>
    </Slide>
  )
}

// ua_telem_type: 'tracker',
// ua_id:  this.props.imei,
// t:      new Date().toISOString(),
// lat:    lat.toFixed(7),
// lng:    lng.toFixed(7),
// alt:    26.0,
// v:      6.2,
// a:      0.2,
// head:   0,
// status: 2,
// v_acc:  3,
// h_acc:  6,
// ver:    '1.2.1',
// bat:    '4000',
// imei:   this.props.imei,
// imsi:   '987654321123456',
// // === no longer found in UATelem docs
// pit:    this.generateRandomNumber(-5, 5).toString(),
// rol:    this.generateRandomNumber(-5, 5).toString(),
// yaw:    yaw ? yaw.toString() : '0',
// name:   this.props.name,
// companyID: 'SIMULATOR',

function TrackerCard({ classes, data, onClose }) {
  const googleMapsLink = `https://maps.google.com/?q=${data.lat},${data.lng}`
  
  return (<>
    <CardHeader
      align='center'
      className={classes.header}
      title={data.name}
      subheader={`IMEI: ${data.imei || '-'}`}
      action={
        <Close className={classes.close} onClick={e => onClose()} />
      }>
    </CardHeader>
    <CardContent style={{ color: 'black' }}>
      <Grid container>
        <Grid item xs={12}>
          <FormRow
            firstTitle='Date / Time'
            firstText={data.t ? format(new Date(data.t), commonFormat) : ''}
            secondTitle='Battery'
            secondText={convertSecondsToHMS(parseInt(data.bat))}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRow
            firstTitle='Latitude'
            firstText={data.lat}
            firstLink={googleMapsLink}
            secondTitle='Longitude'
            secondText={data.lng}
            secondLink={googleMapsLink}
            thirdTitle='AMSL'
            thirdText={`${data.alt / 100} m`}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRow
            firstTitle='Acceleration'
            firstText={`${data.a.toFixed(2)} m/s²`}
            secondTitle='Velocity'
            secondText={`${data.v.toFixed(2)} m/s`}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRow
            firstTitle='Horizontal Accuracy'
            firstText={`${data.h_acc.toFixed(2)} m`}
            secondTitle='Vertical Accuracy'
            secondText={`${data.v_acc.toFixed(2)} m`}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRow
            firstTitle='Yaw'
            firstText={`${data.yaw.toFixed(2)} °`}
            secondTitle='Pitch'
            secondText={`${data.pit.toFixed(2)} °`}
            thirdTitle='Roll'
            thirdText={`${data.rol.toFixed(2)} °`}
          />
        </Grid>
      </Grid>
    </CardContent>
  </>)
}

function FormRow({
  firstTitle,  firstText, firstLink,
  secondTitle, secondText, secondLink,
  thirdTitle,  thirdText, thirdLink,
}) {
  return (
    <div>
      <Grid container>
        <Grid item xs={thirdTitle ? 4 : 6}
          style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Typography component='div' align='center'>
            <Box fontWeight='fontWeightMedium'>{firstTitle}</Box>
          </Typography>
          <Typography align='center'>
            { firstLink ?
              <ExternalLink href={firstLink}>{firstText}</ExternalLink>
            : firstText
            }
          </Typography>
        </Grid>
        <Grid item xs={thirdTitle ? 4 : 6}
          style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Typography component='div' align='center'>
            <Box fontWeight='fontWeightMedium'>{secondTitle}</Box>
          </Typography>
          <Typography align='center'>
            { secondLink ?
              <ExternalLink href={secondLink}>{secondText}</ExternalLink>
            : secondText
            }
          </Typography>
        </Grid>
        { thirdTitle && (
          <Grid item xs={4} style={{ paddingTop: 8, paddingBottom: 8 }}>
            <Typography component='div' align='center'>
              <Box fontWeight='fontWeightMedium'>{thirdTitle}</Box>
            </Typography>
            <Typography align='center'>
              { thirdLink ?
                <ExternalLink href={thirdLink}>{thirdText}</ExternalLink>
              : thirdText
              }
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider />
    </div>
  )
}

export default withStyles(styles)(DemoCard)
