import L from 'leaflet'
import { frzIcon, frzColour } from '../../utils'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import {
  Fullscreen,
  Map,
  GpsFixed,
  Layers,
  Warning,
  Gradient,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => {
  const disabled = {
    color:           theme.palette.map.grey,
    backgroundColor: theme.palette.background.paper,
  }
  return {
    root: {
      display: 'flex',
      gap: theme.spacing(2)
    },
    btnGrp: {
      height: theme.spacing(7),
      backgroundColor: theme.palette.common.black,
    },
    btnFit: disabled,
    btnMyLoc: ({ myLocation }) => (myLocation === 'enabled' ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    } : disabled),
    btnPubNfz: ({ showOnMap }) => (showOnMap.includes('public-nfz') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.liverRed,
    } : disabled),
    btnPubEst: ({ showOnMap }) => (showOnMap.includes('public-test-estate') ? {
      color:           theme.palette.common.black,
      backgroundColor: theme.palette.map.lightGreen,
    } : disabled),
    btnPriNfz: ({ showOnMap }) => (showOnMap.includes('private-nfz') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.darkBlue,
    } : disabled),
    btnPriUse: ({ showOnMap }) => (showOnMap.includes('private-ready-to-use') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.darkBlue,
    } : disabled),
    btnPenFrz: ({ showOnMap }) => (showOnMap.includes('pending-approval-frz') ? {
      color:           theme.palette.common.black,
      backgroundColor: frzColour('pending-approval'),
    } : disabled),
    btnAprFrz: ({ showOnMap }) => (showOnMap.includes('approved-frz') ? {
      color:           theme.palette.common.white,
      backgroundColor: frzColour('approved'),
    } : disabled)
  }
}

function FrzMapControls({
  classes,
  map,
  showOnMap,
  setShowOnMap,
  myLocation,
  setMyLocation,
}) {
  const toggle = value => e => {
    const pos = showOnMap.indexOf(value)
    if (pos < 0)
      setShowOnMap([...showOnMap, value])
    else {
      const newShow = showOnMap.slice()
      newShow.splice(pos, 1)
      setShowOnMap(newShow)
    }
  }
  function fitMapBounds(e) {
    const allPolygons = []
    map.eachLayer(layer => {
      if (layer instanceof L.Polygon)
        allPolygons.push(layer)
    })
    if (allPolygons.length > 0) {
      const bounds = allPolygons[0].getBounds()
      allPolygons.forEach(polygon => {
        bounds.extend(polygon.getBounds())
      })
      map.flyToBounds(bounds, {
        padding: L.point(36, 36), animate: true,
      })
    }
  }
  return (
    <div className={classes.root}>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button
          className={classes.btnMyLoc}
          variant='outlined'
          onClick={() => { setMyLocation(myLocation === 'enabled' ? 'disabled' : 'finding') }}>
          { myLocation === 'finding' ?
            <CircularProgress size={24} />
          : <Tooltip title='Show My Location'><GpsFixed /></Tooltip>
          }
        </Button>
        <Button className={classes.btnFit} onClick={fitMapBounds}>
          <Tooltip title='Zoom to all Map Markers'><Fullscreen /></Tooltip>
        </Button>
      </ButtonGroup>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button className={classes.btnPubNfz} onClick={toggle('public-nfz')}>
          <Tooltip title='Public Airspace (Restricted)'><Warning /></Tooltip>
        </Button>
        <Button className={classes.btnPubEst} onClick={toggle('public-test-estate')}>
          <Tooltip title='Public Airspace (Drone Friendly)'><Gradient /></Tooltip>
        </Button>
        <Button className={classes.btnPriNfz} onClick={toggle('private-nfz')}>
          <Tooltip title='Private Airspace (Restricted)'><Map /></Tooltip>
        </Button>
        <Button className={classes.btnPriUse} onClick={toggle('private-ready-to-use')}>
          <Tooltip title='Private Airspace (Ready To Use)'><Layers /></Tooltip>
        </Button>
      </ButtonGroup>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button className={classes.btnPenFrz} onClick={toggle('pending-approval-frz')}>
          <Tooltip title='Pending Approval Airspace Reservation'>{frzIcon('pending-approval')}</Tooltip>
        </Button>
        <Button className={classes.btnAprFrz} onClick={toggle('approved-frz')}>
          <Tooltip title='Approved Airspace Reservation / Flight Geofence'>{frzIcon('approved')}</Tooltip>
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default withStyles(styles)(FrzMapControls)
