import { Fragment } from 'react'
import { trackerName } from '../../utils'
import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
  },
  left: {
    width: '62%',
  },
  right: {
    width: '38%',
  },
})

function LeftPanel({ classes, flight, drone, tracker }) {
  if (!drone || !flight)
    return null
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Typography variant='body2'>
          <b>{drone.drone_provider?.brand}</b> {drone.drone_model?.name}
        </Typography>
        <Typography variant='body2' gutterBottom>
          by <b>{drone.drone_provider?.name}</b> {
            drone.serial_number && <>(s/n: {drone.serial_number})</>
          }
        </Typography>
        <Typography variant='body2'>
          Pilot(s): { flight.pilots.map(p => (
            <span key={p.id}><b>{p.name}</b> ({p.username}) </span>
          )) }
        </Typography>
      </div>
      <div className={classes.right}>
        { flight.permits && flight.permits.length > 0 ?
          <Typography variant='body2' gutterBottom>
            Permit(s): { flight.permits.map((p, i) => (
              <span key={i}><b>{p.authority}</b> {p.ref} </span>
            )) }
          </Typography>
        :
          <Typography variant='body2' gutterBottom>
            No permits
          </Typography> 
        }
        { (tracker?.name || tracker.imei) &&
          <Typography variant='body2'>
            <b>{trackerName(tracker)}</b> mounted
          </Typography>
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(LeftPanel)
