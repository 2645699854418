/**
 * A wrapper for divIcon markers that accept JSX components as children.
 * Copied from https://github.com/jgimbel/react-leaflet-div-icon/issues/20
 */
import React, { Component } from 'react'
import { divIcon } from 'leaflet'
import PropTypes from 'prop-types'
import { Marker } from 'react-leaflet'

class JsxMarkerContent extends Component {
  onRef = ref => {
    if (ref) {
      const html = ref.innerHTML
      if (html !== this.previousHtml) {
        this.props.onRender(html)
        this.previousHtml = html
      }
    }
  }

  render() {
    const { onRender, children, ...rest } = this.props 
    return (
      <div {...rest} className='jsx-marker' style={{ display: 'none' }} ref={this.onRef}>
        {children}
      </div>
    )
  }
}

class JsxMarker extends Component {
  static propTypes = {
    position: PropTypes.object,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      html: null,
    }
  }

  onInsideRender(html) {
    this.setState({ html })
  }

  render() {
    const { position, children, className, onClick, ...rest } = this.props
    const { html } = this.state

    let marker = false
    if (html) {
      const icon = divIcon({
        className: className,
        html
      })
      marker = <Marker position={position} icon={icon} eventHandlers={{
        click: (e) => {
          onClick(e)
        },
      }} {...rest} />
    }

    return (
      <>
        <JsxMarkerContent onRender={html => this.onInsideRender(html)}>
          {children}
          {marker}
        </JsxMarkerContent>
      </>
    )
  }
}

export default JsxMarker