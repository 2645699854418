import { useGetNFZByIdQuery } from '../../api/airspace'

function Nfz({ id, name, ...rest }) {

  const { data, error, isLoading } = useGetNFZByIdQuery(id, {
    skip: !id
  })

  if (!id || isLoading)
    return ''
  if (error)
    return '(Unavailable NFZ)'
  if (data?.status !== 'success'){
    console.log('Invalid NFZ ID', id)
    return '(Invalid NFZ)'
  }

  const nfz = data?.data?.nfz || {}
  return <span {...rest}>{
    name && nfz.name
  }</span>
}

export default Nfz
