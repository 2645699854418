
export function getApprovedGeometry(frz, index = -1) {
  if (!frz) return null
  if (!frz?.approval_chain || frz?.approval_chain?.length === 0) {
    return frz.geometry ?? frz?.source_nfz?.geometry
  }
  let steps = getApprovedChain(frz, index)
  const lastApprovedGeometry = steps.reverse().find((ac) => ac.approval_geometry)
  if (lastApprovedGeometry) {
    return lastApprovedGeometry.approval_geometry
  } else {
    return frz.geometry ?? frz.source_nfz?.geometry
  }
}
export function getLastApprovedValidity(frz, index = -1) {
  if (!frz) return null

  if (!frz?.approval_chain || frz?.approval_chain?.length === 0) {
    return getValidity(frz)
  }
  let steps = getApprovedChain(frz, index)
  const lastApprovedValidity = steps.reverse().find((ac) => ac?.approval_edits?.validity)
  if (lastApprovedValidity) {
    return { start: new Date(lastApprovedValidity.approval_edits.validity[0].start), end: new Date(lastApprovedValidity.approval_edits.validity[0].end) }
  } else {
    return frz.validity[0]
  }
}

export function getLastApprovedAltitude(frz, index = -1) {
  if (!frz) return null

  if (!frz?.approval_chain || frz?.approval_chain?.length === 0) {
    return null
  }
  let steps = getApprovedChain(frz, index)
  const lastApprovedAltitude = steps.reverse().find((ac) => ac?.approval_edits?.altitude)
  if (lastApprovedAltitude) {
    return lastApprovedAltitude?.approval_edits?.altitude
  } else {
    return frz.altitude
  }
}

function getApprovedChain(frz , index=-1) {
  let steps = [...frz?.approval_chain]
  if (index !== -1) {
    steps = steps.slice(0, index + 1)
  }
  return steps
}
export function getValidity(frz) {
  if (frz?.validity?.length > 0) {
    return { start: new Date(frz?.validity[0].start), end: new Date(frz?.validity[0].end) }
  } else {
    return nextHour()
  }
}
// move to date-fns later. this doens't work over midnight.
export function nextHour() {
  const now = new Date()
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1)
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 2)
  return { start, end }
}