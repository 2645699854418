import { useState } from 'react'
import useNfzValidity from '../../components/useNfzValidity'
import PanelOpen  from './PanelOpen'
import PanelClose from './PanelClose'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
    right:  0,
    marginBottom: theme.spacing(2),
    marginRight:  theme.spacing(40),
    width: theme.spacing(24),
    display: 'flex',
    justifyContent: 'space-between',
  }
})

function NfzControls({ classes }) {
  const [ open, setOpen ] = useState(false)
  const [ nfzValidity,  ] = useNfzValidity('NfzControls')

  // TODO: setNfzValidity - how to edit? 
  //       slider? date time picker? Buttons like "Today", "This Week", "Last Week"?
  const start = nfzValidity.start * 1000
  const end   = nfzValidity.end   * 1000
  return (
    <div className={classes.root}>
      { open ?
        <PanelOpen
          start={start}
          end={end}
          onClose={() => { setOpen(false) }}
        />
      : <PanelClose
          start={start}
          end={end}
          onOpen={() => { setOpen(true) }}
        />
      }
    </div>
  )
}

export default withStyles(styles)(NfzControls)
