import { useState } from 'react'
import {
  useGetCompaniesQuery,
  useGetMyCompanyQuery,
} from '../../../api/accounts'
import XprizeFlights from './XprizeFlights'
import XprizeViolations from './XprizeViolations'
import {
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import {
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/lab'
import {
  Place,  // Place
  Flight, // Flight
  Gavel,  // Violations
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    top:    0,
    right:  0,
    marginRight: theme.spacing(2),
    marginTop:   theme.spacing(8),
    color: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.primary.dark,
    border: 'transparent',
    width:  theme.spacing(32),
    height: theme.spacing(6),
    cursor: 'pointer',
  },
  headerOpen: {
    borderRadius: '16px 16px 0 0',
  },
  headerClosed: {
    borderRadius: '16px',
  },
  body: {
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    width: theme.spacing(32),
  },
  bodyOpen: {
    maxHeight: `calc(100vh - ${theme.spacing(23)}px)`
  },
  bodyClosed: {
    maxHeight: 0
  },
  container: {
    lineHeight:    1.8,
    paddingLeft:   theme.spacing(2),
    paddingRight:  theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.white
  }
})

function XprizeControlPanel({
  classes,
  tracks,
  violationData,
  selectedFlightId,
  setSelectedFlightId,
  onToggleShowOnMap,
  onDroneClick,
  onTrackerClick,
}) {
  const [ open, setOpen ] = useState(true)
  const [ enabled, setEnabled ] = useState(['places', 'flights', 'violations'])

  // admin can find xprize companies, but normal companies can't, but know self is
  const { data: xpData } = useGetCompaniesQuery({ xprize_enabled: 'true' })
  const { data: myData } = useGetMyCompanyQuery()
  const myCompany        = myData?.data?.company   || {}
  const xprizeCompanies  = xpData?.data?.companies || []
  if (!xprizeCompanies.some(c => c.company_id === myCompany.company_id))
    xprizeCompanies.push(myCompany)

  function handleEnableChanged(event, values) {
    setEnabled(values)
    onToggleShowOnMap && onToggleShowOnMap(values)
  }

  return (
    <div className={classes.root}>
      <div
        onClick={e => { setOpen(!open) }}
        className={classes.header + ' ' + (open ? classes.headerOpen : classes.headerClosed)}>
        <Typography variant='body1'>XPRIZE Control Panel</Typography>
        { open ? <ExpandLess /> : <ExpandMore /> }
      </div>
      <div className={classes.body + ' ' + (open ? classes.bodyOpen : classes.bodyClosed)}>
        <div className={classes.container}>
          <ToggleButtonGroup
            size='large'
            value={enabled}
            onChange={handleEnableChanged}>
            <ToggleButton value='places'>
              <Tooltip title='Places'><Place /></Tooltip>
            </ToggleButton>
            <ToggleButton value='flights'>
              <Tooltip title='Flights'><Flight /></Tooltip>
            </ToggleButton>
            <ToggleButton value='violations'>
              <Tooltip title='Violations'><Gavel /></Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        { enabled.includes('violations') &&
          <XprizeViolations
            violationData={violationData}
            onDroneClick={onDroneClick}
            onTrackerClick={onTrackerClick}
          />
        }
        { enabled.includes('flights') &&
          <XprizeFlights
            xprizeCompanies={xprizeCompanies}
            tracks={tracks}
            selectedFlightId={selectedFlightId}
            setSelectedFlightId={setSelectedFlightId}
          />
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(XprizeControlPanel)
