//
// This is a temporary look up table for the types of FRZs ot help complete the MVP
// of the upgraded strategic decconflict section.
//

export const frzTypes = {
  'unspecified': {
    name: 'No Rules',
    description: 'No additional rules are applied to this reservation',
    rules: [],
    source: '',
    country: '',
  },
  'caas-class1ap': {
    name: 'CAAS - Class 1 AP',
    description: 'CAAS Class 1 Activity Permit',
    rules: [],
    source: 'https://www.caas.gov.sg/public-passengers/unmanned-aircraft',
    country: 'SG',
  },
  'caas-class2ap': {
    name: 'CAAS - Class 2 AP',
    description: 'CAAS Class 2 Activity Permit ',
    rules: [],
    source: 'https://www.caas.gov.sg/public-passengers/unmanned-aircraft',
    country: 'SG',
  },
  'caas-uato': {
    name: 'CAAS - UATO',
    description: 'CAAS Unmanned Aircraft Training and Assessment Organization',
    rules: [],
    source: 'https://www.caas.gov.sg/public-passengers/unmanned-aircraft',
    country: 'SG',
  },
  'caas-bvlos': {
    name: 'CAAS - BVLOS',
    description: 'CAAS Beyond Visual Line Of Sight (UOP)',
    rules: [],
    source: '',
    country: 'SG',
  },
  'caam-mcar': {
    name: 'CAAM - MCAR 2016',
    description: 'CAAM Malaysia Civil Aviation Regulation 140-144 (2016)',
    rules: [],
    source: 'https://www.caam.gov.my/public/unmanned-aircraft-system-uas/',
    country: 'MY',
  },
  'caam-rpto': {
    name: 'CAAM - RPTO',
    description: 'CAAM CAD6011(I) Remote Pilot Training Organization',
    rules: [],
    source: 'https://www.caam.gov.my/public/unmanned-aircraft-system-uas/',
    country: 'MY',
  },
  'caam-agri': {
    name: 'CAAM - Agriculture',
    description: 'CAAM CAD6011(II) Agricultural UAS Operations (AGR) Aerial Work Certificate (AWC)',
    rules: [],
    source: 'https://www.caam.gov.my/public/unmanned-aircraft-system-uas/',
    country: 'MY',
  },
  'caam-sup-pdra': {
    name: 'CAAM - SUP(PDRA)',
    description: 'CAAM CAD6011(V) Special UAS Project (Predefined Risk Assessment) for Carrying Dangerous Goods, Beyond Visual Line of Sight, or Research & Development',
    rules: [],
    source: 'https://www.caam.gov.my/public/unmanned-aircraft-system-uas/',
    country: 'MY',
  },
  'caam-sup-sora': {
    name: 'CAAM - SUP(SORA)',
    description: 'CAAM CAS6011(V) Special UAS Project (Specific Operations Risk Assessment) for Carrying Dangerous Goods, Beyond Visual Line of Sight, or Research & Development',
    rules: [],
    source: 'https://www.caam.gov.my/public/unmanned-aircraft-system-uas/',
    country: 'MY',
  },
  'caap-small': {
    name: 'CAAP PCAR - Small RPAS',
    description: 'CAAP Philippines Civil Aviation Regulation for RPAS less than 7kg',
    rules: [],
    source: 'https://caap.gov.ph/rpas-regulation/',
    country: 'PH',
  },
  'caap-large': {
    name: 'CAAP PCAR - Large RPAS',
    description: 'CAAP Philippines Civil Aviation Regulation for RPAS more than 7kg',
    rules: [],
    source: 'https://caap.gov.ph/rpas-regulation/',
    country: 'PH',
  },
}
