import { useState } from 'react'

import CfmsControlPanelFlightDialog from './CfmsControlPanelFlightDialog'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  Help,          // Unknown status
  Flight,        // Flight: PAUSE
  FlightTakeoff, // Flight: START / RESUME
  FlightLand,    // Flight: STOP
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  text: {
    color: theme.palette.common.white
  },
  subtitle: {
    paddingLeft: theme.spacing(2),
    paddingTop:  theme.spacing(2),
  }
})

function CfmsControlPanelFlightsList({ classes, showFlights, flights }) {

  const [ open, setOpen ] = useState(false)
  const [ selectedFlight, setSelectedFlight ] = useState(null)

  if (!showFlights)
    return null

  if (!flights || flights.length === 0)
    return <Typography variant='overline' className={classes.subtitle}>
      No Flights
    </Typography>

  const flightListItems = flights.map((flight, i) => {
    const avatar = {
      start:  <FlightTakeoff />,
      pause:  <Flight />,
      resume: <FlightTakeoff />,
      stop:   <FlightLand />,
    }[flight.status?.toLowerCase()] || <Help />

    return (
      <ListItem key={flight.flight_id || i} onClick={() => {
        setSelectedFlight(flight)
        setOpen(true)}}>
        <ListItemIcon>{avatar}</ListItemIcon>
        <ListItemText
          classes={{ secondary: classes.text }}
          primary={flight.status?.toUpperCase()}
          secondary={new Date(flight.last_updated_at).toLocaleString()}
        />
      </ListItem>
    )
  })

  return (<>
    <Typography variant='overline' className={classes.subtitle}>
      Current Flights
    </Typography>
    <List dense>
      {flightListItems}
    </List>
    <CfmsControlPanelFlightDialog 
      open={open}
      flight={selectedFlight}
      onClose={() => setOpen(false)}
    />
  </>)
}

export default withStyles(styles)(CfmsControlPanelFlightsList)
