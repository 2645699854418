import {
  useLazyGetDockModelsQuery,
} from '../../../api/aircraft'
import TextBox from '../../../components/Inputs/TextBoxV5'

function DockModelSelect({ model, setModel, error }) {
  const [ getDockModels ] = useLazyGetDockModelsQuery()

  function handleChange(model) {
    console.log('setting model', model)
    setModel(model)
  }
  return (
    <TextBox
      variant='standard'
      label='Dock Model'
      get={() => getDockModels({ providers: true })}
      getKey='dock_models'
      dataId='dock_model_id'
      dataLabel='name'
      value={model}
      error={!!error}
      helperText={error}
      onChange={handleChange}
    />
  )
}

export default DockModelSelect
