import { droneModelCategories } from '../../../utils'
import GridLabel from '../../components/GridLabel'
import GridField from '../../components/GridField'
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
  }
})

function CustomDroneModel({ classes, droneModel, customDroneModel, setCustomDroneModel }) {
  return (
    <div className={classes.root}>
      <Grid item xs={12} lg={12} container spacing={2} direction='row'>
        <Grid item xs={12} lg={12} container>
          <Button fullWidth variant='contained' color='primary' onClick={() => setCustomDroneModel(null)}>
            Revert to Standard Drone Model
          </Button>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <Typography variant='h6'>Enter custom drone model's details:</Typography>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <GridLabel>Name</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                variant='outlined'
                value={customDroneModel?.name || droneModel?.name}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel,
                  name: e.target.value
                }) }}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <GridLabel>Category</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
            <Select
              value={customDroneModel?.category || droneModel?.category}
              onChange={e => setCustomDroneModel({
                ...customDroneModel,
                category: e.target.value
              })}>
              { droneModelCategories.map(cat => (
                <MenuItem key={cat.type} value={cat.type}>{cat.label}</MenuItem>
              )) }
            </Select>
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <GridLabel>Subcategory</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                variant='outlined'
                value={customDroneModel?.subcategory || droneModel?.subcategory}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  subcategory: e.target.value
                }) }}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={3} lg={3} container>
          <GridLabel>Width</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.dimension?.width || droneModel?.dimension?.width}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  dimension: {
                    ...customDroneModel.dimension,
                    width: parseFloat(e.target.value)
                  }
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={3} lg={3} container>
          <GridLabel>Length</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.dimension?.length || droneModel?.dimension?.length}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  dimension: {
                    ...customDroneModel.dimension,
                    length: parseFloat(e.target.value)
                  }
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={3} lg={3} container>
          <GridLabel>Height</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.dimension?.height || droneModel?.dimension?.height}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  dimension: {
                    ...customDroneModel.dimension,
                    height: parseFloat(e.target.value)
                  }
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={3} lg={3} container>
          <GridLabel>Diagonal</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.dimension?.diagonal || droneModel?.dimension?.diagonal}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  dimension: {
                    ...customDroneModel.dimension,
                    diagonal: parseFloat(e.target.value)
                  }
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <GridLabel>Weight (kg)</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.weight || droneModel?.weight}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  weight: parseFloat(e.target.value)
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <GridLabel>Weight with batteries (kg)</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.weight_with_batteries || droneModel?.weight_with_batteries}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  weight_with_batteries: parseFloat(e.target.value)
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <GridLabel>Max flight time (mins)</GridLabel>
          <GridField>
            <FormControl variant='outlined' fullWidth>
              <TextField
                type='number'
                variant='outlined'
                value={customDroneModel?.max_flight_time || droneModel?.max_flight_time}
                onChange={e => { setCustomDroneModel({
                  ...customDroneModel, 
                  max_flight_time: parseInt(e.target.value)
                })}}
              />
            </FormControl>
          </GridField>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(CustomDroneModel)
