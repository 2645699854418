import { intervalToDuration, formatDuration, format } from 'date-fns'
import {
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore,
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  panel: {
    width:  theme.spacing(24),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    border: 'transparent',
    width:  theme.spacing(24),
    height: theme.spacing(6),
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
    borderRadius: theme.spacing(2, 2, 0, 0),
  },
  body: {
    overflow: 'scroll',
    maxHeight: theme.spacing(26),
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.common.black,
    scrollbarWidth: 'none'
  },
})

function PanelOpen({ classes, start, end, onClose }) {
  const tzOff = new Date().getTimezoneOffset()
  const tzDur = intervalToDuration({ start: 0, end: tzOff * -60000 })
  return (<>
    <div className={classes.panel}>
      <div className={classes.header} onClick={onClose}>
        <Typography variant='overline'>
          <b>Airspace Validity</b>
        </Typography>
        <IconButton onClick={onClose}>
          <ExpandMore />
        </IconButton>
      </div>
      <div className={classes.body}>
        <Typography variant='overline'>
          Start / End
        </Typography>
        <Typography variant='body1'>
          {start ? format(new Date(start), 'dd MMM yyyy HH:mm:ss') : ''}
        </Typography>
        <Typography variant='body1'>
          {end ? format(new Date(end), 'dd MMM yyyy HH:mm:ss') : ''}
        </Typography>
        <Typography variant='overline'>
          Time Zone
        </Typography>
        <Typography variant='body1'>
          {Intl.DateTimeFormat().resolvedOptions().timeZone}<br />
          GMT { tzOff < 0 ? '+' : '-'
          }{ tzDur.hours?.toString().padStart(2, '0') || '00'
          }:{ tzDur.minutes?.toString().padStart(2, '0') || '00'}
        </Typography>
        <Typography variant='overline'>
          Duration
        </Typography>
        <Typography variant='body1'>
          { formatDuration(intervalToDuration({ start, end })) }
        </Typography>
        <Divider />
        <Typography variant='caption'>
          <Info style={{ float: 'left', margin: '8 8 0 0' }} />
          Airspace currently includes Geofences, No Fly Zones (NFZ), Flight
          Reservation Zones (FRZ), and Test Estates / Friendly Zones.
        </Typography>
      </div>
    </div>
  </>)
}

export default withStyles(styles)(PanelOpen)
