import { useState } from 'react'
import { getDecodedAccessToken } from '../../api/accounts'
import { useLazyDownloadTracksQuery } from '../../api/tracker'
import { StyledDateTimePicker } from '../../components/StyledPickers'
import {
  Button,
  Typography,
} from '@material-ui/core'

function DownloadRawTracks() {
  const [ start,   setStart   ] = useState(null)
  const [ end,     setEnd     ] = useState(null)
  // const [ csvData, setCsvData ] = useState('')
  const [ message, setMessage ] = useState('')

  const { company_id } = getDecodedAccessToken()
  const [ downloadTracks ] = useLazyDownloadTracksQuery()

  function handleDownloadCSV(e) {
    e.preventDefault()

    setMessage('Downloading logs..')
    downloadTracks({ start: start.toISOString(), end: end.toISOString() })
    .then((r) => {
      if (r?.data?.status === 'fail' && r.data?.data?.telemetry) {
        setMessage(r.data.data.telemetry)
      } else if (r?.data?.status === 'fail' || r?.data?.status === 'error') {
        setMessage('Something went wrong')
      } else {
        // setCsvData(r.data)
        setMessage('Logs downloaded')
      }
    })
  }
  // useEffect(() => {
  //   if (csvData) {
  //     const blob = new Blob([csvData], { type: 'plain/text' })
  //     const fileDownloadUrl = URL.createObjectURL(blob)
  //     const link = document.createElement('a')

  //     const name = 'Logs ' + start + ' - ' + end + '.csv'
  //     link.setAttribute('download', name)
  //     link.href = fileDownloadUrl
  //     link.click()
  //     URL.revokeObjectURL(fileDownloadUrl)
  //   }
  // }, [csvData, start, end])

  return (<>
    <Typography variant='body1'>
      Your company ID is {company_id}.<br />
      <br />
      Select the start / end date to download tracks
      that belongs to your company.<br />
      <br />
    </Typography>
    <StyledDateTimePicker
      ampm
      label='Start Date / Time'
      value={start}
      onChange={(dt) => { setStart(dt) }}
    />
    <br />
    <br />
    <StyledDateTimePicker
      ampm
      label='End Date / Time'
      value={end}
      onChange={(dt) => { setEnd(dt) }}
    />
    <br />
    <br />
    <Button variant='contained' onClick={handleDownloadCSV}>
      Download
    </Button>
    <Typography>{message}</Typography>
  </>)
}

export default DownloadRawTracks
