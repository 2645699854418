import { red, yellow, blue, green } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import '@fontsource/roboto'

// MyDroneFleet Web's Site Theme to follow Style Guide (MyDroneFleets)
const myDroneFleetsTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main:  '#E93C2C',
      light: '#FF7257',
      dark:  '#AF0001'
    },
    secondary: {
      main:  '#246FF1',
      light: '#709DFF',
      dark:  '#0045BD'
    },
    error: {
      main: red.A100,
    },
    warning: {
      main: yellow.A700
    },
    info: {
      main: blue.A700
    },
    success: {
      main: green.A700
    },
    background: {
      default: '#23263C',
      paper:   '#4C4E67',
    },
    map: { // to harmonise (also in gcs-web)
      offWhite:   '#F4F4F4',
      // Public NFZs
      liverRed:   '#C0392B',
      freshRed:   '#ED2939',
      magenta:    '#CD006F',
      yellow:     '#FFCD35',
      lightGreen: '#7FFF00',
      // Public Geospatial
      darkGreen:  '#2E7D32',
      orange:     '#E67B3A',
      // Private NFZ (keep out)
      darkBlue:   '#0045BD',
      darkGrey:   '#666666',
      // Private NFZ (ready to use)
      green:      '#4CAF50',
      // Flight / Reservations (keep in)
      lightBlue:  '#4DDBFF',
      blue:       '#246FF1',
      grey:       '#DDDDDD',
      black:      '#000000',
    },
    tracker: { // to harmonise
      limeGreen:  '#CEFA05', // API / Web
      green:      '#4CAF50', // Tracker
      yellow:     '#FFCD35', // No GPS
      red:        '#F44336', // offline
    },
  }
})

export default myDroneFleetsTheme
