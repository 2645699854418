import Nfz from '../../../components/Text/Nfz'
import Drone from '../../../components/Text/Drone'
import Flight from '../../../components/Text/Flight'
import Tracker from '../../../components/Text/Tracker'
import {
  Button,
  Typography,
} from '@material-ui/core'

function RsafViolationToast({ info, ua_id, ackEnabled, onAck }) {
  const name = info.ua_telem_type === 'aircraft' ? <Drone   id={ua_id} name /> :
               info.ua_telem_type === 'tracker'  ? <Tracker id={ua_id} name /> :
               'Unknown aircraft'
  return (
    <div>
      <Typography><b>{name}</b> exceeded airspaces:</Typography>
      <ul>
        { Object.keys(info.areas).map(area_id => {
          if (info.areas[area_id].area_type === 'flight')
            return <li key={area_id}><Flight id={area_id} location /></li>
          else
            return <li key={area_id}><Nfz id={area_id} name /></li>
        } )}
      </ul>
      { ackEnabled &&
        <Button variant='contained' color='secondary' onClick={() => onAck(ua_id)}>
          Acknowledge
        </Button>
      }
    </div>
  )
}

export default RsafViolationToast
