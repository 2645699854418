import { useEffect, useState } from 'react'

function useRememberPanZoom(defaultCenter, defaultZoom) {
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(null)

  const updateCenter = newCenter => {
    const savedStr = `[ ${newCenter.lat}, ${newCenter.lng} ]`
    localStorage.setItem('mapCenter', savedStr)
    setCenter(newCenter)
  }

  const updateZoom = newZoom => {
    localStorage.setItem('mapZoom', newZoom)
    setZoom(newZoom)
  }

  useEffect(() => {
    if (center == null || zoom == null) {
      const savedCenter = JSON.parse(localStorage.getItem('mapCenter'))
      const savedZoom = JSON.parse(localStorage.getItem('mapZoom'))
      setCenter(savedCenter || defaultCenter)
      setZoom(savedZoom || defaultZoom)
    }
  }, [center, zoom, defaultCenter, defaultZoom])

  return { center, zoom, updateCenter, updateZoom }
}

export default useRememberPanZoom
