import { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import L from 'leaflet'
import { useMap } from 'react-leaflet'
import {
  Typography,
} from '@material-ui/core'
import {
  Home,
} from '@material-ui/icons'
import theme from '../../../utils/site-theme'

function CfmsFlightMarkers({ cfmsFlights, onClick }) {

  const map = useMap()
  const markers = useRef([])

  useEffect(() => {
    markers.current.forEach(m => m.removeFrom(map))

    if (!cfmsFlights || cfmsFlights.length === 0)
      return

    markers.current = cfmsFlights.map((flight, i) => {
      const icon = <div style={{ textAlign: 'center', width: '50px' }}>
        <Typography variant='body2'>{flight.status.toUpperCase()}</Typography>
        <Home style={{ color: theme.palette.secondary.main }} />
        <Typography variant='body2'>{flight.data_provider?.reference_data?.reference_id}</Typography>
      </div>
      const divIcon = L.divIcon({
        html: ReactDOMServer.renderToString(icon),
        className: 'no-white-square-please'
      })
      // return <Marker key={'m' + i} icon={divIcon} iconSize={[50, 50]}
      //   position={[ flight.home_location.lat, flight.home_location.lon ]} />
      return L.marker([ flight.home_location.lat, flight.home_location.lon ], {
        icon: divIcon,
        iconSize: [50, 50],
      }).addTo(map)
    })

  }, [cfmsFlights, map])

  return null
}

export default CfmsFlightMarkers