import { useState, useEffect, useRef } from 'react'
import { usePrevious } from '../../../utils/hooks'
import L from 'leaflet'
import * as turf from '@turf/turf'
import {
  useMap,
  FeatureGroup,
} from 'react-leaflet'
import EditorToolbar from './EditorToolbar'

function EditFeatureGroup({
  geometry,
  onCreated,
  onEdited,
  onDeleted,
  setGeoCenter,
  featureStyle = {},
  editing=false,
  edit = {},
  draw = {}
}) {
  const map = useMap()
  const featureGroupRef = useRef(null)
  const editorToolbarRef = useRef(null)
  
  const [geoJson, setGeoJson] = useState(null)
  const prevGeoJson = usePrevious(geoJson) 

  useEffect(()=>{
    if(geometry)
      setGeoJson({
        type: 'FeatureCollection',
        features: [geometry]
      })
  }, [geometry])

  useEffect(() => {
    if ((geoJson && prevGeoJson && !turf.booleanEqual(geoJson?.features[0], prevGeoJson?.features[0])) || (!prevGeoJson && geoJson)) {
      console.info('updated!')
      let layers = featureGroupRef.current.getLayers()
      for(let i = 0; i < layers.length; i++)
        featureGroupRef.current.removeLayer(layers[i])
      
      const geojsonParsed = L.geoJSON(geoJson, {
        style: featureStyle
      })
      geojsonParsed.eachLayer((geojsonLayer) => {
        featureGroupRef.current.addLayer(geojsonLayer)
      })

      map.fitBounds(geojsonParsed.getBounds())
    }
  }, [geoJson, featureStyle, map, prevGeoJson])

  useEffect(() => {
    if (geometry) {
      let polygon
      console.info(geometry)
      switch (geometry.type) {
        case 'Polygon':
          polygon = turf.polygon(geometry.coordinates)
          break
        case 'MultiPolygon':
          polygon = turf.multiPolygon(geometry.coordinates)
          break
        default:
          throw Error(('The value is neither polygon or multipolygon'))
      }
      const fcoll = { // Feature Collection to better match Editor's capability
        type: 'FeatureCollection',
        features: [polygon]
      }
      console.log('Updating fcoll', fcoll, featureGroupRef.current.toGeoJSON())
      if (featureGroupRef.current.toGeoJSON().features.length === 0 || !turf.booleanEqual(fcoll.features[0], featureGroupRef.current.toGeoJSON().features[0])) {
        setGeoJson(fcoll)
      }
      const centroid = turf.centroid(polygon).geometry.coordinates
      const centerLatLng = { lat: centroid[1], lng: centroid[0] }
      setGeoCenter(centerLatLng)
      map.flyTo(centerLatLng)
    } else {
      setGeoJson(null)
      setGeoCenter(null)
    }
  }, [geometry, map, setGeoCenter])

  function handleDrawChange(e, next) {
    const fgroup = featureGroupRef.current
    const geojson = fgroup.toGeoJSON()
    const centroid = turf.centroid(geojson).geometry.coordinates
    console.log('handleChange (not updating) e.layer', e.layer, 'fgroup', fgroup, 'geojson', geojson, 'centroid', centroid)
    const layers = fgroup.getLayers()
    // Short term hack: we know there are 2 polygons in the FG if a new one is drawn
    // or just 1 polygon if nothing existed. We only want the last one to survive.
    for (let i = 0; i < layers.length - 1; i++)
      fgroup.removeLayer(layers[i])
    
    const lastFeature = geojson.features[layers.length - 1]
    
    // convert circle drawing to polygon
    if (lastFeature.geometry.type === 'Point') {
      const bufferedFeature = turf.buffer(lastFeature, e.layer.getRadius() / 1000, { unit: 'kilometers' })
      return next(bufferedFeature.geometry)
    }
    /**
     * TODO: how to decide buffer size without prompt?
      if (lastFeature.geometry.type === 'LineString') {
        const bufferedFeature = turf.buffer(lastFeature, 0.1, { unit: 'kilometers' })
        return next(bufferedFeature.geometry)
      }
     */
    return next(lastFeature.geometry)
  }
  
  return (
    <FeatureGroup ref={featureGroupRef}>
      <EditorToolbar
        onMounted={(e)=>{
          editorToolbarRef.current = e._toolbars.edit
        }}
        onCreated={(e) => { handleDrawChange(e, onCreated) }}
        onEdited={(e) => { handleDrawChange(e, onEdited) }}
        onDeleted={(e) => { handleDrawChange(e, onDeleted) }}
        draw={draw}
        edit={edit}
      />

    </FeatureGroup>
  )
}

export default EditFeatureGroup



  // useEffect(() => {
  //   const layers = featureGroupRef.current?.getLayers()
  //   if (!layers)
  //     return
  //   if (layers.length > 1) {
  //     // TODO: remove the layer from the map (as if drawing a new FRZ)
  //   }
  //   if (layers.length === 0 && geometry) {
  //     L.geoJSON({
  //       type: 'FeatureCollection',
  //       features: [{
  //         type: 'Feature',
  //         properties: {},
  //         geometry,
  //       }]
  //     }).eachLayer(layer => {
  //       featureGroupRef.current?.addLayer(layer)
  //     })
  //   }
  //   // TODO: If !geometry, clear?
  // }, [geometry])