import { useState } from 'react'
import { format } from 'date-fns'
import { commonFormat, trackerName } from '../../../utils'
import { cfms_prefix } from './xprize-utils'
import Drone from '../../../components/Text/Drone'
import Tracker from '../../../components/Text/Tracker'
import Company from '../../../components/Text/Company'
import useGeolocation from '../../../components/useGeolocation'
import AreYouSureDialog from '../../../components/AreYouSureDialog'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import FlyItSafeScreenshot from './images/fis-flightid-example.jpeg'
const styles = theme => ({
  content: {
    display: 'flex',
  },
  grid: {
    padding: theme.spacing(0, 1)
  },
  tracks: {
    padding: theme.spacing(1),
    border: '1px solid grey',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  img: {
    width: theme.spacing(35),
  },
})

function XprizeStartFlight({
  classes,
  pilotsByCompany,
  dronesByCompany,
  trackers,
  geofences,
  activeFlights,
  onStart,
  tracks,
}) {
  const [ companyId,    setCompanyId    ] = useState('')
  const [ pilotId,      setPilotId      ] = useState('')
  const [ droneId,      setDroneId      ] = useState('')
  const [ trackerId,    setTrackerId    ] = useState('')
  const [ geofenceId,   setGeofenceId   ] = useState('')
  const [ cfmsFlightId, setCfmsFlightId ] = useState('')
  const [ aysOpen, setAysOpen ] = useState(false)

  const coords = useGeolocation()

  function handleStart() {
    // all active flights has status === 'flying'
    if (Object.values(activeFlights).find(f => f.drone.id === droneId)) {
      setAysOpen(true)
      return
    }

    const trackToPose = track => ({
      t:   new Date(track.t).getTime(),
      lat: parseFloat(track.lat),
      lng: parseFloat(track.lng),
      alt: track.alt / 100,
      agl: 0,
    })

    const drone = { id: droneId }
    if (trackerId)
      drone.tracker_ids = [trackerId]

    const takeoff = trackerId && tracks[trackerId] ?
      trackToPose(tracks[trackerId]) :
    droneId && tracks[droneId] ?
      trackToPose(tracks[droneId]) : undefined

    const home = coords && coords.latitude && coords.longitude ? {
      lat: coords.latitude,
      lng: coords.longitude
    } : undefined

    const flightToStart = {
      company_id: companyId,
      status:     'flying',
      drone,
      pilots:     [{ id: pilotId }],
      location:   geofenceId ? geofences[geofenceId].name : 'xprize sites',
      path: (takeoff || home) ? {
        home,
        takeoff,
      } : undefined,
      date:       Date.now(),
      duration:   { minutes: 0, seconds: 0 },
      permits:    cfmsFlightId ? [{
        authority: 'SG-CAAS',
        ref: cfms_prefix + cfmsFlightId
      }] : [],
      geofence: geofenceId ? geofences[geofenceId].geometry : undefined,
    }

    onStart({ flightToStart })
  }

  // Disabling cfmsFlightId.match(/[A-Z0-9]{6}/) to allow for flights with no CFMS
  const canStartFlight = ((droneId && tracks[droneId]) || (trackerId && tracks[trackerId]))
  // Flights with no tracks can get some tracks through inject-telemetry

  const _trackers = trackers.map(t => ({
    ...t,
    name: trackerName(t)
  })).sort((a, b) => a.name.localeCompare(b.name))

  return (<>
    <DialogTitle>Start Flight</DialogTitle>
    <DialogContent className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} className={classes.grid}>
          <TextField select fullWidth
            label='Pilot'
            value={pilotId}
            onChange={e => { setPilotId(e.target.value) }}>
            { Object.keys(pilotsByCompany).map(company_id => ([
              <ListSubheader>
                Pilots from {pilotsByCompany[company_id][0].company.name}
              </ListSubheader>,
              pilotsByCompany[company_id].map(p => (
                <MenuItem key={p.user_id} value={p.user_id}>
                  {p.name} ({p.email})
                </MenuItem>
              ))
            ])) }
          </TextField>
          <br /><br />
          <TextField select fullWidth
            label='Drone'
            value={droneId}
            onChange={e => {
              setDroneId(e.target.value)
              setCompanyId(Object.values(dronesByCompany).flat().find(d => d.drone_id === e.target.value).company_id)
            }}>
            { Object.keys(dronesByCompany).map(company_id => ([
              <ListSubheader>
                Drones from <Company id={company_id} name />
              </ListSubheader>,
              dronesByCompany[company_id].map(d => (
                <MenuItem key={d.drone_id} value={d.drone_id}>
                  {d.name}
                </MenuItem>
              ))
            ])) }
          </TextField>
          <br /><br />
          <Typography variant='body1' gutterBottom>
            If you are mounting a hardware tracker, please select it here,
            or leave it blank if you are sending telemetry via API.
          </Typography>
          <TextField select fullWidth
            label='Tracker'
            value={trackerId}
            onChange={e => { setTrackerId(e.target.value) }}>
            { _trackers.map(t => (
              <MenuItem key={t.tracker_id} value={t.tracker_id}>
                {t.name}
              </MenuItem>
            )) }
          </TextField>
          <br /><br />
          { (droneId || trackerId) &&
            <div className={classes.tracks}>
              <Typography variant='overline'>Tracks</Typography>
              { droneId &&
                <div>
                  { tracks[droneId] ?
                    <Typography variant='body2'>
                      Latest drone telemetry from <Drone id={droneId} name /> on {
                        tracks[droneId].t ? format(new Date(tracks[droneId].t), commonFormat) :
                        '(Invalid Time)'
                      }.
                    </Typography>
                  : <Typography variant='caption'>
                      No drone telemetry from <Drone id={droneId} name />
                    </Typography>
                  }
                </div>
              }
              { trackerId &&
                <div>
                  { tracks[trackerId] ?
                    <Typography variant='body2'>
                      Latest tracker telemetry from <Tracker id={trackerId} name /> on {
                        tracks[trackerId].t ? format(new Date(tracks[trackerId].t), commonFormat) :
                        '(Pending GPS Lock)'
                      }.
                    </Typography>
                  : <Typography variant='caption'>
                      No tracker telemetry from <Tracker id={trackerId} name />
                    </Typography>
                  }
                </div>
              }
              <br />
              { trackerId && tracks[trackerId] ?
                <Typography variant='body2'>
                  Takeoff will be set based on the tracker rid data:<br />
                  <br />
                  Lat / Long: {tracks[trackerId].lat}, {tracks[trackerId].lng}<br />
                  <br />
                  Altitude: {tracks[trackerId].alt / 100} meters AMSL
                </Typography>
              : droneId && tracks[droneId] ?
                <Typography variant='body2'>
                  Takeoff will be set based on the drone netrid data: <br />
                  <br />
                  Lat / Long: {tracks[droneId].lat }, {tracks[droneId].lng}<br />
                  Altitude: {tracks[droneId].alt / 100} meters AMSL
                </Typography>
              : <Typography variant='body2'>
                  Please turn on your drone / tracker prior to starting flight.
                </Typography>
              }
              { companyId &&
                <Typography variant='body2'>
                  <br />
                  Flight will be started under the <Company id={companyId} name /> company.
                </Typography>
              }
            </div>
          }
        </Grid>
        <Grid item xs={12} md={6} className={classes.grid}>
          <TextField select fullWidth
            label='Geofence'
            value={geofenceId}
            onChange={e => { setGeofenceId(e.target.value) }}>
            { Object.keys(geofences).map(nfz_id => (
              geofences[nfz_id].restriction === 'test-estate' && // because test estates are exempted by conformance service
              <MenuItem key={nfz_id} value={nfz_id}>
                {geofences[nfz_id].name}
              </MenuItem>
            )) }
          </TextField>
          <br /><br />
          <Typography variant='body1'>
            Unless exempted, before you fly, please register your intent to fly
            on the FlyItSafe mobile app by CAAS.
          </Typography>
          <br />
          <Typography variant='body1'>
            Copy the 6 character Flight ID from FlyItSafe below.
          </Typography>
          <div className={classes.imgContainer}>
            <img src={FlyItSafeScreenshot} alt='FlyItSafe Screenshot'
              className={classes.img} />
          </div>
          <TextField fullWidth
            variant='outlined'
            label='CFMS Flight ID from FlyItSafe'
            helperText='e.g. YQSOMB'
            value={cfmsFlightId}
            onChange={e => { setCfmsFlightId(e.target.value?.toUpperCase() || '') }}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button
        disabled={!canStartFlight}
        variant='contained'
        color='secondary'
        onClick={handleStart}>
        Start Flight
      </Button>
      { aysOpen &&
        <AreYouSureDialog
          open={aysOpen}
          onClose={e => setAysOpen(false)}
          title='Drone is currently airborne'
          message={Object.values(dronesByCompany).flat().find(d => d.drone_id === droneId).name +
            ' is currently flying. If this drone has already landed, please stop the flight first before starting a new one.'}
          onOK={e => setAysOpen(false)}
        />
      }
    </DialogActions>
  </>)
}

export default withStyles(styles)(XprizeStartFlight)
