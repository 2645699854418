import { useState, Fragment } from 'react'
import Marquee from 'react-fast-marquee'
import { getAdvisories } from '../../api/advisories'
import {
  Box,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: ({ shrinkDrawer }) => ({
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
    left:   0,
    right:  0,
    marginLeft:   theme.spacing(shrinkDrawer ? 24 : 36),
    marginBottom: theme.spacing(2),
    marginRight:  theme.spacing(66),
  }),
  panel: {
    height: theme.spacing(6),
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  header: {
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  title: {
    lineHeight: '1em',
  },
  datetime: {
    padding: theme.spacing(0.5, 2),
    color: theme.palette.text.secondary,
  },
  marquee: {
    backgroundColor: theme.palette.common.black,
  }
})

function RealTimeAdvisory({ classes, shrinkDrawer }) {
  const [ advisories, setAdvisories ] = useState(getAdvisories())

  return (
    <div className={classes.root}>
      <div className={classes.panel}
        onClick={() => { window.location = '/advisories' }}>
        <div className={classes.header}>
          <Typography variant='overline' className={classes.title}>
            <b>Advisory</b>
          </Typography>
        </div>
        <Marquee
          gradient={false}
          className={classes.marquee}
          pauseOnHover={true}
          speed={5}
          delay={2}
          onCycleComplete={() => { setAdvisories(getAdvisories()) }}>
          { advisories.length === 0 &&
            <>No real time advisory. Have a nice day ☀️</>
          }
          { advisories.map(a => (
            <Box key={a.date} sx={{ display: 'flex', alignItems: 'center'}}>
              <Typography variant='caption' className={classes.datetime}>
                { new Date(a.date).toLocaleString() }
              </Typography>
              <Typography variant='body2'>
                { a.message }
              </Typography>
            </Box>
          )) }
        </Marquee>
      </div>
    </div>
  )
}

export default withStyles(styles)(RealTimeAdvisory)