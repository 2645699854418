import { Scatter } from 'react-chartjs-2'
import wheel from '../../utils/colour-wheel'
import ChartOptions from './ChartOptions'

export default function OrientationChart({ trackers }) {

  if (!trackers)
    return null

  const
    rolDataset  = [],
    pitDataset  = [],
    yawDataset  = [],
    headDataset = []

  for (const imei in trackers) {
    let col = wheel.nextColour()
    rolDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.rol]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    pitDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.pit]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    yawDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.yaw]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    headDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.head]),
      backgroundColor: col,
      borderColor: col
    })
  }

  const rolOptions = ChartOptions()
  rolOptions.plugins.title.text = 'Roll'

  const pitOptions = ChartOptions()
  pitOptions.plugins.title.text = 'Pitch'

  const yawOptions = ChartOptions()
  yawOptions.plugins.title.text = 'Yaw'

  const headOptions = ChartOptions()
  headOptions.plugins.title.text = 'Heading (currently same as Yaw)'

  return (
    <>
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: rolDataset}}
        options={rolOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: pitDataset}}
        options={pitOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: yawDataset}}
        options={yawOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: headDataset}}
        options={headOptions}
      />
    </>
  )
}
