import ViolatingUa from '../components/ViolatingUa'
import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex:   1,
    bottom:   0,
    left:     theme.spacing(12),
    margin:   theme.spacing(0, 0, 8, 4),
    padding:  theme.spacing(2),
    color:    theme.palette.common.white,
    width:    `calc(100% - ${theme.spacing(46)}px)`,
  },
  body: {
    gap: theme.spacing(1),
  },
})

function RecentViolations({ classes, summary, show, onTrackerClick, onDroneClick}) {
  const violationItems = Object.keys(summary).map((ua_id) => {
    return <Grid item key={ua_id}>
      <ViolatingUa
        key={ua_id}
        ua_id={ua_id}
        summary={summary[ua_id]}
        onTrackerClick={onTrackerClick}
        onDroneClick={onDroneClick}
      />
    </Grid>
  })

  return <>{show && <div className={classes.root}>
      <Grid container className={classes.body} direction='row' alignItems='flex-end'>
        {violationItems}
      </Grid>
    </div >}</>
}

export default withStyles(styles)(RecentViolations)
