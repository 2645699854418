import { useMemo } from 'react'
import { useGetFlightsQuery } from '../../../api/aircraft'
import useCompanies from '../../../components/useCompanies'
import Pill         from '../../../components/Pill'
import FlightColumn    from './components/FlightColumn'
import ViolationColumn from './components/ViolationColumn'
import {
  List,
  ListItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  header: {
    display: 'flex',
  },
  flid: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(24)
  },
  viol: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(10)
  },
})

function ActiveFlights({ classes, summary, tracks, selectedFlightId, setSelectedFlightId }) {
  const { company_ids } = useCompanies()

  const { data, isLoading: loadingFlights } = useGetFlightsQuery({
    status: 'flying',
    company_ids,
  }, { skip: !company_ids })

  const flyingFlights = useMemo(() => Object.values(data?.entities || {}), [data])

  const lastTelem = useMemo(() => {
    let lastTelemCache = {}
    if (!loadingFlights) {
      for (const af of flyingFlights) {
        lastTelemCache[af.flight_id] = Array.isArray(af.drone?.tracker_ids) ?
          af.drone.tracker_ids.reduce(
            (a, v) => (a || tracks[v]), tracks[af.drone.id]
          ) :
          tracks[af.drone?.id] // lastTelemCache[f] = undefined if no telems at all
      }
    }

    return lastTelemCache
  }, [flyingFlights, tracks, loadingFlights])

  const flightRows = flyingFlights.map((flight) => (
    <ListItem key={flight.flight_id}
      button
      dense
      disableGutters
      selected={flight.flight_id === selectedFlightId}
      onClick={e => {
        setSelectedFlightId(flight.flight_id)
      }}>
      <div style={{ display: 'flex' }}>
        <FlightColumn
          id={flight.flight_id}
          drone={flight.drone.name}
          lastTelem={lastTelem[flight.flight_id]}
        />
        <ViolationColumn
          summary={summary}
          flight={flight}
        />
      </div>
    </ListItem>
  ))

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.flid}>Flight ID</div>
        <div className={classes.viol}>Violations</div>
      </div>
      { flightRows.length === 0 ?
        <div>
          <Pill text='No Active Flights' />
        </div>
      : <List>
          {flightRows}
        </List>
      }
    </div>
  )
}

export default withStyles(styles)(ActiveFlights)
