import { meterToFeet2dp } from '../../../utils'
import {
  InputAdornment,
  TextField,
} from '@material-ui/core'

function AltitudeText({ label, altitude, setAltitude, error, ...props }) {

  function strMeterToFeet(str) {
    const alt = parseFloat(str)
    return !isNaN(alt) ? meterToFeet2dp(alt) : '-'
  }

  return (
    <TextField
      label={label}
      variant='outlined'
      fullWidth
      value={altitude}
      error={!!error}
      helperText={error}
      type='number'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            m, {strMeterToFeet(altitude)}ft
          </InputAdornment>
        )
      }}
      onChange={e => {
        setAltitude(e.target.value)
      }}
      {...props}
    />
  )
}


export default AltitudeText
