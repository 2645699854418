import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useLazyGetDockModelByIdQuery,
  useCreateDockMutation,
  useUpdateDockMutation,
  useDeleteDockMutation,
} from '../../../api/aircraft'
import useCompanies    from '../../../components/useCompanies'
import TopXCloseButton from '../../../components/TopXCloseButton'
import AreYouSureDialog from '../../../components/AreYouSureDialog'
import DockModelSelect from './DockModelSelect'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

function DockDialog({ dock, onClose }) {
  const [ form,     setForm     ] = useState({
    name: '',
    serial_number: '',
  })
  const [ model,    setModel    ] = useState(null)
  const [ nameErr,  setNameErr  ] = useState('')
  const [ snErr,    setSnErr    ] = useState('')
  const [ modelErr, setModelErr ] = useState('')
  const [ aysOpen,  setAysOpen  ] = useState(false)

  const { company_id } = useCompanies()
  const [ getDockModelById ] = useLazyGetDockModelByIdQuery()
  const [ createDock ] = useCreateDockMutation()
  const [ updateDock ] = useUpdateDockMutation()
  const [ deleteDock ] = useDeleteDockMutation()

  useEffect(() => {
    if (dock) {
      setForm(dock)
      getDockModelById(dock.dock_model_id)
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status !== 'success') {
          console.log('Failed to find dock model', fulfilled)
          toast.warning('Failed to find dock model')
        } else {
          setModel(fulfilled.data.dock_model)
        }
      })
      .catch(e => {
        console.log('Error finding dock model', e)
        toast.error('Error finding dock model')
      })
    }
  }, [dock, getDockModelById])

  function handleCreate() {
    if (!form.name)
      return setNameErr('Name your dock')
    if (!model)
      return setModelErr('Select a dock model')
    if (!form.serial_number)
      return setSnErr('Copy your dock serial number')

    const newDock = {
      ...form,
      company_id, // temp (to auto add in backend based on req.user)
      dock_model_id: model?.dock_model_id,
    }
    createDock(newDock)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('Failed to create dock', fulfilled)
        toast.warning('Failed to create dock')
      } else {
        toast.success('Dock created')
        onClose()
      }
    })
    .catch(e => {
      console.log('Error creating dock', e)
      toast.error('Error creating dock')
    })
  }

  function handleUpdate() {
    if (!form.name)
      return setNameErr('Name your dock')
    if (!model)
      return setModelErr('Select a dock model')
    if (!form.serial_number)
      return setSnErr('Copy your dock serial number')

    const newDock = {
      ...form,
      dock_model_id: model?.dock_model_id,
    }
    updateDock(newDock)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('Failed to update dock', fulfilled)
        toast.warning('Failed to update dock')
      } else {
        toast.success('Dock updated')
        onClose()
      }
    })
    .catch(e => {
      console.log('Error updating dock', e)
      toast.error('Error updating dock')
    })
  }

  function handleDelete() {
    deleteDock(dock.dock_id)
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status !== 'success') {
        console.log('Failed to delete dock', fulfilled)
        toast.warning('Failed to delete dock')
      } else {
        toast.success('Dock deleted')
        onClose()
      }
    })
    .catch(e => {
      console.log('Error deleting dock', e)
      toast.error('Error deleting dock')
    })
  }

  return (<>
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        { dock ? `Updating ${dock.name}` : 'Create Dock' }
      </DialogTitle>
      <TopXCloseButton onClick={onClose} />
      <DialogContent>
        <Field
          label='Name'
          value={form.name}
          error={!!nameErr}
          helperText={nameErr}
          onChange={e => { setForm(prev => ({
            ...prev,
            name: e.target.value,
          })) }}
        />
        <DockModelSelect
          model={model}
          setModel={setModel}
          error={modelErr}
        />
        <Field
          label='Serial Number'
          value={form.serial_number}
          error={!!snErr}
          helperText={snErr}
          onChange={e => { setForm(prev => ({
            ...prev,
            serial_number: e.target.value,
          })) }}
        />
      </DialogContent>
      <DialogActions>
        { dock ? <>
          <Button onClick={() => { setAysOpen(true) }}>Delete Dock</Button>
          <Button onClick={handleUpdate}>Update Dock</Button>
        </> :
          <Button onClick={handleCreate}>Create Dock</Button>
        }
      </DialogActions>
    </Dialog>
    { aysOpen && 
      <AreYouSureDialog 
        open={true}
        onClose={() => { setAysOpen(false) }}
        title='Delete Dock'
        message='Are you sure you want to permanently delete this dock?'
        action='Delete Permanently'
        onOK={handleDelete}
      />
    }
  </>)
}

function Field(props) {
  return <TextField
    variant='standard'
    fullWidth
    {...props}
  />
}


export default DockDialog