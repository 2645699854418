import { useMemo, useState } from 'react'
import { startOfToday, endOfToday, format } from 'date-fns'
import { commonFormat } from '../../../utils'
import { prettyXprizeFlightId } from '../../plugins/xprize/xprize-utils'
import Pill from '../../../components/Pill'

import * as turf from '@turf/turf'

import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  Done,
  LocationOn,
  Today,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { useGetFlightsQuery } from '../../../api/aircraft'
import { getDecodedAccessToken } from '../../../api/accounts'

const styles = theme => ({
  body: {
    width: theme.spacing(36),
    display: 'flex',
    flexDirection: 'column'
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 2),
  },
  flightId: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  flightBody: {
    padding: theme.spacing(0, 2)
  }
})

function UpcomingFlights({
  classes,
  flights,
  selected,
  onSelect
}) {
  const company_id =  getDecodedAccessToken().company_id
  const [ filterPos,  setFilterPos  ] = useState(null)
  const [ filterTime, setFilterTime ] = useState(true)
  const {data: flyingFlights, isLoading: loadingFlights} = useGetFlightsQuery({
    status: 'preflight',
    company_ids: company_id
  }, {skip: !company_id})
  
  const preflights = useMemo(() => {
    let filtered = []
    if(loadingFlights || !flyingFlights?.entities){
      return []
    }
    if (filterTime) {
      filtered = Object.values(flyingFlights?.entities).filter(f =>
        (f.geofence_validity_start >= startOfToday().getTime() && f.geofence_validity_start <= endOfToday().getTime()) ||
        (f.geofence_validity_end >= startOfToday().getTime() && f.geofence_validity_end <= endOfToday().getTime())
      )
    } else {
      // TODO: impl load more button (load next week etc)
      // when implementing will need to account for validity in streaming updates
      filtered = Object.values(flyingFlights?.entities).filter(f =>
        f.geofence_validity_start >= startOfToday().getTime() ||
        f.geofence_validity_end >= startOfToday().getTime()
      )
    }

    if (filterPos) {
      const point = turf.point([filterPos.coords.longitude, filterPos.coords.latitude])
      filtered = filtered.filter(f => turf.booleanContains(f.geofence, point))
    }
    return filtered.sort((a,b)=>{
      return (a.date > b.date) ? 1 : -1
    })
  }, [filterTime, filterPos, flyingFlights, loadingFlights])

  return (
    <div className={classes.body}>
      <Divider />
      <div className={classes.filters}>
        <Typography variant='body2'>Upcoming Flights</Typography>
        <Chip
          size='small'
          label='Today'
          icon={<Today />}
          onClick={() => setFilterTime(!filterTime)}
          deleteIcon={<Done />}
          onDelete={filterTime ? () => {} : null}
          color={filterTime ? 'secondary' : 'default'}
        />
        <Chip
          size='small'
          label='Near You'
          icon={<LocationOn />}
          onClick={() => {
            if (filterPos)
              setFilterPos(null)
            else
              navigator.geolocation.getCurrentPosition(setFilterPos)
          }}
          deleteIcon={<Done />}
          onDelete={filterPos ? () => {} : null}
          color={filterPos ? 'secondary' : 'default'}
        />
      </div>
      <Box overflow='auto'>
        <List className={classes.flightBody}>
          { preflights.length === 0 ?
            <div>
              <Pill text='No Upcoming Flights' />
            </div>
          : preflights.map(f =>
              <ListItem key={f.flight_id}
                button
                dense
                disableGutters
                selected={selected === f.flight_id}
                onClick={() => onSelect(f.flight_id)}>
                <div className={classes.flightId}>
                  {prettyXprizeFlightId(f.flight_id)}
                </div>
                <ListItemText
                  primary={f.drone.name}
                  secondary={f.date > 0 ? format(f.date, commonFormat) :
                    f.geofence_validity_start > 0 ? format(f.geofence_validity_start, commonFormat) :
                    'Unknown schedule'}
                />
              </ListItem>
            )
          }
        </List>
      </Box>
    </div>
  )
}

export default withStyles(styles)(UpcomingFlights)
