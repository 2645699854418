import { withRouter }   from 'react-router'
import { format }       from 'date-fns'
import { commonFormat } from '../../../utils'
import DataTable        from '../../../components/DataTable'
import Company          from '../../../components/Text/Company'
import {
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getDecodedAccessToken } from '../../../api/accounts'

const styles = theme => ({
  root: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
})

function ApprovalView({ classes, history, frzs }) {

  const { user_id, company_id, app_roles } = getDecodedAccessToken()
  const mdfRoles = app_roles?.my_drone_fleets

  const approval_required_frzs = frzs
    .filter(frz => frz.status === 'pending-approval' && Array.isArray(frz.approval_chain))
    .filter(frz => {
      const next_approver_index = frz.approval_chain.findLastIndex(step => step.approved_on) + 1
      const next_approver = frz.approval_chain[next_approver_index]
      return next_approver &&
        (next_approver.approver_user_id === user_id ||
          (company_id === frz.company_id &&
          next_approver.approver_mdf_role?.some((r) => mdfRoles.includes(r))))
    })
    .sort((a, b) => b.validity[0].start - a.validity[0].start)

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          data={approval_required_frzs}
          columns={[
            { label: 'Company',  render: z => <Company id={z.company_id} /> },
            { label: 'Start',    render: z => z.validity.map((v, i) => <p key={i}>{format(v.start, commonFormat)}</p>) },
            { label: 'End',      render: z => z.validity.map((v, i) => <p key={i}>{format(v.end,   commonFormat)}</p>) },
            { label: 'Name',     render: z => z.name },
            { label: 'Aircraft', render: z => z.aircraft.name },
            { label: 'Pilots',    render: z => z.pilots.map(p => p.name) },
            { label: '', render: z => <div className={classes.actions}>
              <Button variant='outlined'
                onClick={e => { history.push(`/zones/${z.frz_id}`)}}>Review</Button>
            </div> }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default withRouter(withStyles(styles)(ApprovalView))
