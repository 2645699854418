import {
  IconButton,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Add, Map } from '@material-ui/icons'
import { history } from '../../../utils/store'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  controls: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  select: {
    padding: theme.spacing(1),
  }
})

function MainControls({ classes, view}) {
  function goToMap(){
    history.push('/zones?view=map')
  }
  function goToAdd(){
    history.push('/zones/new')
  }
  return (
    <div className={classes.root}>
      <Typography variant='h5'>
        { view === 'approval-required' ?
          'Airspace reservations that require my approval' :
          'My Airspace Reservations'
        }
      </Typography>
      <div className={classes.controls}>
        <IconButton onClick={goToMap}>
          <Map></Map>
        </IconButton>
        <IconButton onClick={goToAdd}>
          <Add></Add>
        </IconButton>

        {/* <FrzFilter
          filter={filter}
          onFilter={(newStatuses) => setFilter(newStatuses)}
        /> */}
        {/* <Select
          value={view}
          onChange={e => { setView(e.target.value) }}
          className={classes.select}>
          <MenuItem value='map'>Map View</MenuItem>
          <MenuItem value='schedule'>Schedule View</MenuItem>
          <MenuItem value='calendar'>Calendar View</MenuItem>
          <MenuItem value='approval-required'>Approval Required</MenuItem>
        </Select> */}
      </div>
    </div>
  )
}

export default withStyles(styles)(MainControls)
