import { useRef, useEffect, useMemo } from 'react'
import {
  useMap,
  GeoJSON,
} from 'react-leaflet'
import { frzColour } from '../../../utils'

function FrzPolygon({ classes, frz, colour, style, focusOnRender, onClick}) {
  const map = useMap()
  const geojsonRef = useRef()
  const statusColor = useMemo(() => frzColour(frz?.status), [frz])
  const geoData = useMemo(() => ({
    type: 'Feature',
    geometry: frz?.geometry
  }), [frz])

  useEffect(() => {
    if (geojsonRef.current) {
      geojsonRef.current.clearLayers().addData(geoData)
      if (focusOnRender && map) {
        const frzBounds = geojsonRef.current.getBounds()
        map.fitBounds(frzBounds)
      }
      geojsonRef.current?.eachLayer(layer => {
        layer.off('click')
        layer.on('click', (event)=>{
          if(onClick) return onClick(event)
        })
      })
    }
  }, [map, geoData, focusOnRender, onClick])

  return (
    <GeoJSON
      key={frz}
      ref={geojsonRef}
      data={geoData}
      style={{
        color: colour || statusColor,
        weight: 4,
        fillOpacity: 0.1,
        fillColor: colour || statusColor,
        ...(style || {})
      }}
    />
  )
}

export default FrzPolygon
