import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import {
  useLazyGetDroneByIdQuery,
  useUpdateDroneMutation,
} from '../../api/aircraft'

import TopXCloseButton from '../../components/TopXCloseButton'
import DroneAvatarPicker from './components/DroneAvatarPicker'
import DroneDeveloper from './components/DroneDeveloper'
import DroneModel     from './components/DroneModel'
import DroneName      from './components/DroneName'
import DroneProvider  from './components/DroneProvider'
import DroneTracklog  from './components/DroneTracklog'
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxBorders: {
    border: 1,
    borderColor: 'Text.primary',
    minHeight: '300px',
  }
})

function DroneDetails({ classes, drone, droneId, onClose }) {
  const [ tabValue, setTabValue ] = useState(0)
  const [ _drone, setDrone ] = useState(null)
  const [ getDroneById ] = useLazyGetDroneByIdQuery()
  const [ updateDrone  ] = useUpdateDroneMutation()

  useEffect(() => {
    if (drone)
      setDrone(drone)
    else if (droneId)
      getDroneById(droneId)
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status === 'success')
          setDrone(fulfilled.data.drone)
        else {
          console.log(fulfilled)
          toast.warning('Failed to load drone')
        }
      })
      .catch(rejected => {
        console.log(rejected)
        toast.error('Error loading drone')
      })
    else
      setDrone(null)
  }, [drone, droneId, getDroneById])

  if (!_drone)
    return null

  function handleSave(data) {
    updateDrone({ drone_id: drone.drone_id, ...data })
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      if (fulfilled.status !== 'success') {
        console.log(fulfilled)
        toast.warning('Failed to update drone')
      } else {
        toast.success('Drone updated')
        onClose()
      }
    })
    .catch((rejected) => {
      console.log('Update rejected: ', rejected)
      toast.error('Error updating drone')
    })
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DialogContent>
        <Grid container>
          <Grid item xs={4} className={classes.avatar}>
            <DroneAvatarPicker
              droneId={drone.drone_id}
              mediaId={_drone.avatar || _drone.drone_model?.avatar || ''}
              onUpload={(avatar) => setDrone({ ..._drone, avatar })}
            />
          </Grid>
          <Grid item xs={8}>
            <DroneName
              name={_drone.name}
              onSave={handleSave}
            />
            <Grid container>
              <Grid item xs={11}>
                <AppBar position='static' color='default'>
                  <Tabs
                    value={tabValue}
                    onChange={(e, v) => { setTabValue(v) }}
                    indicatorColor='secondary'
                    variant='fullWidth'>
                    <Tab label='Tracklog' />
                    <Tab label='Model' />
                    <Tab label='Provider' />
                    <Tab label='Developer' />
                  </Tabs>
                </AppBar>
                <Box bgcolor='dark' borderTop={0} className={classes.boxBorders} boxShadow={1}>
                  <TabPanel value={tabValue} index={0}>
                    <DroneTracklog drone={_drone} onSave={handleSave} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <DroneModel droneModel={_drone.drone_model} onSave={handleSave} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <DroneProvider droneProvider={_drone.drone_provider} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3} >
                    <DroneDeveloper drone={_drone} />
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default withStyles(styles)(DroneDetails)