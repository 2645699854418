import { Fragment } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function CfmsControlPanelFlightDialog({ classes, open, flight, onClose }) {
  if (!flight)
    return null

  return (
    <Dialog
      open={open}
      onClose={onClose}>
      <DialogTitle>Current Flight</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>Status</Grid>
          <Grid item xs={6}>{flight.status.toUpperCase()}</Grid>
          <Grid item xs={6}>Last updated at</Grid>
          <Grid item xs={6}>{new Date(flight.last_updated_at)?.toLocaleString()}</Grid>
          <Grid item xs={6}>Home Location</Grid>
          <Grid item xs={6}>{flight.home_location.lat} {flight.home_location.lon}</Grid>
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={6}>Data Provider</Grid>
          <Grid item xs={6}>{flight.data_provider?.name}</Grid>
          <Grid item xs={6}>Reference ID</Grid>
          <Grid item xs={6}>{flight.data_provider?.reference_data?.reference_id || '-'}</Grid>
          <Grid item xs={6}>OP Number</Grid>
          <Grid item xs={6}>{flight.data_provider?.extras?.op_number|| '-'}</Grid>
          <Grid item xs={6}>AP Number</Grid>
          <Grid item xs={6}>{flight.data_provider?.extras?.ap_number|| '-'}</Grid>
          <Grid item xs={6}>AP Class</Grid>
          <Grid item xs={6}>{flight.data_provider?.extras?.ap_class|| '-'}</Grid>
          <Grid item xs={6}>Received</Grid>
          <Grid item xs={6}>{new Date(flight.data_provider?.extras?.received_timestamp)?.toLocaleString() || '-'}</Grid>
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={12}><u>Status History</u></Grid>
          { flight.status_history.map((h, i) => {
            return (
              <Fragment key={i}>
                <Grid item xs={6}>{new Date(h.timestamp).toLocaleString()}</Grid>
                <Grid item xs={6}>{h.status.toUpperCase()}</Grid>
              </Fragment>
            )
          }) }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(CfmsControlPanelFlightDialog)
