import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { exactDigits } from '../utils'
import { useGetMyCompanyUsersQuery } from '../api/accounts'
import {
  useGetTrackerModelByIdQuery,
  useUpdateTrackerMutation,
} from '../api/tracker'
import TopXCloseButton from '../components/TopXCloseButton'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  ExpandMore,
  Info,
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  model: {
    padding: theme.spacing(2),
    border: '1px solid grey',
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2, 2, 0, 0),
  },
  textfield: {
    marginBottom: theme.spacing(1),
  },
  advanced: {
    boxShadow: 'none',
    border: '1px solid grey',
  },
  iconBg: {
    width:  theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  icon: {
    width:  theme.spacing(4),
    height: theme.spacing(4),
    color:  theme.palette.primary.main,
    margin: theme.spacing(1),
  },
})

function TrackerDialog({ classes, open, tracker, onClose }) {
  const { data } = useGetTrackerModelByIdQuery(tracker?.tracker_model_id, {
    skip: !(tracker?.tracker_model_id)
  })
  const tracker_model = data?.data?.tracker_model || {}

  const [ updateTracker ] = useUpdateTrackerMutation()
  const [ name,  setName  ] = useState('')
  const [ imsi,  setImsi  ] = useState('')
  const [ iccid, setIccid ] = useState('')
  const [ notes, setNotes ] = useState('')
  const [ owner, setOwner ] = useState('') // entire user object or empty string
  const [ assto, setAssto ] = useState('') // used to be email, now can be anything
  const [ formError, setFormError ] = useState({})
  const [excludeTracking, setExcludeTracking ] = useState(false)

  useEffect(() => {
    if (open && tracker) {
      setName(tracker.name         || '')
      setImsi(tracker.imsi         || '')
      setIccid(tracker.iccid       || '')
      setNotes(tracker.notes       || '')
      setOwner(tracker.owner       || '')
      setAssto(tracker.assigned_to || '')
      setExcludeTracking(tracker.exclude_conformance || false)
    }
  }, [open, tracker])

  async function handleEdit(e) {
    const errors = {}

    if (imsi && !exactDigits(imsi, 14, 15))
      errors.imsi = 'Between 14 and 15 digits is required for IMSI, e.g. 800001234567890'

    if (iccid && !exactDigits(iccid, 18, 22))
      errors.iccid = 'ICCID is required (between 18 and 22 digits), e.g. 123456789012343567890'

    setFormError(errors)

    if (Object.keys(errors).length > 0)
      return

    const newTracker = {
      ...tracker,
      name,
      imsi,
      iccid,
      notes,
      owner: owner ? { user_id: owner.user_id, name: owner.name } : null,
      assigned_to: assto,
      exclude_conformance: excludeTracking
    }

    updateTracker({ id: tracker.tracker_id, tracker: newTracker })
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      if (fulfilled.status !== 'success') {
        toast.error('Failed to update tracker')
      } else {
        toast.success('Tracker updated')
        onClose()
      }
    })
    .catch(rejected => {
      console.log('update rejected', rejected)
      toast.error('Error updating tracker')
    })
  }

  // Only support Edit from now on (add and delete do in customer central)
  if (!tracker)
    return null

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>Edit Tracker {tracker.serial_number}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} container alignContent='flex-start'>
            <Grid item xs={4}>Serial Number</Grid>
            <Grid item xs={8}>{tracker.serial_number || '-'}</Grid>
            <Grid item xs={4}>IMEI</Grid>
            <Grid item xs={8}>{tracker.imei || '-'}</Grid>
            <Grid item xs={4}>Puchase Date</Grid>
            <Grid item xs={8}>
              {tracker.sold_on ? format(new Date(tracker.sold_on), 'dd MMM yyyy') : '-'}
            </Grid>
            <Grid item xs={4}>Expected EOL Date</Grid>
            <Grid item xs={8}>
              {tracker.warranty_till ? format(new Date(tracker.warranty_till), 'dd MMM yyyy') : '-'}
            </Grid>
            <Grid container className={classes.model}>
              <Grid item xs={12}><b>Tracker Model</b></Grid>
              <Grid item xs={4}>Model Name</Grid>
              <Grid item xs={8}>{tracker_model.name || '-'}</Grid>
              <Grid item xs={4}>Category</Grid>
              <Grid item xs={8}>{tracker_model.category || '-'}</Grid>
              <Grid item xs={4}>Software Version</Grid>
              <Grid item xs={8}>{tracker_model.version || '-'}</Grid>
              <Grid item xs={4}>Weight</Grid>
              <Grid item xs={8}>{tracker_model.weight_gm || '-'} g</Grid>
              <Grid item xs={4}>Dimension</Grid>
              <Grid item xs={8}>{
                tracker_model.dimensions?.length_mm || '-'} x {
                tracker_model.dimensions?.width_mm || '-'} x {
                tracker_model.dimensions?.height_mm || '-'} (L x W x H)
              </Grid>
              { tracker_model.properties?.proxy_source && <>
                <Grid item xs={4}>Proxy Source</Grid>
                <Grid item xs={8}>{tracker_model.properties?.proxy_source}</Grid>
              </> }
              { tracker_model.properties?.modem_firmware_version && <>
                <Grid item xs={4}>Modem Firmware</Grid>
                <Grid item xs={8}>{tracker_model.properties?.modem_firmware_version}</Grid>
              </> }
              { tracker_model.properties?.battery_capacity_mah && <>
                <Grid item xs={4}>Battery Capacity</Grid>
                <Grid item xs={8}>{tracker_model.properties?.battery_capacity_mah} mAh</Grid>
              </> }
              { tracker_model.properties?.battery_life_seconds && <>
                <Grid item xs={4}>Battery Life</Grid>
                <Grid item xs={8}>{tracker_model.properties?.battery_life_seconds} seconds</Grid>
              </> }
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
              variant='outlined'
              label='Name'
              value={name}
              onChange={e => { setName(e.target.value) }}
              className={classes.textfield}
            />
            <OwnerSelectField
              owner={owner}
              onChange={(owner) => { setOwner(owner) }}
            />
            <TextField fullWidth
              variant='outlined'
              label='IMSI (15 digits)'
              value={imsi}
              error={!!formError.imsi}
              helperText={formError.imsi}
              onChange={e => { setImsi(e.target.value) }}
              className={classes.textfield}
            />
            <TextField fullWidth
              variant='outlined'
              label='ICCID (19-20 digits)'
              value={iccid}
              error={!!formError.iccid}
              helperText={formError.iccid}
              onChange={e => { setIccid(e.target.value) }}
              className={classes.textfield}
            />
            <TextField fullWidth multiline
              variant='outlined'
              label='Notes (optional)'
              value={notes}
              onChange={e => { setNotes(e.target.value) }}
              className={classes.textfield}
            />
            <TextField fullWidth
              variant='outlined'
              label='Assigned To (optional)'
              value={assto}
              onChange={(e) => { setAssto(e.target.value) }}
              className={classes.textfield}
            />
            <Accordion defaultExpanded={false} className={classes.advanced}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Advanced</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item>
                    <Box display='flex' alignItems='start'>
                      <div className={classes.iconBg}>
                        <Warning className={classes.icon} />
                      </div>
                      <div>
                        <Typography variant='subtitle1'><b>Exclude from conformance tracking</b></Typography>
                        <Typography variant='inherit'>
                          Checking this box will exclude trackers from conformance tracking. No violations will be triggered for these Trackers.
                        </Typography>
                      </div>
                      <Checkbox color='primary'
                        checked={excludeTracking}
                        onChange={e=>{
                          setExcludeTracking(e.target.checked)
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' variant='contained' onClick={handleEdit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function OwnerSelectField({ owner, onChange }) {
  const { data, error, isLoading } = useGetMyCompanyUsersQuery()

  if (!isLoading) {
    if (error) {
      console.log('Error fetching users', error)
      toast.error('Error fetching users')
    }
    else if (data.status !== 'success') {
      console.log('Failed fetching users', data)
      toast.error('Failed fetching users')
    }
  }

  const users = data?.data?.users
  if (!users)
    return null

  return <Box display='flex' alignItems='center' mb={1}>
    <TextField select fullWidth
      variant='outlined'
      label='Owner'
      value={owner?.user_id ?? 'none'}
      onChange={e => onChange(users.find(u => u.user_id === e.target.value))}>
      <MenuItem key='none' value='none'>None</MenuItem>
      { users.map(u => (
        <MenuItem key={u.user_id} value={u.user_id}>{u.name}</MenuItem>
      )) }
    </TextField>
    <Tooltip style={{ marginLeft: '8px' }}
      title='Note: when no flights are associated with this tracker, all violations will be sent to this Owner'>
      <Info />
    </Tooltip>
  </Box>
}

export default withStyles(styles)(TrackerDialog)

// Move to customer central form:
//
// if (!exactDigits(formData.imei, 15, 15))
//   errors.imei = 'Exactly 15 digits is required for IMEI, e.g. 800001234567890'
// if (!formData.serial_number || formData.serial_number.search(serialRegex) < 0)
//   errors.serial_number = 'Serial number is required in this format, e.g GRT-1234-5678'
// const serialRegex = /[A-Z]{3}-\d{4}-\d{4}/



