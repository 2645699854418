import { useEffect, useState, useMemo } from 'react'
import { history }  from '../../utils/store'
import StartFlightDialog from './StartFlightDialog'
import StopFlightDialog from './StopFlightDialog'
import {
  format,
  intervalToDuration,
} from 'date-fns'
import {
  commonFormat,
  meter1dp,
  meterToRoundFeet,
  prettyDuration,
} from '../../utils'
import * as turf from '@turf/turf'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
  },
  left: {
    width: '40%',
  },
  right: {
    width: '60%',
    paddingLeft: theme.spacing(1),
  },
  elapsed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
})

function RightPanel({ classes, flight, drone, droneTelemetry, trackerTelemetry, editGeofence, setEditingGeofence }) {
  const [ startFlightOpen, setStartFlightOpen ] = useState(false)
  const [ stopFlightOpen,  setStopFlightOpen  ] = useState(false)
  const [ timeElapsed, setTimeElapsed ] = useState()
  const flightStart = useMemo(() => flight.status === 'preflight' ?
    new Date(flight.geofence_validity_start) : // starting no sooner than this
    new Date(flight.date), [flight])           // started

  useEffect(() => {
    const id = setInterval(function cb() {
      const now = new Date()
      const duration = prettyDuration(intervalToDuration({ start: flightStart, end: now }))
      setTimeElapsed(duration)
      return cb
    }(), 1000)
    return () => clearInterval(id)
  }, [flightStart])

  let altitude = '-'
  let distance = '-'
  let height = '-'
  const lastTelem = trackerTelemetry || droneTelemetry || {}
  const takeoff = flight.path?.takeoff || {}

  if (lastTelem.alt) {
    altitude = lastTelem.alt / 100
    height = takeoff.alt ? (altitude - takeoff.alt) : 0
  }
  if (lastTelem.lat && lastTelem.lng && takeoff.lat && takeoff.lng) {
    const to = turf.point([ parseFloat(lastTelem.lng), parseFloat(lastTelem.lat) ])
    const from = turf.point([ takeoff.lng, takeoff.lat ])
    distance = turf.distance(from, to, { units: 'kilometers' }).toFixed(2)
  }

  return (<>
    <div className={classes.root}>
      <div className={classes.left}>
        <div className={classes.elapsed}>
          <Typography variant='body1'>
            <b>{timeElapsed}</b>
          </Typography>
          <Typography variant='caption'>
            { flight.status === 'preflight' ?
              <>to planned<br />flight</>
            : <>since<br />flight started.</>
            }
          </Typography>
        </div>
        <div style={{ width: '100%', padding: '4px' }}>
          { flight.status === 'preflight' ?
            <>
              <Button fullWidth
                variant='contained'
                color='secondary'
                onClick={() => setStartFlightOpen(true)}>
                Start Flight
              </Button>
              <StartFlightDialog
                flight={flight}
                open={startFlightOpen}
                requireTracker={!drone?.online}
                onClose={() => setStartFlightOpen(false)}
              />
            </>
          : flight.status === 'flying' ?
            <>
              <Button fullWidth
                variant='outlined'
                color='inherit'
                onClick={() =>{
                  setStopFlightOpen(true)
                }}>
                  Stop Flight
              </Button>
              <StopFlightDialog
                flight={flight}
                open={stopFlightOpen}
                onClose={() => setStopFlightOpen(false)}
              />
              <></>
            </>
          : flight.status === 'postflight' ?
            <Button fullWidth
              variant='contained'
              color='primary'
              onClick={() => history.push(`/flights/${flight.flight_id}`)}>
              Review Flight
            </Button>
          : <Typography variant='body2'>Invalid Status</Typography> }
        </div>
      </div>
      <div className={classes.right}>
        <Typography variant='caption'>
          Flight Started <b>{flightStart ? format(flightStart, commonFormat) : 'n/a'}</b>
        </Typography><br />
        { flight.path?.takeoff?.t && <>
          <Typography variant='caption'>
            Drone took off <b>{format(new Date(flight.path.takeoff.t), commonFormat)}</b>
          </Typography><br /></>
        }
        <Typography variant='caption'>
          2D Distance: <b>{distance}km</b>
        </Typography>
        <br />
        <Altitude
          alt={altitude}
          height={height}
          min={flight.geofence_min_alt}
          max={flight.geofence_max_alt}
        />
      </div>
    </div>
  </>)
}


const altStyles = theme => ({
  alt: ({ alt, min, max }) => ({
    backgroundColor: alt < min ? theme.palette.map.blue :
      alt > max ? theme.palette.map.freshRed : theme.palette.background.default
  }),
})

function Altitude({ alt, max, min, height }) {
  const classes = makeStyles(altStyles)({ alt, min, max })
  return (<>
    <Typography variant='caption' className={classes.alt}>
      AMSL: <b>
      { meter1dp(alt) || '-' }m { meterToRoundFeet(alt) || '-' }ft </b>
      AGL: <b>
      { meter1dp(height) || '-' }m { meterToRoundFeet(height) || '-' }ft</b>
    </Typography>
    <br />
    <Typography variant='caption'>
      Geofence Min: <b>
      { meter1dp(min) || '-' }m { meterToRoundFeet(min) || '-' }ft </b>
      Max: <b>
      { meter1dp(max) || '-' }m { meterToRoundFeet(max) || '-' }ft</b>
    </Typography>
  </>)
}

export default withStyles(styles)(RightPanel)
