import L from 'leaflet'
import {
  Button,
  ButtonGroup,
  Tooltip,
} from '@material-ui/core'
import {
  Place,
  Flight,
  Fullscreen,
  Map,
  SettingsOverscan,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => {
  const disabled = {
    color:           theme.palette.map.grey,
    backgroundColor: theme.palette.background.paper,
  }
  return {
    root: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    btnGrp: {
      height: theme.spacing(6),
      backgroundColor: theme.palette.common.black,
    },
    btnFit: disabled,
    btnPubNfz: ({ showOnMap }) => (showOnMap.includes('public-nfz') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.liverRed,
    } : disabled),
    btnPubEst: ({ showOnMap }) => (showOnMap.includes('public-test-estate') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.lightGreen,
    } : disabled),
    btnPriNfz: ({ showOnMap }) => (showOnMap.includes('private-nfz') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.darkBlue,
    } : disabled),
    btnGeofence: ({ showOnMap }) => (showOnMap.includes('flight-geofence') ? {
      color:           theme.palette.common.white,
      backgroundColor: theme.palette.map.blue,
    } : disabled),
  }
}

function MapControls({
  classes,
  map,
  showOnMap,
  setShowOnMap,
}) {
  const toggle = value => e => {
    const pos = showOnMap.indexOf(value)
    if (pos < 0)
      setShowOnMap([...showOnMap, value])
    else {
      const newShow = showOnMap.slice()
      newShow.splice(pos, 1)
      setShowOnMap(newShow)
    }
  }
  function fitMapBounds(e) {
    const allPolygons = []
    map.eachLayer(layer => {
      if (layer instanceof L.Polygon)
        allPolygons.push(layer)
    })
    if (allPolygons.length > 0) {
      const bounds = allPolygons[0].getBounds()
      allPolygons.forEach(polygon => {
        bounds.extend(polygon.getBounds())
      })
      map.flyToBounds(bounds, {
        padding: L.point(36, 36), animate: true,
      })
    }
  }
  return (
    <div className={classes.root}>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button className={classes.btnFit} onClick={fitMapBounds}>
          <Tooltip title='Zoom to all Map Markers'><Fullscreen /></Tooltip>
        </Button>
      </ButtonGroup>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button className={classes.btnPubNfz} onClick={toggle('public-nfz')}>
          <Tooltip title='Public Airspace (Restricted)'><Place /></Tooltip>
        </Button>
        <Button className={classes.btnPubEst} onClick={toggle('public-test-estate')}>
          <Tooltip title='Public Airspace (Drone Friendly)'><Flight /></Tooltip>
        </Button>
        <Button className={classes.btnPriNfz} onClick={toggle('private-nfz')}>
          <Tooltip title='Private Airspace (Restricted)'><Map /></Tooltip>
        </Button>
      </ButtonGroup>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button className={classes.btnGeofence} onClick={toggle('flight-geofence')}>
          <Tooltip title='Flight Geofence'><SettingsOverscan /></Tooltip>
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default withStyles(styles)(MapControls)
