import { intervalToDuration, formatDuration } from 'date-fns'
import {
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ExpandLess,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  leftPanel: {
    width:  theme.spacing(18),
    height: theme.spacing(6),
    overflow: 'hidden',
    backgroundColor: theme.palette.common.black,
  },
  rightPanel: {
    width:  theme.spacing(6),
    height: theme.spacing(6),
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    width: '100%',
  },
  title: {
    lineHeight: '1em',
  },
  body: {
    padding: theme.spacing(0.5, 1),
  }
})

function PanelClose({ classes, start, end, onOpen }) {
  return (<>
    <div className={classes.leftPanel} onClick={onOpen}>
      <div className={classes.header}>
        <Typography variant='overline' className={classes.title}>
          <b>Airspace Validity</b>
        </Typography>
      </div>
      <div className={classes.body}>
        { formatDuration(intervalToDuration({ start, end })) }
      </div>
    </div>
    <div className={classes.rightPanel}>
      <IconButton onClick={onOpen}>
        <ExpandLess />
      </IconButton>
    </div>
  </>)
}

export default withStyles(styles)(PanelClose)
