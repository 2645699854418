import Sockette  from 'sockette'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { makeQueryString, makeWssUrl, standardHeaders } from './api-utils'
import { advise } from './advisories'

const CONFORMANCE_HTTP_API = new URL(process.env.REACT_APP_CONFORMANCE_HTTP_API).href
const CONFORMANCE_WS_API = new URL(process.env.REACT_APP_CONFORMANCE_WS_API).href

let violation_ws      // Sockette
let violation_real_ws // WebSocket inside
const wsCache = {}
// Let violations always be sorted by created_on, system wide
const violation_adapter = createEntityAdapter({
  selectId: violation => violation.violation_id,
  sortComparer: (a, b) => a.created_on.localeCompare(b.created_on)
})

async function openViolationWS(url) {
  return new Promise(function (resolve, reject) {
    if (wsCache[url])
      return resolve()
    violation_ws = wsCache[url] = new Sockette(url, {
      timeout: 10000,
      maxAttempts: Infinity,
      onopen: e => {
        console.log('violation ws connected')
        violation_real_ws = e.target
        resolve(violation_ws)
      },
      onreconnect: e => console.log('violation ws reconnecting'),
      onclose: e => console.log('violation ws close'),
      onerror: e => console.log('violation ws error', e),
    })
  })
}

export function sendWS(obj) {
  violation_ws ? violation_ws.json(obj) : console.log('violation ws not open to send', obj)
}

export const conformanceApi = createApi({
  reducerPath: 'conformanceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: CONFORMANCE_HTTP_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Violations'],
  endpoints: (builder) => ({
    getViolations: builder.query({
      query: (params) => (`/violations${makeQueryString(params, [
        'company_ids',
        'created_start',
        'created_end',
        'ua_ids',
      ])}`),
      async transformResponse(response, meta, arg) {
        // console.log('getViolations transformResponse response', response)
        if (response.status === 'success') {
          return violation_adapter.addMany(
            violation_adapter.getInitialState(),
            response.data.violations
          )
        } else {
          console.log('failed to transform violation', response, meta, arg)
          return violation_adapter.getInitialState()
        }
      },
      providesTags: (result, error, arg) => {
        // console.log('getViolations providesTags result', result)
        return result ?
          [...result.ids.map(id => ({ type: 'Violations', id })), 'Violations'] :
          ['Violations']
      },
      async onCacheEntryAdded(arg, { getCacheEntry, updateCachedData, cacheEntryRemoved, cacheDataLoaded }) {
        await cacheDataLoaded
        if (!(arg?.company_ids))
          return
        const ws_url = makeWssUrl(`${CONFORMANCE_WS_API}/violations`, {
          company_ids: arg?.company_ids
        })
        await openViolationWS(ws_url)
        if (!violation_ws || !violation_real_ws)
          return console.log('violation ws did not open', ws_url)

        let batchData = {}
        let batchInterval = undefined

        violation_real_ws.addEventListener('message', e => {
          let event
          try {
            event = JSON.parse(e.data)
          } catch(err) {
            return console.log('Invalid violation event received', e.data, err)
          }
          if (!event)
            return console.log('Bad violation event received (see data):', e)

          const violation = event.data?.violations
          if (!violation)
            return console.log('No violation data found in event', event)

          const v_id  = violation.violation_id

          if (event.event_type === 'CREATED' || event.event_type === 'UPDATED') {
            batchData[v_id] = violation
          }
          else if (event.event_type === 'DELETED' && batchData[v_id]) {
            delete batchData[v_id]
          }
          if (batchInterval)
            return

          batchInterval = setTimeout(function() {
            batchInterval = undefined
            updateCachedData(draft => {
              const violation_count = Object.keys(batchData).length
              // console.log('batch updating violations', batchData)
              violation_adapter.upsertMany(draft, batchData)
              advise(`${violation_count} violations updated`)
            })
            batchData = {}
          }, 3000)
        })
        // Every 10 minutes, sweep the cache
        setInterval(() => {
          const cache = Object.values(getCacheEntry()?.data?.entities || {})
          const oldIds = []
          const tenMinAgo = Date.now() - 600000

          cache.forEach(v => {
            if (new Date(v.created_on).getTime() < tenMinAgo)
              oldIds.push(v.violation_id)
          })
          if (oldIds.length > 0) {
            console.log('violation cache size', cache.length, 'cleaning out', oldIds.length)
            updateCachedData(draft => {
              violation_adapter.removeMany(draft, oldIds)
            })
          }
        }, 600000)

        await cacheEntryRemoved
        console.log('violation cache entry removed, refuse to close ws')
        // violation_ws.close()
      },
    }),
  })
})

export const {
  useGetViolationsQuery,
} = conformanceApi
