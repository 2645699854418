import TooltipIcon from '../../components/TooltipIcon'
import {
  Grid,
  IconButton,
} from '@material-ui/core'
import {
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  field: {
    display: 'flex',
    alignItems: 'center'
  },
})

function GridField({ classes, children, onMoreInfo }) {
  if (onMoreInfo)
    return (<>
      <Grid item xs={10} md={7} className={classes.field}>
        <div style={{ width: '100%'}}>
          {children}
        </div>
      </Grid>
      <Grid item xs={2} md={1} className={classes.field}>
        <IconButton onClick={onMoreInfo}>
          <TooltipIcon title='More Info' icon={<Info />} />
        </IconButton>
      </Grid>
    </>)
  return (
    <Grid item xs={12} md={8} className={classes.field}>
      <div style={{ width: '100%'}}>
        {children}
      </div>
    </Grid>
  )
}

export default withStyles(styles)(GridField)
