import { useState, useEffect } from 'react'
import { stableSort, getComparator } from '../utils'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const styles = theme => ({
  tableContainer: {
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: theme.spacing(88),
  },
})

function DataTable({
  classes,
  columns,
  data,
  sort,
  initialRowsPerPage,
}) {
  const [ _data,       setData        ] = useState([])
  const [ order,       setOrder       ] = useState('desc')
  const [ orderBy,     setOrderBy     ] = useState('first')
  const [ page,        setPage        ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(5)

  useEffect(() => {
    if (data) {
      setData([ ...data ])
      setPage(0)
    }
  }, [data])

  useEffect(() => {
    if (sort) {
      setOrder(sort.order)
      setOrderBy(sort.by)
    }
  }, [sort])

  useEffect(() => {
    if (initialRowsPerPage)
      setRowsPerPage(initialRowsPerPage)
  }, [initialRowsPerPage])

  if (!_data)
    return null

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (<>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            { columns.map((col, i) => (
              <StyledTableCell key={i}>{col.label}</StyledTableCell>
            )) }
          </TableRow>
        </TableHead>
        <TableBody>
          { stableSort(_data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, i) => (
            <StyledTableRow key={i}>
              { columns.map((col, j) => (
                <StyledTableCell key={j}>
                  { col.render ? col.render(row) :
                    col.key    ? row[col.key]    : '-'
                  }
                </StyledTableCell>
              )) }
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      component='div'
      count={_data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </>)
}

export default withStyles(styles)(DataTable)
