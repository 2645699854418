import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Card,
  makeStyles
} from '@material-ui/core'
import {
  Check,
  Schedule,
  Create,
  Close,
  ChevronLeft,
  ChevronRight 
} from '@material-ui/icons'
import ApprovalProgressBar from './FrzApprovalProgressBar'
import ApprovalStep from './FrzApprovalStep'
import {
  useApproveFRZMutation,
  useRejectFRZMutation,
  useSubmitFRZForApprovalMutation
} from '../../../api/airspace'
import AreYouSureDialog from '../../../components/AreYouSureDialog'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../../utils/store'
import { getLastApprovedAltitude, getLastApprovedValidity } from '../../../utils/frz'

const useStatusStyles = makeStyles(theme => ({
  approved: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main
  },
  pending: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main
  },
  rejected: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main
  },
  draft: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main
  }
}))

function FrzStatus({ status }) {
  const classes = useStatusStyles()
  const className = clsx({
    [classes.approved]: status === 'approved',
    [classes.pending]: status === 'pending-approval',
    [classes.rejected]: status === 'rejected',
    [classes.draft]: status === 'draft'
  })
  return (
    <Box display='flex' alignItems='center' className={className} borderRadius={99} px={2}>
      {
        status === 'approved' ? <Check fontSize='small'/>
        : status === 'pending-approval' ? <Schedule fontSize='small'/>
        : status === 'rejected' ? <Close fontSize='small' />
        : <Create fontSize='small'/>
      }
      <Box width={8} />
      <Typography variant='subtitle1'>
        {
          status === 'approved' ? 'Approved'
          : status === 'pending-approval' ? 'Pending'
          : status === 'rejected' ? 'Rejected'
          : 'Draft'
        }
      </Typography>
    </Box>
  )
}

function FrzApprovalChain({ frz , geometry, setSelectedStep, approvalEdits }) {
  const progress = frz.approval_chain.findLastIndex(s => s.approved_on || s.rejected_on)
  const rejected = frz.status === 'rejected'
  const [ stepIndex, setStepIndex ] = useState(!rejected && progress < frz.approval_chain.length-1 ? progress+1 : progress)

  const [ aysOpen, setAysOpen ] = useState(null)
  const [ approveFRZ, approveResult ] = useApproveFRZMutation()
  const [ rejectFRZ, rejectResult ] = useRejectFRZMutation()
  const [ submitFRZ, submitResult ] = useSubmitFRZForApprovalMutation()
  const [ notes, setNotes ] = useState()
  useEffect(()=>{ 
    setSelectedStep(stepIndex)
  }, [stepIndex, setSelectedStep])
  function handleApproveFrz() {
    setAysOpen(false)
    let approval_edits = {...approvalEdits}
    if(approval_edits.altitude){
      if(!approval_edits.altitude.min){
        approval_edits.altitude.min = getLastApprovedAltitude(frz)?.min
      }
      if(!approval_edits.altitude.max){
        approval_edits.altitude.max = getLastApprovedAltitude(frz)?.max
      }
    }
    if(approval_edits.validity){
      approval_edits.validity = [{
        start: approvalEdits.validity.start.getTime() || getLastApprovedValidity(frz),
        end: approvalEdits?.validity?.end?.getTime() || getLastApprovedValidity(frz)
      }]
    }
    approveFRZ({ frz_id: frz.frz_id, notes, geometry, edits: approval_edits })
    .unwrap()
    .then(result => {
      console.log('approve result', result)
      if (result.status !== 'success') throw result.data.frz
      else window.location.reload()
    })
    .catch(err => {
      console.log('approve failed', err)
      toast.error(`Failed to approve reservaton`)
    })
  }

  function handleRejectFrz() {
    setAysOpen(false)
    rejectFRZ({ frz_id: frz.frz_id, notes })
    .unwrap()
    .then(result => {
      console.log('reject result', result)
      if (result.status !== 'success') throw result.frz
      else window.location.reload()
    })
    .catch(err => {
      console.log('reject failed', err)
      toast.error(`Failed to reject reservaton`)
    })
  }

  function handleSubmitFrz() {
    submitFRZ({ frz_id: frz.frz_id })
    .unwrap()
    .then(result => {
      console.log('submit result', result)
      if (result.status !== 'success') throw result.frz
      else window.location.reload()
    })
    .catch(err => {
      console.log('submit failed', err)
      toast.error(`Failed to submit reservaton for approval`)
    })
  }

  return (
    <Card>
      <Box p={5}>
        <Box display='flex' alignItems='center'>
          <Box pr={2}>
            <Typography variant='h6'>
              <b>{frz.name}</b>
            </Typography>
          </Box>
          <FrzStatus status={frz.status}/>
        </Box>
        {
          frz.status === 'draft' ?
          <Box display='flex' flexDirection='column' minHeight={420} alignItems='center' justifyContent='center'>
            <Typography variant='h6' align='center'>
              This reservation has not been submitted for approval yet
            </Typography>
            <Box height={25}/>
            <Button
              variant='outlined'
              startIcon={<Check />}
              onClick={handleSubmitFrz}
              disabled={submitResult.isLoading}>
              Submit For Approval
            </Button>
            <Box height={10}/>
            <Button variant='outlined' startIcon={<Create />} onClick={
              ()=> history.push(`/zones/${frz?.frz_id}/edit?step=0`)
            }>
              Resume Editing
            </Button>
          </Box> :
          frz.approval_chain?.length > 0 ?
          <Box display='flex' flexDirection='column' minHeight={420}>
            <ApprovalProgressBar
              stepCount={frz.approval_chain.length}
              selected={stepIndex}
              progress={progress}
              rejected={rejected}
            />
            <ApprovalStep
              step={frz.approval_chain[stepIndex]}
              onClickApprove={() => setAysOpen('approve')}
              onClickReject={() => setAysOpen('reject')}
              onChangeNotes={value => setNotes(value)}
              isLoading={approveResult.isLoading || rejectResult.isLoading}
              isActiveStep={stepIndex === progress + 1}
              isFrzRejected={rejected}
            />
            {
              frz.approval_chain.length > 1 &&
              <Box display='flex' justifyContent='flex-end' mt='auto'>
                <ButtonGroup>
                  <Button onClick={() => setStepIndex(stepIndex-1)} disabled={!frz.approval_chain[stepIndex-1]}><ChevronLeft/></Button>
                  <Button onClick={() => setStepIndex(stepIndex+1)} disabled={!frz.approval_chain[stepIndex+1]}><ChevronRight/></Button>
                </ButtonGroup>
              </Box>
            }
          </Box>
          :
          <Box display='flex' flexDirection='column' minHeight={420}>
            <Box my='auto'>
              <Typography variant='h6' align='center'>
                No past approval chain
              </Typography>
            </Box>
          </Box>
        }
      </Box>
      {
        aysOpen === 'approve' &&
        <AreYouSureDialog
          open={aysOpen}
          onClose={() => setAysOpen(false)}
          title={`Confirm Approve "${frz.name}"`}
          message='Are you sure you want to approve this reservation? This action cannot be undone.'
          action='Yes, Approve'
          onOK={handleApproveFrz}
        />
      }
      {
        aysOpen === 'reject' &&
        <AreYouSureDialog
          open={aysOpen}
          onClose={() => setAysOpen(false)}
          title={`Confirm Reject "${frz.name}"`}
          message='Are you sure you want to reject this reservation? This action cannot be undone.'
          action='Yes, Reject'
          onOK={handleRejectFrz}
        />
      }
    </Card>
  )
}

export default FrzApprovalChain