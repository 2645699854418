import { useContext }      from 'react'
import { AccountsContext } from '../AccountsContextProvider'
import { getAccountsURL }  from '../../api/accounts'

import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  Warning,
} from '@material-ui/icons'

function go(pathname) {
  const url = getAccountsURL()
  url.pathname = pathname
  window.open(url, 'plex-accounts')
}

function SubscriptionListItem({ classes, width }) {
  const accounts = useContext(AccountsContext)

  if (accounts?.subscriptions?.findIndex(s => s.app === 'my_drone_fleets') >= 0)
    return null

  return (
    <ListItem button
      onClick={() => { go('/apps/all') }}
      style={{ width }}>
      <ListItemIcon><Warning /></ListItemIcon>
      <ListItemText primary='Subscription Expired' />
    </ListItem>
  )
}

export default SubscriptionListItem
