import { withRouter }   from 'react-router'
import { format }       from 'date-fns'
import {
  commonFormat,
  frzIcon,
  frzColour,
} from '../../../utils'
import TooltipIcon from '../../../components/TooltipIcon'
import DataTable   from '../../../components/DataTable'
import { 
  Button,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%'
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
})

function ScheduleView({ classes, history, frzs, filter }) {
  const latest_filtered_frzs = frzs

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          data={latest_filtered_frzs}
          columns={[
            { label: 'Status',   render: z => <div className={classes.status}>
              <TooltipIcon avatar title={z.status}
                icon={frzIcon(z.status)}
                colour={frzColour(z.status)}
              />
            </div> },
            { label: 'Start',    render: z => z.validity.map((v, i) => <p key={i}>{format(v.start, commonFormat)}</p>) },
            { label: 'End',      render: z => z.validity.map((v, i) => <p key={i}>{format(v.end,   commonFormat)}</p>) },
            { label: 'Location', render: z => z.source_nfz?.name || 'Unknown'},
            { label: 'Name',     render: z => z.name },
            { label: 'Drone(s)', render: z => z.aircrafts?.map((a) => a.name).join(', ')},
            { label: 'Pilot(s)', render: z => z.pilots.map(p => p.name).join(', ') },
            { label: '', render: z => <div className={classes.actions}>
              <Button variant='outlined'
                onClick={e => { history.push(`/zones/${z.frz_id}`)}}>Details</Button>
            </div> }

          ]}
        />
      </Grid>
    </Grid>
  )
}

export default withRouter(withStyles(styles)(ScheduleView))
