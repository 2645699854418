import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import TopXCloseButton from '../../../components/TopXCloseButton'
import { useCallback, useEffect, useState } from 'react'
import GridLabel from '../../../Drones/components/GridLabel'
import GridField from '../../../Drones/components/GridField'
import ValidityPicker from '../Details/ValidityPicker'
import AltitudeText from '../Details/AltitudeText'
import { getValidity } from '../../../utils/frz'
import isEqual from 'fast-deep-equal'

function ApprovalDialog({ open, frz, setFrzOk, frzOk, onClose, onSave, updating , approvalEdits}) {
  const [validity, setValidity] = useState(approvalEdits?.validity ?? getValidity(frz))
  const [vStartError, setVStartError] = useState('')
  const [vEndError, setVEndError] = useState('')

  const [minAlt, setMinAlt] = useState(( Number.isInteger(approvalEdits?.altitude?.min) ? approvalEdits.altitude.min : frz?.altitude?.min) - (frz?.altitude?.ground_point?.alt ?? 0))
  const [minAltError, setMinAltError] = useState('')

  const [maxAlt, setMaxAlt] = useState(( Number.isInteger(approvalEdits?.altitude?.max) ? approvalEdits.altitude.max : frz?.altitude?.max)- (frz?.altitude?.ground_point?.alt ?? 0))
  const [maxAltError, setMaxAltError] = useState('')

  const [formDirty, setFormDirty] = useState(false)

  const validateForm = useCallback(() => {
    let hasError
    if (!formDirty)
      setFormDirty(true)

    if (!isFinite(minAlt)) {
      hasError = true
      setMinAltError('Min altitude is required')
    }
    else if (!isFinite(maxAlt)) {
      hasError = true
      setMaxAltError('Max altitude is required')
    } else if (Number(maxAlt) < Number(minAlt)) {
      hasError = true
      setMinAltError(' ') // highlight both fields
      setMaxAltError('Max must be greater than min altitude')
    }
    else {
      setMinAltError('')
      setMaxAltError('')
    }
    if (!validity.start) {
      hasError = true
      setVStartError('Start date / time is required')
    }
    else if (!validity.end) {
      hasError = true
      setVEndError('End date / time is required')
    }
    else if (validity.end < validity.start) {
      hasError = true
      setVStartError(' ') // highlight both fields
      setVEndError('End date / time must be after Start date / time')
      console.info('set error!')
    }
    else {
      setVStartError('')
      setVEndError('')
    }
    setFrzOk(!hasError)

  }, [validity, minAlt, maxAlt, formDirty, setFrzOk])
  
  useEffect(()=>{
    validateForm()
  }, [validity, validateForm])

  return <Dialog open={open} fullWidth maxWidth='md'>
    <TopXCloseButton onClick={onClose} />
    <DialogTitle>
      Edit Reservation
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <GridLabel>Date / Time</GridLabel>
        <GridField>
          <ValidityPicker
            validity={validity}
            setValidity={(v)=>{
              setValidity(v)
              console.info('set!', v)
              validateForm()
            }}
            vStartError={vStartError}
            vEndError={vEndError}
          />
        </GridField>
        <GridLabel>Altitude ({frz?.altitude?.ground_point ? 'AGL' : 'AMSL'})</GridLabel>
        <GridField>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <AltitudeText
                label='Min'
                altitude={minAlt}
                setAltitude={setMinAlt}
                error={minAltError}
                onBlur={validateForm}
              />
            </Grid>

            <Grid item xs={6}>
              <AltitudeText
                label='Max'
                altitude={maxAlt}
                setAltitude={setMaxAlt}
                error={maxAltError}
                onBlur={validateForm}
              />
            </Grid>
          </Grid>
        </GridField>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' disabled={!frzOk || updating} onClick={() => {
        validateForm()
        if(!frzOk){
          console.info('not valid')
          return
        }
        if(!formDirty){
          onSave()
        }else{
          const diff = {...approvalEdits}
          if(minAlt !== frz?.altitude?.min){
            if(!diff.altitude)  diff.altitude = {}

            diff.altitude = {
              ...diff.altitude,
              min: minAlt + (frz?.altitude?.ground_point?.alt ?? 0),
            }
          }
          if(maxAlt !== frz?.altitude?.max){
            if(!diff.altitude)  diff.altitude = {}
            
            diff.altitude = {
              ...diff.altitude,
              max: maxAlt + (frz?.altitude?.ground_point?.alt ?? 0),
            }
          }
          let formattedV = {start: validity.start.getTime(), end: validity.end.getTime()}
          
          if(!isEqual(formattedV, frz.validity[0])){
            diff.validity = validity
          }
          onSave(diff)
        }
      }}>{ updating ? 'Updating Reservation Details' : 'Make changes'}</Button>
    </DialogActions>
  </Dialog>
}
export default ApprovalDialog