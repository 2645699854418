import {
  Avatar,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display:    'flex',
    alignItems: 'center',
    width:      theme.spacing(10),
  },
  breachAvatar: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft:      theme.spacing(0.5),
    width:           theme.spacing(4),
    height:          theme.spacing(4),
    justifyContent: 'center',
    color:          'white'
  },
  warnAvatar: {
    backgroundColor: theme.palette.primary.main,
    marginLeft:      theme.spacing(0.5),
    width:           theme.spacing(4),
    height:          theme.spacing(4),
    color:           'white'
  },
})

function getViolationsByFlight(summary, flight, type) {
  if (!flight || !summary) {
    return 0
  }
  if (type !== 'breach' && type !== 'warning') {
    console.error('invalid violation prefix ', type)
    return 0
  }
  let areasBreached = []
  if (summary[flight?.drone.id]) {
    areasBreached.push(
      Object.keys(summary[flight?.drone?.id][`${type}_keep_in_areas`]),
      Object.keys(summary[flight?.drone?.id][`${type}_keep_out_areas`])
    )
  }

  if (flight?.drone.tracker_ids) {
    areasBreached = areasBreached.concat(flight?.drone.tracker_ids.map((tracker_id) => {
      if (summary[tracker_id]) {
        return [
          ...Object.keys(summary[tracker_id][`${type}_keep_in_areas`]),
          ...Object.keys(summary[tracker_id][`${type}_keep_out_areas`])
        ]
      } else {
        return []
      }
    }).flat())
  }
  return areasBreached.length
}

function ViolationColumn({ classes, summary, flight }) {

  return (
    <div className={classes.root}>
      <Avatar className={classes.breachAvatar}>
        <Typography variant='body2'>
          <b>{getViolationsByFlight(summary, flight, 'warning')}</b>
        </Typography>
      </Avatar>
      <Avatar className={classes.warnAvatar}>
        <Typography variant='body2'>
          <b>{getViolationsByFlight(summary, flight, 'breach')}</b>
        </Typography>
      </Avatar>
    </div>
  )
}

export default withStyles(styles)(ViolationColumn)
