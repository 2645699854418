import { useState } from 'react'
import {
  useGetDocksQuery,
} from '../../api/aircraft'
import DockDialog from './components/DockDialog'
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material'
import {
  Dock,
  Error,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const Container = styled(Box)(({ theme }) => ({
  width:   '100%',
  padding: theme.spacing(2),
}))

function Docks() {
  const [ open, setOpen ] = useState(false) // open create / update dialog
  const [ dock, setDock ] = useState(null)  // selected dock

  const { data, isLoading, error } = useGetDocksQuery()
  const docks = data?.data?.docks || []

  return <Container>
    <Controls
      onOpen={() => { setOpen(true) }}
    />
    { isLoading &&
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    }
    { error && 
      <Typography variant='body1'>
        <Error /> Error: {error.error}
      </Typography>
    }
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar sx={{ bgcolor: 'grey' }}><Dock /></Avatar>
      <Box ml={2}>
        <Typography variant='h5'>
          { docks.length === 0 ? 'No Docks' : 'Docks' }
        </Typography>
        { docks.length > 0 &&
          <Typography variant='body1'>
            Total: { docks.length } docks
          </Typography>
        }
      </Box>
    </Box>
    <Grid container>
      { docks.map(dock => (
        <Grid item key={dock.dock_id} p={2} sx={{ border: '1px solid grey' }}>
          <Typography variant='h6'>
            {dock.name}
          </Typography>
          <Typography variant='body1'>
            {dock.dock_model_id}
          </Typography>
          <Typography variant='body1'>
            {dock.serial_number}
          </Typography>
          <Button onClick={() => {
            setDock(dock)
            setOpen(true)
          }}>Edit</Button>
        </Grid>
      )) }
    </Grid>
    { open && <DockDialog
      dock={dock}
      onClose={() => {
        setDock(null)
        setOpen(false)
      }}
    /> }
  </Container>
}

function Controls({ onOpen }) {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Button onClick={onOpen}>
        New Dock
      </Button>
    </Box>
  )
}

export default Docks
