import { useState } from 'react'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { startOfDay, subDays, format } from 'date-fns'
import { useGetFlightsQuery } from '../../api/aircraft'

import WaitDialog   from '../../components/WaitDialog'
import ActionButton from '../../components/Buttons/ActionButton'
import DataTable    from '../../components/DataTable'
import MainControls from './components/MainControls'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
})

function RecentFlights({ classes, history }) {
  const [ daysAgo, setDaysAgo ] = useState(1)
  const since = startOfDay(subDays(new Date(), daysAgo))
  const { data, error, isLoading } = useGetFlightsQuery({ start_date: since.getTime() })

  if (isLoading)
    return <WaitDialog msg={`Loading flights since ${format(since, 'dd MMM yyyy')}...`} />
  else if (error) {
    console.log('Error loading flights', error)
    toast.error('Error loading flights')
    return null
  }
  const flights = Object.values(data?.entities || {})

  return (
    <div className={classes.root}>
      <MainControls
        flights={flights}
        since={since}
        setDaysAgo={setDaysAgo}
      />
      <DataTable
        data={flights}
        columns={[
          { label: 'Date',   render: f => format(new Date(f.date), 'dd MMM yyyy') },
          { label: 'Drone',  render: f => f.drone?.name },
          { label: 'Pilots', render: f => f.pilots?.map(p => p.name).join(', ') },
          { label: 'Status', render: f => f.status },
          { label: '', render: f => <div className={classes.actions}>
            <ActionButton label='Details'
              onClick={e => history.push(`/flights/${f.flight_id}`)} />
          </div> }
        ]}
        sort={{
          order: 'desc',
          by: 'date',
        }}
      />
    </div>
  )
}

export default withRouter(withStyles(styles)(RecentFlights))
