import { format } from 'date-fns'
import { meterToFeet2dp } from '../../../utils'
import { frzTypes } from '../../../utils/frz-type'
import Media from '../../../components/Media'
import ExternalLink from '../../../components/ExternalLink'
import {
  Avatar,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  grid: {
    padding: theme.spacing(2),
  },
  pilot: {
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
  },
  aircraft: {
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  desc: {
    border: '1px solid white',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  type: {
    padding: theme.spacing(1),
  },
})

function FrzProps({ classes, frz }) {
  if (!frz)
    return null
  
  const displayAlt = (alt) => `${alt}m, ${meterToFeet2dp(alt)}ft`
  // TODO: fix inconsistency across local form state and API response
  let zone = frz?.zone || frz?.source_nfz
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} lg={6} className={classes.grid}>
          {zone ?
            <>
              <FormLabel>NAMED ZONE</FormLabel>
              <Typography variant='body1'><b>{zone.name}</b></Typography>
              {zone.description &&
                <Typography variant='body2'>{zone.description}</Typography>
              }
              {zone.owner &&
                <Typography variant='body2'>Zone Owner: {zone.owner.name || '-'}</Typography>
              }
            </>
            : <>
              <FormLabel>CUSTOM ZONE</FormLabel>
              <Typography variant='body2'>
                <i>Custom polygon drawn on map</i>
              </Typography>
            </>
          }
        </Grid>
        <Grid item xs={12} lg={6} className={classes.grid}>
          <FormLabel>DATE & TIME</FormLabel>
          <Typography variant='body1' style={{ textWrap: 'nowrap' }}>
            {
              (frz.validity?.length > 0 ? frz.validity : [frz.validity]).map((v) => {
                return <>{
                  format(v.start, 'dd MMM yyyy hh:mm a')
                }<br />to<br />{
                  format(v.end, 'dd MMM yyyy hh:mm a')
                }</>
              })
            }
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.grid}>
          <FormLabel>PILOT(S)</FormLabel>
          {
            frz.pilots.map(pilot =>
              <div className={classes.pilot}>
                <Avatar className={classes.avatar}>
                  {pilot.avatar
                    ? <Media
                      mediaId={pilot.avatar}
                      size='thumb'
                      alt={pilot.name} fill />
                    : pilot.name.charAt(0)
                  }
                </Avatar>
                <div>
                  <Typography variant='body1'><b>{pilot.name}</b></Typography>
                  <Typography variant='body2'>{pilot.designation}</Typography>
                  <Typography variant='body2'>{pilot.email}</Typography>
                </div>
              </div>
            )
          }
          <FormLabel>DRONE(S)</FormLabel>
          {frz.aircrafts.map((aircraft) => {
            return <div className={classes.aircraft}>
              {(aircraft.avatar || aircraft.drone_model?.avatar) ?
                <Media mediaId={aircraft.avatar || aircraft.drone_model?.avatar}
                  size='preview' alt={aircraft.name} fill />
                : <Avatar className={classes.avatar}>
                  {aircraft.name.charAt(0)}
                </Avatar>
              }
              <div>
                <Typography variant='body1'>{aircraft.name}</Typography>
                <Typography variant='body2'>{aircraft.drone_provider.brand}</Typography>
                <Typography variant='body2'>{aircraft.drone_model.name}</Typography>
              </div>
            </div>
          })
          }
        </Grid>
        <Grid item xs={12} lg={6} className={classes.grid}>
          <FormLabel>ALTITUDE</FormLabel>
          <Typography variant='body2'>
            Height Above WGS84 Ellipsoid
          </Typography>
          <Typography variant='body1'>
            Min: <b>{
              isFinite(frz?.altitude?.min) ? displayAlt(frz.altitude.min)
              : <>Unspecified</>
            }</b>
          </Typography>
          <Typography variant='body1'>
            Max: <b>{
              isFinite(frz?.altitude?.max) ? displayAlt(frz.altitude.max)
              : <>Unspecified</>
            }</b>
          </Typography>
          <br />
          {frz.altitude.ground_point && <>
            <Typography variant='body2'>
              Height Above Ground Level
            </Typography>
            <Typography variant='body1'>
              Min: <b>{
                displayAlt(frz.altitude.min - frz.altitude.ground_point.alt)
              }</b>
            </Typography>
            <Typography variant='body1'>
              Max: <b>{
                displayAlt(frz.altitude.max - frz.altitude.ground_point.alt)
              }</b>
            </Typography>
          </>}
        </Grid>
        <Grid item xs={12} lg={6} className={classes.grid}>
          {frz.desc &&
            <div className={classes.desc}>
              <FormLabel>COMMENTS</FormLabel>
              <Typography>{frz.desc}</Typography>
            </div>
          }
          <div className={classes.type}>
            <FormLabel>RESERVATION TYPE / RULE</FormLabel>
            <Typography variant='body1'>
              {frzTypes[frz.type].name}
            </Typography>
            <Typography variant='body2'>
              {frzTypes[frz.type].description}
            </Typography>
            {frzTypes[frz.type].source &&
              <Typography variant='caption'>
                <ExternalLink href={frzTypes[frz.type].source}>
                  Learn More
                </ExternalLink>
              </Typography>
            }
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(FrzProps)
