import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { makeQueryString, standardHeaders } from './api-utils'

const GEOSPATIAL_API = new URL(process.env.REACT_APP_GEOSPATIAL_API).href

export const geospatialApi = createApi({
  reducerPath: 'geospatialApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GEOSPATIAL_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Terrain', 'Place'],
  endpoints: (builder) => ({
    // Place
    getPlace: builder.query({
      query: (params) => (`/place${makeQueryString(params, [
        'company_ids',
        'type',
        'near',
        'minDistance',
        'maxDistance',
      ])}`),
      providesTags: ['Place'],
    }),
    // Terrain
    getElevation: builder.query({
      query: (locations) => ({
        url: '/terrain/elevation',
        method: 'POST',
        body: { locations: Array.isArray(locations) ? locations : [locations] },
      }),
      providesTags: ['Terrain'],
    }),
  }),
})

export const {
  useGetPlaceQuery,
  useGetElevationQuery,
} = geospatialApi
