import PublicAirspace  from '../../../components/Airspace/Public'
import PrivateAirspace from '../../../components/Airspace/Private'
import FlightGeofence  from '../../../components/Airspace/FlightGeofence'
import DroneMarker     from '../../../Dashboard/components/DroneMarker'
import TrackMarker     from './TrackMarker'

import 'leaflet/dist/leaflet.css'
import CommonLayers from '../../../components/Map/CommonLayers'
import {
  MapContainer,
  Polyline,
  ScaleControl,
  ZoomControl,
} from 'react-leaflet'
import {
  DEFAULT_MAP_CENTER,
} from '../../../utils/site-maps'
import theme          from '../../../utils/site-theme'
import { withStyles } from '@material-ui/core/styles'
import useCompanies from '../../../components/useCompanies'

const styles = theme => ({
  map: {
    zIndex:   '0',
    position: 'relative',
    width:    '99.99%', 
    height:   'calc(100vh - 128px)',
  }
})

function PlaybackMap({ classes, flights, tracks, trackIndex, setShowCard, showOnMap, setMap }) {
  const currTelem = tracks[trackIndex] || null
  const {company_ids} = useCompanies()

  return (
    <MapContainer
      className={classes.map}
      center={DEFAULT_MAP_CENTER}
      zoomControl={false}
      zoom={15}
      maxZoom={22}
      ref={setMap}>
      <CommonLayers position='topleft' />
      <ZoomControl position='topleft' />
      <ScaleControl position='bottomleft' imperial={false} />
      { (showOnMap.includes('public-nfz') || showOnMap.includes('public-test-estate')) &&
        <PublicAirspace
          showStaticNfz={showOnMap.includes('public-nfz')}
          showTempNfz={showOnMap.includes('public-nfz')}
          showTestEstate={showOnMap.includes('public-test-estate')}
        />
      }
      { showOnMap.includes('private-nfz') &&
        <PrivateAirspace 
          company_ids={company_ids}
          showNfz={true}
        />
      }
      { showOnMap.includes('flight-geofence') && flights?.length > 0 &&
        flights.map(f => <FlightGeofence key={f.flight_id} flight={f} />)
      }
      { tracks.length > 0 &&
        <TrackMarker start
          key='start'
          track={tracks[0]}
        />
      }
      { tracks.length > 0 &&
        <TrackMarker end
          key={tracks.length - 1}
          track={tracks[tracks.length - 1]}
        />
      }
      <Polyline
        pathOptions={{ color: theme.palette.primary.dark }}
        positions={tracks.map(t => [
          parseFloat(t.lat),
          parseFloat(t.lng),
        ])}
      />
      { currTelem &&
        <DroneMarker
          key={currTelem.ua_id}
          lat={currTelem.lat}
          lng={currTelem.lng}
          yaw={currTelem.yaw}
          iconColor={{ arrow: { fill: 'green', stroke: 'green' }}}
          onClick={e => { setShowCard(true) }}
        />
      }
    </MapContainer>
  )
}

export default withStyles(styles)(PlaybackMap)
