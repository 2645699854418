import { useState, forwardRef } from 'react'
import { nfzIcon } from '../../utils'
import {
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/lab'
import {
  Public,         // Low Altitude Airspace ("space")
                     // Static NFZ     use nfzIcon('static')
                     // Temporary NFZ  use nfzIcon('temp')
                     // Vessel         use nfzIcon('vessel')
                     // Test Estate    use nfzIcon('estate')
    SettingsOverscan,// Approved FRZs (icon is for all FRZs, more meaningful in this context (blue))
                     // Pending Approval FRZs (icon is for all FRZs, ditto (light blue))
    Place,           // Places / On Ground Points Of Interest
    GpsFixed,        // Show My Location
    Fullscreen,      // Fit Bounds
  TrackChanges,   // Drone Tracking & Surveillance ("drone")
    Flight,          // Flight
    TrendingUp,      // Tracks
    Gavel,           // Violations
  FilterDrama,    // Weather & Advisory ("weather")
  ContactPhone,   // Safety & Emergency Services ("safety")
  Settings,       // Settings ("settings")
    Extension,       // Plugins
    //Home,            // Map Preference
    PlayArrow,
    Language,       // Demo
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    top:    0,
    right:  0,
    margin: theme.spacing(1, 2, 0, 0),
  },
  panel: {
    height: theme.spacing(6),
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: theme.spacing(36),
    border: '2px solid ' + theme.palette.background.default,
    borderRadius: theme.spacing(1.5),
    textAlign: 'center',
  },
  shown: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 0),
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(2),
    color: theme.palette.common.white,
  },
  hidden: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 0),
    marginLeft: theme.spacing(2),
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  toggleButton: {
    width: theme.spacing(7),
    borderTop: '0',
    borderBottom: '0'
  },
  toggleButtonGroup: {
    '& button:first-child': {
      borderLeft: '0',
    },
    '& button:last-child': {
      borderRight: '0',
    },
  }
})

const colour = {
  // Public (keep out unless)
  'space-static-nfz':   theme.palette.map.freshRed,   // airport / camp
  'space-temp-nfz':     theme.palette.map.liverRed,   // NDP / F1
  'space-nparks-nfz':   theme.palette.map.yellow,     // Windsor
  'space-vessel-nfz':   theme.palette.map.orange,     // Ships
  'space-estate-nfz':   theme.palette.map.lightGreen, // OHR / MDE (keep in)
  'space-places':       theme.palette.map.darkGreen,  // (TBD, no effect)
  // Private
  'space-own-nfz':      theme.palette.map.darkBlue,   // keep out
  'space-own-estate':   theme.palette.map.darkGreen,  // for copying from
  'space-approved-frz': theme.palette.map.blue,       // keep in
  'space-pending-frz':  theme.palette.map.lightBlue,  // keep in

  'space-my-location':  theme.palette.secondary.main,
  'space-fit-bounds':   theme.palette.secondary.main,
  'drone-flights':      theme.palette.tracker.yellow,
  'drone-tracks':       theme.palette.tracker.green,
  'drone-violations':   theme.palette.primary.dark,

  'plugins-demo':       theme.palette.primary.dark,
  'plugins-htx':        theme.palette.map.magenta,
  'plugins-rsaf':       theme.palette.map.darkBlue,
  'plugins-xprize':     theme.palette.map.darkGreen,
} || theme.palette.primary.dark

function MainControlPanel({
  classes,
  showOnMap,
  isHtx,
  isRsaf,
  isXprize,
  onToggle,
  onZoomToFit,
}) {
  const [ spaceMenu,    setSpaceMenu    ] = useState(null)
  const [ droneMenu,    setDroneMenu    ] = useState(null)
  const [ weatherMenu,  setWeatherMenu  ] = useState(null)
  const [ settingsMenu, setSettingsMenu ] = useState(null)

  function select(feature) {
    setSpaceMenu(null)
    setDroneMenu(null)
    setSettingsMenu(null)
    onToggle(feature)
  }

  const ToggleItem = forwardRef(function ({ feature, label, icon }, ref) {
    const shown = showOnMap[feature]
    const backgroundColor = shown ? colour[feature] : theme.palette.background.paper
    return (
      <MenuItem onClick={e => select(feature)}>
        <ListItemText>{ shown ? 'Hide' : 'Show' } {label}</ListItemText>
        { icon &&
          <ListItemIcon
            className={shown ? classes.shown : classes.hidden}
            style={{ backgroundColor }}>
            {icon}
          </ListItemIcon>
        }
      </MenuItem>
    )
  })
  const enabled = [
    spaceMenu ? 'space' : '',
    droneMenu ? 'drone' : '',
    weatherMenu ? 'weather' : '',
    // showOnMap['weather'] ? 'weather' : '',
    showOnMap['safety']  ? 'safety'  : '',
    settingsMenu ? 'settings' : ''
  ]

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <ToggleButtonGroup className={classes.toggleButtonGroup} value={enabled}>
          <ToggleButton className={classes.toggleButton} value='space' onClick={e => { setSpaceMenu(e.currentTarget) }}>
            <Tooltip arrow title='Low Altitude Airspace'><Public /></Tooltip>
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value='drone' onClick={e => { setDroneMenu(e.currentTarget) }}>
            <Tooltip arrow title='Drone Tracking & Surveillance'><TrackChanges /></Tooltip>
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value='weather' onClick={e => { setWeatherMenu(e.currentTarget) }}>
            <Tooltip arrow title='Weather & Advisory'><FilterDrama /></Tooltip>
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value='safety' onClick={e => { onToggle('safety') }}>
            <Tooltip arrow title='Safety & Emergency Services'><ContactPhone /></Tooltip>
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value='settings' onClick={e => { setSettingsMenu(e.currentTarget) }}>
            <Tooltip arrow title='Settings'><Settings /></Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        { !!spaceMenu &&
          <MainMenu anchorEl={spaceMenu} onClose={() => setSpaceMenu(null)}>
            <ToggleItem feature='space-static-nfz'   icon={nfzIcon('static')} label='No Fly Zones (Static)' />
            <ToggleItem feature='space-temp-nfz'     icon={nfzIcon('temp'  )} label='No Fly Zones (Temporary)' />
            <ToggleItem feature='space-vessel-nfz'   icon={nfzIcon('vessel')} label='Maritime Vessels' />
            <ToggleItem feature='space-estate-nfz'   icon={nfzIcon('estate')} label='Drone Friendly / Test Estates' />
            <ToggleItem feature='space-places'       icon={<Place />} label='On Ground Points Of Interest' />
            <Divider />
            <ToggleItem feature='space-own-estate' icon={<Language />} label='Ready To Use Airspace' />
            <ToggleItem feature='space-approved-frz' icon={<SettingsOverscan />} label='Airspace Reservations (Approved)' />
            <ToggleItem feature='space-pending-frz'  icon={<SettingsOverscan />} label='Airspace Reservations (Pending Approval)' />
            <Divider />
            { /* These two probably don't belong here, might move to leaflet map controls, if we can figure out toggle */ }
            <ToggleItem feature='space-my-location'  icon={<GpsFixed />} label='My Location' />
            <MenuItem onClick={e => onZoomToFit()}>
              <ListItemText>Zoom Map To Fit</ListItemText>
              <ListItemIcon className={classes.hidden}><Fullscreen /></ListItemIcon>
            </MenuItem>
          </MainMenu>
        }
        { !!droneMenu &&
          <MainMenu anchorEl={droneMenu} onClose={() => setDroneMenu(null)}>
            <ToggleItem feature='drone-flights'    icon={<Flight     />} label='Drone Flights'    />
            <ToggleItem feature='drone-tracks'     icon={<TrendingUp />} label='Drone Tracks'     />
            <ToggleItem feature='drone-violations' icon={<Gavel      />} label='Drone Violations' />
          </MainMenu>
        }
        { !!weatherMenu &&
          <MainMenu anchorEl={weatherMenu} onClose={() => setWeatherMenu(null)}>
            <ToggleItem feature='weather' icon={<FilterDrama />} label='Weather' />
          </MainMenu>
        }
        { !!settingsMenu &&
          <MainMenu anchorEl={settingsMenu} onClose={() => setSettingsMenu(null)}>
            <ListSubheader className={classes.subtitle}>
              <Typography>Demo</Typography>
            </ListSubheader>
            <ToggleItem feature='plugins-demo' label='Demo Tracks' icon={<PlayArrow />} />
            <ListSubheader className={classes.subtitle}>
              <Typography>Plugins</Typography>
            </ListSubheader>
            { isHtx    && <ToggleItem feature='plugins-htx'    icon={<Extension />} label='HTX Plugin'    /> }
            { isXprize && <ToggleItem feature='plugins-xprize' icon={<Extension />} label='XPRIZE Plugin' /> }
            <ListSubheader className={classes.subtitle}>
              <Typography>Map Preferences</Typography>
            </ListSubheader>
            { /* TODO: toggle always remember last view */ }
            { /* TODO: toggle default map center */ }
          </MainMenu>
        }
      </div>
    </div>
  )
}

function MainMenu({ anchorEl, onClose, children }) {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}>
      {children}
    </Menu>
  )
}

export default withStyles(styles)(MainControlPanel)
