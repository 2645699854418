
import {
  Button,
  ButtonGroup,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  btnGrp: {
    height: theme.spacing(6),
    color:           theme.palette.map.grey,
    backgroundColor: theme.palette.background.paper,
  },
})

function LayerControls({
  classes,
  showCard,
  setShowCard
}) {
  return (
    <div className={classes.root}>
      <ButtonGroup size='large' variant='outlined' className={classes.btnGrp}>
        <Button variant={showCard ? 'contained' : 'outlined'}
          onClick={e => { setShowCard(!showCard) }}>
          Data
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default withStyles(styles)(LayerControls)
