import { useState, useEffect } from 'react'
import L from 'leaflet'
import {
  useMap,
} from 'react-leaflet'

function LockMapControl({ position = 'topleft', onLock, onUnlock }) {
  const map = useMap()

  const [ disabled, setDisabled ] = useState(false)
  const [ loaded,   setLoaded   ] = useState(false)

  useEffect(() => {
    if (map) {
      if (disabled) {
        map.touchZoom.disable()
        map.doubleClickZoom.disable()
        map.scrollWheelZoom.disable()
        map.keyboard.disable()
        map.dragging.disable()
      } else {
        map.touchZoom.enable()
        map.doubleClickZoom.enable()
        map.scrollWheelZoom.enable()
        map.keyboard.enable()
        map.dragging.enable()
      }
    }
  }, [disabled, map])

  useEffect(() => {
    if (map && !loaded) {
      L.Control.lockMap = L.Control.extend({
        onAdd: function(map) {
          const btn = L.DomUtil.create('button', 'leaflet-control')
          const icon = L.DomUtil.create('img', 'map-lock', btn)
          icon.src = '/assets/map-lock.png'
          icon.height = 16
          icon.style.verticalAlign = 'middle'
          btn.style.marginBottom = '0px'
          btn.innerHTML += ' Lock'
          btn.style.backgroundColor = '#fff'
          btn.style.border = '2px solid rgba(0,0,0,0.32)'
          btn.style.borderRadius = '4px'
          btn.style.borderBottomLeftRadius = '0px'
          btn.style.borderBottomRightRadius = '0px'
          btn.style.borderBottom = '1px'
          btn.style.borderBottomStyle = 'solid'
          btn.style.borderBottomColor = 'rgba(0, 0, 0, 0.32)'
          btn.style.textAlign = 'left'
          btn.style.paddingLeft = '8px'
          btn.style.lineHeight = 2
          btn.style.width = '80px'
          btn.style.cursor = 'pointer'

          btn.addEventListener('mouseover', e => {   
            btn.style.backgroundColor = '#f4f4f4'
          }, false)

          btn.addEventListener('mouseout', e => {   
            btn.style.backgroundColor = '#fff'
          }, false)

          btn.onclick = e => {
            setDisabled(true)
            onLock && onLock()
          }
          return btn
        },
      })

      L.control.lockMap = function(opts) {
        return new L.Control.lockMap(opts)
      }
      L.control.lockMap({ position }).addTo(map)

      L.Control.unlockMap = L.Control.extend({
        onAdd: function(map) {
          const btn = L.DomUtil.create('button', 'leaflet-control')
          const icon = L.DomUtil.create('img', 'map-unlock', btn)
          icon.src = '/assets/map-lock-open.png'
          icon.height = 16
          icon.style.verticalAlign = 'middle'
          icon.style.marginBottom = '3px'
          btn.innerHTML += ' Unlock'
          btn.style.marginTop = '0px'
          btn.style.backgroundColor = '#fff'
          btn.style.border = '2px solid rgba(0,0,0,0.32)'
          btn.style.borderRadius = '4px'
          btn.style.borderTopLeftRadius = '0px'
          btn.style.borderTopRightRadius = '0px'
          btn.style.borderTop = '0px'
          btn.style.textAlign = 'left'
          btn.style.paddingLeft = '8px'
          btn.style.lineHeight = 2
          btn.style.width = '80px'
          btn.style.cursor = 'pointer'

          btn.addEventListener('mouseover', e => {   
            btn.style.backgroundColor = '#f4f4f4'
          }, false)

          btn.addEventListener('mouseout', e => {   
            btn.style.backgroundColor = '#fff'
          }, false)

          btn.onclick = e => {
            setDisabled(false)
            onUnlock && onUnlock()
          }
          return btn
        },
      })

      L.control.unlockMap = function(opts) {
        return new L.Control.unlockMap(opts)
      }
      L.control.unlockMap({ position }).addTo(map)
      setLoaded(true)
    }

  }, [position, map, disabled, loaded, onLock, onUnlock])

  return null
}

export default LockMapControl
