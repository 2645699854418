import { useGetTrackerByIdQuery } from '../../api/tracker'
import TopXCloseButton from '../../components/TopXCloseButton'
import DroneAvatar from './DroneAvatar'
import LeftPanel   from './LeftPanel'
import RightPanel  from './RightPanel'
import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    zIndex: 2,
    position: 'fixed',
    top: theme.spacing (1),
    left: theme.spacing(15),
    right: theme.spacing(40),
    height: theme.spacing(12),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
  },
})

function FlyingPanel({ classes, flight, drone, droneTelemetry, trackerTelemetry, onClose, editGeofence, setEditingGeofence}) {
  const trackerIds = flight?.drone?.tracker_ids || []
  const { data: trackerData } = useGetTrackerByIdQuery(trackerIds[0], {
    skip: trackerIds.length === 0
  })
  const tracker = trackerData?.data?.tracker || {}

  if (!flight)
    return null

  return (
    <div className={classes.root}>
      <TopXCloseButton onClick={onClose} />
      <Grid container>
        <Grid item xs={5}>
          <LeftPanel flight={flight} drone={drone} tracker={tracker} />
        </Grid>
        <Grid item xs={2}>
          <DroneAvatar flight={flight} drone={drone} droneTelemetry={droneTelemetry} trackerTelemetry={trackerTelemetry} />
        </Grid>
        <Grid item xs={5}>
          <RightPanel flight={flight} drone={drone} droneTelemetry={droneTelemetry} trackerTelemetry={trackerTelemetry} editGeofence={editGeofence} setEditingGeofence={setEditingGeofence} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(FlyingPanel)
