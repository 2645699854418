import { Typography } from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  panel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    border: 'transparent',
    width:  theme.spacing(36),
    height: theme.spacing(6),
    cursor: 'pointer',
  },
  panelOpen: {
    borderRadius: theme.spacing(2, 2, 0, 0),
  },
  panelClosed: {
    borderRadius: theme.spacing(2),
  },
})

function Header({ classes, droneArray, trackerArray, open, setOpen }) {
  return (
    <div
      className={`${classes.panel} ${open ? classes.panelOpen : classes.panelClosed}`}
      onClick={e => { setOpen(!open) }}>
      <div>
        <Typography variant='body1'>Online</Typography>
      </div>
      <div>
        <Typography variant='caption'>Drones</Typography>
        <Typography variant='body2' className='panelBody'>
          {droneArray.filter(d => d.online).length} / {droneArray.length}
        </Typography>
      </div>
      <div>
        <Typography variant='caption'>Trackers</Typography>
        <Typography variant='body2' className='panelBody'>
          {trackerArray.filter(t => t.online).length} / {trackerArray.length}
        </Typography>
      </div>
      { open ? <ExpandMore /> : <ExpandLess /> }
    </div>
  )
}

export default withStyles(styles)(Header)
