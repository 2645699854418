import { defaultNfzValidity } from '../api/airspace'

// Cannot useState because each caller has its own local copy
// This is a poor man's React Context (there's only 1 instance of global variable)
let nfzValidity = defaultNfzValidity()
// TODO: shift to React Context / Redux or implement observer pattern
// https://stackoverflow.com/questions/57602715/react-custom-hooks-fetch-data-globally-and-share-across-components

// TODO: Save to local storage so that it's like a (local) preference
function useNfzValidity(callFrom) {

  function setNfzValidity(v) {
    console.log(callFrom, 'newNfzValidity', v)
    nfzValidity = v
  }
  return [ nfzValidity, setNfzValidity ]
}

export default useNfzValidity
