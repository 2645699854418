import { useEffect } from "react"
import { Pane, GeoJSON, useMap } from "react-leaflet"

function pathOption(properties) {
  return {
    color: properties.color,
    weight: 3,
    fillOpacity: 0.4,
    fillColor: properties.color,
    className: properties.customCssClassName,
  }
}

function SelectionLayer({ feature }) {
  const map = useMap()
  useEffect(() => {
    map.getPane('overlayPane').style.opacity = feature ? "0.5" : "1"
    if (feature?.properties?.zoom) {
      const coords = feature.geometry.coordinates
      const latLngBounds = coords.map(coord => coord.map(c => [ c[1], c[0] ]))
      map.flyToBounds(latLngBounds)
    }
  }, [feature, map])
  return <Pane name='selectionPane'>
    {feature && <GeoJSON
      key={Date.now()}
      data={feature}
      style={() => pathOption(feature.properties)}
      interactive={false}
    />}
  </Pane>
}

export default SelectionLayer