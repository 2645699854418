import { Scatter } from 'react-chartjs-2'
import wheel from '../../utils/colour-wheel'
import ChartOptions from './ChartOptions'

export default function LocationChart({ trackers }) {

  if (!trackers)
    return null

  const
    latDataset = [],
    lngDataset = [],
    hdopDataset = []

  for (const imei in trackers) {
    let col = wheel.nextColour()
    latDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.lat]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    lngDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.lng]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    hdopDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.hdop]),
      backgroundColor: col,
      borderColor: col
    })
  }
  const latOptions = ChartOptions()
  latOptions.plugins.title.text = 'Latitude'

  const lngOptions = ChartOptions()
  lngOptions.plugins.title.text = 'Longitude'

  const hdopOptions = ChartOptions()
  hdopOptions.plugins.title.text = 'Horizontal Dillution of Precision (HDOP)'

  return (
    <>
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: latDataset}}
        options={latOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: lngDataset}}
        options={lngOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: hdopDataset}}
        options={hdopOptions}
      />
    </>
  )
}
