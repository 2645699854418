// Temp wrapper until we can have a full fledged preference service

function usePreference(company) {
  if (!company)
    return {}

  return {
    timezone: company.properties?.pref_default_timezone || '',
    isHtx:    company.properties?.pref_enable_htx === true,
    isRsaf:   company.properties?.pref_enable_rsaf === true,
    isXprize: company.properties?.pref_enable_xprize === true,
  }
}

export default usePreference
