import Sockette  from 'sockette'
import { toast } from 'react-toastify'
import { makeWssUrl } from './api-utils'

const ERRORS_WS_API = new URL(process.env.REACT_APP_ERRORS_WS_API).href

let error_ws
const wsCache = {}
export async function errorWS({ company_ids }) {
  if (!company_ids)
    return null

  return new Promise(function (resolve, reject) {
    const wssUrl = makeWssUrl(ERRORS_WS_API, { company_ids })
    if (wsCache[wssUrl])
      return resolve()

    error_ws = wsCache[wssUrl] = new Sockette(wssUrl, {
      timeout:     10000,
      maxAttempts: Infinity,
      onopen:      e => {
        console.info('error ws connected')
        resolve(error_ws)
      },
      onreconnect: e => console.info('error ws reconnecting'),
      onclose:     e => console.info('error ws closed'),
      onerror:     e => console.info('error ws error:', e),
      onmessage:   e => {
        let error
        try {
          error = JSON.parse(e.data)
        } catch(e) {
          return console.log('Invalid error received', e.data)
        }
        if (!error)
          return console.log('Bad error received', error)

        const message = error?.data?.logs?.message
        switch (error?.data?.logs.type) {
          case 'info':
            toast.info(message)
            break
          case 'warn':
            toast.warn(message)
            break
          case 'error':
            toast.error(message)
            break
          default:
        }
      },
    })
    
  })
}