import 'chartjs-adapter-date-fns'

export default function ChartOptions(title) {
  const plugins = {
    title: {
      display: true,
      text: title || 'Replace Me'
    },
    // annotation: {
    //   annotations: {
    //     line1: {
    //       type: 'line',
    //       yMin: 0,
    //       yMax: 3600,
    //       borderColor: 'rgb(255, 99, 132)',
    //       borderWidth: 3,
    //     }
    //   }
    // }
  }

  const scales = {
    xAxes: {
      type: 'time',
      time: {
        unit: 'second',
        stepSize: 900, // 15 minutes
        displayFormats: {
          'second': 'h:mm:ss'
        },
        tooltipFormat: 'h:mm:ss'
      },
    },
    yAxes: {

    }
  }

  return {
    plugins: plugins,
    scales: scales,
    responsive: true,
    maintainAspectRatio: true,
  }
}
