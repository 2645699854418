import { format } from 'date-fns'
import { commonFormat } from '../../../utils'

import {
  Typography,
} from '@material-ui/core'

function FlightTitle({ flight }) {
  if (!flight)
    return <Typography variant='h6'>New Flight</Typography>

  const takeoffDate = flight.date ?
    format(new Date(flight.date), commonFormat) :
    'unknown takeoff date'

  const pilots = flight.pilots ?
    flight.pilots.map(p => p.name).join(', ') :
    'nobody'

  return (
    <Typography variant='h6'>
      The flight <b>{flight.drone?.name}</b> made on <b>{takeoffDate}</b> by <b>{pilots}</b>
    </Typography>
  )
}

export default FlightTitle
