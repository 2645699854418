import { useState } from 'react'
import { useInterval } from 'usehooks-ts'
import DroneMarker from '../../components/DroneMarker'
import oneNorth    from './path/OneNorth.json'
import ohr1        from './path/OHR_1.json'
import ohr2        from './path/OHR_2.json'
import parkwayEast from './path/parkwayEast.json'
import hdb         from './path/HDB.json'
import changi      from './path/changi.json'

const demoTrackers = [
  { imei: '867584032403901', coord: oneNorth,    name: 'One North Security',
    iconColor: { arrow: {fill: 'green', stroke: 'green'}}},

  { imei: '867584032403902', coord: ohr1,        name: 'Old Holland Road Field Test Drone : 1',
    iconColor: { arrow: {fill: 'green', stroke: 'green'}}},

  { imei: '867584032403903', coord: ohr2,        name: 'Old Holland Road Field Test Drone : 2',
    iconColor: { arrow: {fill: 'green', stroke: 'green'}}},

  { imei: '867584032403904', coord: parkwayEast, name: 'Medical Delivery Parkway Labs',
    iconColor: { drone: {fill: 'white', stroke: 'blue'}, arrow: {fill: 'red', stroke: 'red'}}},

  { imei: '867584032403905', coord: hdb,         name: 'Facility Inspection Sterling Road',
    iconColor: { arrow: {fill: 'green', stroke: 'green'}}},

  { imei: '867584032403906', coord: changi,      name: 'Facility Inspection Changi Airport Terminal 2',
    iconColor: { arrow: {fill: 'red',   stroke: 'red'  }}},
]

function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function DemoTracks({ show, selectedTrackerId, onClick }) {
  const [ iterators, setIterators ] = useState(Array(demoTrackers.length).fill(0))

  useInterval(() => {
    setIterators(iterators.map((it, i) => (it === demoTrackers[i].coord.length - 1 ? 0 : it + 1)))
  }, show ? 2000 : null)

  return (<>
    { demoTrackers.map((tracker, i) => {
      const coord = tracker.coord[iterators[i]]
      let lng, lat, yaw
      try {
        lng = coord[0].toFixed(7)
        lat = coord[1].toFixed(7)
        yaw = coord[2] || 0
      } catch (e) {
        console.log('Invalid demo coord', coord, 'skipping one turn')
        return null
      }
      const track = {
        ua_telem_type: 'tracker',
        ua_id:  tracker.imei,
        t:      new Date().toISOString(),
        lat,
        lng,
        alt:    2600.0,
        v:      6.2,
        a:      0.2,
        head:   0,
        status: 2,
        v_acc:  3,
        h_acc:  6,
        ver:    '1.2.1',
        bat:    '3200',
        imei:   tracker.imei,
        imsi:   '987654321123456',
        // === no longer found in UATelem docs
        pit:    rand(-5, 5),
        rol:    rand(-5, 5),
        yaw,
        name:   tracker.name,
      }
      return (
        <DroneMarker
          key={i}
          onClick={(e) => onClick(track)}
          lat={lat}
          lng={lng}
          yaw={yaw}
          iconColor={tracker.iconColor}
        />
      )
    }) }
  </>)
}


export default DemoTracks
