import theme from './site-theme'

export default function rulesToPolygonProps(restriction) {
  const properties = {}

  // TODO: To recatalogue restriction in the data model for airspace
  //       restriction should be an enum not a random list of strings
  //       even if not, code and database must be in sync at all times
  //       (ie. stop the practise of correcting letter case / space)
  switch (restriction) {
    case 'operation-restriction':
      properties.color = theme.palette.map.blue
      break
    
    case 'protected-area':
    case 'industrial properties':
    case 'military properties':
    case 'government properties':
    case 'power plant':
    case 'prison':
    case 'event-tfr':
    case 'event TFR':
      properties.color = theme.palette.map.liverRed
      break

    case 'aerodrome':
    case 'military airport':
    case 'public airport':
      properties.color = theme.palette.map.magenta
      properties.icon = 'airport'
      properties.text = 'white'
      break

    case 'danger-area':
    case 'prohibited-area':
    case 'restricted-area':
      properties.color = theme.palette.map.magenta
      properties.icon = 'awas-light'
      properties.text = 'white'
      break

    case 'controlled-airspace':
    case 'controlled airspace':
      properties.color = theme.palette.map.freshRed
      properties.icon = 'fence'
      properties.text = 'white'
      break

    case 'national-park':
    case 'national park':
      properties.color = theme.palette.map.yellow
      properties.icon = 'national-park'
      properties.text = 'black'
      break

    case 'test-estate':
    case 'Test Estate':
      properties.color = theme.palette.map.lightGreen
      properties.icon = 'awas-light'
      properties.text = 'black'
      break

    default:
      console.info('Unknown restriction', restriction)
      properties.color = 'black'
      properties.icon = 'danger-amber'
      properties.text = 'white'
      break
  }

  return properties
}