import { useState } from 'react'
import { format } from 'date-fns'
import {
  prettyXprizeFlightId,
} from './xprize-utils'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function XprizeStopFlight({ classes, activeFlights, onStop }) {
  const [ selectedFlightId, setSelectedFlightId ] = useState('')

  function handleStop() {
    onStop({
      flightToStop: activeFlights[selectedFlightId]
    })
  }

  return (<>
    <DialogTitle>Stop Flight</DialogTitle>
    <DialogContent>
      { Object.values(activeFlights).length === 0 ?
        <Typography variant='body1' gutterBottom>
          There are no Active Flights to stop.
        </Typography>
      : <>
        <Typography variant='body1' gutterBottom>
          Select the Flight to Stop
        </Typography>
        <br />
        <TextField select fullWidth
          label='Flights'
          value={selectedFlightId}
          onChange={e => { setSelectedFlightId(e.target.value) }}>
          { Object.values(activeFlights).map(fl => (
            <MenuItem key={fl.flight_id} value={fl.flight_id}>
              {prettyXprizeFlightId(fl.flight_id)} ({ fl.drone.name }) since {
                fl.date ? format(fl.date, 'dd MMM yyyy HH:mm:ss (eee)') : '(unknown date)'
              }
            </MenuItem>
          )) }
        </TextField>
      </>}
    </DialogContent>
    <DialogActions>
      <Button
        disabled={!selectedFlightId}
        variant='contained'
        color='secondary'
        onClick={handleStop}>
        Stop Flight
      </Button>
    </DialogActions>
  </>)
}

export default withStyles(styles)(XprizeStopFlight)
