import Media from '../../components/Media'

import {
  Typography,
} from '@material-ui/core'
import {
  BatteryChargingFull,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    margin: theme.spacing(-1, 0),
    padding: theme.spacing(1, 0),
    textAlign: 'center',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  batt: {
    display: 'flex',
    height: theme.spacing(2),
  },
  battOuter: {
    width: theme.spacing(8),
    height: theme.spacing(2),
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.common.black,
  },
  battInner: {
    height: theme.spacing(2),
  },
  ok: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.map.freshRed, // to review error.main
  },
  dead: {
    backgroundColor: theme.palette.primary.light, // to figure out how to blink
  },
  invalid: {
    backgroundColor: theme.palette.common.black,
  },
})

function DroneAvatar({ classes, flight, drone, droneTelemetry, trackerTelemetry }) {
  if (!flight)
    return null

  //TODO: Deprecating batt_duration. Remove when completely deprecated
  // const battDur =
  //   droneTelemetry?.batt_duration ? parseInt(droneTelemetry.batt_duration) :
  //   trackerTelemetry?.batt_duration ? parseInt(trackerTelemetry.batt_duration) : 0

  const flightTimeRemaining =
    droneTelemetry?.flight_time_remaining ? parseInt(droneTelemetry.flight_time_remaining) :
    trackerTelemetry?.flight_time_remaining ? parseInt(trackerTelemetry.flight_time_remaining) : 0

  const flightTimeCapacity =
    droneTelemetry?.flight_time_capacity ? parseInt(droneTelemetry.flight_time_capacity) :
    trackerTelemetry?.flight_time_capacity ? parseInt(trackerTelemetry.flight_time_capacity) : 0

  const remainingMinutes = Math.floor(flightTimeRemaining / 60)

  const flightTimePercentage     = Math.floor((flightTimeRemaining / flightTimeCapacity) * 100)
  const colourClass =
    flightTimePercentage > 50 ? classes.ok :
    flightTimePercentage > 30 ? classes.warning :
    flightTimePercentage > 10 ? classes.danger :
    flightTimePercentage > 0  ? classes.dead : classes.invalid

  return (
    <div className={classes.root}>
      { drone &&
        <Media mediaId={drone.avatar} size='thumb' alt={drone.name} style={{ height: '48px' }} />
      }
      <Typography variant='body1'>
        { flight.drone?.name || drone?.name || 'Unknown Aircraft' }
      </Typography>
      <div className={classes.batt}>
        <Typography variant='overline' style={{ lineHeight: '16px' }}>
          { flightTimePercentage || '-' }%
        </Typography>
        <BatteryChargingFull style={{ height: '16px' }} />
        <div className={classes.battOuter}>
          <div style={{ width: `${flightTimePercentage}%` }} className={`${classes.battInner} ${colourClass}`} />
        </div>
        <Typography variant='caption' style={{ lineHeight: '16px' }}>
          {remainingMinutes || '-'}m&nbsp;left
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(DroneAvatar)

