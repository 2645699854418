import formatcoords from 'formatcoords'
import * as L from 'leaflet'
import 'leaflet-mouse-position'
import 'leaflet-mouse-position/src/L.Control.MousePosition.css'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { toast } from 'react-toastify'
import { useGetMyCompanyQuery } from '../../api/accounts'
import usePreference from '../usePreference'

export function MousePosition(){
    const map = useMap()
    const { data: companyData } = useGetMyCompanyQuery()
    const prefs = usePreference(companyData?.data?.company)
    const { isRsaf } = prefs
    
    function convertLatLngToDMS(lat, lng, decimal=true){
        if(decimal){
            return `${lat.toFixed(7)}, ${lng.toFixed(7)}`
        }else{
            return formatcoords(lat, lng).format({latLonSeparator: ', '})
        }
    }
    useEffect(()=>{
        if(!map) return
        let control = L.control.mousePosition({
            formatter: (lng, lat)=>{
                return convertLatLngToDMS(lat, lng, !isRsaf).toString().replace(',', '<br/>')
            }
        }).addTo(map)
        const handleRightClick = function(e){
            if(!navigator.clipboard) return 
            const wrapped = e.latlng.wrap()
            navigator.clipboard.writeText(convertLatLngToDMS(wrapped.lat, wrapped.lng, !isRsaf)).then(()=>{
                toast.info("Copied coordinates to clipboard")
            }).catch((e)=>{
                // seems like when developer tools is opened in chrome, clipboard API will throw a DOMException error, handle silently first, since in production developer tools won't be opened
                console.warn(e)
                console.warn("Document is not in focus, select main window first")
            })
        }

        map.on('contextmenu', handleRightClick)
        return ()=>{
            map.off('contextmenu', handleRightClick)
            map.removeControl(control)
        }
    }, [map, isRsaf])
    return null
}