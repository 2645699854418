import { useEffect } from 'react'
import * as turf from '@turf/turf'
import {
  useMap
} from 'react-leaflet'

import EditFeatureGroupGeoman from '../../../Zones/Editor/components/EditFeatureGroupGeoman'

function RsafEditFeatureGroup({
  templatePolygons,
  geometry,
  onSave,
  setSelectingTemplate
}) {
  const map = useMap()

  useEffect(() => {
    if (geometry) {
      const centroid = turf.centroid(geometry).geometry.coordinates
      const centerLatLng = { lat: centroid[1], lng: centroid[0] }
      map.flyTo(centerLatLng)
    }
  }, [map, geometry])

  return (<EditFeatureGroupGeoman
    templatePolygons={templatePolygons}
    geometry={geometry}
    onSave={onSave}
    setSelectingTemplate={setSelectingTemplate}
  />)
}

export default RsafEditFeatureGroup
