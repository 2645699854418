import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useGetTrackersQuery } from '../api/tracker'
import { trackerName } from '../utils'
import useCompanies from '../components/useCompanies'
import DataTable from '../components/DataTable'
import TrackerDialog from './TrackerDialog'
import {
  Button,
  Chip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
})

function Trackers({ classes, pathname }) {
  const trackerId = pathname.split('/trackers/')[1]

  const [ tracker, setTracker ] = useState(null)
  const [ open,    setOpen    ] = useState(false)

  const { company_ids } = useCompanies()

  const {
    data,
    error,
    isLoading,
    isFetching,
  } = useGetTrackersQuery({ company_ids })

  useEffect(() => {
    const trackers = data?.data?.trackers
    if (trackerId && trackers) {
      const tracker = trackers.find(t => t.tracker_id === trackerId)
      if (tracker) {
        setTracker(tracker)
        document.title = `MyDroneFleets | Trackers | ${trackerName(tracker) || '(Unknown)'}`
        setOpen(true)
      }
    }
  }, [trackerId, data])

  const trackers = data?.data?.trackers || []

  if (isLoading)
    return 'Loading ...'
  if (isFetching)
    return 'Reloading ...'
  if (!isLoading && !isFetching && error)
    toast.error(JSON.stringify(error?.data?.data))

  function handleEditTracker(tracker) {
    setTracker(tracker)
    setOpen(true)
  }

  function handleCloseEdit() {
    setTracker(null)
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Typography align='center' variant='h5'>
        My Trackers
      </Typography>
      <DataTable
        data={trackers}
        columns={[
          { label: 'Name', render: t => trackerName(t) || '-' },
          { label: 'Serial Number', key: 'serial_number' },
          { label: 'Hardware IMEI', key: 'imei' },
          { label: 'SIM Card IMSI / ICCID', render: t => <>{t.imsi || '-'}<br />{t.iccid || '-'}</> },
          { label: 'Notes', render: t => <>
            { t.exclude_conformance && <>
              <Chip label='Excluded from conformance' />
              <br /><br />
            </> }
            { t.notes }
          </> },
          { label: 'Owner', render: t => t.owner?.name || '-' },
          { label: 'Assigned to', render: t => t.assigned_to || '-' },
          { label: '', render: t => <Button size='small' variant='contained'
            onClick={() => handleEditTracker(t)}>Edit</Button> },
        ]}
      />
      { open &&
        <TrackerDialog
          open={open}
          onClose={handleCloseEdit}
          tracker={tracker}
        />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname
})

export default connect(mapStateToProps, null)(withStyles(styles)(Trackers))
