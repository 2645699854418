import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function DroneProvider({ classes, droneProvider }) {
  if (!droneProvider)
    return null

  return (
    <Grid container>
      <Grid item xs={4}>
        Brand
      </Grid>
      <Grid item xs={8}>
        {droneProvider.brand || '-'}
      </Grid>
      <Grid item xs={4}>
        Name
      </Grid>
      <Grid item xs={8}>
        {droneProvider.name || '-'}
      </Grid>
      <Grid item xs={4}>
        Country
      </Grid>
      <Grid item xs={8}>
        {droneProvider.country || '-'}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(DroneProvider)
