import { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { toast }      from 'react-toastify'
import {
  format,
  startOfDay,
  subDays,
} from 'date-fns'
import { useGetTracksQuery } from '../../api/tracker'

// import DataTable from '../components/DataTable'
import WaitDialog   from '../../components/WaitDialog'
import useCompanies from '../../components/useCompanies'

import TableViewRow from './components/Row'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    display:    'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:    theme.spacing(0, 2),
  },
  table: {
    padding: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.common.black,
  },
  footer: {
    position: 'relative',
    bottom: 0,
    padding: theme.spacing(2)
  },
})

function TableView({ classes, history }) {
  const [ daysAgo, setDaysAgo ] = useState(14)
  const since = startOfDay(subDays(new Date(), daysAgo))

  const { company_ids } = useCompanies()
  const { data, error, isLoading } = useGetTracksQuery({
    company_ids,
    after: since.getTime()
  })
  const [ tracks, setTracks ] = useState([])

  useEffect(() => {
    if (data?.data?.flights) {
      const _tracks = data.data.flights.slice()
      _tracks.sort((a, b) => {
        return (a.start < b.start ? 1 :-1)
      })
      setTracks(_tracks)
    }
  }, [data])

  const [ page, setPage ] = useState(0)
  const [ rowsPerPage, setRowsPerPage ] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }


  if (isLoading) {
    return <WaitDialog msg='Loading Tracks...' />
  } else {
    if (error) {
      toast('Error loading Tracker Flights')
      console.log('Error loading Tracker Flights', error)
    } else if (data?.status !== 'success') {
      toast('Failed loading Tracker Flights')
      console.log('Failed loading Tracker Flights', data)
    }
  }

  // return (
  //   <div className={classes.root}>
  //     <DataTable
  //       data={tracksTable}
  //       columns={[
  //         { label: 'UA (Drone / Tracker)', key: }
  //         { label: , key: }
  //         { label: , key: }
  //         { label: , key: }
  //       ]}
  //       initialRowsPerPage={5}
  //       intialSort={{ by: 'first', order: 'desc' }}
  //     />
  //   </div>
  // )

  // Tentative: fixed sorting on latest tracks starrt time first.
  //      TODO: migrate to shared table component (which will have sort).
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div>
        <Typography variant='h5'>
          Track Playback
        </Typography>
        { since && daysAgo &&
          <Typography variant='body2'>
            Since {format(since, 'dd MMM yyyy')} (past {daysAgo} days)
          </Typography>
        }
        </div>
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => { setDaysAgo(daysAgo * 2) }}>
          Load Earlier
        </Button>
      </div>
      <TableContainer className={classes.table}>
        <Table size='small'>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell>Track Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Details</TableCell>
              <TableCell style={{ display: 'flex' }}>
                Start Date / Time
                <ExpandMore />
              </TableCell>
              <TableCell>
                End Date / Time
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tracks?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
              (track, i) => (<TableViewRow track={track} key={i} />)
            ) }
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 100]}
        component='div'
        count={tracks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div className={classes.footer}>
        <Typography variant='body2'>
          <b>Note</b>: For current tracks, see Live Airspace.
          An entry is created automatically if there are no tracks received for 10 minutes.
          There is currently no mapping between Track playbacks and Flight Records.
        </Typography>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(TableView))
