import { connect }         from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import AccountsContextProvider from './AccountsContextProvider'

function AuthenticatedRoute({
  component: Component,
  pathname,
  isLoading,
  user,
  ...rest
}) {
  if (isLoading)
      return null

  return (
    <Route {...rest}
      render={props => (
        user ?
          <AccountsContextProvider>
            <Component {...props} />
          </AccountsContextProvider>
        :
          <Redirect to={`/login?orig_url=${pathname.substring(1)}`} />
      )}
    />
  )
}

const mapStateToProps = state => ({
  user:      state.oidc.user,
  isLoading: state.oidc.isLoadingUser,
  pathname:  state.router.location.pathname,
})

export default connect(mapStateToProps, null)(AuthenticatedRoute)
