export const cfms_prefix = 'CFMS-Flight-ID_'

export function prettyXprizeFlightId(flight_id) {
  return flight_id ? flight_id.slice(-4).toUpperCase() : 'Invalid Flight ID'
}

export function prettyCfmsFlightId(permits) {
  if (!Array.isArray(permits))
    return 'Invalid permits'

  const cfmsPermits = permits.filter(p => (
    p.authority === 'SG-CAAS' && p.ref?.includes(cfms_prefix)
  ))
  if (cfmsPermits.length === 0)
    return 'No CFMS Flight ID found'
  if (cfmsPermits.length > 1)
    return 'More than 1 CFMS Flight ID found!'

  return cfmsPermits[0].ref.slice(cfms_prefix.length)
}

export function makeDuration(since) {
  const now = Date.now()
  const duration_seconds = Math.round((now - since) / 1000)
  return {
    hours:   Math.floor(duration_seconds / 3600),
    minutes: Math.floor(duration_seconds / 60) % 60,
    seconds: Math.floor(duration_seconds % 60),
  }
}