import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width:   '100%',
    padding: theme.spacing(2),
  },
  cards: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  card: {
    margin: theme.spacing(1),
    width: theme.spacing(35),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: theme.spacing(15),
  },
  rightAlign: {
    marginLeft: 'auto',
  }
})

function External({ classes }) {

  return (
    <div className={classes.root}>
      <Typography align='center' variant='h5'>
        Connected Apps
      </Typography>

      <div className={classes.cards}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media}
              image='https://upload.wikimedia.org/wikipedia/commons/a/a4/PenAir_Flight_3296（N686PA）_after_crash_landing.jpg'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Incident Report
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Report incidents on Unified Incident Management System
              </Typography>
              <Typography variant='caption'>(on AppSheet)</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' variant='contained' color='secondary'
              onClick={() => { window.open('https://incident.mydronefleets.com/', '_blank') }}>
              Launch UIMS
            </Button>
          </CardActions>
        </Card>

        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media}
              image='https://mydronefleets.com/wp-content/uploads/2022/04/drz-dts-1024x768.jpg'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                Drone Test Site
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Book and use DRZ Iskandar Drone Test Site (DTS)
              </Typography>
              <Typography variant='caption'>(on AppSheet)</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' variant='contained' color='secondary'
              onClick={() => { window.open('https://mydronefleets.com/drz-iskandar-dts-booking-form/', '_blank') }}>
              Book DTS
            </Button>
            <Button size='small' variant='outlined'
              onClick={() => { window.open('https://mydronefleets.com/drz-iskandar-dts/', '_blank') }}>
              Learn More
            </Button>
          </CardActions>
        </Card>

        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media}
              image='https://static.wixstatic.com/media/698cbe_5a262c6a4aee4588acdc4419889454cf~mv2.png/v1/fill/w_1480,h_722,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/698cbe_5a262c6a4aee4588acdc4419889454cf~mv2.png'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                OneSky UTMS
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Launch OneSky UTM System @ DRZ Iskandar Drone Test Site
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' variant='contained' color='secondary'
              onClick={() => { window.open('https://caas.oneskysystems.com/', '_blank') }}>
              Launch OneSky
            </Button>
          </CardActions>
        </Card>
      </div>

      <hr />

      <Typography align='center' variant='h5'>
        Other Services
      </Typography>

      <div className={classes.cards}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media}
              image='https://live.staticflickr.com/3112/3133822075_de371f53cd_b.jpg'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                NOTAM @ SG
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Browse NOTAMs from Malaysia, Singapore, and Thailand
              </Typography>
              <Typography variant='caption'>(on AppSheet)</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' variant='contained' color='secondary'
              onClick={() => { window.open('https://notam.mydronefleets.com/', '_blank') }}>
              NOTAMS
            </Button>
          </CardActions>
        </Card>

        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media}
              image='https://uato.sg/wp-content/uploads/2020/10/theory-class2a.jpg'
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                UATO Students
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Complete your assignments from UATO using MyDroneFleets
              </Typography>
              <Typography variant='caption'>(on AppSheet)</Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size='small' variant='contained' color='secondary'
              onClick={() => { window.open('https://student.uato.sg/', '_blank') }}>
              UATO Student App
            </Button>
          </CardActions>
        </Card>
      </div>

    </div>
  )
}

export default withStyles(styles)(External)
