import { useEffect, useRef } from 'react'
import moment          from 'moment'
import * as turf       from '@turf/turf'
import { GeoJSON }     from 'react-leaflet'
import featureToMarker from './featureToMarker'

function PublicNfzPolygon({ nfzGeoJson, geoJsonArray, isLocked, onClick }) {

  const geojsonRef = useRef()

  useEffect(() => {
    geojsonRef.current?.eachLayer(layer => {
      layer.off('click')
      layer.on('click', (event) => {
        if (isLocked?.current) {
          layer.unbindPopup()
          return
        }

        if (onClick) return onClick(event)
        
        const { lat, lng } = event.latlng
        const selectedPoint = turf.point([lng, lat])

        const selectedNfzs = geoJsonArray
          .filter(nfz => {
            const geoJsonGeometry = nfz.geometry
            const coordinates = geoJsonGeometry.coordinates
            let polygon = null

            if (geoJsonGeometry.type === 'Polygon') {
              polygon = turf.polygon(coordinates)
            } else if (geoJsonGeometry.type === 'MultiPolygon') {
              polygon = turf.multiPolygon(coordinates)
            }

            return polygon && turf.booleanPointInPolygon(selectedPoint, polygon)
          })
          .map(nfz => {
            return nfz.validity
              .map(v => {
                const start = moment.unix(v.start).format('DD MMM hh:mm A')
                const end = moment.unix(v.end).format('DD MMM hh:mm A')

                if (nfz.nfz_type === 'temp') {
                  return `<div class="nfz-popup-content">${nfz.name} (${start} - ${end}) </div>`
                } else {
                  return `<div class="nfz-popup-content">${nfz.name}</div>`
                }
              })
              .join('')
          })

        if (selectedNfzs.length > 0) {
          const popupContent = `
          <Popup class="nfz-popup">
            <div class="nfz-popup-title">Selected Airspaces</div>
            ${selectedNfzs.join('')}
          </Popup>`

          layer.bindPopup(popupContent)
        }
      })
    })
  }, [geoJsonArray, isLocked, onClick])

  // This is not HTML style, it's SVG path option
  // Read https://leafletjs.com/reference.html#path-option
  function pathOption(properties) {
    return {
      color: properties.color,
      weight: 2,
      fillOpacity: 0.2,
      fillColor: properties.color,
      className: properties.customCssClassName,
    }
  }

  return (
    <GeoJSON
      ref={geojsonRef}
      data={nfzGeoJson}
      style={() => pathOption(nfzGeoJson.properties)}
      pointToLayer={(feature, latlng) => featureToMarker(feature.properties.icon, latlng)}
    />
  )
}

export default PublicNfzPolygon

