import { useEffect, useState, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useGetTrackersQuery }  from '../api/tracker'
import {
  useGetDronesQuery,
  useGetFlightsQuery
} from '../api/aircraft'
import { useGetViolationsQuery } from '../api/conformance'

import useCompanies      from '../components/useCompanies'
import useTracks         from './components/useTracks'
import useErrors         from './components/useErrors'
import useRefreshOnline  from './components/useRefreshOnline'

import CardSlide         from '../components/CardSlide'
import MainControlPanel  from './components/MainControlPanel'
import RealTimeAdvisory  from './components/RealTimeAdvisory'

import BaseMap       from './BaseMap'
import OnlinePanel   from './OnlinePanel'
import SingleTracker from './Data/SingleTracker'
import SingleDrone   from './Data/SingleDrone'
import NfzControls   from './NfzControls'
import FlyingPanel   from './FlyingPanel'

import FlightCtrlPanel  from './FlightCtrlPanel'  
import RecentViolations from './RecentViolations'
import RsafViolations from './plugins/rsaf/RsafViolations'
// HTX plugin
import CfmsControlPanel from './plugins/htx/CfmsControlPanel'
// import CfmsFlightViolationListener from './plugins/htx/CfmsFlightViolationListener'

// XPRIZE plugin
import XprizeControlPanel from './plugins/xprize/XprizeControlPanel'
import { withStyles } from '@material-ui/core'
import AirspaceDetailsPanel from '../Airspace/components/AirspaceDetailsPanel'
import useNfzValidity from '../components/useNfzValidity'
import { useGetPrivateNFZsQuery } from '../api/airspace'

const styles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }
})

function DashboardView({
  classes,
  shrinkDrawer,
}) {
  const [ allDrones,        setAllDrones        ] = useState({}) // [id]: drone
  const [ allTrackers,      setAllTrackers      ] = useState({}) // [id]: tracker
  const [ violationSummary, setViolationSummary ] = useState({}) // [ua_id]: violation info
  const cbSetAllDrones   = useCallback(setAllDrones,   [ setAllDrones   ])
  const cbSetAllTrackers = useCallback(setAllTrackers, [ setAllTrackers ])

  const [ selectedDroneId,   setSelectedDroneId   ] = useState('')    // only this
  const [ selectedTrackerId, setSelectedTrackerId ] = useState('')    // or this can be set, not together
  const [ followMarker,      setFollowMarker      ] = useState(false) // follow the above selectedDrone or selectedTracker (one only)

  const [ selectedFlightId,   setSelectedFlightId   ] = useState('')
  const [ selectedGeofenceId, setSelectedGeofenceId ] = useState('')
  const [ selectedReadyToUse, setSelectedReadyToUse ] = useState('')
  
  // Mutually exclusive selection
  useEffect(() => { 
    if(selectedGeofenceId){
      setSelectedFlightId('')
      setSelectedReadyToUse('') 
    }
  }, [selectedGeofenceId])
  
  useEffect(() => { 
    if(selectedFlightId){
      setSelectedGeofenceId('')
      setSelectedReadyToUse('')
    }
  }, [selectedFlightId])
  
  useEffect(() => { 
    if(selectedReadyToUse){
      setSelectedGeofenceId('')
      setSelectedFlightId('') 
    }
  }, [selectedReadyToUse])

  // Plugin states
  const [ cfmsFlights,    ] = useState(null)
  const [ cfmsViolations, ] = useState(null)
  const [ rsafEditingFlightGeofence , setRsafEditingFlightGeofence] = useState(false)
  
  //
  // Commented values are valid and default to "off"
  //
  // 'nfz',     // deprecate -> space-*-nfz
  // 'tracks',  // deprecate -> move-tracks
  // 'vessels', // deprecate -> space-vessel-nfz
  // 'plugins', // deprecate -> settings-plugins-*
  //
  const [ showOnMap, setShowOnMap ] = useState({
    // 'drone-card'        : true,
    // 'tracker-card'      : true,

    'space-static-nfz'     : true,
    'space-temp-nfz'       : true,
    // 'space-vessel-nfz'  : true,
    'space-estate-nfz'     : true,
    'space-places'         : true,
    'space-approved-frz'   : true,
    // 'space-pending-frz' : true,
    // 'space-my-location' : true,
    // 'space-fit-bounds'  : true,
    'space-geofences'      : true,

    'drone-flights'        : true,
    'drone-tracks'         : true,
    'drone-violations'     : true,

    // 'weather'           : true,

    // 'safety'            : true,

    // 'settings'          : true,
    // 'plugins-demo'      : true,
    'plugins-htx'          : true,
    'plugins-rsaf'         : true,
    'plugins-xprize'       : true,

    // 'htx-nfz'           : true,
    // 'htx-tracks'        : true,
    // 'htx-flights'       : true,
    // 'htx-violations'    : true,

    'rsaf-geofences'       : true,
    'rsaf-violations'      : true,

    'xprize-places'        : true,
    'xprize-flights'       : true,
    'xprize-violations'    : true,
  })

  // Accounts, Errors
  const { isHtx, isRsaf, isXprize, company_ids } = useCompanies()
  useErrors({ company_ids })

  // Drones, Trackers, Flights, Violations
  const {
    data: droneData,
    error: droneError,
    isLoading: droneIsLoading,
  } = useGetDronesQuery({ company_ids }, { skip: !company_ids })
  const {
    data: trackerData,
    error: trackerError,
    isLoading: trackerIsLoading
  } = useGetTrackersQuery({ company_ids }, { skip: !company_ids })

  const {
    data: flights,
    isLoading: isLoadingFlights
  } = useGetFlightsQuery({ company_ids, status: 'flying,preflight'} , { skip: !company_ids})
  
  // only filter flights that are active or inactive
  const activeFlights = useMemo(()=>{
    if(isLoadingFlights || !flights?.entities) return {}

    return Object.keys(flights?.entities).filter((k) => flights.entities[k].status === 'flying' || flights.entities[k].status === 'preflight').reduce((prev, v) =>{
      return {
          ...prev, 
        [v]: flights.entities[v]
      }
    }, {})
  }, [flights, isLoadingFlights])

  const {
    data: violationData,
    error: violationError,
    isLoading: violationIsLoading
  } = useGetViolationsQuery({ company_ids }, { skip: !company_ids })

  if (!droneIsLoading && droneError) {
    // toast.error('Error loading drones') // hiding for OneAviation
    console.log('Error loading drones', droneError)
  }
  if (!trackerIsLoading && trackerError) {
    // toast.error('Error loading trackers') // hiding for OneAviation
    console.log('Error loading trackers', trackerError)
  }
  // if (!flightIsLoading && flightError) {
  //   // toast.error('Error loading flights') // hiding for OneAviation
  //   console.log('Error loading flights', flightError)
  // }
  if (!violationIsLoading && violationError) {
    // toast.error('Error loading violations') // hiding for OneAviation
    console.log('Error loading violations', violationError)
  }
  
  const [ nfzValidity ] = useNfzValidity('PrivateAirspace')

  const { data: nfzData } = useGetPrivateNFZsQuery({
    company_ids:    company_ids, // backwards compatible. future: all must provide company_ids
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
  })

  const nfzs = useMemo(() => !nfzData ? {} : nfzData.entities, [nfzData])

  useEffect(() => {
    // currently doing the transformation here TODO transform it at api level
    const droneArray = droneData?.data?.drones
    const newAllDrones = {}
    if (droneArray) {
      for (const d of droneArray)
        newAllDrones[d.drone_id] = d
      setAllDrones(newAllDrones)
    }
  }, [droneData])

  useEffect(() => {
    // currently doing the transformation here TODO transform it at api level
    const trackerArray = trackerData?.data?.trackers
    const newAllTrackers = {}
    if (trackerArray) {
      for (const t of trackerArray)
        newAllTrackers[t.tracker_id] = t
      setAllTrackers(newAllTrackers)
    }
  }, [trackerData])

  useEffect(() => {
    if (!violationData || violationData.ids.length === 0) {
      setViolationSummary({})
      return
    }
    const summ = {}
    violationData.ids.forEach(v_id => {
      const v = violationData.entities[v_id]
      if (!summ[v.ua_id])
        summ[v.ua_id] = {
          breach_keep_in_areas: {},
          breach_keep_out_areas: {},
          warning_keep_in_areas: {},
          warning_keep_out_areas: {},
        }
      const s = summ[v.ua_id]
      if (!s.ua_telem_type)
        s.ua_telem_type = v.ua_telem_type
      if (!s.company_id)
        s.company_id = v.company_id
      if (v.flight_id && !s.flight_id)
        s.flight_id = v.flight_id
      if (v.created_on)
        s.last_occurance = new Date(v.created_on).getTime()
      if (v.area_id) {
        if (v.status === 'WARNING-KEEPOUT') {
          if (s.breach_keep_out_areas[v.area_id])
            delete s.breach_keep_out_areas[v.area_id]
          s.warning_keep_out_areas[v.area_id] = v.area_type
        }
        else if (v.status === 'BREACHED-KEEPOUT') {
          if (s.warning_keep_out_areas[v.area_id])
            delete s.warning_keep_out_areas[v.area_id]
          s.breach_keep_out_areas[v.area_id] = v.area_type
        }
        else if (v.status === 'WARNING-KEEPIN') {
          if (s.breach_keep_in_areas[v.area_id])
            delete s.breach_keep_in_areas[v.area_id]
          s.warning_keep_in_areas[v.area_id] = v.area_type
        }
        else if (v.status === 'BREACHED-KEEPIN') {
          if (s.warning_keep_in_areas[v.area_id])
            delete s.warning_keep_in_areas[v.area_id]
          s.breach_keep_in_areas[v.area_id] = v.area_type
        }
      }
    })
    setViolationSummary(summ)
  }, [violationData])

  useRefreshOnline({ allDrones, setAllDrones, allTrackers, setAllTrackers })

  // TODO: Move to a dedicated useViolations
  useEffect(() => {
    const violation_ids = violationData?.ids // already ordered by created_on
    if (!(violation_ids?.length > 0))
      return

    const violations = violationData?.entities

    setAllDrones(prevAD => {
      const now = Date.now()
      const droneUpdates = {}
      for (const v_id of violation_ids) {
        const ua_id = violations[v_id].ua_id
        if (prevAD[ua_id]) {
          droneUpdates[ua_id] = {
            ...prevAD[ua_id],
            violations: { ...violations[v_id] }, // only one, latest one
            violations_last_updated: now
          }
        }
      }
      return { ...prevAD, ...droneUpdates }
    })
    setAllTrackers(prevAT => {
      const now = Date.now()
      const trackerUpdates = {}
      for (const v_id of violation_ids) {
        const ua_id = violations[v_id].ua_id
        if (prevAT[ua_id]) {
          trackerUpdates[ua_id] = {
            ...prevAT[ua_id],
            violations: { ...violations[v_id] }, // only one, latest one
            violations_last_updated: now
          }
        }
      }
      return { ...prevAT, ...trackerUpdates }
    })
  }, [violationData])

  // Map
  function addToMap(feature) {
    if (!showOnMap[feature])
      setShowOnMap(prevSom => {
        prevSom[feature] = true
        return {...prevSom}
      })
  }
  // function addToMap(feature) {
  //   if (!showOnMap.includes(feature))
  //     setShowOnMap([ ...showOnMap, feature ])
  // }
  function removeFromMap(feature) {
    if (showOnMap[feature])
      setShowOnMap(prevSom => {
        prevSom[feature] = false
        return {...prevSom}
      })
  }
  // function removeFromMap(feature) {
  //   if (showOnMap.includes(feature))
  //     setShowOnMap(showOnMap.filter(f => f !== feature))
  // }
  function toggleOnMap(feature) {
    setShowOnMap(prevSom => {
      prevSom[feature] = !prevSom[feature]
      return {...prevSom}
    })
  }

  // function toggleOnMap(feature) {
  //   if (showOnMap.includes(feature))
  //     setShowOnMap(showOnMap.filter(f => f !== feature))
  //   else
  //     setShowOnMap([ ...showOnMap, feature ])
  // }

  function toggleShowOnMap(pluginPrefix, pluginFeatures, features) {
    pluginFeatures.forEach(f => {
      if (features.includes(f))
        addToMap(pluginPrefix + f)
      else
        removeFromMap(pluginPrefix + f)
    })
  }
  function handleHtxShowOnMap(features) {
    toggleShowOnMap('htx-', ['nfz', 'tracks', 'flights', 'violations'], features)
  }
  function handleXprizeShowOnMap(features) {
    toggleShowOnMap('xprize-', ['places', 'flights', 'violations'], features)
  }

  // Tracks
  const { tracks } = useTracks({ company_ids, isXprize, cbSetAllDrones, cbSetAllTrackers })

  function handleCardClose() {
    removeFromMap('drone-card')
    removeFromMap('tracker-card')
    setSelectedDroneId('')
    setSelectedTrackerId('')
  }
  function handleDroneClick(drone_id) {
    setSelectedDroneId(drone_id)
    setSelectedTrackerId('')
    removeFromMap('tracker-card')
    addToMap('drone-card')
  }
  function handleTrackerClick(tracker_id) {
    setSelectedTrackerId(tracker_id)
    setSelectedDroneId('')
    removeFromMap('drone-card')
    addToMap('tracker-card')
  }
  function handleTrackMarkerClicked(track) {
    if (track.ua_telem_type === 'aircraft')
      handleDroneClick(track.ua_id)
    else if (track.ua_telem_type === 'tracker')
      handleTrackerClick(track.ua_id)
  }

  // TODO: This should switch based solely on Tracker Model categories
  //       and type needs to be valid values
  //       See tracker-web/models/TrackerModels (category) for details
  function handleDroneMarkerClicked(track) {
    // Legacy, had 5 usage
    //   1. TrackMarkers >> can be tracker track or drone track or legacy tracker track
    //   2. DemoTrackerMarkers
    //   3. CfmsTrackMarkers
    //   4. Tracks randomly injected from backend
    //   5. Tracks randomly injected from frontend
    // Use case 1 has been moved over to handleTrackMarkerClicked, other 4 pending investigation

    const selectedId = track.id || track.imei || track.IMEI || ''
    setSelectedTrackerId(selectedId)
    addToMap('tracker-card')

    // This is for trackers that are injected from the front end
    // We only add them in when we click on them. They include the telem,
    // so this results in the updating of the allTrackers array every second
    if (selectedId) {
      setAllTrackers(prevAT => ({
        ...prevAT,
        [selectedId]: { tracker_id: selectedId, imei: selectedId, ...track }
      }))
      // setAllTrackers(prevAllTrackers => ([
      //   { imei: track.imei, ...track },
      //   ...prevAllTrackers.filter(t => (t.imei !== track.imei))
      // ]))
    }
  }

  function handleZoomtoFit() {
    toast.info('Coming Soon')
  }

  const selectedFlight = useMemo(()=> activeFlights[selectedFlightId] , [activeFlights, selectedFlightId])
  const selectedReadyToUseEntity = useMemo(()=> nfzs[selectedReadyToUse] , [nfzs, selectedReadyToUse])

  return <div className={classes.root}>
    <BaseMap
      showOnMap={showOnMap}
      company_ids={company_ids}
      isHtx={isHtx}
      isRsaf={isRsaf}
      isXprize={isXprize}
      tracks={tracks}
      drones={allDrones}
      flights={flights}
      nfzs={nfzs}
      selectedDroneId={ selectedDroneId}
      trackers={allTrackers}
      selectedTrackerId={selectedTrackerId}
      // cfmsTracks={cfmsTracks} // temporarily disabled
      cfmsFlights={cfmsFlights}
      onDroneMarkerClicked={handleDroneMarkerClicked}
      onTrackMarkerClicked={handleTrackMarkerClicked}
      
      selectedGeofenceId={selectedGeofenceId}
      setSelectedGeofenceId={setSelectedGeofenceId}

      followMarker={followMarker}
      flight={selectedFlight}
      rsafEditingFlightGeofence={rsafEditingFlightGeofence}
      selectedReadyToUse={selectedReadyToUse}
      setSelectedReadyToUse={setSelectedReadyToUse}
    />
    { selectedReadyToUse && <AirspaceDetailsPanel nfz={selectedReadyToUseEntity} onClose={()=> setSelectedReadyToUse(null)}/>}
    <OnlinePanel
      isLoading={droneIsLoading || trackerIsLoading}
      drones={allDrones}
      trackers={allTrackers}
      flights={activeFlights}
      onDroneClick={handleDroneClick}
      onTrackerClick={handleTrackerClick}
    />
    <MainControlPanel
      showOnMap={showOnMap}
      isHtx={isHtx}
      isRsaf={isRsaf}
      isXprize={isXprize}
      onToggle={toggleOnMap}
      onZoomToFit={handleZoomtoFit}
    />
    <RealTimeAdvisory
      shrinkDrawer={shrinkDrawer}
      showWeather={showOnMap['weather']}
    />
    <NfzControls />
    { showOnMap['drone-flights'] &&
      <FlightCtrlPanel
        flights={Object.values(activeFlights)}
        tracks={tracks}
        violations={violationData}
        setSelectedFlightId={setSelectedFlightId}
        selectedFlightId={selectedFlightId}
        selectedGeofenceId={selectedGeofenceId}
        onClickGeofence={ag => setSelectedGeofenceId(ag.nfz_id)}
        summary={violationSummary}
        onTrackerClick={handleTrackerClick}
        onDroneClick={handleDroneClick}
        isRsaf={isRsaf}
      />
    }

    <RecentViolations
      summary={violationSummary}
      show={showOnMap['drone-violations']}
      onDroneClick={handleDroneClick}
      onTrackerClick={handleTrackerClick}
    />
  
    { isHtx && showOnMap['plugins-htx'] &&
      <CfmsControlPanel
        cfmsFlights={cfmsFlights}
        cfmsViolations={cfmsViolations}
        onToggleShowOnMap={handleHtxShowOnMap}
      />
    }
    { isXprize && showOnMap['plugins-xprize'] &&
      <XprizeControlPanel
        tracks={tracks}
        violationData={violationData}
        selectedFlightId={selectedFlightId}
        setSelectedFlightId={setSelectedFlightId}
        onToggleShowOnMap={handleXprizeShowOnMap}
        onDroneClick={handleDroneClick}
        onTrackerClick={handleTrackerClick}
      />
    }
    { isRsaf && <RsafViolations
        violations={Object.values(violationData?.entities || {})}
        onDroneClick={handleDroneClick}
        onTrackerClick={handleTrackerClick}
      />}
    { selectedFlight &&
      <FlyingPanel
        editGeofence={rsafEditingFlightGeofence}
        setEditingGeofence={setRsafEditingFlightGeofence}
        flight={selectedFlight}
        drone={selectedFlight.drone?.id ? allDrones[selectedFlight.drone.id] : null}
        droneTelemetry={selectedFlight.drone?.id ? tracks[selectedFlight.drone.id] : null}
        trackerTelemetry={selectedFlight.drone?.tracker_ids?.length > 0 ? tracks[selectedFlight.drone.tracker_ids[0]] : null}
        onClose={() => {
          setSelectedFlightId('')
          setRsafEditingFlightGeofence(false)
        }}
      />
    }
    { showOnMap['drone-card'] &&
      <CardSlide subheader='Drone' onClose={handleCardClose} showCard={showOnMap['drone-card']}>
        <SingleDrone
          drone={allDrones[selectedDroneId]}
          flights={Object.values(activeFlights).filter(f => f.drone.id === selectedDroneId && f.status === 'flying')}
          telem={selectedDroneId ? tracks[selectedDroneId] : null}
          onClose={handleCardClose}
          onFollowDroneClick={checked => { setFollowMarker(!!checked) }}
        />
      </CardSlide>
    }
    { showOnMap['tracker-card'] &&
      <CardSlide subheader='Tracker' onClose={handleCardClose} showCard={showOnMap['tracker-card']}>
        <SingleTracker
          tracker={allTrackers[selectedTrackerId]}
          flights={Object.values(activeFlights).filter(f => f.drone.tracker_ids?.includes(selectedTrackerId) && f.status === 'flying')}
          telem={selectedTrackerId ? tracks[selectedTrackerId] : null}
          onClose={handleCardClose}
          onFollowTrackerClick={checked => { setFollowMarker(!!checked) }}
        />
      </CardSlide>
    }
  </div>
}

export default withStyles(styles)(DashboardView)

  //
  // No more combining this way. Individual telem type gets its own card. Leaving around for troubleshooting
  //
  // const selectedTrack     = Object.values(tracks).find(track => (track.IMEI === selectedTrackerId))
  // const selectedCFMSTrack = Object.values(cfmsTracks).find(track => (track.data_provider?.reference_data?.reference_id === selectedTrackerId))
  // const selectedDemoTrack = allTrackers.find(t => t.IMEI === selectedTrackerId)
  // const trackerTelem      = selectedTrack || selectedCFMSTrack || selectedDemoTrack

    /* <CfmsFlightViolationListener
      turnOnFlightsListener={cfmsFlights !== null}
      turnOnViolationListener={cfmsViolations !== null}
      onFlightMessage={handleFlightMessage}
    />*/

  // function handleFlightMessage(event) {
  //   const flightData = JSON.parse(event.data)

  //   if (flightData.event_type === 'CREATED')
  //     setCfmsFlights([
  //       flightData.flight,
  //       ...cfmsFlights
  //     ])
  //   else if (flightData.event_type === 'UPDATED')
  //     setCfmsFlights([
  //       flightData.flight,
  //       ...cfmsFlights.filter(f => (f.flight_id !== flightData.flight.flight_id))
  //     ])
  //   else if (flightData.event_type === 'DELETED')
  //     setCfmsFlights([
  //       ...cfmsFlights.filter(f => (f.flight_id !== flightData.flight.flight_id))
  //     ])
  // }

    // Temporarily disabled until backend reworked
    // if (features.includes('flights')) {
    //   getFlights() // This used to retrieve, from CFMS, a list of start / stop
    //   .then(result => {
    //     setCfmsFlights(result) // turn on Flights
    //   })
    // } else {
    //   setCfmsFlights(null) // turn off Flights
    // }

    // Temporarily disabled until we rewrite our own Conformance Service
    // or connect it properly to OneSky's Conformance Service
    // if (features.includes('violations')) {
    //   getViolations()
    //   .then(result => {
    //     setCfmsViolations(result.data)
    //   })
    // } else {
    //   setCfmsViolations(null)
    // }
