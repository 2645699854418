import userManager from '../../utils/user-manager'

import {
  useActiveAppRolesQuery,
  useGetMyUserQuery,
  getAccountsURL,
} from '../../api/accounts'

import Media from '../../components/Media'
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  AccountCircle,
  ExitToApp,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  small: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  appRoles: {
    padding: theme.spacing(0, 0, 1, 9)
  },
})

function go(pathname) {
  const url = getAccountsURL()
  url.pathname = pathname
  window.open(url, 'plex-accounts')
}

function AccountListItem({ classes, width, drawerOpen, history }) {
  const { data, isLoading, error } = useGetMyUserQuery()
  const userInfo = data?.data.user

  if (isLoading)
    return null
  if (error) {
    console.log('Error fetching user', error)
    return null
  }
  if (data?.status !== 'success') {
    console.log('Failed fetching user', data)
    return null
  }

  function handleSignOut(e) {
    e.preventDefault()
    userManager.signoutRedirect()
    .then(() => {
      history.push('/login')
    })
    .catch(e =>
      console.error('Error while signing out user: ', e)
    )
  }

  return (<>
    <ListItem button
      onClick={() => { go('/general/profile') }}
      style={{ width, paddingLeft: '8px' }}>
      <ListItemAvatar>
        <Avatar className={drawerOpen ? classes.large : classes.small}>
          { userInfo.avatar ?
            <Media mediaId={userInfo.avatar} size='thumb' alt={userInfo.username}
              fill />
          : userInfo.name?.charAt(0) || <AccountCircle />
          }
        </Avatar>
      </ListItemAvatar>
      { drawerOpen &&
        <ListItemText
          primary={userInfo.name}
          secondary={userInfo.email}
        />
      }
    </ListItem>
    { drawerOpen && <>
      <div className={classes.appRoles}>
        <MyDroneFleetsRoles roles={userInfo.app_roles?.my_drone_fleets} />
      </div>
      <ListItem button
        onClick={(e) => handleSignOut(e)}
        style={{ width }}>
        <ListItemIcon><ExitToApp /></ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItem>
    </> }
  </>)
}

function MyDroneFleetsRoles({ roles }) {
  const { data } = useActiveAppRolesQuery()
  const allRoles = data?.data?.app_roles || []
  //TODO: Resolve the root cause of this!! Users with no MDF app roles shouldn't be able to access MDF
  const mdfRoles = allRoles.filter(r => r.app === 'my_drone_fleets' && roles?.includes(r.role))
  /**
   * Tooltip disabled, until we have plugin based description
   * <Tooltip key={r.role} title={r.description} placement='right' style={{ cursor: 'pointer' }}>
        <Typography>{r.name}</Typography>
   * </Tooltip>
   */
  return (<>
    { mdfRoles.map(r =>
        <Typography key={r.role}>{r.name}</Typography>
    ) }
  </>)
}

export default withStyles(styles)(AccountListItem)
