import { useState } from 'react'
import { trackerName } from '../../utils'
import Pill from '../../components/Pill'
import Header from './Header'
import Status from './Status'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  Gavel,
} from '@material-ui/icons'
import DroneMarkerIcon from '../../components/Icons/DroneMarkerIcon'
import { withStyles } from '@material-ui/core/styles'
import TrackerIcon from '../../components/Icons/TrackerIcon'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    zIndex: 2,
    bottom: 0,
    right:  0,
    marginRight:  theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  body: {
    overflow: 'scroll',
    backgroundColor: theme.palette.common.black,
    width: theme.spacing(36),
  },
  open: {
    maxHeight: theme.spacing(40),
    scrollbarWidth: 'none'
  },
  close: {
    maxHeight: 0
  },
  text: {
    color: theme.palette.common.white,
  },
  tab: {
    minWidth: theme.spacing(18), // override default 20 (160px)
  },
})

const droneSortOrder = (a, b) => (a.name.localeCompare(b.name))
const flightSortOrder = (a, b) => (a?.last_modified_on > (b?.last_modified_on)) // TODO sort time
const trackerSortOrder = (a, b) => (a.name.localeCompare(b.name)) // used to be imei, but xprize names will be out of order

function OnlinePanel({ classes, isLoading, drones, trackers, flights, onDroneClick, onTrackerClick}) {
  const [open, setOpen] = useState(true)
  const droneArray = Object.values(drones).sort(droneSortOrder)
  const trackerArray = Object.values(trackers).sort(trackerSortOrder)
  const flightsArray = Object.values(flights).sort(flightSortOrder)

  // violating drones, then violating trackers, then
  // online drones, then online trackers, then offline drones, then offline trackers
  const violatingDrones = droneArray.filter(d => d.violations)
  const violatingTrackers = trackerArray.filter(t => t.violations)
  const onlineDrones = droneArray.filter(d => d.online && !d.violations)
  const offlineDrones = droneArray.filter(d => !d.online && !d.violations)
  const onlineTrackers = trackerArray.filter(t => t.online && !t.violations)
  const offlineTrackers = trackerArray.filter(t => !t.online && !t.violations)

  return (
    <div className={classes.root}>
      <Header
        droneArray={droneArray}
        trackerArray={trackerArray}
        flightsArray={flightsArray}
        open={open}
        setOpen={setOpen}
      />

      <div className={`${classes.body} ${open ? classes.open : classes.close}`}>
        <Box>
            { isLoading ?
              <Pill text='Loading ...' />
            : droneArray.length + trackerArray.length === 0 ?
              <Pill text='No Drones or Trackers' />
            : <List>
                { violatingDrones.map(d => <Item key={d.drone_id} icon={<Gavel />}
                    name={d.name || '-'} online={d.online} appearOnMap={d.lat && d.lng}
                    onClick={e => onDroneClick(d.drone_id)} />)
                }
                { violatingTrackers.map(t => <Item key={t.tracker_id || t.imei} icon={<Gavel />}
                    name={trackerName(t) || '-'} online={t.online} appearOnMap={t.lat && t.lng}
                    fake={t.fake} onClick={e => onTrackerClick(t.tracker_id || t.imei)} />)
                }
                { onlineDrones.map(d => <Item key={d.drone_id} icon={<DroneMarkerIcon />}
                    name={d.name || '-'} online={true} appearOnMap={d.lat && d.lng}
                    onClick={e => onDroneClick(d.drone_id)} />)
                }
                { onlineTrackers.map(t => <Item key={t.tracker_id || t.imei}
                    icon={<TrackerIcon variant={t.exclude_conformance && 'exclude_conformance'} />}
                    name={trackerName(t) || '-'} online={true} appearOnMap={t.lat && t.lng}
                    fake={t.fake} onClick={e => onTrackerClick(t.tracker_id || t.imei)} />)
                }
                { offlineDrones.map(d => <Item key={d.drone_id} icon={<DroneMarkerIcon />}
                    name={d.name || '-'} online={false} appearOnMap={false}
                    onClick={e => onDroneClick(d.drone_id)} />)
                }
                { offlineTrackers.map(t => <Item key={t.tracker_id || t.imei}
                    icon={<TrackerIcon variant={t.exclude_conformance && 'exclude_conformance'} />}
                    name={trackerName(t) || '-'} online={false} appearOnMap={false}
                    onClick={e => onTrackerClick(t.tracker_id || t.imei)} />)
                }
              </List>
            }
        </Box>
      </div>

    </div>
  )
}

function Item({ icon, name, online, appearOnMap, fake = false, onClick }) {
  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
      <Status online={online} appearOnMap={appearOnMap} fake={fake} />
    </ListItem>
  )
}

export default withStyles(styles)(OnlinePanel)
