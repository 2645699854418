import { useEffect, useState } from 'react'
import { toast }      from 'react-toastify'
import { format }     from 'date-fns'
import { commonFormat, trackerName } from '../../../utils'
import { useLazyGetDroneByIdQuery   } from '../../../api/aircraft'
import { useLazyGetTrackerByIdQuery } from '../../../api/tracker'
import {
  IconButton,
  TableCell,
  TableRow,
} from '@material-ui/core'
import {
  PlayCircleFilled,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function TableViewRow({ classes, track }) {
  const [ getTrackerById    ] = useLazyGetTrackerByIdQuery()
  const [ getDroneById      ] = useLazyGetDroneByIdQuery()
  const [ ua,     setUa     ] = useState(null)
  const [ uaType, setUaType ] = useState('')

  useEffect(() => {
    if (!track)
      return

    if (track.tracker_id) {
      getTrackerById(track.tracker_id)
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status !== 'success') {
          toast.warning('Failed to load tracker for track')
          console.log('load tracker failed', fulfilled)
        } else {
          setUa(fulfilled.data.tracker)
          setUaType('tracker')
        }
      })
      .catch(rejected => {
        toast.error('Error loading tracker for track')
        console.oog('error loading tracker', rejected)
      })
    }
    else if (track.drone_id) {
      getDroneById(track.drone_id)
      .unwrap()
      .then(fulfilled => {
        if (fulfilled.status !== 'success') {
          toast.warning('Failed to load drone for track')
          console.log('load drone failed', fulfilled)
        } else {
          setUa(fulfilled.data.drone)
          setUaType('drone')
        }
      })
      .catch(rejected => {
        toast.error('Error loading drone for track')
        console.oog('error loading drone', rejected)
      })
    }
    else {
      console.log('Found invalid track with no tracker_id or drone_id', track)
    }
  }, [track, getDroneById, getTrackerById])

  return (
    <TableRow hover role='checkbox' tabIndex={-1}>
      <TableCell>
        {uaType}
      </TableCell>
      <TableCell>
        { uaType === 'tracker' ? (trackerName(ua) || 'Unnamed Tracker') :
          uaType === 'drone'   ? (ua.name         || 'Unnamed Drone'  ) : '-'
        }
      </TableCell>
      <TableCell>
        { uaType === 'tracker' ? <>
            Serial Number: {ua.serial_number || 'missing'}<br />
            IMEI: {ua.imei || 'missing'}
        </> : uaType === 'drone' ? <>
            Serial Number: {ua.serial_number || 'missing'}<br />
            {ua.drone_provider?.brand || '-'} | {ua.drone_model?.name || '-'}
        </> : '-' }
      </TableCell>
      <TableCell>
        {track?.start ? format(new Date(track.start), commonFormat) : '-'}
      </TableCell>
      <TableCell>
        {track?.end ? format(new Date(track.end), commonFormat) : '-'}
      </TableCell>
      <TableCell>
        <IconButton onClick={e => { window.open('/playback/' + track.flight_id) }}>
          <PlayCircleFilled />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(TableViewRow)
