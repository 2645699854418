import { useState } from 'react'
import DownloadCSV      from './components/DownloadCSV'
import TrackerSummary   from './components/TrackerSummary'
import BatteryChart     from './components/BatteryChart'
import LocationChart    from './components/LocationChart'
import AltitudeChart    from './components/AltitudeChart'
import MovementChart    from './components/MovementChart'
import OrientationChart from './components/OrientationChart'
import SystemChart      from './components/SystemChart'
import ExpandLogs       from './components/ExpandLogs'

import siteTheme from '../utils/site-theme'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

function UploadRawTracks(props) {
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [serverTimeArray, setServerTimeArray] = useState(undefined)
  const [imeiInTopicArray, setImeiInTopicArray] = useState(undefined)
  const [telemetryArray, setTelemetryArray] = useState(undefined)
  const [trackers, setTrackers] = useState(false)

  // These are "external", part of ICD with DSTA, also eventually for developers
  const [showBatteryChart,     setShowBatteryChart     ] = useState(true)
  const [showLocationChart,    setShowLocationChart    ] = useState(true)
  const [showAltitudeChart,    setShowAltitudeChart    ] = useState(true)
  const [showMovementChart,    setShowMovementChart    ] = useState(true)
  const [showOrientationChart, setShowOrientationChart ] = useState(true)

  // These are "internal", part of system monitoring
  const [showSystemChart,      setShowSystemChart      ] = useState(false)
  const [showExpandLogs,       setShowExpandLogs       ] = useState(false)

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
    setIsFilePicked(true)
  }

  const handleUpload = () => {
    const regex = /^(.*)\s:\s\sdt\/tracker\/(\d+)\/telemetry:\s(.*)$/
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      const
        result = event.target.result,
        lineArray = result.split('\n')
      const
        telemArray = [],
        imeiArray = [],
        timeArray = []

      lineArray.forEach((line) => {
        const match = regex.exec(line)
        try {
          if (match[2].startsWith('80000000'))
            return
          telemArray.push(JSON.parse(match[3]))
          imeiArray.push(match[2])
          timeArray.push(match[1])
        } catch (e) {
          console.info('Ignoring:', line)
        }
      })
      setTelemetryArray(telemArray)
      setImeiInTopicArray(imeiArray)
      setServerTimeArray(timeArray)
      setTrackers(undefined)
    })
    reader.readAsText(selectedFile)
  }

  const handleAnalyze = () => {
    const
      trackers = {},
      telemCount = imeiInTopicArray.length

    for (let i = 0; i < telemCount; i++) {
      const imei = imeiInTopicArray[i]

      telemetryArray[i].serverTime = new Date(serverTimeArray[i])

      if (trackers[imei]) {
        trackers[imei].push(telemetryArray[i])
      } else {
        trackers[imei] = [telemetryArray[i]]
      }
    }
    setTrackers(trackers)
  }

  const useStyles = makeStyles(theme => ({
    body: {
      padding: '16px',
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      padding: '16px',
      border: `1px solid ${theme.palette.primary.main}`,
      textAlign: 'center'
    }
  }))

  const classes = useStyles(siteTheme)

  const chartCheckboxes =
    <Grid container>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='Battery' color='secondary'
          onChange={() => setShowBatteryChart(!showBatteryChart)}
          checked={showBatteryChart} />} label='Battery Chart (bat)' />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='Location' color='secondary'
        onChange={() => setShowLocationChart(!showLocationChart)}
        checked={showLocationChart} />} label='Location Charts (lat, lng, hdop)' />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='Altitude' color='secondary'
        onChange={() => setShowAltitudeChart(!showAltitudeChart)}
        checked={showAltitudeChart} />} label='Altitude Charts (alt, vdop)' />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='Movement' color='secondary'
        onChange={() => setShowMovementChart(!showMovementChart)}
        checked={showMovementChart} />} label='Movement Charts (v, a, bear)' />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='Orientation' color='secondary'
        onChange={() => setShowOrientationChart(!showOrientationChart)}
        checked={showOrientationChart} />} label='Orientation Charts (rol, pit, yaw, head)' />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='System' color='secondary'
        onChange={() => setShowSystemChart(!showSystemChart)}
        checked={showSystemChart} />} label='System Charts (rssi, volt, temp)' />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel control={<Checkbox name='Logs' color='secondary'
        onChange={() => setShowExpandLogs(!showExpandLogs)}
        checked={showExpandLogs} />} label='Expand Logs (from number to label)' />
      </Grid>
    </Grid>

  return (
    <Paper className={classes.body}>
      <p>Select a raw output file from mqtt-raw</p>
      <input type="file" name="file" onChange={changeHandler} />

      { isFilePicked && !trackers && (<>
          <p><b>{selectedFile.name}</b> ({selectedFile.type}, {selectedFile.size} bytes, last modified: {new Date(selectedFile.lastModified).toLocaleString()})</p>
          <Button
            color='secondary'
            variant='contained'
            onClick={handleUpload}>
            Upload
          </Button>
        </>) }

      { telemetryArray && !trackers && (<>
          {chartCheckboxes}
          <Button
            color='secondary'
            variant='contained'
            onClick={handleAnalyze}>
            Analyze
          </Button>
        </>) }

      { telemetryArray && !trackers && (<>
          <p>Number of telemetry lines: {telemetryArray.length}</p>
          <p><DownloadCSV fileName={selectedFile.name} telemetryArray={telemetryArray} /></p>
          <pre>{JSON.stringify(telemetryArray, null, 2)}</pre>
        </>) }

      { telemetryArray && trackers && (<>
         {chartCheckboxes}
         <div className={classes.title}>
           Result of Analysis of {selectedFile.name}
         </div>
         <TrackerSummary trackers={trackers} />
         { showBatteryChart     && <BatteryChart     trackers={trackers} /> }
         { showLocationChart    && <LocationChart    trackers={trackers} /> }
         { showAltitudeChart    && <AltitudeChart    trackers={trackers} /> }
         { showMovementChart    && <MovementChart    trackers={trackers} /> }
         { showOrientationChart && <OrientationChart trackers={trackers} /> }
         { showSystemChart      && <SystemChart      trackers={trackers} /> }
         { showExpandLogs       && <ExpandLogs       trackers={trackers} /> }
       </>) }
    </Paper>
  )
}
export default UploadRawTracks
