import { useLazyGetDroneProvidersQuery } from '../../../api/aircraft'
import TextBox from '../../../components/Inputs/TextBox'

function DroneProviderSelect({model, provider, setProvider, error }) {

  const [ getProviders ] = useLazyGetDroneProvidersQuery()

  return (
    <TextBox
      // TODO: filter by model_id
      get={() => getProviders({ model: model?.model_id })}
      getKey='drone_providers'
      dataId='drone_provider_id'
      dataLabel='name'
      value={provider}
      error={!!error}
      helperText={error}
      onChange={setProvider}
    />
  )
}

export default DroneProviderSelect
