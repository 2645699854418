import {
  GeoJSON,
} from 'react-leaflet'
import theme from '../../../utils/site-theme'

function XprizeFlightGeofence({ flight }) {
  if (!(flight?.geofence))
    return null

  return (
    <GeoJSON
      data={{
        type: 'Feature',
        geometry: flight.geofence,
      }}
      style={{
        color: theme.palette.secondary.light,
        weight: 4,
        fillOpacity: 0.1,
        fillColor: theme.palette.secondary.light,
      }}
    />
  )
}

export default XprizeFlightGeofence
