// Stub for advisory, needs to be a backend service to advise everybody

const EXPIRY_MS = 60000 // 1 minute

let advisories = []

export function getAdvisories() {
  return advisories
}

export function advise(message) {
  advisories = advisories.filter(adv => adv.message !== message)
  advisories.push({
    date: Date.now(),
    message, // e.g. CAT 1 lighting issued for West Singapore and Gelang Patah, all drones advised to postpone operations.
  })
}

setInterval(() => {
  const expiry_date = Date.now() - EXPIRY_MS
  advisories = advisories.filter(a => a.date > expiry_date)
}, EXPIRY_MS)
