import Nfz from '../../../components/Text/Nfz'
import Drone from '../../../components/Text/Drone'
import Flight from '../../../components/Text/Flight'
import Tracker from '../../../components/Text/Tracker'
import { prettyXprizeFlightId } from './xprize-utils'
import {
  ListItem,
  Typography,
} from '@material-ui/core'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  vCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    margin: 0,
    border: 0,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.map.yellow,
    },
  },
  vTitle: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(2, 2, 0, 0),
    fontWeight: 'bold',
  },
  vBody: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  flight: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 1)
  },
  bTitle: {
    padding: theme.spacing(0.5, 1),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
  },
  bBody: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.primary.dark,
  },
  wTitle: {
    padding: theme.spacing(0.5, 1),
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main,
  },
  wBody: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.secondary.dark,
  },
})

// [ua_id]: {
//   ua_telem_type:
//   company_id:
//   flight_id:
//   breach_keep_in_areas: {
//     [area_id]: area_type
//   }
//   breach_keep_out_areas: {
//     [area_id]: area_type
//   }
//   warning_keep_in_areas: {
//     [area_id]: area_type
//   }
//   warning_keep_out_areas: {
//     [area_id]: area_type
//   }
// }

function XprizeViolatingUa({ classes, ua_id, summary, onDroneClick, onTrackerClick }) {

  const name = summary.ua_telem_type === 'aircraft' ? <Drone   id={ua_id} name /> :
               summary.ua_telem_type === 'tracker'  ? <Tracker id={ua_id} name /> :
               'Unknown aircraft'

  function handleClick() {
    summary.ua_telem_type === 'aircraft' && onDroneClick(ua_id)
    summary.ua_telem_type === 'tracker'  && onTrackerClick(ua_id)
  }

  const bKeepIn  = Object.keys(summary.breach_keep_in_areas)
  const bKeepOut = Object.keys(summary.breach_keep_out_areas)
  const wKeepIn  = Object.keys(summary.warning_keep_in_areas)
  const wKeepOut = Object.keys(summary.warning_keep_out_areas)

  const last = Date.now() - summary.last_occurance
  const time_ago = last < 5000 ? 'now' :
    last < 15000 ? '15s ago' :
    last < 30000 ? '30s ago' :
    last < 45000 ? '45s ago' :
    last < 600000 ? `${Math.floor(last / 60000)} min ago` : '10 or more min ago'

  return (
    <ListItem button className={classes.vCard} onClick={handleClick}>
      <div className={classes.vTitle}>
        <Typography variant='subtitle1'>{name}</Typography>
      </div>
      <div className={classes.vBody}>
        { summary.flight_id &&
          <div className={classes.flight}>
            Flight {prettyXprizeFlightId(summary.flight_id)}
          </div>
        }
        { bKeepIn.length + bKeepOut.length > 0 &&
          <>
            <div className={classes.bTitle}>BREACH {time_ago}</div>
            <div className={classes.bBody}>
              { bKeepIn.map(id => (
                <Keep In id={id} key={id} type={summary.breach_keep_in_areas[id]} />
              )) }
              { bKeepOut.map(id => (
                <Keep Out id={id} key={id} type={summary.breach_keep_out_areas[id]} />
              )) }
            </div>
          </>
        }
        { wKeepIn.length + wKeepOut.length > 0 &&
          <>
            <div className={classes.wTitle}>WARNING {time_ago}</div>
            <div className={classes.wBody}>
              { wKeepIn.map(id => (
                <Keep In id={id} key={id} type={summary.warning_keep_in_areas[id]} />
              )) }
              { wKeepOut.map(id => (
                <Keep Out id={id} key={id} type={summary.warning_keep_out_areas[id]} />
              )) }
            </div>
          </>
        }
      </div>
    </ListItem>
  )
}

function Keep({ In, Out, id, type }) {
  const classes = makeStyles(theme => ({
    black: {
      fontWeight: 'bold',
      padding: theme.spacing(0, 1),
      color: theme.palette.background.paper,
      backgroundColor: 'white',
      border: '1px solid white',
    },
    white: {
      fontWeight: 'bold',
      padding: theme.spacing(0, 1),
      color:  'white',
      border: '1px solid white',
    },
  }))()
  return (
    <div style={{ paddingBottom: '8px' }}>
      <div style={{ display: 'flex' }}>
        { In && <>
          <div className={classes.black}>KEEP</div>
          <div className={classes.white}>IN</div>
        </> }
        { Out && <>
          <div className={classes.white}>KEEP</div>
          <div className={classes.black}>OUT</div>
        </> }
      </div>
      <div>
        { type === 'flight'?
          <Flight id={id} location />
        :
          <><Nfz id={id} name /> ({ type === 'no-fly-zone' ? 'NFZ' : type })</>
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(XprizeViolatingUa)
