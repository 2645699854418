import store from '../utils/store'
import { jwtDecode } from 'jwt-decode'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { makeQueryString, standardHeaders } from './api-utils'

const ACCOUNTS_API = new URL(process.env.REACT_APP_ACCOUNTS_API).href
const ACCOUNTS_URL = new URL(process.env.REACT_APP_ACCOUNTS_URL).href

export function getAccountsURL() {
  return new URL(ACCOUNTS_URL)
}

export function getAccessToken() {
  return store.getState()?.oidc?.user?.access_token 
}

export function getDecodedAccessToken() {
  const token = getAccessToken()
  if (!token)
    return undefined

  const decoded = jwtDecode(token)
  const {
    plex: { company_id, company_status }
  } = decoded
  return {
    ...decoded,
    user_id: decoded.sub,
    company_id,
    company_status,
  }
}

// To correspond to feature matrix for RSAF
// https://docs.google.com/document/d/1xM1PQ00IQaAXS48JSkyoZxg0nPk9rLfRSi5FHyo_9HI/edit
export const controllerRoles = ['accountable-manager', 'safety-officer', 'instructor']
// not controllers pilot spotter technician gcs-pilot

export function isController() {
  const mdfRoles = getDecodedAccessToken().app_roles?.my_drone_fleets
  if (!mdfRoles || mdfRoles.length === 0)
    return false
  for (let role of mdfRoles) {
    if (controllerRoles.includes(role))
      return true
  }
  return false
}

export function isNotController() {
  const mdfRoles = getDecodedAccessToken().app_roles?.my_drone_fleets
  if (!mdfRoles || mdfRoles.length === 0)
    return false
  for (let role of mdfRoles) {
    if (!controllerRoles.includes(role))
      return true
  }
  return false
}

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ACCOUNTS_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['AppRoles', 'User', 'Company', 'Subscription'],
  endpoints: (builder) => ({
    // App Roles
    activeAppRoles: builder.query({
      query: () => `/app_roles`,
      providesTags: ['AppRoles']
    }),

    // Users
    getMyUser: builder.query({
      query: () => `/users/${getDecodedAccessToken().user_id}`,
      providesTags: ['User'],
    }),
    getUserById: builder.query({
      query: (user_id) => `/users/${user_id}`,
      providesTags: ['User'],
    }),
    getMyCompanyUsers: builder.query({
      query: () => `/users?company_id=${getDecodedAccessToken().company_id}`,
      providesTags: ['User'],
    }),
    // Users -> only for super admins type accounts (eg for X-Prize see everyone)
    getUsers: builder.query({
      query: (params) => (`/users${makeQueryString(params, [
        'company_id',
        'company_ids',
        'name',
        'username',
        'email',
        'status',
        'limit',
      ])}`),
      providesTags: ['User'],
    }),

    // Companies
    getMyCompany: builder.query({
      query: () => `/companies/${getDecodedAccessToken().company_id}`,
      providesTags: ['Company']
    }),
    // Companies -> only for super admins type accounts (eg for X-Prize see everyone)
    getCompanyById: builder.query({
      query: (company_id) => `/companies/${company_id}`,
      providesTags: ['Company']
    }),
    getCompanies: builder.query({
      query: (params) => (`/companies${makeQueryString(params, [
        'name',
        'htx_enabled',
        'rsaf_enabled',
        'xprize_enabled',
        'dts_enabled'
      ])}`),
      providesTags: ['Company']
    }),

    // Subscriptions
    getMySubscriptions: builder.query({
      query: () => {
        const company_id = getDecodedAccessToken()?.company_id
        if (company_id)
          return `/subscriptions?company_id=${company_id}`
        throw new Error('User not logged in')
      },
      providesTags: ['Subscription']
    }),
    getSubscriptionsByCompanyId: builder.query({
      query: (company_id) => `/subscriptions?company_id=${company_id}`,
      providesTags: ['Subscription']
    }),
  }),
})

export const {
  useActiveAppRolesQuery,

  useGetMyUserQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetMyCompanyUsersQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,

  useGetMyCompanyQuery,
  useGetCompanyByIdQuery,
  useLazyGetCompanyByIdQuery,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,

  useGetMySubscriptionsQuery,
  useGetSubscriptionsByCompanyIdQuery,
} = accountsApi
