import DroneMarker from '../../components/DroneMarker'

function CfmsTrackMarkers({ cfmsTracks, onClick }) {
  return (<>
    {Object.keys(cfmsTracks).map((uasid) => {
      const t = cfmsTracks[uasid]
      return (
        <DroneMarker
          key={uasid}
          onClick={(e) => onClick(t)}
          lat={t.lat}
          lng={t.lng}
          yaw={t.data_provider.extras.trackDirection} // consider pulling this out to root level as yaw
          iconColor={{ arrow: {fill: 'green', stroke: 'green' }}}
        />
      )
    })}
  </>)
}

export default CfmsTrackMarkers
