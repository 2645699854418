import { useEffect } from 'react'
import { errorWS } from '../../api/error'

function useErrors({ company_ids }) {
  useEffect(() => {
    if (company_ids) {
      (async () => { await errorWS({ company_ids }) })()
    }
  }, [company_ids])
  return null
}

export default useErrors
