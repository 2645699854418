import moment from 'moment'

export function convertSecondsToHMS(seconds) {
  if (!Number.isInteger(seconds))
    return seconds

  const abssec = Math.abs(seconds)
  const sign = seconds < 0 ? '-' : ''

  let hrs = Math.floor(abssec / 3600)
  let min = Math.floor(abssec / 60) % 60
  let sec = Math.floor(abssec % 60)

  if (sec < 10) sec = `0${sec}`
  if (min < 10) min = `0${min}`
  if (hrs < 10) hrs = `0${hrs}`
  if (seconds > 0)
    return `${sign}${hrs}:${min}:${sec}`
  else
    return `${sign}${min}:${sec}`
}

export function prettyPrintEpoch(epoch) {
  return moment(parseInt(epoch)).format('YYYY-MM-DD HH:mm:ss')
}

export function prettyDateTime(str) {
  if (Number.isInteger(str))
    return prettyPrintEpoch(str)

  if(str.length === 23){
    return moment(str).format('YYYY-MM-DD HH:mm:ss')
  }
  // Only special handling for Tracker v1 ICD
  const m = str.match(/^([0-9]{8})T([0-9]{6})$/)

  if (m)
    return moment(`${m[1].substr(0, 4)}-${m[1].substr(4, 2)}-${m[1].substr(6, 2)} ` +
      `${m[2].substr(0, 2)}:${m[2].substr(2, 2)}:${m[2].substr(4, 2)}`).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')
  else
    return str
}

