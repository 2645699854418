import moment from 'moment'
import TimeScale from './TimeScale'
// import VariableSelector from './VariableSelector'
import {
  ButtonGroup,
  Button,
  Grid,
} from '@material-ui/core'
import {
  PlayArrow,
  Pause,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    height:    theme.spacing(8),
    marginTop: theme.spacing(2),
    overflow:  'hidden',
  },
  play: {
    height: theme.spacing(6),
  }
})

function PlaybackControls({
  classes,
  trackStart,
  trackEnd,

  // For playback button
  playing,
  onPlay,
  onPause,

  // For timescale
  track,
  startTime,
  totalTime,

  // Tentative replacement for right controls
  speed,
}) {
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={2}>
        <ButtonGroup
          fullWidth
          size='large'
          variant='contained'
          className={classes.play}>
          <Button disabled={playing} onClick={onPlay}>
            <PlayArrow />
          </Button>
          <Button disabled={!playing} onClick={onPause}>
            <Pause />
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={8}>
        <TimeScale
          trackStart={trackStart}
          trackEnd={trackEnd}
          track={track}
          startTime={startTime}
          totalTime={totalTime}
        />
      </Grid>
      <Grid item xs={2}>
        Current: {track?.t ? moment.utc(track.t).toDate().toLocaleTimeString() : '-'}<br />
        Speed: {speed}x faster
        {/* <VariableSelector /> */}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(PlaybackControls)
