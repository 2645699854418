import { Link } from 'react-router-dom'
import usePermissions    from '../components/usePermissions'
import UploadRawTracks   from './UploadRawTracks'
import DownloadRawTracks from './DownloadRawTracks'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  col: {
    padding: theme.spacing(2)
  },
})

function Developer({ classes }) {

  const canDevelop = usePermissions('developer-main-read', { warn: true })
  if (!canDevelop)
    return null

  return (
    <Grid container>
      <Grid md={4} item className={classes.col}>
        <Typography variant='h6'>Upload Raw Tracks</Typography>
        <UploadRawTracks />
      </Grid>
      <Grid md={4} item className={classes.col}>
        <Typography variant='h6'>Download Tracks</Typography>
        <DownloadRawTracks />
      </Grid>
      <Grid md={4} item className={classes.col}>
        <Typography variant='h6'>Inject Tracks</Typography>
        <Link to={'/developer/inject-telemetry'}>
          <Button variant='outlined'>
            Open
          </Button>
        </Link>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Developer)
