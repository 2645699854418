import {
  MapContainer,
  ScaleControl,
  ZoomControl,
} from 'react-leaflet'
import { DEFAULT_MAP_CENTER } from '../../utils/site-maps'
import rulesToPolygonProps    from '../../utils/rulesToPolygonProps'
import { privateNfzColour }   from '../../utils'

import PublicAirspace    from '../../components/Airspace/Public'
import PrivateAirspace   from '../../components/Airspace/Private'
import SelectionLayer    from '../../components/Map/SelectionLayer'
import CommonLayers      from '../../components/Map/CommonLayers'
import StickyPanZoom     from '../../components/Map/StickyPanZoom'
import CommonMapControls from '../../components/Map/CommonMapControls'
import { MousePosition } from '../../components/Map/MousePosition'

function AirspaceMap({
  selectedNfz,
  className,
  onClickLayer,
  privateNfzFilter,
  showPublicNfz,
  showPublicTestEstate,
  showPrivateNfz,
  showPrivateReadyToUse,
  setMap,
  children,
  nfzs
}) {

  return <>
    <MapContainer
      className={className}
      minZoom={2}
      zoom={12}
      zoomControl={false}
      center={DEFAULT_MAP_CENTER}
      ref={setMap}>
      <StickyPanZoom/>
      <CommonLayers />
      <ZoomControl position='topleft' />
      <ScaleControl position='bottomleft' imperial={false} />
      <CommonMapControls />
      <MousePosition/>
      <PublicAirspace
        showStaticNfz={showPublicNfz}
        showTestEstate={showPublicTestEstate}
        showTempNfz={showPublicNfz}
        onClickLayer={onClickLayer}/>
      <PrivateAirspace
        nfzs={nfzs}
        showNfz={showPrivateNfz}
        showReadyToUse={showPrivateReadyToUse}
        onClickLayer={onClickLayer}
        filter={privateNfzFilter}
      />
      <SelectionLayer feature={selectedNfz && {
        type: 'Feature',
        geometry: selectedNfz.geometry,
        properties: {
          ...(!selectedNfz.owner?.company_id && rulesToPolygonProps(selectedNfz.restriction)),
          ...(selectedNfz.owner?.company_id && {color: privateNfzColour(selectedNfz)}),
          ...selectedNfz.properties
        }
      }}/>
      {children}
    </MapContainer>
  </>
}

export default AirspaceMap
