import { useGetPlaceQuery } from '../../../api/geospatial'
// import { useMap } from 'react-leaflet'
import L from 'leaflet'
import { Marker, Popup } from 'react-leaflet'

// import building  from '../../../assets/map/geospatial/building.png'
// import field     from '../../../assets/map/geospatial/field.png'
// import opsCenter from '../../../assets/map/geospatial/operations-center.png'
// import opsTower  from '../../../assets/map/geospatial/operations-tower.png'
// import emergency from '../../../assets/map/geospatial/emergency-services.png'
import teardrop from '../../../assets/map/teardrop-pin.png'
// TODO find a permament pin representation for each type of geospatial object

const typeToIcon = {
  building:             teardrop,
  field:                teardrop,
  'operations-center':  teardrop,
  'operations-tower':   teardrop,
  'emergency-services': teardrop,
}

function XprizePlaceMarkers({ company_ids }) {
  // const map = useMap()

  const { data } = useGetPlaceQuery({
    company_ids,
    near:        '1.360578164,103.8268464', // Windsor DOC
    maxDistance: 12000,
  }, { skip: !company_ids })

  const places = data?.data?.places || []
  // const size   = Math.floor((map.getZoom() + 3) * 2)
  const size = 24

  return places.map(place => {
    const icon = L.icon({
      iconUrl:        typeToIcon[place.type],
      iconRetinaUrl:  typeToIcon[place.type],
      iconSize:       [size, size],
      iconAnchor:     [size / 2, size], // point of the icon which will correspond to marker's location
      popupAnchor:    [0, -size], // point from which the popup should open relative to the iconAnchor
    })
    const coords = place.location.geometry.coordinates
    return (
      <Marker key={place.place_id} position={[coords[1], coords[0]]} icon={icon}>
        <Popup className='nfz-popup'>
          <div className='nfz-popup-title'>{place.short_name}</div>
          <div className='nfz-popup-content'>
            {place.name}<br /><br />
            <i>{place.type}</i><br/>
            Address: {place.address || '-'}<br />
            Contact: {place.contact || '-'}<br />
            Distance from Windsor DOC: {Math.round(place.distance / 100) / 10} km<br />
            <a href={`https://www.google.com/maps/search/?api=1&query=${coords[1]},${coords[0]}`}
            // <a href={`https://www.google.com/maps/@${coords[1]},${coords[0]},18z`}
              target='_blank' rel='noreferrer'>
              Google Maps
            </a> | <a href={`https://www.bing.com/maps?sp=point.${coords[1]}_${coords[0]}_${encodeURIComponent(place.name)}&lvl=17`}
            // </a> | <a href={`https://www.bing.com/maps?cp=${coords[1]}%7E${coords[0]}&lvl=18`}
              target='_blank' rel='noreferrer'>
              Bing Maps
            </a>
          </div>
        </Popup>
      </Marker>
    )
  })
}

export default XprizePlaceMarkers

// OLD: From DOC Marker which is now retired.
// TODO add link to website selling the estate services
// const droneEstates = [
//   {
//     _id: 'sg-onenorth',
//     position: [1.2963112, 103.786377],
//     name:     'one-north Drone Estate, Singapore',
//     acronym:  'ODE',
//   },
//   {
//     _id:      'sg-maritime',
//     position: [1.2721713, 103.8640578],
//     name:     'Maritime Drone Estate, Singapore',
//     acronym:  'MDE',
//   },
//   {
//     _id:      'my-medini',
//     position: [1.4131113045289252, 103.62634733319283],
//     name:     'DRZ Iskandar Drone Test Site, Malaysia',
//     acronym:  'DTS1',
//   },
// ]
