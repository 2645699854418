import { withRouter } from 'react-router'
import { format } from 'date-fns'
import { useGetMySubscriptionsQuery } from '../../../api/accounts'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  control: {
    marginLeft: theme.spacing(1),
  },
})

function MainControls({ classes, history, flights, since, setDaysAgo }) {
  const { data } = useGetMySubscriptionsQuery()
  const subActive = data?.data?.subscriptions?.findIndex(s => s.app === 'my_drone_fleets') >= 0
  return (
    <div className={classes.root}>
      <div>
        <Typography variant='h5'>
          Recent Flights
        </Typography>
        <Typography variant='body1'>
          Since {format(since, 'dd MMM yyyy')} (Total: {flights?.length || 'n/a'},
          Flying: {flights ? flights.filter(f => f.status === 'flying').length : 'n/a'})
        </Typography>
      </div>
      <div>
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => { setDaysAgo(daysAgo => daysAgo * 2) }}>
          Load Earlier
        </Button>
        { subActive &&
          <Button
            className={classes.control}
            variant='outlined'
            color='inherit'
            onClick={e => { history.push('/flights/new') }}>
            New Flight
          </Button>
        }
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(MainControls))
