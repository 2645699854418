import { Button } from '@material-ui/core'
import { FlightTakeoff } from '@material-ui/icons'

function NotFound() {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <p style={{ fontSize: '96px', lineHeight: '1', fontFamily: 'Courier New' }}>404</p>
      <h1>Page Not Found</h1>
      <br />
      <Button variant='contained' color='secondary'
        onClick={() => { window.location ='/dashboard' }}>
        Back to Live Airspace
      </Button>
      <FlightTakeoff style={{
        position: 'absolute',
        top:      0,
        right:    0,
        width:    '100%',
        height:   '800px',
        color:    '#666',
        zIndex:   -100,
      }} />
    </div>
  )
}

export default NotFound
