import {
  Avatar,
  ListItemIcon,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: ({ avatar }) => (avatar ? {
    padding: theme.spacing(1, 2),
  } : {}),
  title: {
    fontWeight: 'bold',
  },
  icon: {
    color: ({ colour }) => colour || theme.palette.common.white,
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: ({ colour }) => colour || theme.palette.background.paper,
  },
})

function TooltipIcon({ classes, title, icon, listItem, avatar, colour }) {
  return listItem ?
    <ListItemIcon className={classes.root}>
      <Tooltip arrow
        title={<Typography className={classes.title}>{title}</Typography>}>
        { avatar ?
          <Avatar className={classes.avatar}>{icon}</Avatar>
        : <span className={classes.icon}>{icon}</span>
        }
      </Tooltip>
    </ListItemIcon>
  :
    <Tooltip arrow className={classes.root}
      title={<Typography className={classes.title}>{title}</Typography>}>
      { avatar ?
        <Avatar className={classes.avatar}>{icon}</Avatar>
      : <span className={classes.icon}>{icon}</span>
      }
    </Tooltip>
}

export default withStyles(styles)(TooltipIcon)
