import { Scatter } from 'react-chartjs-2'
import wheel from '../../utils/colour-wheel'
import ChartOptions from './ChartOptions'

export default function AltitudeChart({ trackers }) {

  if (!trackers)
    return null

  const
    altDataset = [],
    vdopDataset = []

  for (const imei in trackers) {
    let col = wheel.nextColour()
    altDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.alt]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    vdopDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.vdop]),
      backgroundColor: col,
      borderColor: col
    })
  }

  const altOptions = ChartOptions()
  altOptions.plugins.title.text = 'Altitude'

  const vdopOptions = ChartOptions()
  vdopOptions.plugins.title.text = 'Vertical Dillution of Precision (VDOP)'

  return (
    <>
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: altDataset}}
        options={altOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: vdopDataset}}
        options={vdopOptions}
      />
    </>
  )
}
