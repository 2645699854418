import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function DroneDeveloper({ classes, drone }) {
  if (!drone)
    return null

  return (
    <Grid container>
      <Grid item xs={4}>
        Drone ID
      </Grid>
      <Grid item xs={8}>
        {drone.drone_id}
      </Grid>
      <Grid item xs={4}>
        Drone Status
      </Grid>
      <Grid item xs={8}>
        {drone.status}
      </Grid>
      <Grid item xs={4}>
        Drone Shareable
      </Grid>
      <Grid item xs={8}>
        {drone.shared ? 'Yes' : 'No'}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(DroneDeveloper)
