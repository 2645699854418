import { useEffect, useState } from 'react'
import { DEFAULT_MAP_CENTER } from '../../utils/site-maps'
import theme                  from '../../utils/site-theme'

import CommonLayers     from '../../components/Map/CommonLayers'
import MyLocationMarker from '../../components/Map/MyLocationMarker'
import PublicAirspace   from '../../components/Airspace/Public'
import PrivateAirspace  from '../../components/Airspace/Private'
import ReservedAirspace from '../../components/Airspace/Reserved'
import FrzMapControls   from './FrzMapControls'

import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import {
  LayersControl,
  MapContainer,
  Marker,
  ScaleControl,
  ZoomControl,
  GeoJSON
} from 'react-leaflet'
import { withStyles } from '@material-ui/core/styles'
import EditFeatureGroup from '../Editor/components/EditFeatureGroup'
import pushpin from '../../assets/pushpin.svg'
import StickyPanZoom from '../../components/Map/StickyPanZoom'
const styles = theme => ({
  container: {
    width:  '100%',
    height: 'inherit',
  },
  map: {
    width: '100%',
    height: '100%',
    minHeight: '400px'
  },
  crosshair: {
    cursor: 'crosshair'
  }
})

function FrzMap({
  classes,
  company_ids,
  hideControls,
  geometry,
  originalGeometry,
  setGeometry,
  showDrawControls=false,
  showEditControls=false,
  selectMode=false,
  onSelectPoint,
  groundPoint
}) {
  
  const [ map, setMap ] = useState(null)
  const [ myLocation, setMyLocation ] = useState('disabled') // finding, enabled
  const [ geoCenter, setGeoCenter ] = useState()
  const [ showOnMap,  setShowOnMap  ] = useState([
    // 'public-nfz',
    // 'public-test-estate',
    // 'private-nfz',
    // 'draft-frz',
    // 'pending-approval-frz',
    // 'approved-frz',
    // 'rejected-frz',
  ])
  
  function onMapFeatureChanged(e){
    console.info("FRZ Map feature created", e)
    setGeometry(e)
  } 

  useEffect(() => {
    if (selectMode && map) {
      map.getContainer().style.cursor = 'crosshair'
      const cb = (e) => onSelectPoint(e.latlng)
      map.on('click', cb)
      return () => {
        map.off('click', cb)
        map.getContainer().style.cursor = ''
      }
    }
  }, [selectMode, map, onSelectPoint])

  let groundPointIcon = L.icon({
    iconUrl: pushpin,
    iconAnchor: [12, 24]
  })
  return (
    <div className={classes.container}>
      <MapContainer
        minZoom={2}
        zoom={12}
        zoomControl={false}
        center={DEFAULT_MAP_CENTER}
        ref={setMap}
        className={classes.map}>
        {originalGeometry && <LayersControl position='topright' collapsed={false}>
          <LayersControl.Overlay name="Show Reservation Boundary">
            <GeoJSON data={originalGeometry} style={{color: theme.palette.map.black}}/>
          </LayersControl.Overlay>
        </LayersControl>
        }
        <StickyPanZoom></StickyPanZoom>
        <CommonLayers />
        <ZoomControl position='topright' />
        <ScaleControl position='bottomleft' imperial={false} />
        { groundPoint && <Marker position={[groundPoint.lat, groundPoint.lng]} icon={groundPointIcon}></Marker>}
        <EditFeatureGroup
          geometry={geometry}
          onCreated={onMapFeatureChanged}
          onDeleted={onMapFeatureChanged}
          onEdited={onMapFeatureChanged}
          featureStyle={{color: theme.palette.map.lightBlue}}
          geoCenter={geoCenter}
          setGeoCenter={setGeoCenter}
          draw={{
            polygon: showDrawControls,
            circle: showDrawControls,
            rectangle: showDrawControls,
            polyline: false,
            marker: false,
            circlemarker: false,
          }}
          edit={{
            edit: showEditControls,
            remove: showEditControls
          }}
        />
        
        { myLocation !== 'disabled' &&
          <MyLocationMarker
            onSuccess={() => { setMyLocation('enabled') }}
            onFailure={() => { setMyLocation('disabled') }}
          />
        }
        { (showOnMap.includes('public-nfz') || showOnMap.includes('public-test-estate')) &&
          <PublicAirspace
            showStaticNfz={showOnMap.includes('public-nfz')}
            showTempNfz={showOnMap.includes('public-nfz')}
            showTestEstate={showOnMap.includes('public-test-estate')}
          />
        }
        { (showOnMap.includes('private-nfz') || showOnMap.includes('private-ready-to-use')) &&
          <PrivateAirspace
            company_ids={company_ids}
            showReadyToUse={showOnMap.includes('private-ready-to-use')}
            showNfz={showOnMap.includes('private-nfz')}
          />
        }
        { showOnMap.some(v => v.substr(-4) === '-frz') &&
          <ReservedAirspace
            company_ids={company_ids}
            showStatuses={
              showOnMap
              .filter(v => v.substr(-4) === '-frz')
              .map(v => v.slice(0, -4))
            }
          />
        }
      </MapContainer>
      { !hideControls &&
        <FrzMapControls
          map={map}
          showOnMap={showOnMap}
          setShowOnMap={setShowOnMap}
          myLocation={myLocation}
          setMyLocation={setMyLocation}
        />
      }
    </div>

    // <ReservationMap
    //   frzs={frzs || []}
    //   minAlt={minAlt}
    //   maxAlt={maxAlt}
    //   geometry={geometry}
    //   onCreated={geom => {
    //     setGeometry(geom)
    //   }}
    //   onEdited={geom => {
    //     setGeometry(geom)
    //   }}
    //   onDeleted={e => setGeometry(null)}
    // />
  )
}

export default withStyles(styles)(FrzMap)
