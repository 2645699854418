import { Close }  from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const X = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  cursor: 'pointer'
}))

const TopXCloseButton = ({ onClick }) => (
  <X onClick={onClick}><Close /></X>
)

export default TopXCloseButton
