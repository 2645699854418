import { useParams } from 'react-router'
import RecentFlights from './RecentFlights'
import SingleFlight  from './SingleFlight'

function Flights() {
  const { id } = useParams()

  // /flights/new
  if (id === 'new')
    return <SingleFlight flightId='' />

  // /flights/123
  if (id)
    return <SingleFlight flightId={id} />

  // /flights /flights/
  return <RecentFlights />
}

export default Flights
