import Sockette  from 'sockette'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { makeQueryString, makeWssUrl, standardHeaders } from './api-utils'

const TRACKER_HTTP_API = new URL(process.env.REACT_APP_TRACKER_HTTP_API).href
const TRACKER_WS_API = new URL(process.env.REACT_APP_TRACKER_WS_API).href

let track_ws
let trackBatchData = {}
let batchInterval

const wsCache = {}
export async function trackWS({ company_ids, setTracks, setCfmsTracks, onTrack }) {
  if (!Array.isArray(company_ids) || company_ids.length === 0)
    return null

  return new Promise(function (resolve, reject) {
    const wssUrl = makeWssUrl(`${TRACKER_WS_API}/web/`, { company_ids })
    if (wsCache[wssUrl])
      return resolve()
    track_ws = wsCache[wssUrl] = new Sockette(wssUrl, {
      timeout:     10000,
      maxAttempts: Infinity,
      onopen:      e => {
        console.info('track ws connected')
        resolve(track_ws)
      },
      onreconnect: e => console.info('track ws reconnecting'),
      onclose:     e => console.info('track ws closed'),
      onerror:     e => console.info('track ws error:', e),
      onmessage:   e => {
        let track
        try {
          track = JSON.parse(e.data)
        } catch(e) {
          return console.log('Invalid track received', e.data)
        }
        if (!track)
          return console.log('Bad track received', track)

        // telem subscribed from CFMS (future: all other UTMs)
        if (track.type === 'cfms_drone') {
          // When we get a chance to revisit, would like to merge this with unified telemetry
          // TODO: name it "CFMS x4444" or 6 char CFMS flight ID (in the reference_id field)
          const uasid = track.data_provider?.reference_data?.reference_id
          setCfmsTracks(prevCfmsTracks => ({ ...prevCfmsTracks,
            [uasid]: track
          }))
        } else {
          // TODO: migrate everything (include demo trackers) to unified telemetry
          //       for now, accomodate track.imei for those without ua_id
          const id = track.ua_id || track.imei
          trackBatchData[id] = track
          if (batchInterval)
            return
          batchInterval = setTimeout(function() {
            batchInterval = undefined
            // console.log('batch upserting tracks', trackBatchData)
            console.log(`batch upserting ${Object.keys(trackBatchData).length} tracks`)
            onTrack && onTrack(Object.values(trackBatchData))
            setTracks(prevTracks => {
              const tracks =  { ...prevTracks, ...trackBatchData }
              trackBatchData = {}
              return tracks
            })
          }, 1000)
        }
      },
    })
  })
}

// TODO: move the ws back here to have a global instance
export function netridWS({ type, ua_id, onMessage }) {
  if (!ua_id)
    return null

  // TODO: support drone_id or tracker_id
  return new Sockette(makeWssUrl(`${TRACKER_WS_API}/netrid/`, {
    [ type === 'tracker' ? 'tracker_id' : 'drone_id' ]: ua_id
  }), {
    timeout:     10000,
    maxAttempts: Infinity,
    onopen:      e => console.info('netrid ws connected'),
    onreconnect: e => console.info('netrid ws reconnecting'),
    onclose:     e => console.info('netrid ws closed'),
    onerror:     e => console.info('netrid ws error', e),
    onmessage:   e => { onMessage ? onMessage(e) : console.info('netrid ws received:', e.data) },
  })
}

export const trackerApi = createApi({
  reducerPath: 'trackerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: TRACKER_HTTP_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Tracker Models', 'Trackers', 'Tracks'],
  endpoints: (builder) => ({
    // === TRACKER MODELS ===
    getTrackerModelById: builder.query({
      query: (id) => `/tracker-models/${id}`,
      providesTags: ['Tracker Models'],
    }),
    getTrackerModels: builder.query({
      query: () => '/tracker-models',
      providesTags: ['Tracker Models'],
    }),

    // === TRACKERS ===
    getTrackerById: builder.query({
      query: (id) => `/trackers/${id}`,
      providesTags: ['Trackers'],
    }),
    getTrackers: builder.query({
      query: (params) => (`/trackers${makeQueryString(params, [
        'company_ids',
        'imei',
        'serial_number',
        'tracker_model_id'
      ])}`),
      providesTags: ['Trackers'],
    }),
    updateTracker: builder.mutation({
      query: ({ id, tracker }) => ({
        url: `/trackers/${id}`,
        method: 'PATCH',
        body: tracker,
      }),
      invalidatesTags: ['Trackers'],
    }),

    // === TRACKS (TRACKER FLIGHTS) ===
    getTracks: builder.query({
      query: (params) => (`/tracker-flights${makeQueryString(params, [
        'company_ids',
        'tracker_id',
        'drone_id',
        'before',
        'after',
      ])}`),
      providesTags: ['Tracks'],
    }),
    getTracksById: builder.query({
      query: (trackId) => `/tracker-flights/${trackId}/`,
      providesTags: ['Tracks'],
    }),
    downloadTracks: builder.query({
      query: ({ start, end }) => ({
        url: `/tracker-flights/telemetry`,
        method: 'POST',
        body: { start_time: start, end_time: end },
        cache: 'no-cache',
        responseHandler: async response => {
          // FIXME: This is an endpoint issue - the endpoint should give JSON, and
          //        the data should be usable / reusable throughout the system eg.
          //        for playback etc. The CSV "format" should be generated by the
          //        front end (or XML or any format desired).
          // TODO: Change backend to give JSON before downloading this in the view
          //       component

          // Current method from https://github.com/reduxjs/redux-toolkit/issues/1522#issuecomment-1167482553
          const blob = await response.blob() // ReadableStream => Blob
          const fileDownloadUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')

          const name = 'Tracks ' + start + ' - ' + end + '.csv'
          link.setAttribute('download', name)
          link.href = fileDownloadUrl
          link.click()
          URL.revokeObjectURL(fileDownloadUrl)
        },
      }),
      providesTags: ['Tracks']
    }),
  }),
})

export const {
  useGetTrackerModelByIdQuery,
  useGetTrackerModelsQuery,

  useGetTrackerByIdQuery,
  useLazyGetTrackerByIdQuery,
  useGetTrackersQuery,
  useLazyGetTrackersQuery,
  useUpdateTrackerMutation,

  useGetTracksQuery,
  useGetTracksByIdQuery,
  useLazyDownloadTracksQuery,
} = trackerApi
