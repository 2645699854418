import { useGetPrivateNFZsQuery } from '../../../api/airspace'
import { frzColour } from '../../../utils'
import useNfzValidity from '../../../components/useNfzValidity'
import {
  FeatureGroup,
  GeoJSON,
} from 'react-leaflet'

function XprizeTestEstates({ company_ids }) {
  const [ nfzValidity ] = useNfzValidity('XprizeTestEstates')
  const { data: nfzData } = useGetPrivateNFZsQuery({
    company_ids,
    restriction:    'test-estate',
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
  }, { skip: !company_ids })

  const testEstates = nfzData?.entities || {}
  const color = frzColour('draft')

  return (
    <FeatureGroup>
      { Object.values(testEstates).map(te => (
        <GeoJSON
          key={te.nfz_id}
          data={{
            type: 'Feature',
            geometry: te.geometry
          }}
          style={{
            color,
            weight: 4,
            fillOpacity: 0.1,
            fillColor: color,
          }}
        />
      )) }
    </FeatureGroup>
  )
}

export default XprizeTestEstates