import { connect }           from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import { push }              from 'connected-react-router'
import userManager           from '../utils/user-manager'

function Callback({ push }) {
  const centerStyle = {
    margin: '42px'
  }
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={user => {
        push(user.state ?? '/dashboard')
      }}
      errorCallback={error => {
        console.error('Error in callback', error)
      }}>
      <div style={centerStyle}>Loading MyDroneFleets...</div>
    </CallbackComponent>
  )
}

export default connect(null, { push })(Callback)
