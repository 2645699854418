import {
  Check,
  Clear,
  Create,
  DeleteForever,
  DirectionsBoat,
  Gradient,
  Help,
  ThumbsUpDown,
  Schedule,
  Warning,
} from '@material-ui/icons'
import theme from './site-theme'

export const droneModelCategories = [
  { type: 'aeroplane',    label: 'Aeroplane' },
  { type: 'airship',      label: 'Airship' },
  { type: 'helicopter',   label: 'Helicopter' },
  { type: 'multi-rotor',  label: 'Multi-Rotor' },
  { type: 'powered-lift', label: 'Powered Lift' },
]

export const flightPurposes = [
  { purpose_id: 'recreation',     name: 'Recreation' },
  { purpose_id: 'testing',        name: 'Testing' },
  { purpose_id: 'research',       name: 'Research & Development' },
  { purpose_id: 'photography',    name: 'Aerial Photography' },
  { purpose_id: 'videography',    name: 'Aerial Videography' },
  { purpose_id: 'demonstration',  name: 'Flight Demonstration' },
  { purpose_id: 'training',       name: 'Pilot Training' },
  { purpose_id: 'inspection',     name: 'Inspection' },
  { purpose_id: 'survey',         name: 'Survey & Mapping' },
  { purpose_id: 'surveillance',   name: 'Aerial Surveillance' },
  { purpose_id: 'delivery',       name: 'Aerial Delivery' },
  { purpose_id: 'first-response', name: 'First Response' },
]

export const frzStatuses = [
  { status: 'draft',            name: 'Draft' },
  { status: 'pending-approval', name: 'Pending Approval' },
  { status: 'approved',         name: 'Approved' },
  { status: 'rejected',         name: 'Rejected' },
  // Deleted is not usually shown in lists
]

export function frzIcon(status) {
  return {
    draft:              <Create />,
    'pending-approval': <ThumbsUpDown />,
    approved:           <Check />,
    rejected:           <Clear />,
    deleted:            <DeleteForever />,
  }[status] || <Help />
}

export function frzColour(status) {
  return {
    draft:              theme.palette.map.lightBlue,
    'pending-approval': theme.palette.map.lightBlue,
    approved:           theme.palette.map.blue,
    rejected:           theme.palette.map.black,
    deleted:            theme.palette.map.black,
  }[status] || theme.palette.map.offWhite
}

export function nfzIcon(type) {
  return {
    static:  <Warning />,
    temp:    <Schedule />,
    vessel:  <DirectionsBoat />,
    estate:  <Gradient />,
  }[type] || <Help />
}

export function withinValidity(validity) {
  const now = Date.now() / 1000
  return validity.reduce((a, v) => (a || (v.start < now && v.end > now)), false)
}

export function privateNfzColour(nfz) {
  if (!nfz)
    return ''

  if (nfz.restriction === 'test-estate')
    return theme.palette.map.lightGreen

  if (nfz.restriction === 'operation-restriction')
    return (nfz.status === 'active' && withinValidity(nfz.validity)) ?
      theme.palette.map.darkBlue : theme.palette.map.darkGrey

  if (nfz.restriction === 'keepin-area')
    return nfz.status ==='active' && withinValidity(nfz.validity) ? theme.palette.map.lightBlue : theme.palette.map.darkGrey

  if (nfz.restriction === 'ready-to-use')
    return nfz.type === 'static' || withinValidity(nfz.validity) ? theme.palette.map.green : theme.palette.map.darkGrey
  
  console.log('FIXME: unhandled private NFZ restriction', nfz)
  return theme.palette.map.darkBlue
}

//
// Simple polyfill while waiting for 
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/group
// Produces an additional group call 'ungrouped' for values that are not strings
//
export function group(arrayOfObjects, key) {
  if (!Array.isArray(arrayOfObjects))
    throw Error('First argument must be an array')
  const result = { ungrouped: [] }
  arrayOfObjects.forEach(obj => {
    if (typeof obj[key] !== 'string' && !(obj[key] instanceof String))
      result.ungrouped.push(obj)
    if (!result[obj[key]])
      result[obj[key]] = [obj]
    else
      result[obj[key]].push(obj)
  })
  if (result.ungrouped.length === 0)
    delete result.ungrouped
  return result
}

export function roundMeter(m) {
  return isFinite(m) ? Math.round(m) : m
}

export function meter1dp(m) {
  return isFinite(m) ? Math.round(m * 10) / 10 : m
}

export function meterToRoundFeet(m) {
  return isFinite(m) ? Math.round(m * 3.28084) : m
}

export function meterToFeet1dp(m) {
  return isFinite(m) ? Math.round(m * 3.28084 * 10) / 10 : m
}

export function meterToFeet2dp(m) {
  if(m === 0){
    return 0
  }
  return isFinite(m) ? Math.round(m * 3.28084 * 100) / 100 : m
}

export function feetToMeter2dp(f) {
  if (!isFinite(f))
    return f
  return Math.round(f / 3.28084 * 100) / 100
}

export function exactDigits(str, minlen, maxlen) {
  const num = parseInt(str)
  return (!!str && Number.isInteger(num) && str.length >= minlen && str.length <= maxlen)
}

export function makeId(numChar) {
  let res = ''
  const c = 'ABCDEFGHJKLMNPQRSTUVWXYZ0123456789' // I O avoid confusion with 1 0
  for (let i = 0; i < numChar; i++)
    res += c.charAt(Math.floor(Math.random() * c.length))
  return res
}

export const commonFormat = 'dd MMM yyyy HH:mm:ss'

// shorter alternative to date-fns formatDuration
export function prettyDuration(dur) {
  if (!dur)
    return ''

  if (dur.years > 0)
    return `${dur.years} years`
  if (dur.months > 0)
    return `${dur.months} months`
  if (dur.days > 0)
    return `${dur.days} days`

  const minsec = `${dur.minutes ? dur.minutes.toString().padStart(2, '0') : '00'}:${
    dur.seconds ? dur.seconds.toString().padStart(2, '0') : '00'}`
  if (dur.hours > 0)
    return `${dur.hours}:${minsec}`
  else
    return minsec
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy])
    return -1
  if (b[orderBy] > a[orderBy])
    return 1
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function downloadCsv(name, data) {
  const blob = new Blob(data)
  const fileDownloadUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('download', name)
  link.href = fileDownloadUrl
  link.click()
  URL.revokeObjectURL(fileDownloadUrl)
}

export function trackerName(tracker) {
  if (!tracker)              return ''
  if (tracker.name)          return tracker.name
  if (tracker.imei)          return `Tracker x${tracker.imei.slice(-4)}`
  if (tracker.serial_number) return `Tracker ${tracker.serial_number}`
  if (tracker.tracker_id)    return `Tracker ID x${tracker.tracker_id.slice(-4)}`
  return 'Invalid Tracker'
}
