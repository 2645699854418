import { format } from 'date-fns'
import Row from './Row.js'
import Nfz from '../../components/Text/Nfz'
import User from '../../components/Text/User'
import Drone from '../../components/Text/Drone'
import Flight from '../../components/Text/Flight'
import Tracker from '../../components/Text/Tracker'
import Company from '../../components/Text/Company'
import { commonFormat } from '../../utils'
import {
  Grid,
  Typography,
} from '@material-ui/core'

function Violation({ violations }) {
  if (!violations || Object.keys(violations).length === 0)
    return (
      <Typography variant='body1' style={{ textAlign: 'center' }}>
        No Recent Violations
      </Typography>
    )
  const v = violations

  return (<>
    <Typography variant='body1'>Latest Violation</Typography>
    <Grid container>
      <Row l='Status' v={v.status} />
      { v.ua_telem_type === 'aircraft' &&
        <Row l='UA' v={<Drone id={v.ua_id} name />} />
      }
      { v.ua_telem_type === 'tracker' &&
        <Row l='UA' v={<Tracker id={v.ua_id} name />} />
      }
      <Row l='Area Type' v={v.area_type} />
      <Row l='Area or Flight' v={v.area_type === 'flight' ?
        <Flight id={v.area_id} location /> :
        <Nfz id={v.area_id} name />
      } />
      <Row l='Company' v={<Company id={v.company_id} name />} />
      <Row l='Created On' v={v.created_on ? format(new Date(v.created_on), commonFormat) : 'invalid'} />
      <Row l='Has Flight ID?' v={v.flight_id ? 'Yes' : 'No'} />
      <Row l='Data Provider' v={v.data_provider ? v.data_provider.name : 'unknown'} />
      <Row l='Last Acknowledged By' v={v.last_acknowledged_by ? <User id={v.last_acknowledged_by} name /> : '-'} />
      <Row l='Last Acknowledged On' v={v.last_acknowledged_on ? format(new Date(v.last_acknowledged_on), commonFormat) : '-'} />
    </Grid>
  </>)
}

export default Violation
