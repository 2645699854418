import { useState, useEffect } from 'react'
import { StyledDatePicker } from '../../../components/StyledPickers'
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  DeleteOutline,
  EditOutlined,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import usePermissions from '../../../components/usePermissions'

const styles = theme => ({
  root: {
    position: 'relative',
  },
  editBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(12),
  },
  acquired: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  serviceable: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
  }
})

function DroneTracklog({ classes, drone, onSave }) {
  const [ editing, setEditing ] = useState(false)

  const [ acquireDate, setAcquireDate ] = useState(null)
  const [ serviceable, setServiceable ] = useState(true)
  const [ simulated,   setSimulated   ] = useState(false)
  const [ notes,       setNotes       ] = useState('')

  useEffect(() => {
    if (drone) {
      setSimulated(!!(drone.properties?.simulated))
      setServiceable(!!drone.serviceable)
      setAcquireDate(drone.properties?.acquire_date ? new Date(drone.properties?.acquire_date) : null)
      setNotes(drone.properties?.notes || '')
    }
  }, [drone])

  function handleSave() {
    const data = {
      serviceable,
      properties: {}
    }
    if (acquireDate)
      data.properties.acquire_date = acquireDate.getTime()
    if (notes)
      data.properties.notes = notes
    onSave(data)
    setEditing(false)
  }
  const canModifyDrones = usePermissions('drone-operation-write')
  return (
    <div className={classes.root}>
      { !editing &&
        <div className={classes.editBtn}>
          {canModifyDrones && <IconButton onClick={e => { setEditing(true) }}>
            <EditOutlined />
          </IconButton>}
        </div>
      }
      <div className={classes.topRow}>
        <div className={classes.acquired}>
          <Typography variant='body1'>
            Acquired On
          </Typography>
          <StyledDatePicker
            disableToolbar
            variant='inline'
            maxDate={new Date()}
            margin='normal'
            value={acquireDate}
            onChange={date => { setAcquireDate(date) }}
            disabled={!editing}
          />
          { acquireDate && (
            <IconButton onClick={(e) => setAcquireDate(null)} disabled={!editing}>
              <DeleteOutline />
            </IconButton>
          )}
        </div>
        <div className={classes.serviceable}>
          { simulated ?
              <Button disabled>
                Simulated
              </Button>
          : <>
              <Typography variant='body1'>
                Serviceable
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    onChange={e => { setServiceable(e.target.checked) }}
                    disabled={!editing}
                  />
                }
                label={serviceable ? 'Yes' : 'No'}
                checked={serviceable}
              />
            </>
          }
        </div>
      </div>
      <TextField
        label='Notes'
        variant='outlined'
        fullWidth
        maxRows={4}
        disabled={!editing}
        value={notes}
        onChange={e => setNotes(e.target.value)}
      />
      { editing &&
        <div className={classes.saveBtn}>
          <Button variant='outlined' onClick={e => { setEditing(false) }}>
            Cancel
          </Button>
          <Button variant='contained' color='secondary' onClick={handleSave}>
            Save
          </Button>
        </div>
      }
    </div>
  )
}

export default withStyles(styles)(DroneTracklog)