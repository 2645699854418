import { connectRouter, routerMiddleware } from 'connected-react-router'
import { loadUser, reducer as oidcReducer } from 'redux-oidc'
import { createBrowserHistory } from 'history'
import userManager from './user-manager'

import { configureStore } from '@reduxjs/toolkit'
import { accountsApi }    from '../api/accounts'
import { aircraftApi }    from '../api/aircraft'
import { airspaceApi }    from '../api/airspace'
import { conformanceApi } from '../api/conformance'
import { geospatialApi }  from '../api/geospatial'
import { meteorologyApi } from '../api/meteorology'
import { trackerApi }     from '../api/tracker'

export const history = createBrowserHistory()

// Redux DevTools Extension comes free with Redux Toolkit, install from Chrome Store to use
const store = configureStore({
  reducer: {
    router: connectRouter(history),
    oidc:   oidcReducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [aircraftApi.reducerPath]: aircraftApi.reducer,
    [airspaceApi.reducerPath]: airspaceApi.reducer,
    [conformanceApi.reducerPath]: conformanceApi.reducer,
    [geospatialApi.reducerPath]: geospatialApi.reducer,
    [meteorologyApi.reducerPath]: meteorologyApi.reducer,
    [trackerApi.reducerPath]: trackerApi.reducer,
  },
  // default middleware already included thunk, immutableStateInvariant, serializableStateInvariant
  // https://redux-toolkit.js.org/api/getDefaultMiddleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // oidc.user is a class, issue still open, muting
      // https://github.com/maxmantz/redux-oidc/issues/169
      ignoredActions: ['redux-oidc/USER_FOUND'],
      ignoredPaths: ['oidc.user'],
      warnAfter: 1000,
    },
  })
  .concat(routerMiddleware(history))
  .concat(accountsApi.middleware)
  .concat(aircraftApi.middleware)
  .concat(airspaceApi.middleware)
  .concat(conformanceApi.middleware)
  .concat(geospatialApi.middleware)
  .concat(meteorologyApi.middleware)
  .concat(trackerApi.middleware)
})

loadUser(store, userManager)

export default store
