import { format } from 'date-fns'
import StatusStepper from '../../DroneFlights/components/StatusStepper'

import {
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
  },
})

function FlightMeta({ classes, flight }) {
  return (
    <div className={classes.root}>
      { flight ?
        <>
          <Typography variant='overline'>
            Flight Status
          </Typography>
          <StatusStepper status={flight.status} />
          <Typography variant='overline'>
            Flight Date / Time
          </Typography>
          <Typography variant='body1'>
            { flight.date ? format(new Date(flight.date), 'dd MMM yyyy HH:mm:ss zzzz') : '-' }
          </Typography>
          <Typography variant='overline'>
            Flight Permits
          </Typography>
          <Typography variant='body1'>
            { flight.permit ?
              <><b>{flight.permit.authority}</b>: {flight.permit.ref}</> :
              <>No permits</>
            }
          </Typography>
        </>
      : <>
          <TextField select
            label='Flight Status'
            value=''
            fullWidth>
            <MenuItem value=''></MenuItem>
            <MenuItem value='preflight'>Preflight</MenuItem>
            <MenuItem value='flying'>Flying</MenuItem>
            <MenuItem value='postflight'>Postflight</MenuItem>
          </TextField>
          <TextField
            label='Flight Date / Time'
            fullWidth
          />
          <Button>Add Permits</Button>
        </>
      }
    </div>
  )
}

export default withStyles(styles)(FlightMeta)