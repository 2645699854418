import { useEffect, useState } from 'react'
import { useMap } from 'react-leaflet'
import DroneMarker from './DroneMarker'
import BlinkingDroneMarker from './BlinkingDroneMarker'

function TrackMarkers({
  trackers,
  tracks,
  drones,
  selectedTrackerId,
  selectedDroneId,
  onClick,
  followMarker,
}) {
  const map = useMap()
  const [ selectedTrack, setSelectedTrack ] = useState('')
  useEffect(() => {
    if (selectedDroneId)
      setSelectedTrack(tracks[selectedDroneId])
    else if (selectedTrackerId)
      setSelectedTrack(tracks[selectedTrackerId])
    else
      setSelectedTrack('')
  }, [tracks, selectedDroneId, selectedTrackerId])

  useEffect(() => {
    if (map && selectedTrack?.lat && selectedTrack?.lng && followMarker)
      map.setView([selectedTrack.lat, selectedTrack.lng], map.getZoom(), {animate: true})
  }, [map, selectedTrack, followMarker])

  return (<>{
    Object.keys(tracks).map(key => {
      const t = tracks[key]
      const ua = (t.ua_telem_type === 'aircraft' && drones) ?
          drones[t.ua_id] :
        t.ua_telem_type === 'tracker' ?
          (trackers && trackers[t.ua_id || t.imei]) ||
          { online: true } // Broadcast UA track that does not belong to this company
        : null

      if (!(ua?.online))
        return null // Not rendering the last track of an UA who has went offline
                    // TODO: request for "ghost" icons - preference?
      if (ua.violations)
        return <BlinkingDroneMarker key={key} lat={t.lat} lng={t.lng} yaw={t.yaw}
          onClick={(e) => { onClick(t) }} />
      return <DroneMarker key={key} lat={t.lat} lng={t.lng} yaw={t.yaw}
        onClick={(e) => { onClick(t) }}
        iconColor={ua.exclude_conformance ? {drone: {stroke: 'magenta', fill: 'white'}} : null}/>
    })
  }</>)
}

export default TrackMarkers

// Archival of CFMS data model - need to put back later in HTX plugin
// const [ violatingTrackers, setViolatingTrackers ] = useState({})
// const violatingTrackerImei = v.vehicle_info.id
// const violationType = v.violation_type
// const violationTime = v.start_time
// switch (event.event_type) {
//   case 'CREATED':
//   case 'UPDATED':
//     // TODO edge case: tracker violating more than one event
//     if (!(violatingTrackerImei in violatingTrackers)) {
//       const updatedViolatingTrackers = violatingTrackers
//       updatedViolatingTrackers[violatingTrackerImei] = {
//         violatingType: violationType, // TODO: change this to an array or map of violation events
//         violationTime: violationTime,
//       }
//       setViolatingTrackers(updatedViolatingTrackers)
//     }
//     break
//   case 'DELETED':
//     if (violatingTrackerImei in violatingTrackers) {
//       const updatedViolatingTrackers = violatingTrackers
//       delete updatedViolatingTrackers[violatingTrackerImei]
//       setViolatingTrackers(updatedViolatingTrackers)
//     }
//     break
