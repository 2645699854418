import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { advise } from '../api/advisories'
import {
  useGetMyCompanyQuery,
  useLazyGetCompaniesQuery,
} from '../api/accounts'

function useCompanies() {
  const [ companies, setCompanies ] = useState([]) // other people's companies (might include self)
  const [ getCompanies ] = useLazyGetCompaniesQuery()
  const { data: myCompanyData } = useGetMyCompanyQuery()
  const my_company = myCompanyData?.data?.company || {}
  const company_id = my_company.company_id
  const prefs = my_company.properties || {}

  const { isHtx, isRsaf, isXprize, isDts } = {
    isHtx:    prefs.pref_enable_htx === true,
    isRsaf:   prefs.pref_enable_rsaf === true,
    isXprize: prefs.pref_enable_xprize === true,
    isDts:    prefs.pref_enable_dts === true
  }
  useEffect(() => {
    const my_company = myCompanyData?.data?.company || {}
    const company_id = my_company.company_id
    if (!company_id)
      return

    // Queries are always AND conditions. To get everything, must call one by one
    const queries = []
    if (isHtx)
      queries.push({ htx_enabled: 'true' })
    if (isRsaf)
      queries.push({ rsaf_enabled: 'true' })
    if (isXprize)
      queries.push({ xprize_enabled: 'true' })
    if (isDts)
      queries.push({ dts_enabled: 'true' })

    Promise.all(queries.map(getCompanies))
    .then(results => {
      const newCompanies = { [company_id]: my_company }
      results.forEach((r, i) => {
        if (r.data.status === 'success') {
          r.data.data?.companies?.forEach(comp => {
            newCompanies[comp.company_id] = comp
          })
        } // else fail is normal for regular companies
      })
      // console.log('use companies', Object.values(newCompanies))
      const companies = Object.values(newCompanies)
      setCompanies(companies)
      advise(`Displaying data from ${companies.length} ${
        companies.length === 1 ? 'company' : 'companies'
        }: ${companies.map(c => c.name).join(', ')}`)
    })
    .catch(error => {
      console.log('error', error)
      toast.error('Error loading companies')
    })
  }, [myCompanyData, companies.length, isHtx, isRsaf, isXprize, isDts, getCompanies])

  return {
    // About own company
    company_id,
    my_company,
    isHtx,
    isRsaf,
    isXprize,
    isDts,
    // timezone: my_company.properties?.pref_default_timezone || '',

    // About companies I can see
    companies,
    company_ids: companies.length > 0 ? companies.map(c => c.company_id) : undefined,
  }
}

export default useCompanies
