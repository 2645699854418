import { Fragment } from 'react'
import { push }     from 'connected-react-router'
import { connect }  from 'react-redux'

import Company from '../../../components/Text/Company'
import Tracker from '../../../components/Text/Tracker'
import TopXCloseButton from '../../../components/TopXCloseButton'
import StatusStepper from './StatusStepper'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  CallMade,
} from '@material-ui/icons'

function prettyFlightDuration(duration) {
  return (duration.hours ? (duration.hours + ':') : '') +
    duration.minutes.toString().padStart(2, '0') + ':' +
    duration.seconds.toString().padStart(2, '0')
}

function FlightDetails({ flight, push, onClose }) {
  const takeoffDate = new Date(flight.date).toLocaleString()
  const pilots = flight.pilots ? flight.pilots.map(p => p.name).join(', ') : 'none'
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='md'>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>
        The flight <b>{flight.drone.name}</b> made on <b>{takeoffDate}</b> by <b>{pilots}</b>
      </DialogTitle>
      <DialogContent>
        <StatusStepper status={flight.status} />
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='h6'>PreFlight Setup</Typography>
            <Grid container>
              <Grid item xs={4}>Company</Grid>
              <Grid item xs={8}><Company id={flight.company_id} name /></Grid>
              <Grid item xs={4}>Pilot(s)</Grid>
              <Grid item xs={8}>{ pilots }</Grid>
              <Grid item xs={4}>Purpose</Grid>
              <Grid item xs={8}>{flight.purpose || '-'}</Grid>
            </Grid>
            <Divider />
            <Typography variant='body1'>Equipment</Typography>
            <Grid container>
              <Grid item xs={4}>Drone</Grid>
              <Grid item xs={8}>
                { flight.drone ? flight.drone.name : 'unknown' }
              </Grid>
              <Grid item xs={4}>Trackers</Grid>
              <Grid item xs={8}>
                { flight.drone?.tracker_ids ?
                  flight.drone.tracker_ids.map(id => <Tracker key={id} id={id} name imei serial />)
                : 'n/a'
                  // TODO: migrate this when working on drone.battery_ids
                }
              </Grid>
              <Grid item xs={4}>Batteries</Grid>
              <Grid item xs={8}>
                { flight.batteries ?
                  flight.batteries.map(b => b.name).join(', ')
                : 'n/a'
                  // TODO: migrate this when working on drone.battery_ids
                }
              </Grid>
              <Grid item xs={4}>Cameras</Grid>
              <Grid item xs={8}>
                { flight.cameras ?
                  flight.cameras.map(c => c.name).join(', ')
                : 'n/a'
                  // TODO: migrate this when working on drone.payload_ids
                }
              </Grid>
              <Grid item xs={4}>Payloads</Grid>
              <Grid item xs={8}>
                { flight.payloads ?
                  flight.payloads.map(p => p.name).join(', ')
                : 'n/a'
                  // TODO: migrate this when working on drone.payload_ids
                }
              </Grid>
            </Grid>
            <Divider />
            <Typography variant='body1'>Permits</Typography>
            <Grid container>
              { flight.permits ?
                flight.permits.map((p, i) => <Fragment key={i}>
                  <Grid item xs={4}>{p.authority}</Grid>
                  <Grid item xs={8}>{p.ref}</Grid>
                </Fragment>)
              : 'No Permits Found' }
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>Flying Parameters</Typography>
            <Grid container>
              <Grid item xs={4}>Date</Grid>
              <Grid item xs={8}>{ takeoffDate }</Grid>
              <Grid item xs={4}>Location</Grid>
              <Grid item xs={8}>{ flight.location || 'none' }</Grid>
              <Grid item xs={4}>Geofence</Grid>
              <Grid item xs={8}>{ flight.geofence ? 'Yes' : 'No' }</Grid>
            </Grid>
            <Divider />
            <Typography variant='body1'>Configurations</Typography>
            <Grid container>
              { flight.configurations ?
                flight.configurations.map((c, i) => <Fragment key={i}>
                  <Grid item xs={4}>{c.subsystem}</Grid>
                  <Grid item xs={8}>{c.component_name}</Grid>
                </Fragment>)
              : 'none' }
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>PostFlight Data</Typography>
            <Grid container>
              <Grid item xs={4}>Servicable</Grid>
              <Grid item xs={8}>{ flight.serviceable ? 'Yes' : 'No' }</Grid>
              <Grid item xs={4}>Duration</Grid>
              <Grid item xs={8}>{ prettyFlightDuration(flight.duration) }</Grid>
              <Grid item xs={4}>Max Distance</Grid>
              <Grid item xs={8}>{ isFinite(flight.distance) ? flight.distance : '-' }m</Grid>
              <Grid item xs={4}>Max Alt AMSL</Grid>
              <Grid item xs={8}>{ isFinite(flight.max_alt) ? flight.max_alt : '-' }m</Grid>
              <Grid item xs={4}>Max Alt AGL</Grid>
              <Grid item xs={8}>{ isFinite(flight.max_agl) ? flight.max_agl : '-' }m</Grid>
              <Grid item xs={4}>Notes</Grid>
              <Grid item xs={8}>{ flight.notes || '-' }</Grid>
            </Grid>
            <Divider />
            <Typography variant='body1'>Flight Logs</Typography>
            <Grid container>
              { flight.logs ?
                flight.logs.map((l, i) => <Fragment key={i}>
                  <Grid item xs={4}>{i}</Grid>
                  <Grid item xs={8}>{l.type} (download link coming soon)</Grid>
                </Fragment>)
              : 'none' }
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button small
          variant='contained'
          endIcon={<CallMade />}
          onClick={() => push(`/flights/${flight.flight_id}`)}>
          More
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(null, { push })(FlightDetails)
