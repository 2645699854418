import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  title: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  }
})

function AreYouSureDialog({ classes, open, onClose, title, message, action, onOK, error }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      fullWidth
      maxWidth='xs'>
      <DialogTitle className={classes.title}>
        { title || 'Confirmation' }
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          { message || 'Are you sure?' }
        </Typography>
      </DialogContent>
      <DialogActions style={{ textAlign: 'center' }}>
        { error &&
          <Typography variant='body1' className={classes.error}>{error}</Typography>
        }
        <Button variant='outlined'
          onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant='contained' color='secondary'
          onClick={() => onOK()}>
          { action || 'OK' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(AreYouSureDialog)