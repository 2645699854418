import {
  Step,
  Stepper,
  StepConnector,
  Box,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import {
  Check,
  Close
} from '@material-ui/icons'

const useConnectorStyles = (props) => makeStyles(theme => ({
  active: {
    '& $line': {
      borderColor: props.rejected ? theme.palette.error.main : theme.palette.success.main
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.success.main,
    },
  },
  line: {
    borderColor: props.rejected ? theme.palette.error.main : theme.palette.text.disabled,
    borderTopWidth: 3,
    borderRadius: 1,
    opacity: '30%'
  }
}))()

const useIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.disabled
  },
  unselected: {
    opacity: '30%'
  },
  selected: {
    opacity: '100%'
  },
  pending: {
    color: theme.palette.warning.main
  },
  approved: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  }
}))

function StepIcon(props) {
  const { approved, pending, rejected, failed, selected } = props
  const classes = useIconStyles()
  return (
    <div className={clsx({
      [classes.root]: true,
      [classes.selected]: selected,
      [classes.unselected]: !selected,
      [classes.approved]: approved,
      [classes.pending]: pending,
      [classes.error]: rejected || failed
    })}>
      {
        approved ? <Check />
        : rejected ? <Close />
        : <div className={classes.circle}/>
      }
    </div>
  )
}

function ApprovalProgressBar({ stepCount, selected, progress, rejected }) {
  const connectorStyles = useConnectorStyles({rejected})
  return stepCount > 1 ? (
    <Stepper
      activeStep={rejected ? progress : progress+1}
      connector={<StepConnector classes={connectorStyles}/>}>
      {
        [...Array(stepCount).keys()].map(i => 
          <Step key={i}>
            <StepIcon
              approved={i < progress || (!rejected && i === progress)}
              pending={!rejected && i === progress + 1}
              rejected={rejected && i === progress}
              failed={rejected && i > progress}
              selected={i === selected} />
          </Step>
        )
      }
    </Stepper>
  ) : (<Box height={25}/>)
}

export default ApprovalProgressBar