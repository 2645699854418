import { format } from 'date-fns'
import { commonFormat } from '../../../utils'
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(1, 2),
  }
})

function FlightFlying({ classes, flight }) {
  return (
    <div className={classes.root}>
      { flight ?
        <>
          <Typography variant='overline'>
            Flight Configuration
          </Typography>
          <Typography>
            { flight.configurations ? 'Some' : 'None' }
          </Typography>
          <Typography variant='overline'>
            Flight Geofence
          </Typography>
          <Typography variant='body1'>
            { flight.geofence ? 'Yes, see map for geofence geometry' : 'No geofence geometry' }
          </Typography>
          <Typography variant='body1'>
            { flight.geofence_min_alt ?
              `Minimum Altitude: ${flight.geofence_min_alt}m` :
              'No minimum altitude'
            }
          </Typography>
          <Typography variant='body1'>
            { flight.geofence_max_alt ?
              `Maximum Altitude: ${flight.geofence_min_alt}m` :
              'No maximum altitude'
            }
          </Typography>
          <Typography variant='body1'>
            { flight.geofence_validity_start ?
              `Valid from: ${format(new Date(flight.geofence_validity_start), commonFormat)}` :
              'No start validity'
            }
          </Typography>
          <Typography variant='body1'>
            { flight.geofence_validity_end ?
              `Valid till: ${format(new Date(flight.geofence_validity_end), commonFormat)}` :
              'No end validity'
            }
          </Typography>
        </>
      : <>
          <Button>Add Flight Configurations</Button>
          <Button>Add Geofence</Button>
          <TextField
            label='Geofence Minimum Altitude (HAE)'
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position='end'>metres</InputAdornment>
            }}
          />
          <TextField
            label='Geofence Maximum Altitude (HAE)'
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position='end'>metres</InputAdornment>
            }}
          />
          <TextField
            label='Geofence Validity Start'
            type='datetime'
            fullWidth
          />
          <TextField
            label='Geofence Validity End'
            type='datetime'
            fullWidth
          />
        </>
      }
    </div>
  )
}



export default withStyles(styles)(FlightFlying)
