import { useState, useEffect } from 'react'
import XprizeViolatingUa from './XprizeViolatingUa'
import {
  List,
  Typography,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    padding: theme.spacing(1),
  },
  nov: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  body: {
    padding: theme.spacing(1),
  },
  activeViolation: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.map.orange
  },
})

function XprizeViolations({
  classes,
  violationData,
  sendViolationAck,
  onDroneClick,
  onTrackerClick,
}) {
  const [ summary, setSummary ] = useState({})

  // To transform this to summary = {
  // [ua_id]: {
  //   ua_telem_type:
  //   company_id:
  //   flight_id:
  //   last_occurance:
  //   breach_keep_in_areas: {
  //     [area_id]: area_type
  //   }
  //   breach_keep_out_areas: {
  //     [area_id]: area_type
  //   }
  //   warning_keep_in_areas: {
  //     [area_id]: area_type
  //   }
  //   warning_keep_out_areas: {
  //     [area_id]: area_type
  //   }
  // }

  useEffect(() => {
    if (!violationData || violationData.ids.length === 0) {
      setSummary({})
      return
    }
    const summ = {}
    violationData.ids.forEach(v_id => {
      const v = violationData.entities[v_id]
      if (!summ[v.ua_id])
        summ[v.ua_id] = {
          breach_keep_in_areas: {},
          breach_keep_out_areas: {},
          warning_keep_in_areas: {},
          warning_keep_out_areas: {},
        }
      const s = summ[v.ua_id]
      if (!s.ua_telem_type)
        s.ua_telem_type = v.ua_telem_type
      if (!s.company_id)
        s.company_id = v.company_id
      if (v.flight_id && !s.flight_id)
        s.flight_id = v.flight_id
      if (v.created_on)
        s.last_occurance = new Date(v.created_on).getTime()
      if (v.area_id) {
        if (v.status === 'WARNING-KEEPOUT') {
          if (s.breach_keep_out_areas[v.area_id])
            delete s.breach_keep_out_areas[v.area_id]
          s.warning_keep_out_areas[v.area_id] = v.area_type
        }
        else if (v.status === 'BREACHED-KEEPOUT') {
          if (s.warning_keep_out_areas[v.area_id])
            delete s.warning_keep_out_areas[v.area_id]
          s.breach_keep_out_areas[v.area_id] = v.area_type
        }
        else if (v.status === 'WARNING-KEEPIN') {
          if (s.breach_keep_in_areas[v.area_id])
            delete s.breach_keep_in_areas[v.area_id]
          s.warning_keep_in_areas[v.area_id] = v.area_type
        }
        else if (v.status === 'BREACHED-KEEPIN') {
          if (s.warning_keep_in_areas[v.area_id])
            delete s.warning_keep_in_areas[v.area_id]
          s.breach_keep_in_areas[v.area_id] = v.area_type
        }
      }
    })
    setSummary(summ)
  }, [violationData])

  if (!violationData)
    return null

  return (<>
    <div className={classes.container}>
      { Object.keys(summary).length === 0 ?
        <div className={classes.nov}>
          <Typography variant='overline'>No Violations</Typography>
        </div>
      : <div className={classes.body}>
          <Typography variant='overline'>Violations (last 10 minutes)</Typography>
          <List>
            { Object.keys(summary).map(ua_id => (
              <XprizeViolatingUa key={ua_id}
                ua_id={ua_id}
                summary={summary[ua_id]}
                onDroneClick={onDroneClick}
                onTrackerClick={onTrackerClick}
              />
            )) }
          </List>
        </div>
      }
    </div>
  </>)
}

export default withStyles(styles)(XprizeViolations)
