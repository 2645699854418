import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { LayersControl } from 'react-leaflet'
const { BaseLayer } = LayersControl
const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

// Google Maps asks us to take the latest version using v=weekly
// https://developers.google.com/maps/documentation/javascript/versions

function GoogleMapsBaseLayers() {
  return (<>
    <BaseLayer name='Google Maps Terrain'>
      <ReactLeafletGoogleLayer apiKey={key} version='weekly' type='terrain' />
    </BaseLayer>
    <BaseLayer name='Google Maps Satellite'>
      <ReactLeafletGoogleLayer apiKey={key} version='weekly' type='satellite' />
    </BaseLayer>
    <BaseLayer name='Google Maps Hybrid'>
      <ReactLeafletGoogleLayer apiKey={key} version='weekly' type='hybrid' libraries={['geometry', 'places']} />
    </BaseLayer>
  </>)
}

export default GoogleMapsBaseLayers
