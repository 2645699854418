import { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { useGetFRZsQuery } from '../../api/airspace'
import { frzStatuses } from '../../utils'

import useCompanies from '../../components/useCompanies'
import WaitDialog from '../../components/WaitDialog'
import MainControls from './components/MainControls'
import MapView from './components/MapView'
import ScheduleView from './components/ScheduleView'
import CalendarView from './components/CalendarView'
import ApprovalView from './components/ApprovalView'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router'
import { startOfDay } from 'date-fns'

const styles = theme => ({
  root: {
    width: '100%'
  },
  rootMargin: {
    width: '100%',
    margin: theme.spacing(2)
  }
})

function Landing({ classes, history }) {
  const location = useLocation()
  const view = useMemo(()=> new URLSearchParams(location.search).get('view') ?? 'map', [location]) // map, schedule, calendar, approval-required
  const [filter, setFilter] = useState({name: '', status: frzStatuses.map(f => f.status)})
  const [selectedFrzId, setSelectedFrzId] = useState()

  const { company_ids } = useCompanies()
  const { data, error, isLoading } = useGetFRZsQuery({
    company_ids,
    'after-validity': startOfDay(new Date()).getTime()
  })
  const [frzResults, setFrzResults] = useState([])
  const frzs = useMemo(() => data?.data?.frzs || [], [data])
  
  useEffect(() => {
    if (frzs)
      setFrzResults(() => {
        let result = frzs.filter(frz => filter.status.includes(frz.status)).filter((f)=> f.name.toLowerCase().includes(filter.name.toLowerCase()))
        result.sort((a, b) => a.validity[0].start - b.validity[0].start).reverse()
        return result
      })
  }, [frzs, filter])

  if (isLoading) {
    return <WaitDialog msg='Loading airspace reservations...' />
  }
  else if (error) {
    console.log('Error loading airspace reservations', error)
    toast.error('Error loading airspace reservations')
  }
  else if (data?.status !== 'success') {
    console.log('Failed to load airspace reservations', data)
    toast.error('Failed to load airspace reservations')
  }

  const renderElement = (a) => {
    return <>
      <Typography variant='body1'>{a.title}</Typography>
      <Typography variant='body2'>{a.event.description}</Typography>
    </>
  }

  return (
    <div className={view === 'calendar' ? classes.rootMargin : classes.root}>
      {view === 'map' &&
        <MapView frzs={frzResults} company_ids={company_ids} filter={filter} setFilter={setFilter} selectedFrzId={selectedFrzId} setSelectedFrzId={setSelectedFrzId}/>
      }
      {view === 'schedule' &&
        <ScheduleView frzs={frzs} />
      }
      {view === 'calendar' && <>
        <MainControls
          view={view}
          filter={filter}
          setFilter={setFilter}
        /> 
        <CalendarView
            entries={frzs}
            entriesToEvent={(data) => {
              return ({
                start: new Date(data.validity[0].start),
                end: new Date(data.validity[0].end),
                title: data.name,
                description: data.aircrafts.name,
                frz_id: data.frz_id,
                status: data.status
              })
            }}
            onEventSelected={(event) => {
              history.push(`/zones/${event.frz_id}`)
            }}
            getAgendaComponent={renderElement}
            getMonthComponent={renderElement}
            getDayComponent={renderElement}
            getWeekComponent={renderElement}
          />
      </>
      }
      {view === 'approval-required' &&
        <ApprovalView frzs={frzs} />
      }
    </div>
  )
}

export default withRouter(withStyles(styles)(Landing))
