import { useRef, useMemo, useEffect } from 'react'
import {
  GeoJSON,
} from 'react-leaflet'
import { privateNfzColour } from '../../../utils'

function PrivateNfzPolygon({ nfz, onClick }) {
  const geojsonRef = useRef()
  const color = useMemo(() => privateNfzColour(nfz), [nfz])

  useEffect(() => {
    geojsonRef.current?.eachLayer(layer => {
      layer.off('click')
      layer.on('click', (event) => {
        if (onClick) onClick(event)
      })
      layer.bringToFront()
    })
  }, [onClick])
  
  return (
    <GeoJSON
      ref={geojsonRef}
      data={{
        type: 'Feature',
        geometry: nfz.geometry
      }}
      style={{
        color,
        weight: 4,
        fillOpacity: 0.1,
        fillColor: color,
      }}
    />
  )
}

export default PrivateNfzPolygon
