import { useState } from 'react'
import {
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // marginRight: 'auto',
  },
}

function DualLabelSwitch({classes, checked, leftLabel, rightLabel, onChange}) {
  const [isChecked, setIsChecked] = useState(checked)

  const handleChange = (event) => {
    setIsChecked(event.target.checked)
    onChange(event)
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{leftLabel}</Typography>
      <Switch checked={isChecked} onChange={handleChange} color='primary' />
      <Typography>{rightLabel}</Typography>
    </div>
  )
}

export default withStyles(styles)(DualLabelSwitch)
