import { trackerName } from '../../utils'
import FollowToggle from './FollowToggle'
import InjectButton from './InjectButton'
import Flight       from './Flight'
import Track        from './Track'
import Violation    from './Violation'
import {
  Divider,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    color: 'black',
    overflow: 'auto',
  },
})

function SingleTracker({ classes, tracker, flights, telem, onFollowTrackerClick }) {
  if (!tracker)
    return null

  return (
    <div className={classes.container}>
      <Typography variant='h6'>{trackerName(tracker)}</Typography>
      <Typography variant='caption'>ua_id: {tracker.tracker_id}</Typography>
      <Flight
        hasAvatar={false}
        flights={flights}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FollowToggle
          onToggle={onFollowTrackerClick}
          disabled={!(tracker.lat && tracker.lng)}
        />
        <InjectButton trackerId={tracker.tracker_id} />
      </div>
      <Divider className={classes.divider} />
      <Track
        telem={telem}
        trackerId={tracker.tracker_id}
      />
      <Divider className={classes.divider} />
      <Violation
        violations={tracker.violations}
      />
    </div>
  )
}

export default withStyles(styles)(SingleTracker)
