export default function ExpandLogs({ trackers }) {

  if (!trackers)
    return null

  const
    okLogCount  = {},
    render = []

  for (const imei in trackers) {
    okLogCount[imei]  = 0
    const noGPSLogs   = []
    const otherLogs   = []
    const gpsMessages = []

    trackers[imei].forEach(telem => {
      if (!telem.log) {
        otherLogs.push([telem.serverTime, 'no logs'])
        return
      }
      telem.log.split(' ').forEach(log => {
        if (log === '70' || log === '67')
          okLogCount[imei] = okLogCount[imei] + 1
        // else if (log === '67')
        //   noGPSLogs.push(telem.serverTime)
        else
          otherLogs.push([telem.serverTime, log])
      })
      if (!telem.t)
        noGPSLogs.push(telem.serverTime)
    })
    if (noGPSLogs.length > 0) {
      gpsMessages.push(noGPSLogs[0].toLocaleString() + ': GPS not available')

      for (let i = 1; i < noGPSLogs.length; i++) {
        if (noGPSLogs[i].getTime() - noGPSLogs[i - 1].getTime() > 8000) {
          gpsMessages.push(noGPSLogs[i - 1].toLocaleString() + ': GPS restored')
          gpsMessages.push(noGPSLogs[i].toLocaleString() + ': GPS lost')
        }
      }
      gpsMessages.push(noGPSLogs[noGPSLogs.length - 1].toLocaleString() + ': GPS restored till the end')
    }
    const otherMessages = otherLogs.map(([date, log]) => {
      return date.toLocaleString() + ': ' + (expand[log] || 'Unknown Log Entry') + ' (' + log + ')'
    })
    render.push(
      <div key={imei} style={{ padding: '16px', fontFamily: 'consolas, courier new'}}>
        <b>Tracker {imei} Log Summary</b><br />
        <br />
        Total successfully transmitted messages: {okLogCount[imei]}<br />
        <br />
        <b>GPS availability</b><br />
        {gpsMessages.map((m, i) => <div key={i}>{m}</div>)}
        <br />
        <b>Other Log Messages</b><br />
        {otherMessages.map((m, i) => <div key={i}>{m}</div>)}
        <br />
      </div>
    )
  }

  return (<>{render}</>)
}

const expand = {
  // FATAL CODES
  '0': 'Generic Fatal',
  '1': 'Reboot LTE Module',

  // ERROR CODES
  '10': 'Generic Error',
  '11': 'Unknown Firmware',
  '12': 'Unknown SIM card',
  '13': 'Not searching for network',
  '14': 'Network registration denied',
  '15': 'Network unknown error',
  '16': 'Registered on roaming network',
  '17': 'Network unknown status',
  '18': 'Error setting APN',
  '19': 'Sensor failed',
  '20': 'Error setting GNSS polling interval (1s)',
  '21': 'Error setting GNSS mode (all constellations)',
  '22': 'Error setting GPS position accuracy (100m)',
  '23': 'Error getting GNSS info',
  '24': 'GNSS invalid northing',
  '25': 'GNSS invalid easting',
  '26': 'GNSS too many fields',
  '27': 'GNSS malformed',
  '28': 'GNSS insufficient fields',
  '29': 'Error getting GPS (on/off) status',
  '31': 'Can’t set SSL version',
  '32': 'Can’t set SSL auth mode to mutual TLS',
  '33': 'Can’t set SSL CA Cert',
  '34': 'Can’t set SSL Client Cert',
  '35': 'Can’t set SSL Client Key',
  '36': 'Can’t read back SSL configuration',
  '37': 'Invalid accelerometer values',
  '38': 'Invalid gyroscope values',
  '39': 'Invalid barometer values',
  '40': 'Invalid magnetometer values',
  '41': 'Can’t release MQTT client',
  '42': 'Can’t stop MQTT service',
  '43': 'Start MQTT service timeout',
  '44': 'Start MQTT service error',
  '45': 'Can’t acquire MQTT client',
  '46': 'Can’t set MQTT SSL context',
  '47': 'Can’t set MQTT will topic',
  '48': 'Can’t set MQTT will message',
  '51': 'Disconnect MQTT server timeout',
  '52': 'Disconnect MQTT server error',
  '53': 'Connect MQTT server timeout',
  '54': 'Connect MQTT server error',
  '55': 'Can’t set MQTT Topic',
  '56': 'Can’t set MQTT Message',
  '57': 'Publish MQTT Payload timeout',
  '58': 'Publish MQTT Payload error',

  // INFO CODES
  '60': 'Generic Info',
  '61': 'Powering On LTE Module',
  '62': 'Searching for networks',
  '63': 'Registered on home network',
  '64': 'Waiting for GPS status',
  '65': 'Waiting for GPS shutdown',
  '66': 'Waiting for GPS auto start',
  '67': 'Valid GNSS with no position (all commas)',
  '68': 'Setting SSL certs for Mutual TLS',
  '69': 'MQTT server connected',
  '70': 'MQTT Message published',
  '71': 'MQTT server disconnected',
  '72': 'Waiting for MQTT server to disconnect',
  '73': 'Waiting for MQTT server to connect',
  '74': 'Waiting for Network to connect',

  // SIMCOM ERROR CODES (+80)
  '80': 'operation succeeded',
  '81': 'failed',
  '82': 'bad UTF-8 string',
  '83': 'sock connect fail',
  '84': 'sock create fail',
  '85': 'sock close fail',
  '86': 'message receive fail',
  '87': 'network open fail',
  '88': 'network close fail',
  '89': 'network not opened',
  '90': 'client index error',
  '91': 'no connection',
  '92': 'invalid parameter',
  '93': 'not supported operation',
  '94': 'client is busy',
  '95': 'require connection fail',
  '96': 'sock sending fail',
  '97': 'timeout',
  '98': 'topic is empty',
  '99': 'client is used',
  '100': 'client not acquired',
  '101': 'client not released',
  '102': 'length out of range',
  '103': 'network is opened',
  '104': 'packet fail',
  '105': 'DNS error',
  '106': 'socket is closed by server',
  '107': 'connection refused: unaccepted protocol version',
  '108': 'connection refused: identifier rejected',
  '109': 'connection refused: server unavailable',
  '110': 'connection refused: bad user name or password',
  '111': 'connection refused: not authorized',
  '112': 'handshake fail',
  '113': 'not set certificate',
}
