import { useState } from 'react'
import { toast } from 'react-toastify'
import {
  format,
  formatDuration,
  intervalToDuration,
  startOfDay,
  subDays,
} from 'date-fns'
import { commonFormat } from '../utils'
import { useGetViolationsQuery } from '../api/conformance'

import Company   from '../components/Text/Company'
import Tracker   from '../components/Text/Tracker'
import Flight    from '../components/Text/Flight'
import Drone     from '../components/Text/Drone'
import Nfz       from '../components/Text/Nfz'
import DataTable from '../components/DataTable'
import WaitDialog      from '../components/WaitDialog'
import TopXCloseButton from '../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
})

function Violations({ classes }) {

  const [ daysAgo, setDaysAgo ] = useState(2)
  const since = startOfDay(subDays(new Date(), daysAgo))

  const { data, error, isLoading } = useGetViolationsQuery({
    created_start: since.toISOString(),
  })
  const [ details, setDetails ] = useState(null)

  if (isLoading)
    return <WaitDialog msg={`Loading violations since ${format(since, 'dd MMM yyyy')} ...`} />
  else if (error) {
    toast.error('Error loading violations')
    console.log('Error loading violations', error)
  }

  const violations = data?.entities || {}

  const summary = {}
  Object.keys(violations).forEach(v_id => {
    const v = violations[v_id]
    if (!summary[v.ua_id])
      summary[v.ua_id] = { areas: {}, ua_telem_type: '', company_id: '' }
    const u = summary[v.ua_id]
    if (!u.ua_telem_type)
      u.ua_telem_type = v.ua_telem_type
    if (!u.company_id)
      u.company_id = v.company_id
    if (!u.areas[v.area_id])
      u.areas[v.area_id] = { status: {}, area_type: v.area_type, first: '', last: '' }
    const a = u.areas[v.area_id]
    if (!a.status[v.status])
      a.status[v.status] = []
    a.status[v.status].push(v)
    if (!a.first || v.created_on < a.first)
      a.first = v.created_on
    if (!a.last || v.created_on > a.last)
      a.last = v.created_on
  })
  // console.log('violation summary', summary)

  const violationTable = []
  Object.keys(summary).forEach(ua_id => {
    Object.keys(summary[ua_id].areas).forEach(area_id => {
      const a = summary[ua_id].areas[area_id]
      violationTable.push({
        ua_id,
        ua_telem_type: summary[ua_id].ua_telem_type,
        company_id:    summary[ua_id].company_id,
        area_id,
        area_type:     a.area_type,
        statuses: Object.keys(a.status).map(status => ({
          status,
          total: a.status[status].length,
        })),
        first: a.first,
        last:  a.last,
      })
    })
  })

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant='h5'>
            My Airspace Violations
          </Typography>
          <Typography variant='body2'>
            Since {format(since, 'dd MMM yyyy')} (past {daysAgo} days)
          </Typography>
        </div>
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => { setDaysAgo(daysAgo * 2) }}>
          Load Earlier
        </Button>
      </div>
      <DataTable
        data={violationTable}
        columns={[
          { label: 'UA (Drone / Tracker)',
            render: v => <Typography><b>
              { v.ua_telem_type === 'tracker' ? <Tracker id={v.ua_id} name /> :
                v.ua_telem_type === 'aircraft' ? <Drone id={v.ua_id} name /> :
                'Invalid UA Telem Type'
              }</b><br />{v.ua_telem_type}
            </Typography>
          },
          { label: 'Area / Flight',
            render: v => <Typography><b>
              { v.area_type === 'flight' ?
                <Flight id={v.area_id} drone pilots permits /> :
                <><Nfz id={v.area_id} name /><br />Area Type: {v.area_type}</>
              }
            </b></Typography>
          },
          { label: 'Events',
            render: v => v.statuses.map(s => <Typography key={s.status}>
              <b>{s.status}</b><br />{s.total} violation(s)<br />
            </Typography>)
          },
          { label: 'Start Time / Duration',
            render: v => <Typography>
              {v.first ? format(new Date(v.first), commonFormat) : '-'}<br />
              {formatDuration(intervalToDuration({ start: new Date(v.first), end: new Date(v.last) }))}
            </Typography>
          },
          { label: '',
            render: v => <div className={classes.actions}>
              <Button variant='outlined' onClick={() => setDetails(v)}>
                Details
              </Button>
              <Button variant='outlined' onClick={() => alert('coming soon..')}>
                Playback
              </Button>
            </div>
          },
        ]}
        initialRowsPerPage={5}
        intialSort={{ by: 'first', order: 'desc' }}
      />
      { details &&
        <ViolationDetailDialog
          details={details}
          violations={Object.values(summary[details.ua_id].areas[details.area_id].status).flat()}
          onClose={() => setDetails(null)}
        />
      }
    </div>
  )
}

function ViolationDetailDialog({ details, violations, onClose }) {
  const v = details || {}
  const name =
    v.ua_telem_type === 'tracker' ? <Tracker id={v.ua_id} name /> :
    v.ua_telem_type === 'aircraft' ? <Drone id={v.ua_id} name /> :
    'Invalid UA Telem Type'

  return (
    <Dialog open fullWidth maxWidth='md' onClose={onClose}>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle>
        Violations by {name} at {v.area_type} <Nfz id={v.area_id} name />
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          Company: <b><Company id={v.company_id} name email /></b>
        </Typography>
        <DataTable
          data={violations}
          columns={[
            { label: 'Event', key: 'status' },
            { label: 'Created', key: 'created_on' },
            { label: 'Violation ID', key: 'violation_id' },
          ]}
        />
      </DialogContent>
    </Dialog>
  )
}


export default withStyles(styles)(Violations)
