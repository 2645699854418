import { useState, useEffect } from 'react'
import {
  useMap,
  useMapEvents,
} from 'react-leaflet'
import { DEFAULT_MAP_CENTER } from '../../utils/site-maps'
import useRememberPanZoom from './useRememberPanZoom'

function StickyPanZoom() {
  const map = useMap()
  const [ flown, setFlown ] = useState(false)
  const {
    center,
    zoom,
    updateCenter,
    updateZoom
  } = useRememberPanZoom(DEFAULT_MAP_CENTER, 12)

  useEffect(() => {
    if (!flown && map && center) {
      map.flyTo(center, zoom)
      setFlown(true)
    }
  }, [map, center, zoom, flown])

  function makeDebounced(func, timeout) {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => func(...args), timeout)
    }
  }

  const debouncedUpdateCenter = makeDebounced(ctr => updateCenter(ctr), 5000)
  const debouncedUpdateZoom   = makeDebounced(zm  => updateZoom(zm),    5000)

  useMapEvents({
    moveend: () => {
      const center = map?.getCenter()
      center && debouncedUpdateCenter(center)
    },
    zoomend: () => {
      const zoom = map?.getZoom()
      zoom && debouncedUpdateZoom(zoom)
    }
  })

  return null
}

export default StickyPanZoom
