import { Button, Box } from '@material-ui/core'

function TopActionPanel({ actionLabel, onAction, onCancel, disabled }) {
  return <Box
    position='fixed'
    display='flex'
    bgcolor='background.paper'
    right='50%'
    top={10}
    padding={1}
    borderRadius={4}
    sx={{ transform: 'translate(50%)' }}>
    <Button
      variant='contained'
      color='secondary'
      style={{ marginRight: '10px' }}
      onClick={onAction}
      disabled={disabled}>
      {actionLabel}
    </Button>
    <Button onClick={onCancel}>
      Cancel
    </Button>
  </Box>
}

export default TopActionPanel