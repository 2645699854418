import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { trackerName } from '../../utils'
import { useGetDronesQuery } from '../../api/aircraft'
import {
  netridWS,
  trackWS,
  useGetTrackersQuery,
} from '../../api/tracker'
import useCompanies    from '../../components/useCompanies'
import usePermissions  from '../../components/usePermissions'
import DualLabelSwitch from '../../components/Inputs/DualLabelSwitch'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core'
import {
  ExpandMore,
  Send,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(3)
  },
  fabMargin: {
    marginTop: theme.spacing(3)
  }
})

let ws
let uaIdOnWs

function InjectTracks({ classes, query }) {

  const canInjectTelem = usePermissions('developer-inject-write', { warn: true })

  const [droneId, setDroneId] = useState('')
  const [trackerId, setTrackerId] = useState('')
  const [altitudeType, setAltitudeType] = useState('HAE')
  const [autoFill, setAutoFill] = useState(false)
  const [groundLevelHae, setGroundLevelHae] = useState('0')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [altitude, setAltitude] = useState('0')
  const [status, setStatus] = useState('2')
  const [dateTime, setDateTime] = useState('')
  const [velocity, setVelocity] = useState('')
  const [acceleration, setAcceleration] = useState('')
  const [heading, setHeading] = useState('')
  const [verticalAccuracy, setVerticalAccuracy] = useState('')
  const [horizontalAccuracy, setHorizontalAccuracy] = useState('')
  const [flightTimeRemaining, setFlightTimeRemaining] = useState('')
  const [flightTimeCapacity, setFlightTimeCapacity] = useState('')
  const [temperature, setTemperature] = useState('')
  const [vdop, setVdop] = useState('')
  const [hdop, setHdop] = useState('')
  const [roll, setRoll] = useState('')
  const [yaw, setYaw] = useState('')
  const [pitch, setPitch] = useState('')
  const [battVoltage, setBattVoltage] = useState('')
  const [battDuration, setBattDuration] = useState('')
  const [rssi, setRssi] = useState('')
  const [imsi, setImsi] = useState('')
  const [imei, setImei] = useState('')
  const [log, setLog] = useState('')

  const [formErrors, setFormErrors] = useState({
    droneId: '',
    trackerId: '',
    altitudeType: '',
    groundLevelHae: '',
    latitude: '',
    longitude: '',
    altitude: '',
    status: '',
    dateTime: '',
    velocity: '',
    acceleration: '',
    heading: '',
    verticalAccuracy: '',
    horizontalAccuracy: '',
    flightTimeRemaining: '',
    flightTimeCapacity: '',
    temperature: '',
    vdop: '',
    hdop: '',
    roll: '',
    yaw: '',
    pitch: '',
    battVoltage: '',
    battDuration: '',
    rssi: '',
    imsi: '',
    imei: '',
    log: ''
  })
  const [setupExpanded, setSetupExpanded] = useState(true)

  const { company_ids } = useCompanies()

  const { data: droneData } =  useGetDronesQuery({
    company_ids,
    serviceable: 'true',
    status:      'active',
  }, { skip: !company_ids })

  const { data: trackerData } = useGetTrackersQuery({
    company_ids,
  }, { skip: !company_ids })

  const drones   = droneData?.data?.drones     || []
  const trackers = trackerData?.data?.trackers || []

  const [tracks, setTracks] = useState({})

  useEffect(() => {
    if (company_ids) {
      (async () => { await trackWS({
        company_ids,
        setTracks,
      }) })()
    }
  }, [company_ids])

  useEffect(() => {
    let telem
    if (droneId) {
      telem = tracks[droneId]
    } else if (trackerId) {
      telem = tracks[trackerId]
    } else {
      telem = null
    }

    if (telem?.lat && telem?.lng && autoFill) {
      setLatitude(telem.lat)
      setLongitude(telem.lng)
    }
    if (telem?.alt && autoFill) {
      setAltitude(telem.alt / 100)
    }
  },  [autoFill, droneId, trackerId, tracks] )

  // Same tracker taking precedence
  useEffect(() => {
    const { tracker, drone } = query
    if (tracker && trackerData?.data?.trackers) {
      setTrackerId(tracker)
      setSetupExpanded(false)
      document.title = `MyDroneFleets | Developer | Inject Telem for Tracker ${
        trackerName(trackerData.data.trackers.find(t => t.tracker_id === tracker))}`
    }
    else if (drone && droneData?.data?.drones) {
      setDroneId(drone)
      setSetupExpanded(false)
      document.title = `MyDroneFleets | Developer | Inject Telem for Drone ${
        droneData.data.drones.find(d => d.drone_id === drone)?.name || '(Unknown)'}`
    }
  }, [query, droneData, trackerData])

  useEffect(() => {
    const ua_id = trackerId || droneId
    if ((!ws && ua_id) || (ws && ua_id !== uaIdOnWs)) {
      const type = trackerId ? 'tracker' : 'drone'
      ws = netridWS({ ua_id, type })
      console.log('swapping ua_id', ua_id, 'type', type)
      uaIdOnWs = ua_id
    }
  }, [trackerId, droneId])

  if (!canInjectTelem) {
    return null
  }

  const handleAltitudeType = (event) => {
    if (event.target.checked) {
      setAltitudeType('HAE')
      setGroundLevelHae('0')
    } else {
      setAltitudeType('AGL')
    }
  }

  const validateInject = () => {
    let uaIdSelect = false
    if (droneId || trackerId) {
      uaIdSelect = true
    }
    let coordinates = false
    if (latitude && longitude) {
      coordinates = true
    }

    return uaIdSelect &&
      coordinates &&
      formErrors.latitude === '' &&
      formErrors.longitude === '' &&
      formErrors.altitude === ''
  }

  const handleInject = () => {
    let rfc3339Date
    if (dateTime){
      const now = new Date(dateTime)
      rfc3339Date = now.toISOString()
    } else {
      const now = new Date()
      rfc3339Date = now.toISOString()
    }
    const payload = {
      t: rfc3339Date,
      lat: latitude,
      lng: longitude,
      alt: (parseInt(altitude) + parseInt(groundLevelHae)) * 100,
      status: parseInt(status),
      v_acc: verticalAccuracy ? parseFloat(verticalAccuracy) : -1,
      h_acc: horizontalAccuracy ? parseFloat(horizontalAccuracy) : -1,
    }

    if (velocity) {
      payload.v = parseFloat(velocity)
    }
    if (acceleration) {
      payload.a = parseFloat(acceleration)
    }
    if (heading) {
      payload.head = parseFloat(heading)
    }
    if (flightTimeRemaining) {
      payload.flight_time_remaining = parseInt(flightTimeRemaining)
    }
    if (flightTimeCapacity) {
      payload.flight_time_capacity = parseInt(flightTimeCapacity)
    }
    if (temperature) {
      payload.temp = parseFloat(temperature)
    }
    if (vdop) {
      payload.vdop = parseFloat(vdop)
    }
    if (hdop) {
      payload.hdop = parseFloat(hdop)
    }
    if (roll) {
      payload.roll = parseFloat(roll)
    }
    if (yaw) {
      payload.yaw = parseFloat(yaw)
    }
    if (pitch) {
      payload.pitch = parseFloat(pitch)
    }
    if (battVoltage) {
      payload.batt_voltage = parseInt(battVoltage)
    }
    if (battDuration) {
      payload.batt_duration = parseInt(battDuration)
    }
    if (rssi) {
      payload.rssi = rssi
    }
    if (imsi) {
      payload.imsi = imsi
    }
    if (imei) {
      payload.imei = imei
    }
    if (log) {
      payload.log = log
    }

    if (ws) {
      console.log('sending netrid payload', payload, ws)
      try {
        ws.json(payload)
        toast.info(`track sent (${new Date().toLocaleTimeString()})`)
      } catch (e) {
        console.log('NerRID error', e)
        toast.error('NetRID connection failed. Please try again.')
      }

    } else {
      console.log('netrid ws not open to send', payload)
      toast.info('Could not send telemetry over NetRID connection because it is not open. Please try again.')
    }
  }

  const setupInputs = <>
    <Grid item xs={12}>
      <Accordion expanded={setupExpanded} onChange={() => { setSetupExpanded(!setupExpanded) }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}>
          <Typography>
            { setupExpanded ? 'Setup' :
              trackerId ?
                `Injecting as Tracker: ${trackerName(trackers.find(t => t.tracker_id === trackerId))}` :
              droneId ?
                `Injecting as Drone: ${drones.find(d => d.drone_id === droneId)?.name || ''}` :
              'No drone or tracker selected'
            }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            item
            spacing={3}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            xs={12}>
            <Grid item xs={12} md={3}>
              <TextField select fullWidth
                         label='Drone'
                         value={droneId}
                         variant={'filled'}
                         onChange={e => {
                           setTrackerId('')
                           setDroneId(e.target.value)
                         }}>
                { drones.map(d => (
                  <MenuItem key={d.drone_id} value={d.drone_id}>{d.name}</MenuItem>
                )) }
              </TextField>
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography align={'center'}>OR</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField select fullWidth
                         label='Tracker'
                         value={trackerId}
                         variant={'filled'}
                         onChange={e => {
                           setDroneId('')
                           setTrackerId(e.target.value)
                         }}>
                { trackers.map(t => (
                  <MenuItem key={t.tracker_id} value={t.tracker_id}>
                    {trackerName(t)}
                  </MenuItem>
                )) }
              </TextField>
            </Grid>
            <Grid item xs={12} md={altitudeType === 'AGL' ? 2 : 4}>
              <DualLabelSwitch
                leftLabel={'AGL'}
                rightLabel={'HAE'}
                checked={altitudeType === 'HAE'}
                onChange={handleAltitudeType}
              />
              <Typography>{altitudeType === 'AGL' ? 'Above Ground Level' : 'Height Above Ellipsoid(HAE), measured from the WGS84 ellipsoid'}</Typography>
            </Grid>
            {altitudeType === 'AGL' ?
              <Grid item xs={12} md={2}>
                <MdfTextInput
                  type='float'
                  label='Ground Level HAE'
                  value={groundLevelHae}
                  disabled={altitudeType === 'HAE'}
                  adornment={'m'}
                  validate={(value) => value < -100000 || value > 100000}
                  onChange={e => {setGroundLevelHae(e.target.value)}}
                />
              </Grid> : null
            }
            <Grid item xs={12} md={2}>
              <DualLabelSwitch
                leftLabel={'No Auto Fill'}
                rightLabel={'Auto Fill'}
                checked={autoFill}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAutoFill(true)
                  } else {
                    setAutoFill(false)
                  }
                }}
              />
              <Typography>{'Auto fill lat/lng from prev track'}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </>

  const telemetryInputs = <>
    <Grid
      container
      item
      spacing={3}
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      xs={12}>
      <Grid item xs={12} md={3}>
        <MdfTextInput
          required={true}
          type='float'
          label={'Latitude'}
          value={latitude}
          errorMessage={formErrors.latitude}
          adornment={'°'}
          validate={(value) => {
            if (value === '') {
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                latitude: 'Latitude is required',
              }))
              return true
            }
            const regex = /^([-]?(([1-8][0-9]|[0-9])\.[0-9]{1,7})|90\.0{1,7})?$/
            if (!regex.test(value)) {
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                latitude: 'Must be a valid latitude',
              }))
              return true
            }
            setFormErrors((prevFormErrors) => ({ ...prevFormErrors, latitude: '' }))
            return false
          }}
          onChange={e => {
            const newValue = e.target.value
            if (/^-?\d*\.?\d{0,7}$/.test(newValue)) {
              setLatitude(newValue)
            } else {
              const truncatedValue = parseFloat(newValue).toFixed(7)
              setLatitude(truncatedValue)
            }
          }}
          onClick={e => {
            if (droneId || trackerId) {
              setSetupExpanded(false)
            }
          }} />
      </Grid>
      <Grid item xs={12} md={3}>
        <MdfTextInput
          required={true}
          type='float'
          label={'Longitude'}
          value={longitude}
          adornment={'°'}
          errorMessage={formErrors.longitude}
          validate={(value) => {
            if (value === '') {
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                longitude: 'Longitude is required',
              }))
              return true
            }
            const regex = /^([-]?((1[0-7][0-9]|[1-9][0-9]|[0-9])\.[0-9]{1,7})|180\.0{1,7})?$/
            if (!regex.test(value)) {
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                longitude: 'Must be a valid longitude',
              }))
              return true
            }
            setFormErrors((prevFormErrors) => ({ ...prevFormErrors, longitude: '' }))
            return false
          }}
          onChange={e => {
            const newValue = e.target.value
            if (/^-?\d*\.?\d{0,7}$/.test(newValue)) {
              setLongitude(newValue)
            } else {
              const truncatedValue = parseFloat(newValue).toFixed(7)
              setLongitude(truncatedValue)
            }
          }} />
      </Grid>
      <Grid item xs={12} md={3}>
        <MdfTextInput
          required={true}
          type='float'
          label={altitudeType === 'HAE' ?
            'Altitude, Height Above Ellipsoid(HAE)' :
            'Altitude, Above Ground Level in metres'}
          value={altitude}
          adornment={'m'}
          errorMessage={formErrors.altitude}
          validate={(value) => {
            if (value === '') {
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                altitude: 'Altitude is required',
              }))
              return true
            }
            if (value < -100000 || value > 100000) {
              setFormErrors((prevFormErrors) => ({
                ...prevFormErrors,
                altitude: 'Value should be (-100000, 100000)',
              }))
              return true
            }
            setFormErrors((prevFormErrors) => ({ ...prevFormErrors, altitude: '' }))
            return false
          }}
          onChange={e => {setAltitude(e.target.value)}}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DualLabelSwitch
          leftLabel={'Grounded'}
          rightLabel={'Airborne'}
          checked={status === '2'}
          onChange={(e) => {
            if (e.target.checked) {
              setStatus('2')
            } else {
              setStatus('1')
            }
          }}
        />
      </Grid>
    </Grid>
  </>

  const optionalInputs = <>
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}>
          <Typography>Optional Telemetry</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            item
            spacing={3}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            xs={12}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                value={dateTime}
                type='datetime-local'
                variant='filled'
                helperText={'Leave blank to default to time sent'}
                onChange={e => {setDateTime(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Velocity'
                value={velocity}
                adornment={'m/s'}
                validate={(value) => value < -63 || value > 63}
                helperText={'Leave blank to calculate from Lat/Lng'}
                onChange={e => {setVelocity(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Acceleration'
                value={acceleration}
                adornment={'m/s²'}
                validate={(value) => value < -63 || value > 63}
                helperText={'Leave blank to calculate from Lat/Lng'}
                onChange={e => {setAcceleration(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Heading, direction of aircraft is moving (clockwise from Magnetic North)'
                value={heading}
                adornment={'°'}
                validate={(value) => value < 0 || value > 359}
                helperText={'Leave blank to calculate from Lat/Lng'}
                onChange={e => {setHeading(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Vertical Accuracy'
                value={verticalAccuracy}
                adornment={'m'}
                validate={(value) => {
                  if (value) {
                    //Validate for two decimal points
                    const regex = /^\d+(\.\d{0,2})?$/
                    return value < -1 || !regex.test(value)
                  } else {
                    return false
                  }
                }}
                helperText={'Leave blank if unknown or not available (it will be set to -1)'}
                onChange={e => {setVerticalAccuracy(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Horizontal Accuracy'
                value={horizontalAccuracy}
                adornment={'m'}
                validate={(value) => {
                  if (value) {
                    //Validate for two decimal points
                    const regex = /^\d+(\.\d{0,2})?$/
                    return value < -1 || !regex.test(value)
                  } else {
                    return false
                  }
                }}
                helperText={'Leave blank if unknown or not available (it will be set to -1)'}
                onChange={e => {setHorizontalAccuracy(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Flight Time Remaining'
                value={flightTimeRemaining}
                adornment={'s'}
                helperText={'Estimated remaining duration that the UA can remain to be powered on'}
                onChange={e => {setFlightTimeRemaining(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Flight Time Capacity'
                value={flightTimeCapacity}
                adornment={'s'}
                helperText={'Estimated remaining duration that the UA can fly on a full tank of fuel or a full battery charge'}
                onChange={e => {setFlightTimeCapacity(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Temperature'
                value={temperature}
                adornment={'°C'}
                helperText={'Internal temperature sensor reading for UA/Tracker'}
                onChange={e => {setTemperature(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='VDOP, Vertical dilution of precision'
                value={vdop}
                helperText={'Unknown or not available is indicated by -1'}
                onChange={e => {setVdop(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='HDOP, Horizontal dilution of precision'
                value={hdop}
                helperText={'Unknown or not available is indicated by -1'}
                onChange={e => {setHdop(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Roll'
                value={roll}
                adornment={'°'}
                helperText={'Angle of rotation along the front-to-back axis of the UA'}
                onChange={e => {setRoll(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Yaw'
                value={yaw}
                adornment={'°'}
                helperText={'Angle of rotation along the vertical axis of the UA'}
                onChange={e => {setYaw(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Pitch'
                value={pitch}
                adornment={'°'}
                helperText={'Angle of rotation along side-to-side axis relative of the UA'}
                onChange={e => {setPitch(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Battery Voltage'
                value={battVoltage}
                adornment={'mV'}
                helperText={'Refers to the voltage reading from the UA\'s power monitor'}
                onChange={e => {setBattVoltage(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                type='float'
                label='Battery Duration'
                value={battDuration}
                adornment={'s'}
                helperText={'Refers to the estimated remaining duration that the UA can remain to be powered on'}
                onChange={e => {setBattDuration(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                label='RSSI'
                value={rssi}
                helperText={'Received Signal Strength Indicator'}
                onChange={e => {setRssi(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                label='IMSI'
                value={imsi}
                helperText={'International Mobile Subscriber Identity'}
                onChange={e => {setImsi(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                label='IMEI'
                value={imei}
                helperText={'International Mobile Equipment Identity'}
                onChange={e => {setImei(e.target.value)}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MdfTextInput
                label='Log'
                value={log}
                helperText={'Diagnostic String/Text'}
                onChange={e => {setLog(e.target.value)}}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  </>

  return (
    <div className={classes.root}>
      <Typography variant='h6' gutterBottom>
        Inject Telemetry
      </Typography>
      <Grid container spacing={3}>
        {setupInputs}
        {telemetryInputs}
        {optionalInputs}
      </Grid>
      <Fab variant={'extended'} className={classes.fabMargin} onClick={handleInject} disabled={!validateInject()}>
        <Send />
        Inject
      </Fab>
    </div>
  )
}

function MdfFormErrorText({ helperText }) {
  const classes = makeStyles(theme => ({
    text: {
      color: theme.palette.tracker.red
    },
  }))()
  return (
    <FormHelperText className={classes.text}>
      {helperText}
    </FormHelperText>
  )
}

function MdfTextInput({
  type,
  label,
  value,
  disabled,
  adornment = '',
  errorMessage,
  validate,
  onChange,
  ...props
}) {
  const [ error, setError ] = useState(false)

  function handleBlur(e) {
    setError(!!(validate && validate(e.target.value)))
  }

  function handleChange(e) {
    onChange(e)
    setError(!!(validate && validate(e.target.value)))
  }

  switch (type) {
    case 'float':
      return <>
        <TextField
          fullWidth
          type='number'
          label={label}
          value={value}
          variant='filled'
          disabled={disabled}
          error={error}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position='start'>{adornment}</InputAdornment>,
          }}
          onChange={handleChange}
          {...props}
        />
        {error && <MdfFormErrorText helperText={errorMessage} />}
      </>

    case 'coordinates':
      return <>
        <TextField
          fullWidth
          type='number'
          label={label}
          value={value}
          variant='filled'
          disabled={disabled}
          error={error}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position='start'>{adornment ? adornment : '°'}</InputAdornment>,
            inputProps: { step: '0.0000001' }
          }}
          onChange={handleChange}
          {...props}
        />
        {error && <MdfFormErrorText helperText={errorMessage} />}
      </>

    default:
      return <>
        <TextField
          fullWidth
          label={label}
          value={value}
          variant='filled'
          disabled={disabled}
          error={error}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position='start'>{adornment}</InputAdornment>,
          }}
          onChange={handleChange}
          {...props}
        />
        {error && <MdfFormErrorText helperText={errorMessage} />}
      </>
  }
}

const mapStateToProps = (state) => ({
  query: state.router.location.query
})

export default connect(mapStateToProps, null)(withStyles(styles)(InjectTracks))
