import {
  Card,
  CardContent,
  CardHeader,
  Slide,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    position: 'fixed',
    left:   theme.spacing(5),
    top:    theme.spacing(1),
    zIndex: 500,
    margin: theme.spacing(2),
  },
  card: {
    width:      theme.spacing(50),
    height:     theme.spacing(80),
    overflow:   'scroll',
    background: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  closeBtn: {
    cursor: 'pointer',
    color:  theme.palette.common.white,
  },
  content: {
    color: theme.palette.common.black,
  },
})

function CardSlide({ classes, showCard, title, subheader, onClose, children }) {
  return (
    <Slide
      direction='right'
      in={showCard}
      mountOnEnter
      unmountOnExit>
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardHeader
            align='center'
            className={classes.header}
            title={title}
            subheader={subheader}
            action={<Close className={classes.closeBtn} onClick={onClose} />}>
          </CardHeader>
          <CardContent>
            {children}
          </CardContent>
        </Card>
      </div>
    </Slide>
  )
}

export default withStyles(styles)(CardSlide)
