import { useInterval } from 'usehooks-ts'

const REFRESH_PERIOD   = 30000 // check every 30 seconds (frequent enough for below)
const OFFLINE_PERIOD   = 60000 // no telemetry events for 1 minute prior to becoming offline
const CONFORM_PERIOD   = 60000 // no violation events for 1 minute prior to reverting to in compliance

function useRefreshOnline({
  allDrones,
  setAllDrones,
  allTrackers,
  setAllTrackers,
}) {
  useInterval(() => {
    const now = Date.now()
    const offline_time = now - OFFLINE_PERIOD
    const conformance_time = now - CONFORM_PERIOD

    function updateConformOrOffline(ua) {
      const newUa = { ...ua }

      if (ua.violations && ua.violations_last_updated < conformance_time) {
        delete newUa.violations
        newUa.violations_last_updated = now
      }
      if (ua.online && ua.last_updated < offline_time) {
        newUa.online = false
        newUa.last_updated = now
      }
      return newUa
    }

    if (Object.keys(allDrones).length > 0) {
      setAllDrones(prevAD => {
        const droneArray = Object.values(prevAD).map(updateConformOrOffline)
        const newAllDrones = {}
        for (const d of droneArray)
          newAllDrones[d.drone_id] = d
        return newAllDrones
      })
    }
    if (Object.keys(allTrackers).length > 0) {
      setAllTrackers(prevAT => {
        const trackerArray = Object.values(prevAT).map(updateConformOrOffline)
        const newAllTrackers = {}
        for (const t of trackerArray)
          newAllTrackers[t.tracker_id] = t
        return newAllTrackers
      })
    }
  }, Object.keys(allDrones).length + Object.keys(allTrackers).length > 0 ? REFRESH_PERIOD : null)
}

export default useRefreshOnline
