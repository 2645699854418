import { useState }     from 'react'
import { toast }        from 'react-toastify'
import { useDeleteNFZMutation } from '../../../../api/airspace'
import { meterToFeet2dp } from '../../../../utils'
import WaitDialog       from '../../../../components/WaitDialog'
import AreYouSureDialog from '../../../../components/AreYouSureDialog'
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  DeleteOutlined,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { isController } from '../../../../api/accounts'

const styles = theme => ({
  list: {
    flexDirection: 'column',
  },
  selectedList: {
    flexDirection: 'column',
    border: `5px solid ${theme.palette.map.darkBlue}`,
    fontWeight: 'bold',
  },
  active: {
    width: theme.spacing(8),
    height: theme.spacing(5),
    padding: theme.spacing(1, 0, 1, 2),
    backgroundColor: theme.palette.map.darkBlue,
  },
  inactive: {
    width: theme.spacing(8),
    height: theme.spacing(5),
    padding: theme.spacing(1, 0, 1, 2),
  },
  actions: {
    width:          '100%',
    paddingLeft:    theme.spacing(1),
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'space-between',
  },
  left: {
    display:     'flex',
    alignItems:  'center',
    width:       theme.spacing(24),
  },
  right: {
    display:     'flex',
    alignItems:  'center',
    width:       theme.spacing(10),
  },
  minAlt: {
    backgroundColor: theme.palette.map.darkBlue,
    marginLeft:      theme.spacing(0.5),
    width:           theme.spacing(4),
    height:          theme.spacing(4),
    justifyContent: 'center',
    color:          'white'
  },
  maxAlt: {
    backgroundColor: theme.palette.map.darkBlue,
    marginLeft:      theme.spacing(0.5),
    width:           theme.spacing(4),
    height:          theme.spacing(4),
    color:           'white'
  },
})

function sToDuration(s) {
  return s < 120  ? 'moments' :
    s <      3600 ? `${Math.floor(s / 60)} mins`  :
    s < 24 * 3600 ? `${Math.floor(s / 3600)} hrs` :
    'day(s)'
}

function isNfzActive(nfz) {
  if (!nfz || !nfz.validity)
    return false
  const now = Date.now() / 1000 // in seconds
  return nfz.validity.reduce((a, v) => (a || (v.start < now && now < v.end)), false)
}

function GeofenceList({ classes, geofences, selectedId, onClickGeofence, isRsaf }) {
  const [ deleteNFZ ] = useDeleteNFZMutation()
  const [ aysDelete,  setAysDelete  ] = useState(false)
  const [ waitDelete, setWaitDelete ] = useState(false)

  const selectedName = geofences?.find(gf => gf.nfz_id === selectedId)?.name || ''
  function handleDelete() {
    if (!selectedId)
      return
    const name = selectedName

    // Unselecting before calling backend to delete has the benefit of no
    // errorneous rendering of a deleted geofence in the RTK Query cache
    setWaitDelete(true)

    deleteNFZ(selectedId)
    .unwrap()
    .then(fulfilled => {
      console.log('delete fulfilled', fulfilled)
      setWaitDelete(false)
      if (fulfilled.status === 'success') {
        toast.success(`Geofence ${name} deleted`)
        setAysDelete(false)
      } else {
        toast.warning(`Failed to delete geofence ${name}`)
      }
    })
    .catch(rejected => {
      setWaitDelete(false)
      console.log('delete rejected', rejected)
      toast.error('Error deleting geofence')
    })
  }

  const now = Math.round(Date.now() / 1000)

  return (<>
    <List>
      { geofences.map(ag => (
        <ListItem key={ag.nfz_id}
          button 
          dense
          disableGutters
          selected={selectedId === ag.nfz_id}
          className={selectedId === ag.nfz_id ? classes.selectedList : classes.list}
          onClick={() => onClickGeofence(ag)}>
          { selectedId === ag.nfz_id && isController() && isRsaf &&
            <div className={classes.actions}>
              <div>{ ag.name }</div>
              <IconButton onClick={e => { setAysDelete(true) }}>
                <DeleteOutlined />
              </IconButton>
            </div>
          }
          <div style={{ display: 'flex' }}>
            <div className={classes.left}>
              <Tooltip title={ ag.name || '-' }>
                <Typography variant='body2' className={
                  (isNfzActive(ag) ? classes.active : classes.inactive)
                }>
                  { ag.name?.length <= 4 ? ag.name : ag.nfz_id.slice(-4).toUpperCase() }
                </Typography>
              </Tooltip>
              <Typography variant='body2' style={{ paddingLeft: '8px' }}>
                <b>{isNfzActive(ag) ?
                  (ag.restriction === 'keepin-area' ? 'KEEP IN' : 'KEEP OUT') :
                  'Inactive'}</b>
                <br />
                { ag.validity.map(v => (
                  !(isFinite(v.start) && isFinite(v.end) && v.start > 0 && v.end > 0) ? '(unknown time)' :
                  now > v.end   ? (sToDuration(now - v.end) + ' ago') :
                  now > v.start ? (sToDuration(v.end - now) + ' left') :
                  ('starting in ' + sToDuration(v.start - now))
                )) }
              </Typography>
            </div>
            <div className={classes.right}>
              <Tooltip title={`${meterToFeet2dp(ag.min_altitude)} ft`}>
                <Avatar className={classes.minAlt}>
                  <Typography variant='body2'>
                    <b>{ag.min_altitude <= -10000 ? 'MIN' : ag.min_altitude.toFixed(0)}</b>
                  </Typography>
                </Avatar>
              </Tooltip>
              <Tooltip title={`${meterToFeet2dp(ag.altitude)} ft`}>
                <Avatar className={classes.maxAlt}>
                  <Typography variant='body2'>
                    <b>{ag.altitude >= 100000 ? 'MAX' : ag.altitude.toFixed(0)}</b>
                  </Typography>
                </Avatar>
              </Tooltip>
            </div>
          </div>
        </ListItem>
      )) }
    </List>
    { aysDelete &&
      <AreYouSureDialog
        open={aysDelete}
        onClose={() => { setAysDelete(false) }}
        title='Confirm Delete Geofence'
        message={<>
          Are you sure you want to delete this geofence?<br /><br />
          <b>{selectedName}</b>
        </>}
        action='Delete'
        onOK={handleDelete}
      />
    }
    { waitDelete &&
      <WaitDialog msg={`Deleting geofence ${selectedName}, please wait...`} />
    }
  </>)
}

export default withStyles(styles)(GeofenceList)
