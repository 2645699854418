import { toast } from 'react-toastify'
import { useGetUsersQuery } from '../../../api/accounts'
import { useGetDronesQuery } from '../../../api/aircraft'
import { useGetTrackersQuery } from '../../../api/tracker'
import { useGetPrivateNFZsQuery } from '../../../api/airspace'
import {
  useCreateFlightMutation,
  useUpdateFlightMutation,
} from '../../../api/aircraft'
import TopXCloseButton from '../../../components/TopXCloseButton'

import {
  makeDuration,
  prettyXprizeFlightId,
} from './xprize-utils'
import XprizeStartFlight from './XprizeStartFlight'
import XprizeStopFlight  from './XprizeStopFlight'
import {
  Dialog,
} from '@material-ui/core'

function XprizeFlightsDialog({
  open,
  onClose,
  xprizeCompanies,
  tracks,
  action,
  activeFlights,
}) {
  const xprizeCompanyIds = xprizeCompanies?.map(c => c.company_id)

  const { data: droneData } = useGetDronesQuery({
    company_ids: xprizeCompanyIds,
    serviceable: 'true',
    status:      'active',
  }, { skip: !xprizeCompanies })

  const { data: pilotData } = useGetUsersQuery({
    company_ids: xprizeCompanyIds,
    // do we limit to app role pilots?
  }, { skip: !xprizeCompanies })

  const { data: trackerData } = useGetTrackersQuery({
    company_ids: xprizeCompanyIds,
  }, { skip: !xprizeCompanies })

  const { data: geofenceData } = useGetPrivateNFZsQuery({
    company_ids: xprizeCompanyIds,
    restriction: 'test-estate'
  }, { skip: !xprizeCompanies })

  const drones = droneData?.data?.drones       || []
  const pilots = pilotData?.data?.users        || []
  const trackers = trackerData?.data?.trackers || []
  const geofences = geofenceData?.entities     || {}
  const [ createFlight ] = useCreateFlightMutation()
  const [ updateFlight ] = useUpdateFlightMutation()

  const pilotsByCompany = {}
  pilots.forEach(p => {
    if (pilotsByCompany[p.company.company_id])
      pilotsByCompany[p.company.company_id].push(p)
    else
      pilotsByCompany[p.company.company_id] = [p]
  })

  const dronesByCompany = {}
  drones.forEach(d => {
    if (dronesByCompany[d.company_id])
      dronesByCompany[d.company_id].push(d)
    else
      dronesByCompany[d.company_id] = [d]
  })

  function handleStartFlight({ flightToStart }) {

    createFlight(flightToStart)
    .unwrap()
    .then(fulfilled => {
      console.log('create fulfilled', fulfilled)
      if (fulfilled.status === 'success') {
        toast.success(`Flight ${prettyXprizeFlightId(fulfilled.data?.flight?.flight_id)} Started`)
        onClose()
      } else {
        console.log('Failed to start flight', fulfilled)
        toast.warning(`Failed to start flight: ${JSON.stringify(fulfilled.data)}`)
      }
    })
    .catch(rejected => {
      console.log('create rejected', rejected)
      toast.error('Error starting flight')
    })
  }

  function handleStopFlight({ flightToStop }) {

    updateFlight({
      flight_id: flightToStop.flight_id,
      status: 'postflight',
      duration: makeDuration(flightToStop.date),
      // Review if a separate endpoint is needed to start / stop flight
      // or split requestbody from schema (unnecessary required fields)
      date: flightToStop.date,
      drone: flightToStop.drone,
      pilots: flightToStop.pilots,
      location: flightToStop.location,
      // TODO: the rest of the post flight needs to be done separately
      //       through querying the tracks using date / duration later
    })
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      if (fulfilled.status === 'success') {
        toast.success(`Flight ${prettyXprizeFlightId(flightToStop.flight_id)} Stopped`)
        onClose()
      } else {
        toast.warning(`Failed to stop flight ${prettyXprizeFlightId(flightToStop.flight_id)}`)
      }
    })
    .catch(rejected => {
      console.log('update rejected', rejected)
      toast.error('Error stopping flight')
    })
  }

  return (
    <Dialog fullWidth maxWidth='md'
      open={open}
      onClose={onClose}>
      <TopXCloseButton onClick={onClose} />
      { action === 'start' &&
        <XprizeStartFlight
          dronesByCompany={dronesByCompany}
          pilotsByCompany={pilotsByCompany}
          trackers={trackers}
          geofences={geofences}
          activeFlights={activeFlights}
          onStart={handleStartFlight}
          tracks={tracks}
        />
      }
      { action === 'stop' &&
        <XprizeStopFlight
          activeFlights={activeFlights}
          onStop={handleStopFlight}
        />
      }
    </Dialog>
  )
}

export default XprizeFlightsDialog
