import { useMemo, useState } from 'react'
import {
  MapContainer,
  ScaleControl,
  ZoomControl,
} from 'react-leaflet'
import { DEFAULT_MAP_CENTER } from '../../../utils/site-maps'
import { frzColour } from '../../../utils'
import { getApprovedGeometry } from '../../../utils/frz'
import { getPolygonsAtPoint, useClickRotation } from '../../../utils/hooks'

import CommonLayers    from '../../../components/Map/CommonLayers'
import CommonMapControls from '../../../components/Map/CommonMapControls'
import StickyPanZoom   from '../../../components/Map/StickyPanZoom'
import SelectionLayer  from '../../../components/Map/SelectionLayer'
import FrzFeatureGroup from '../../components/FrzFeatureGroup'
import { MousePosition } from '../../../components/Map/MousePosition'

function FRZListMap({ frzs , selectedFrzId, setSelectedFrzId, mapRef, children }) {
  const [ ready, setReady ] = useState(false)
  const selectedFrz = useMemo(
    () => frzs.find((f) => f.frz_id === selectedFrzId),
    [selectedFrzId, frzs]
  )

  const onClickLayer = useClickRotation(
    frzs,
    f => f.frz_id,
    selectedFrz,
    getPolygonsAtPoint,
    c => ({ type: 'Feature', geometry: c.geometry }),
    (_, selectedFrzId) => { setSelectedFrzId(selectedFrzId) }
  )

  return <MapContainer
    ref={mapRef}
    minZoom={2}
    zoom={12}
    zoomControl={false}
    center={DEFAULT_MAP_CENTER}
    whenReady={() => { setReady(true) }}>
    <CommonLayers />
    <ZoomControl position='topleft' />
    <ScaleControl position='bottomleft' imperial={false} />
    <MousePosition/>
    { ready &&
      <StickyPanZoom />
    }
    <CommonMapControls />
    <FrzFeatureGroup 
      showFrz={true}
      frzs={frzs}
      onClick={onClickLayer}
    />
    <SelectionLayer feature={selectedFrz && {
        type: 'Feature',
        geometry: getApprovedGeometry(selectedFrz),
        properties: {
          ...(selectedFrz.company_id && {color: frzColour(selectedFrz.status)}),
          ...selectedFrz.properties
        }
      }}/>
    {children}
  </MapContainer>
}
export default FRZListMap
