import { frzTypes } from '../../../utils/frz-type'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore,
} from '@material-ui/icons'

function AdvancedFields({ name, setName, nameError, type, setType }) {

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Advanced</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body1'>Reservation Type</Typography>
            <TextField
              variant='outlined'
              select
              fullWidth
              value={type}
              onChange={e => { setType(e.target.value) }}>
              { Object.keys(frzTypes).map(t => (
                <MenuItem key={t} value={t}>
                  <div id={`menu-${t}`}>
                    <Typography variant='body1'>
                      <b>{frzTypes[t].name}</b>
                    </Typography>
                    <Typography variant='body2' style={{ textWrap: 'wrap' }}>
                      {frzTypes[t].description}
                    </Typography>
                  </div>
                </MenuItem>
              )) }
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant='outlined' fullWidth>
              <Typography variant='body1'>
                Custom Reservation Name
              </Typography>
              <TextField 
                variant='outlined'
                value={name}
                error={!!nameError}
                helperText={nameError}
                onChange={e => { setName(e.target.value) }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}


export default AdvancedFields
