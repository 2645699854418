import { useGetFRZsQuery } from '../../api/airspace'
import {
  FeatureGroup,
} from 'react-leaflet'
import FrzPolygon from './components/FrzPolygon'
import { startOfDay } from 'date-fns'

function ReservedAirspace({
  company_ids,
  showStatuses,
}) {
  const { data: frzData } = useGetFRZsQuery({
    company_ids,
    'after-validity': startOfDay(new Date()).getTime()
  })
  const frzs = (frzData?.data?.frzs || [])
    .filter(frz => showStatuses.includes(frz.status))

  return (
    <FeatureGroup>
      { frzs.map(frz => (
        <FrzPolygon key={frz.frz_id} frz={frz} />
      )) }
    </FeatureGroup>
  )
}

export default ReservedAirspace
