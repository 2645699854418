import { withRouter } from 'react-router'
import usePermissions from '../../components/usePermissions'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  Build,
} from '@material-ui/icons'

function DeveloperListItem({ classes, history }) {

  const canDevelop = usePermissions('developer-main-read')
  if (!canDevelop)
    return null

  return (
    <ListItem button onClick={e => history.push('/developer')}>
      <ListItemIcon><Build /></ListItemIcon>
      <ListItemText primary='Developer' />
    </ListItem>
  )
}

export default withRouter(DeveloperListItem)
