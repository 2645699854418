import { useState, useEffect } from 'react'
import L from 'leaflet'
import {
  useMap,
} from 'react-leaflet'
import MyLocationMarker from './MyLocationMarker'

function CommonMapControls({ position = 'topleft' }) {
  const map = useMap()
  const [ enableLocation, setEnableLocation ] = useState(false)

  useEffect(() => {
    if (map) {
      L.Control.locateMe = L.Control.extend({
        onAdd: function(map) {
          const btn = L.DomUtil.create('button', 'leaflet-control')
          const icon = L.DomUtil.create('img', '', btn)
          icon.src = require('../../assets/map/target.png')
          icon.height = 16
          icon.style.verticalAlign = 'middle'
          btn.style.marginBottom = '0px'
          btn.style.backgroundColor = '#fff'
          btn.style.border = '2px solid rgba(0,0,0,0.32)'
          btn.style.borderRadius = '4px'
          btn.style.borderBottomLeftRadius = '0px'
          btn.style.borderBottomRightRadius = '0px'
          btn.style.borderBottom = '1px'
          btn.style.borderBottomStyle = 'solid'
          btn.style.borderBottomColor = 'rgba(0, 0, 0, 0.32)'
          btn.style.textAlign = 'center'
          btn.style.lineHeight = 2
          btn.style.cursor = 'pointer'
          btn.style.height = '34px'
          btn.style.width = '34px'

          btn.addEventListener('mouseover', e => {   
            btn.style.backgroundColor = '#f4f4f4'
          }, false)

          btn.addEventListener('mouseout', e => {   
            btn.style.backgroundColor = '#fff'
          }, false)

          btn.onclick = e => {
            setEnableLocation(l => !l)
          }
          return btn
        },
      })

      L.control.locateMe = function(opts) {
        return new L.Control.locateMe(opts)
      }
      L.control.locateMe({ position }).addTo(map)

      L.Control.panToAll = L.Control.extend({
        onAdd: function(map) {
          const btn = L.DomUtil.create('button', 'leaflet-control')
          const icon = L.DomUtil.create('img', '', btn)
          icon.src = require('../../assets/map/fullscreen.png')
          icon.height = 16
          icon.style.verticalAlign = 'middle'
          btn.style.marginTop = '0px'
          btn.style.backgroundColor = '#fff'
          btn.style.border = '2px solid rgba(0,0,0,0.32)'
          btn.style.borderRadius = '4px'
          btn.style.borderTopLeftRadius = '0px'
          btn.style.borderTopRightRadius = '0px'
          btn.style.borderTop = '1px'
          btn.style.borderTopStyle = 'solid'
          btn.style.borderTopColor = 'rgba(0, 0, 0, 0.32)'
          btn.style.textAlign = 'center'
          btn.style.lineHeight = 2
          btn.style.cursor = 'pointer'
          btn.style.height = '34px'
          btn.style.width = '34px'

          btn.addEventListener('mouseover', e => {   
            btn.style.backgroundColor = '#f4f4f4'
          }, false)

          btn.addEventListener('mouseout', e => {   
            btn.style.backgroundColor = '#fff'
          }, false)

          btn.onclick = e => {
            const allPolygons = []
            map.eachLayer(layer => {
              if (layer instanceof L.Polygon)
                allPolygons.push(layer)
            })
            if (allPolygons.length > 0) {
              const bounds = allPolygons[0].getBounds()
              allPolygons.forEach(polygon => {
                bounds.extend(polygon.getBounds())
              })
              map.flyToBounds(bounds, {
                padding: L.point(36, 36), animate: true,
              })
            }
          }
          return btn
        },
      })

      L.control.panToAll = function(opts) {
        return new L.Control.panToAll(opts)
      }
      L.control.panToAll({ position }).addTo(map)
    }

  }, [position, map])

  return <>
    {enableLocation && <MyLocationMarker
    />}
  </>
}

export default CommonMapControls
