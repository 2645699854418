import { useState, useEffect } from 'react'
import { toast }   from 'react-toastify'
import {
  useLazyGetCompaniesQuery,
  useLazyGetCompanyByIdQuery,
  useLazyGetUsersQuery,
} from '../../../api/accounts'
import {
  useLazyGetDronesQuery,
} from '../../../api/aircraft'
import {
  useLazyGetTrackersQuery,
  useLazyGetTrackerByIdQuery,
} from '../../../api/tracker'
import { flightPurposes } from '../../../utils'

import DroneDetails from '../../DroneDetails'

import TooltipIcon from '../../../components/TooltipIcon'
import TextBox     from '../../../components/Inputs/TextBox'
import GridTitle   from '../../components/GridTitle'
import GridLabel   from '../../components/GridLabel'
import GridField   from '../../components/GridField'
import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Edit,
  Save,
  Undo,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'relative'
  },
  title: {
    padding: theme.spacing(0, 2),
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  column: {
    alignContent: 'flex-start',
    padding: theme.spacing(1),
  },
})

function PreFlight({ classes, flight }) {
  const [ droneInfo, setDroneInfo ] = useState(false)

  const [ edit, setEdit ] = useState(false)

  const [ company, setCompany ] = useState({})
  const [ getCompanies ] = useLazyGetCompaniesQuery()
  const [ getCompany ] = useLazyGetCompanyByIdQuery()

  const [ pilots, setPilots ] = useState([])
  const [ getUsers ] = useLazyGetUsersQuery()

  const [ purpose, setPurpose ] = useState([])

  const [ drone, setDrone ] = useState({})
  const [ getDrones ] = useLazyGetDronesQuery()

  const [ tracker, setTracker ] = useState([])
  const [ getTrackers ] = useLazyGetTrackersQuery()
  const [ getTracker ] = useLazyGetTrackerByIdQuery()

  useEffect(() => {
    if (!flight) {
      setEdit(true)
      return
    }
    if (flight.company_id)
      setCompany({
        company_id: flight.company_id,
        name: '',
      })
    if (flight.drone?.id)
      setDrone({
        drone_id: flight.drone.id,
        name:     flight.drone.name,
      })
    if (flight.drone?.tracker_ids?.length > 0)
      setTracker(flight.drone.tracker_ids.map(tracker_id => ({
        tracker_id,
        name: '',
      })))
    if (flight.pilots)
      setPilots(flight.pilots.map(p => ({
        user_id:   p.id,
        name:      p.name,
        username:  p.username,
        app_roles: p.app_roles,
      })))
    if (flight.purpose)
      setPurpose(flight.purpose.map(id => flightPurposes.find(p => p.purpose_id === id)))
  }, [flight])

  // console.log('company', company, 'drone', drone, 'tracker', tracker, 'purpose', purpose)

  function handleSave() {
    toast.info('Coming Soon')
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography variant='h6'>PreFlight Information</Typography>
        </div>
        <div>{ edit ? <>
          <IconButton onClick={e => setEdit(false)}>
            <TooltipIcon title='Undo' icon={<Undo />} />
          </IconButton>
          <IconButton onClick={handleSave}>
            <TooltipIcon title='Save' icon={<Save />} />
          </IconButton>
        </>:
          <IconButton onClick={e => setEdit(true)}>
            <TooltipIcon title='Edit' icon={<Edit />} />
          </IconButton>
        }</div>
      </div>
      <Grid container>
        <Grid item xs={12} xl={6} container className={classes.column}>
          <GridTitle>Participants</GridTitle>
          <GridLabel>Company</GridLabel>
          <GridField>
            <TextBox
              get={getCompanies}
              getKey='companies'
              getById={() => getCompany(flight?.company_id)}
              getByIdKey='company'
              dataId='company_id'
              dataLabel='name'
              value={company}
              onChange={setCompany}
              disabled={true}
            />
          </GridField>
          <GridLabel>Pilot(s)</GridLabel>
          <GridField>
            <TextBox
              multiple
              get={getUsers}
              getKey='users'
              dataId='user_id'
              dataLabel='name'
              value={pilots}
              onChange={setPilots}
            />
            {/* flight.pilots.map(p => (<Typography variant='body1' key={p.id}>
              { p.name } ({ p.username }) { p.app_roles ? 'as' : ''}
              { p.app_roles?.my_drone_fleets?.length > 0 &&
                <>MyDroneFleets Roles: {p.app_roles.my_drone_fleets.join(', ')}</>
              }
              { p.app_roles?.plex_horizon?.length > 0 &&
                <>Plex Horizon Roles: {p.app_roles.plex_horizon.join(', ')}</>
              }
              { p.app_roles?.plex_pilot?.length > 0 &&
                <>Plex Pilot Roles: {p.app_roles.plex_pilot.join(', ')}</>
              }
            </Typography>)) */}
          </GridField>
          <GridLabel>Purpose(s)</GridLabel>
          <GridField>
            <TextBox
              multiple
              options={flightPurposes}
              dataId='purpose_id'
              dataLabel='name'
              value={purpose}
              onChange={setPurpose}
            />
          </GridField>
        </Grid>
        <Grid item xs={12} xl={6} container className={classes.column}>
          <GridTitle>Equipment</GridTitle>
          <GridLabel>Drone</GridLabel>
          <GridField onMoreInfo={drone ? () => { setDroneInfo(true) } : undefined}>
            <TextBox
              get={getDrones}
              getKey='drones'
              dataId='drone_id'
              dataLabel='name'
              value={drone}
              onChange={setDrone}
            />
          </GridField>
          <GridLabel>Tracker(s)</GridLabel>
          <GridField>
            <TextBox
              multiple
              get={getTrackers}
              getKey='trackers'
              getById={flight?.drone?.tracker_ids?.map(t => () => getTracker(t))}
              getByIdKey='tracker'
              dataId='tracker_id'
              dataLabel='name' // todo support function, then pass in trackerName
              value={tracker}
              onChange={setTracker}
            />
          </GridField>
          {/* TODO: Batteries / Cameras / Payload */}
        </Grid>
      </Grid>
      { droneInfo && drone &&
        <DroneDetails
          droneId={drone.drone_id}
          onClose={() => setDroneInfo(false)}
        />
      }
    </div>
  )
}

export default withStyles(styles)(PreFlight)
