import { toast } from 'react-toastify'
import { useUpdateFlightMutation } from '../../api/aircraft'
import AreYouSureDialog from '../../components/AreYouSureDialog'

function StopFlightDialog({flight, open, onClose}) {
  const [ updateFlight ] = useUpdateFlightMutation()
  return (
    <AreYouSureDialog
      open={open}
      onClose={onClose}
      title='Confirm Stop Flight'
      message='Are you sure you want to stop this flight?'
      action='Stop Flight'
      onOK={() => {
        const elapsed = Date.now() - flight.date
        updateFlight({
          ...flight,
          status: 'postflight',
          duration: {
            hours: Math.floor(elapsed / (1000 * 60 * 60)),
            minutes: Math.floor((elapsed / (1000 * 60)) % 60),
            seconds: Math.floor((elapsed / 1000) % 60)
          }
        }).unwrap().then(result => {
          if (result.status !== 'success') throw result
          toast.success('Flight stopped')
          onClose()
        }).catch(err => {
          toast.error('Failed to stop flight')
          console.info('Failed to stop flight', err)
        })
      }}
    />
  )
}

export default StopFlightDialog