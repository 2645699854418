import CfmsReport from './CfmsReport'
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
  },
})

function ReportsView({ classes }) {

  return (
    <div className={classes.root}>
      <Typography variant='h5'>REPORTS</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant='h6'>System</Typography>
            <Typography variant='body1'>Disabled</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant='h6'>CFMS</Typography>
            <CfmsReport />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )

}

export default withStyles(styles)(ReportsView)