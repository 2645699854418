import { useEffect, useState } from 'react'
import { useWeatherForecastMutation } from '../../../api/meteorology'
import { toast } from 'react-toastify'
import { GeoJSON } from 'react-leaflet'
import L from 'leaflet'
import WeatherIcon from './WeatherIcon'

// temporary, to set up based on severity
const fillOptions = { color: 'blue', fillColor: 'blue' }

function Weather() {
  const [ weatherForecast ] = useWeatherForecastMutation()
  const [ weatherForecastData, setWeatherForecastData ] = useState(null)

  useEffect(() => {
    // for now hardcoded to 1.5 hours forecast
    const endTime = new Date().getTime() + (1.5 * 60 * 60 * 1000)

    weatherForecast({ dataSource: 'sg-nea-2h', endTime })
    .then((res) => {
      const forecasts = res.data.data.forecasts
      setWeatherForecastData(forecasts)
    })
    .catch((err) => {
      console.error('weather forecast error', err)
      toast.error('Failed to get weather forecast')
    })
  }, [weatherForecast])
      
  const generatePointToLayerWithType = (type) => {
    return (feature, latlng) => {
      // const icon = <WeatherIcon type={type} />
      const icon = WeatherIcon({ type })
      console.log('icon', icon)

      // Create and return a marker with the determined icon
      return L.marker(latlng, { icon })
    }
  }
  
  console.log('weather forecast data', weatherForecastData)

  return (<>
    { weatherForecastData && weatherForecastData.map((f, idx) => (
        <GeoJSON
          key={idx}
          data={f.geometry}
          eventHandlers={{
            click: (e) => {
              console.log('click', e)
            }
          }}
          style={fillOptions}
          pointToLayer={generatePointToLayerWithType(f.type)}
        >
          {/* <Popup>hello</Popup> */}
        </GeoJSON>
      ))}
  </>)
}

export default Weather