import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core'
import {
  Warning,
  Error,
} from '@material-ui/icons'

function secToMinSec(sec) {
  return Math.floor(sec / 60) + ' min ' + Math.floor(sec % 60) + ' sec'
}

export default function TrackerSummary({ trackers }) {

  if (!trackers)
    return null

  const
    headerCells =      [<TableCell key='tracker'>Tracker</TableCell>],
    telemCountCells =  [<TableCell key='telemcount'>Telem Count</TableCell>],
    startTimeCells =   [<TableCell key='start'>Start</TableCell>],
    lowBattTimeCells = [<TableCell key='lowbatt'>Low Batt</TableCell>],
    endTimeCells =     [<TableCell key='end'>End</TableCell>],
    sixtyMinCells =    [<TableCell key='sixty'>At 60 Min</TableCell>],
    eightyMinCells =   [<TableCell key='eighty'>At 80 Min</TableCell>]

  for (const imei in trackers) {

    const startTime    = trackers[imei][0].serverTime
    const lowBattTime  = trackers[imei].find(t => t.bat > 0 && t.bat <= 360)?.serverTime
    const endTime      = trackers[imei][trackers[imei].length - 1].serverTime

    const totalDurationSec   = (endTime - startTime) / 1000
    const lowBattDurationSec = lowBattTime ? (lowBattTime - startTime) / 1000 : -1

    const warningLowBattIcon =
      lowBattDurationSec < 0 ? <Error /> :
      lowBattDurationSec < 60 * 60 ? <Warning /> : null

    const errorTotalDurationIcon =
      totalDurationSec < 80 * 60 ? <Error /> : null

    const sixtyMinTime  = new Date(startTime.getTime() + 60 * 60 * 1000)
    const eightyMinTime = new Date(startTime.getTime() + 80 * 60 * 1000)
    const sixtyMinBat   = trackers[imei].find(t => t.serverTime - sixtyMinTime  > 0)?.bat
    const eightyMinBat  = trackers[imei].find(t => t.serverTime - eightyMinTime > 0)?.bat

    const explainLowBatt = () => {
      alert('The low batt duration should be at least 60 minutes.')
    }

    const explainTotalDuration = () => {
      alert('The start to end duration should be at least 80 minutes.')
    }

    headerCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>{ imei }</Typography>
      </TableCell>
    )
    telemCountCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>{ trackers[imei].length }</Typography>
      </TableCell>
    )
    startTimeCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>{ startTime.toLocaleString() }</Typography>
      </TableCell>
    )
    lowBattTimeCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>{ lowBattTime?.toLocaleString() || 'Cannot find low batt time (10% batt)' }</Typography>
        <Button
          color={ warningLowBattIcon ? 'primary' : 'secondary' }
          variant={ warningLowBattIcon ? 'contained' : 'outlined' }
          endIcon={ warningLowBattIcon }
          onClick={ explainLowBatt }>
          { lowBattDurationSec < 0 ? 'Invalid Test' : secToMinSec(lowBattDurationSec) }
        </Button>
      </TableCell>
    )
    endTimeCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>{ endTime.toLocaleString() }</Typography>
        <Button
          color={ errorTotalDurationIcon ? 'primary' : 'secondary' }
          variant={ errorTotalDurationIcon ? 'contained' : 'outlined' }
          endIcon={ errorTotalDurationIcon }
          onClick={ explainTotalDuration }>
          { secToMinSec(totalDurationSec) }
        </Button>
      </TableCell>
    )
    sixtyMinCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>
          { sixtyMinBat ? secToMinSec(sixtyMinBat) + ' left (' + sixtyMinBat + ')' : 'never reached' }
        </Typography>
      </TableCell>
    )
    eightyMinCells.push(
      <TableCell key={imei}>
        <Typography variant='body1'>
          { eightyMinBat ? secToMinSec(eightyMinBat) + ' left (' + eightyMinBat + ')' : 'never reached' }
        </Typography>
      </TableCell>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>{ headerCells      }</TableRow>
      </TableHead>
      <TableBody>
        <TableRow>{ telemCountCells  }</TableRow>
        <TableRow>{ startTimeCells   }</TableRow>
        <TableRow>{ sixtyMinCells    }</TableRow>
        <TableRow>{ eightyMinCells   }</TableRow>
        <TableRow>{ lowBattTimeCells }</TableRow>
        <TableRow>{ endTimeCells     }</TableRow>
      </TableBody>
    </Table>
  )
}
