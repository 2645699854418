import { withRouter } from 'react-router'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btns: {
    display: 'flex',
    gap: theme.spacing(3),
  },
})

function ReservationComplete({ classes, history }) {
  return (
    <div className={classes.root}>
      <div>
        <Typography variant='h5' gutterBottom>
          Your airspace reservation is complete.
        </Typography>
        <div className={classes.btns}>
          <Button variant='outlined' onClick={() => { history.push('/zones/new') }}>
            Add Another Reservation
          </Button>
          <Button variant='outlined' onClick={() => { history.push('/zones') }}>
            See All Reservations
          </Button>
          <Button variant='contained' onClick={() => { history.push('/dashboard') }}>
            Live Airspace
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(ReservationComplete))
