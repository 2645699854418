import {
  useDeleteNFZMutation
} from '../../api/airspace'
import AreYouSureDialog from '../../components/AreYouSureDialog'
import { toast } from 'react-toastify'

function DeleteDraftAreaDialog({ open, onClose, nfz, onSuccess }) {
  const [ deleteNfz ] = useDeleteNFZMutation()

  return <AreYouSureDialog
    open={open}
    onClose={onClose}
    title={`Delete "${nfz?.name}"`}
    onOK={() => deleteNfz(nfz.nfz_id).then(result => {
      if (result.data.status !== 'success') throw Error(JSON.stringify(result.data))
      toast.success(`Airspace '${nfz.name}' deleted`)
      onSuccess()
    }).catch(e => {
      toast.error(`Failed to delete airspace`)
      console.error(e.message)
    })}
  />
}

export default DeleteDraftAreaDialog