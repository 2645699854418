import { forwardRef } from 'react'
import { SvgIcon } from '@material-ui/core'

export default forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <path fillRule='evenodd' clipRule='evenodd' d='M21 3H3V21H21V3ZM19 5H5V19H19V5Z' fill='white'/>
    <path d='M6 18H2V22H6V18Z' fill='white'/>
    <path d='M6 2H2V6H6V2Z' fill='white'/>
    <path d='M22 18H18V22H22V18Z' fill='white'/>
    <path d='M22 2H18V6H22V2Z' fill='white'/>
  </SvgIcon>
))
