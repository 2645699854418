import { useState } from 'react'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import { useGetMyCompanyQuery } from '../../../api/accounts'
import { useCreateNFZMutation } from '../../../api/airspace'
import { makeId, meterToFeet2dp } from '../../../utils'
import TopXCloseButton from '../../../components/TopXCloseButton'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

const styles = theme => ({
  root: {
    position: 'absolute',
    top:      theme.spacing(20),
    left:     '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    textAlign: 'center',
  },
  body: {
    margin: theme.spacing(2)
  },
  name: {
    width: theme.spacing(36)
  },
  alt: {
    width: theme.spacing(24)
  }
})

function RsafCreateGeofenceDialog({ classes, open, onClose, geometry, onSuccess }) {
  const [ name,   setName   ] = useState(makeId(4))
  const [ vStart, setVStart ] = useState(new Date())
  const [ vEnd,   setVEnd   ] = useState(new Date(Date.now() + 1000 * 60 * 60))
  const [ minAlt, setMinAlt ] = useState('0')
  const [ maxAlt, setMaxAlt ] = useState('100000') // 100km
  const [ keep,   setKeep   ] = useState('KEEP OUT') // KEEP IN, KEEP OUT
  const [ wait,   setWait   ] = useState(false)
  const { data: companyData } = useGetMyCompanyQuery()
  const myCompany = companyData?.data?.company || {}

  const [ createNFZ, createResult ] = useCreateNFZMutation()

  function handleCreateNFZ() {
    if (vStart.getTime() >= vEnd.getTime())
      return toast.error('Start Date/Time needs to be before End Date/Time')
    const minAltNumber = parseFloat(minAlt)
    const maxAltNumber = parseFloat(maxAlt)
    if (!isFinite(minAltNumber))
      return toast.error('Minimum Altitude must be a number (in meters)')
    if (!isFinite(maxAltNumber))
      return toast.error('Maximum Altitude must be a number (in meters)')
    if (minAltNumber >= maxAltNumber)
      return toast.error('Minimum Altitude needs to be below Maximum Altitude')
    if (name.trim().length === 0)
      return toast.error('Geofence Name is required')

    setWait(true)

    const nfz = {
      type:        'temp',
      restriction: keep === 'KEEP OUT' ? 'operation-restriction' : 'keepin-area',
      name:        name.trim(),
      description: 'User created geofence',
      owner: {
        name:       myCompany.name,
        company_id: myCompany.company_id,
      },
      geometry,
      altitude:     maxAltNumber,
      min_altitude: minAltNumber,
      altitude_reference: 'WGS84',
      validity: [{
        start: Math.floor(vStart.getTime() / 1000),
        end:   Math.ceil(vEnd.getTime() / 1000),
      }],
      status: 'active',
      data_provider: {
        name: 'MyDroneFleets RSAF Plugin'
        // reference (?)
      }
    }
    createNFZ(nfz)
    .unwrap()
    .then(fulfilled => {
      console.log('create fulfilled', fulfilled)
      console.log('create result', createResult)
      setWait(false)
      if (fulfilled.status !== 'success')
        toast.error('Failed to create Geofence')
      else {
        toast.success('Geofence created')
        onSuccess()
        onClose()
      }
    })
    .catch(rejected => {
      console.log('create rejected', rejected)
      toast.error('Error creating Geofence')
      setWait(false)
    })
  }

  return (
    <Dialog fullWidth maxWidth='md'
      open={open}
      onClose={onClose}
      classes={{ paper: classes.root }}>
      <TopXCloseButton onClick={onClose} />
      <DialogTitle className={classes.title}>
        Create a New Geofence
      </DialogTitle>
      <DialogContent className={classes.body}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label='Geofence Name'
              helperText='Randomly generated name, you can change it.'
              value={name}
              onChange={e => { setName(e.target.value) }}
              className={classes.name}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label='Min Altitude'
              helperText='above sea level'
              value={minAlt}
              onChange={e => { setMinAlt(e.target.value) }}
              className={classes.alt}
              InputProps={{
                endAdornment: <InputAdornment position='end'>m, {meterToFeet2dp(minAlt)}ft</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label='Max Altitude'
              helperText='above sea level'
              value={maxAlt}
              onChange={e => { setMaxAlt(e.target.value) }}
              className={classes.alt}
              InputProps={{
                endAdornment: <InputAdornment position='end'>m, {meterToFeet2dp(maxAlt)}ft</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ height: '24px' }} />
          <Grid item xs={6}>
            <FormControl>
              <ToggleButtonGroup size='small'
                exclusive
                value={keep}
                onChange={(e, val) => { setKeep(val) }}>
                <ToggleButton
                  color='primary'
                  alignment='left'
                  value='KEEP IN'>
                  KEEP IN
                </ToggleButton>
                <ToggleButton
                  color='primary'
                  alignment='left'
                  value='KEEP OUT'>
                  KEEP OUT
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            <br />
            <br />
            <Typography variant='body2'>
              { keep === 'KEEP IN' &&
                <><b>About KEEP IN</b><br />
                All drones must fly inside this geofence when it is active.</>
              }
              { keep === 'KEEP OUT' &&
                <><b>About KEEP OUT</b><br />
                All drones must fly outside this geofence when it is active.</>
              }
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label='Start Date / Time'
              type='datetime-local'
              InputLabelProps={{ shrink: true }}
              value={vStart ? format(vStart, "yyyy-MM-dd'T'HH:mm") : ''}
              onChange={e => { setVStart(new Date(e.target.value)) }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label='End Date / Time'
              type='datetime-local'
              InputLabelProps={{ shrink: true }}
              value={vEnd ? format(vEnd, "yyyy-MM-dd'T'HH:mm") : ''}
              onChange={e => { setVEnd(new Date(e.target.value)) }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { wait && <CircularProgress /> }
        <Button variant='contained' color='secondary'
          disabled={wait}
          onClick={handleCreateNFZ}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(RsafCreateGeofenceDialog)
