import { Fragment, useState } from 'react'
// import { getFlightRecords } from '../modules/cfmsFlight'

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
  TrendingUp,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function CfmsReport({ classes }) {

  const [ cmfsFlightRecords, ] = useState([])
  const [ expandHistory, setExpandHistory ] = useState([])

  // useEffect(() => {
  //   getFlightRecords()
  //   .then(result => {
  //     setCfmsFlightRecords(result)
  //     setExpandHistory(Array(result.length).fill(false))
  //   })
  // }, [])

  const statusHistoryTable = (history) => (
    <Table size='small'>
      <TableHead>
        <TableRow key='s'>
          <TableCell>Date / Time</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { history.map((f, i) => (
          <TableRow key={'s' + i}>
            <TableCell>{new Date(f.timestamp).toLocaleString()}</TableCell>
            <TableCell>{f.status.toUpperCase()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  const violationHistoryTable = (history) => (
    <Table size='small'>
      <TableHead>
        <TableRow key='c'>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Airspace</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { history.map((v, i) => (
          <TableRow key={'c' + i}>
            <TableCell>{new Date(v.start_time).toLocaleString()}</TableCell>
            <TableCell>{new Date(v.end_time).toLocaleString()}</TableCell>
            <TableCell>{v.violation_type}</TableCell>
            <TableCell>Name of Airspace</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  function handleExpand(i) {
    const newExpandHistory = expandHistory.slice()
    newExpandHistory[i] = !newExpandHistory[i]
    setExpandHistory(newExpandHistory)
  }

  const flightRecordTableRows = cmfsFlightRecords.map((rec, i) => (
    <Fragment key={'rec' + i}>
      <TableRow>
        <TableCell>{i + 1}</TableCell>
        <TableCell>Missing User ID / Name</TableCell>
        <TableCell>
          {rec.data_provider?.extras?.op_number || '-'}<br />
          {rec.data_provider?.extras?.ap_number || '-'}<br />
          ({rec.data_provider?.extras?.ap_class || '-'})
        </TableCell>
        <TableCell>Missing IMEI / Mode</TableCell>
        <TableCell>
          Lat: {rec.home_location?.lat}<br />
          Lng: {rec.home_location?.lon}
        </TableCell>
        <TableCell>
          {rec.data_provider?.reference_data?.reference_id || '-'}
          <Tooltip title='Playback Flight'>
            <IconButton><TrendingUp /></IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          {rec.status || 'UNKNOWN'}<br />
          {new Date(rec.last_updated_at)?.toLocaleString()}
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleExpand(i)}>
            { expandHistory[i] ? <ExpandLess /> : <ExpandMore /> }
          </IconButton>
        </TableCell>
        <TableCell>{new Date(rec.data_provider?.extras?.received_timestamp)?.toLocaleString() || '-'}</TableCell>
      </TableRow>
      { expandHistory[i] && 
        <TableRow key={i + 'sh'}>
          <TableCell colSpan={3}>Status History</TableCell>
          <TableCell colSpan={6}>
            {statusHistoryTable(rec.status_history)}
          </TableCell>
        </TableRow>
      }
      { expandHistory[i] &&
        <TableRow key={i + 'ch'}>
          <TableCell colSpan={3}>Violation History</TableCell>
          <TableCell colSpan={6}>
            {violationHistoryTable(rec.conformance_history)}
          </TableCell>
        </TableRow>
      }
    </Fragment>
  ))

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow key='rec'>
            <TableCell>No.</TableCell>
            <TableCell>CFMS User ID / Name</TableCell>
            <TableCell>OP No. / AP No. / Class</TableCell>
            <TableCell>Tracker IMEI / Mode</TableCell>
            <TableCell>Home Location</TableCell>
            <TableCell>Flight ID / Tracks</TableCell>
            <TableCell>Latest Status / Sent Time</TableCell>
            <TableCell>History</TableCell>
            <TableCell>Server Receive Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {flightRecordTableRows}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withStyles(styles)(CfmsReport)