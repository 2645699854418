import { toast } from 'react-toastify'

const synth = typeof window !== 'undefined' && window.speechSynthesis

const dontrepeat = {}

export function say(words, opts) {
  if (!synth)
    return

  const ssu = new SpeechSynthesisUtterance(words)

  ssu.onend = function() {
    delete dontrepeat[words]
  }
  // TODO: Find a better sound to represent the entire product, and abstract into Utils
  // speech.voice = await synth.getVoices().find(v => v.name === 'Daniel')
  if (opts) {
    if (opts.lang)
      ssu.lang = opts.lang
  }
  synth.speak(ssu)
}

export function warnAndSay(words) {
  if (dontrepeat[words])
    return
  dontrepeat[words] = new Date()
  toast.warning(words)
  say(words)
}

export function infoAndSay(words, mute=false) {
  if (dontrepeat[words])
    return
  dontrepeat[words] = new Date()
  toast.info(words)
  if (!mute) say(words)
}

// TODO create a LocalStorage setting to turn on and off sound.