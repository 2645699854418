import { useRef, useEffect, useState } from 'react'
import { getDecodedAccessToken } from '../../../api/accounts'
import User from '../../../components/Text/User'
import {
  TextField,
  Button,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  ThumbUp,
  ThumbDown
} from '@material-ui/icons'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  approve: {
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  },
  reject: {
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
})

function ApprovalStep({ classes, step, onClickApprove, onClickReject, isLoading, onChangeNotes, isActiveStep, isFrzRejected }) {
  const [ savedNotes, setSavedNotes ] = useState('')
  const decodedToken = getDecodedAccessToken()
  const userId = decodedToken.sub
  const mdfRoles = decodedToken.app_roles?.my_drone_fleets
  const rejected = step.rejected_on
  const approved = step.approved_on
  const authorized = !(approved || rejected || isFrzRejected || !isActiveStep) && (
    step.approver_user_id === userId
    || step.approver_mdf_role?.some(r => mdfRoles.includes(r)))

  const notesRef = useRef()
  useEffect(() => {
    if (authorized) notesRef.current.value = savedNotes ?? ''
  }, [authorized, savedNotes])
  
  return (
    <div className={classes.root}>
      <div>
        <Typography variant='subtitle1'><b>
        {
          step.approval_type === 'by_mdf_roles' ? 'Request for approval by roles:'
          : (step.approval_type === 'by_user' ? 'Request for approval by user:'
          : 'Request for approval by UTM:')
        }
        </b></Typography>
        <Typography variant='body1'>
          {
            step.approver_label ?? (
              step.approval_type === 'by_mdf_roles' ? step.approver_mdf_role.join(', ')
              : (step.approval_type === 'by_user' ? <User id={step.approver_user_id} name email/>
              : step.approver_utm_provider)
            )
          }
          { step.approver_company_name && ` (${step.approver_company_name})` }
        </Typography>
      </div>
      {
        (approved || rejected) &&
        <Grid container direction='column'>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant='body1'>
                {approved ? 'Approved' : 'Rejected'} On:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {new Date(approved ? step.approved_on : step.rejected_on).toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
      <div>
        <Typography variant='subtitle1'>
          <b>Comments</b>
        </Typography>
        {
          approved
          ? <Typography>{step.approval_notes || <i>No comments provided</i>}</Typography>
          : rejected
          ? <Typography>{step.rejection_notes || <i>No comments provided</i>}</Typography>
          : <TextField
              inputRef={notesRef}
              placeholder={
                authorized ? 'Leave your comments here (optional)'
                : isFrzRejected ? 'This flight reservation has been rejected in a previous step'
                : isActiveStep ? 'Pending approval'
                : 'Previous step not yet approved'
              }
              variant='outlined'
              multiline
              minRows={4}
              fullWidth={true}
              disabled={!authorized || isLoading}
              helperText={authorized && 'These comments will be visible to everyone on the approval chain, including the applicant.'}
              onChange={e => {
                if (authorized) {
                  onChangeNotes(e.target.value)
                  setSavedNotes(e.target.value)
                }
              }}
            />
        }
      </div>
      {
        authorized &&
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant='contained'
              startIcon={<ThumbUp />}
              className={classes.approve}
              onClick={onClickApprove}
              disabled={isLoading}>
              Approve
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              startIcon={<ThumbDown />}
              className={classes.reject}
              onClick={onClickReject}
              disabled={isLoading}>
              Reject
            </Button>
          </Grid>
        </Grid>
      }
    </div>
  )
}

export default withStyles(styles)(ApprovalStep)