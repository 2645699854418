import React from 'react'
import { FlashOn as ThunderstormIcon } from '@material-ui/icons'
import L from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server'

const CustomRainIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-drizzle-fill" viewBox="0 0 16 16"> <path d="M4.158 12.025a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm-3.5 1.5a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 0 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm6 0a.5.5 0 0 1 .316.633l-.5 1.5a.5.5 0 1 1-.948-.316l.5-1.5a.5.5 0 0 1 .632-.317zm.747-8.498a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 11H13a3 3 0 0 0 .405-5.973z"/> </svg>
  )
}

// for weather apis that may return a single point instead of polygon
const icons = {
  // sunny: {
  //   name: 'Sunny',
  //   icon: L.divIcon({
  //     className: 'leaflet-sunny-icon',
  //     html: renderToStaticMarkup(<SunnyIcon />),
  //     iconSize: [30, 30],
  //     iconAnchor: [15, 15],
  //   })
  // },
  // cloudy: {
  //   name: 'Cloudy',
  //   icon: L.divIcon({
  //     className: 'leaflet-cloudy-icon',
  //     html: renderToStaticMarkup(<CloudyIcon />),
  //     iconSize: [30, 30],
  //     iconAnchor: [15, 15],
  //   })
  // },
  rain: {
    name: 'Rainy',
    color: '#e9f5f7', // Light blue-gray color for the rain cloud
    backgroundColor: '#6EC6FF', // Choosing a contrasting background color for visibility
    // icon: L.divIcon({
    //   className: 'leaflet-rain-icon',
    //   html: <RainIcon />,
    //   iconSize: [30, 30],
    //   iconAnchor: [15, 15],
    // }),
    icon: CustomRainIcon,
  },
  thunderstorm: {
    name: 'Thunderstorm',
    // color: '#fff6a3', // Yellow like lightning
    // color: '#fffac7',
    color: '#ffd103',
    backgroundColor: '#91796d', // Choosing a contrasting background color for visibility
    // icon: L.divIcon({
    //   className: 'leaflet-thunderstorm-icon',
    //   html: <ThunderstormIcon />,
    //   iconSize: [30, 30],
    //   iconAnchor: [15, 15],
    // })
    icon: ThunderstormIcon
  },
  default: {
    name: '',
    color: '#00000000',
    backgroundColor: '#00000000',
    // icon: L.divIcon({
    //   className: 'leaflet-default-icon',
    //   html: renderToStaticMarkup(<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1"/>),
    //   iconSize: [30, 30],
    //   iconAnchor: [15, 15],
    // })
    icon: ThunderstormIcon
  }
}

const createIconMarkup = (iconType) => {
  const { color, backgroundColor, icon: IconComponent } = icons[iconType] ?? icons.default
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    backgroundColor,
    borderRadius: '10px',
    color
  }
  console.log('creating static markup for type', iconType)
  return renderToStaticMarkup(
    <div style={style}>
      <IconComponent />
    </div>
  )
}

const WeatherIcon = ({ type }) => {
  return L.divIcon({
    className: `leaflet-${type}-icon`,
    html: createIconMarkup(type),
    iconSize: [30, 30],
    iconAnchor: [15, 15]
  })
}

export default WeatherIcon