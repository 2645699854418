import { Scatter } from 'react-chartjs-2'
import wheel from '../../utils/colour-wheel'
import ChartOptions from './ChartOptions'

export default function SystemChart({ trackers }) {

  if (!trackers)
    return null

  const
    rssiDataset  = [],
    voltDataset  = [],
    tempDataset  = []

  for (const imei in trackers) {
    let col = wheel.nextColour()
    rssiDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.rssi ? t.rssi.slice(0, -3) : 0]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    voltDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.volt || 0]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    tempDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.temp || 0]),
      backgroundColor: col,
      borderColor: col
    })
  }

  const rssiOptions = ChartOptions()
  rssiOptions.plugins.title.text = 'Signal Strength (RSSI)'

  const voltOptions = ChartOptions()
  voltOptions.plugins.title.text = 'Battery Voltage'

  const tempOptions = ChartOptions()
  tempOptions.plugins.title.text = 'Barometer Temperature'

  return (
    <>
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: rssiDataset}}
        options={rssiOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: voltDataset}}
        options={voltOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: tempDataset}}
        options={tempOptions}
      />
    </>
  )
}
