import {
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core'

const steps = [ 'Details', 'Confirmation', 'Done' ]

// TODO Redesign StepperBanner. So big, yet give little context
function StepperBanner({ activeStep }) {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel>
      { steps.map(label => (
        <Step key={label}>
          <StepLabel>
            {label}
          </StepLabel>
        </Step>
      )) }
    </Stepper>
  )
}

export default StepperBanner
