import { useMemo } from 'react'
import { getDecodedAccessToken } from '../../api/accounts'
import { useGetFRZsQuery, useGetPrivateNFZsQuery } from '../../api/airspace'
import useNfzValidity from '../../components/useNfzValidity'
import {
  FeatureGroup,
} from 'react-leaflet'
import PrivateNfzPolygon from './components/PrivateNfzPolygon'
import { frzColour } from '../../utils'
import FrzPolygon from './components/FrzPolygon'
import { startOfDay } from 'date-fns'

function PrivateAirspace({
  company_ids,
  showNfz,
  showReadyToUse,
  showPendingFrzs,
  showApprovedFrzs,
  filter,
  onClickLayer,
  onSelectNfz,
  onSelectGeofence,
}) {
  const [ nfzValidity ] = useNfzValidity('PrivateAirspace')
  const company_id = useMemo(() => getDecodedAccessToken()?.company_id, [])

  const { data: frzData } = useGetFRZsQuery({
    company_ids,
    'after-validity': startOfDay(new Date()).getTime()
  })
  
  const frzs = useMemo(()=> !frzData ? [] : frzData?.data?.frzs?.filter((frz) => {
    return ((showPendingFrzs && frz.status === 'pending-approval') || (showApprovedFrzs && frz.status === 'approved'))
  }), [frzData, showPendingFrzs, showApprovedFrzs])
  
  const { data: nfzData } = useGetPrivateNFZsQuery({
    company_ids:    company_ids || company_id, // backwards compatible. future: all must provide company_ids
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
  })

  const nfzs = useMemo(() => !nfzData ? [] : Object.values(nfzData.entities).filter(nfz => {
    return ((showReadyToUse && nfz.restriction === 'ready-to-use')
      || (showNfz && nfz.restriction !== 'ready-to-use')
    ) && (!filter || filter(nfz))
  }), [nfzData, showReadyToUse, filter, showNfz])

  return (    
    <FeatureGroup>
      { nfzs.map(nfz => (
        <PrivateNfzPolygon key={nfz.nfz_id + Date.now()} nfz={nfz} onClick={(e)=>{
          if(onClickLayer){
            return onClickLayer(e)
          }else if(nfz.restriction !== 'ready-to-use'){
            onSelectGeofence(nfz)
          }else{
            onSelectNfz(nfz)
          }
        }}/>
      )) }
      {
        frzs.map((frz)=> {
          return <FrzPolygon key={frz.frz_id}
            frz={frz}
            colour={frzColour(frz.status)}
            onClick={onClickLayer}
          />
        })
      }
      
      {/* selectedGeofence &&
        <PrivateNfzPolygon
          geofence={selectedGeofence}
          colour={theme.palette.warning.main}
        />
      */}
    </FeatureGroup>
  )
}

export default PrivateAirspace
