
import 'leaflet/dist/leaflet.css'
import CommonLayers from '../../../components/Map/CommonLayers'
import {
  MapContainer,
  ScaleControl,
  ZoomControl,
  GeoJSON,
} from 'react-leaflet'
import {
  DEFAULT_MAP_CENTER
} from '../../../utils/site-maps'
import {
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  mapContainer: {
    position: 'relative',
    height:   theme.spacing(40),
    minWidth: theme.spacing(40),
  },
  map: {
    width: '100%',
    height: '100%',
  },
  location: {
    position: 'absolute',
    top: theme.spacing(2),
    height: theme.spacing(7),
    maxWidth: theme.spacing(37),
    left: 0,
    right: 0,
    margin: '0 auto',
    borderRadius: theme.spacing(2),
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  txtfield: {
    margin: theme.spacing(2),
  }
})

// TODO: Draw the flight log's track on this map
function FlightMap({ classes, flight }) {
  return (<div className={classes.mapContainer}>
    <MapContainer className={classes.map}
      center={DEFAULT_MAP_CENTER}
      zoom={10}
      zoomControl={false}>
      <CommonLayers />
      <ScaleControl position='bottomleft' imperial={false} />
      <ZoomControl position='topright' />
      { flight?.geofence &&
        <GeoJSON
          data={flight.geofence}
          style={{
            color: 'blue', // TODO: What is the color of geofence?
            weight: 2,
            fillOpacity: 0.2,
            fillColor: 'blue',
          }}
        />
      }
    </MapContainer>
    <div className={classes.location}>
      { flight ?
        <Typography variant='body1'>
          Location: { flight.location || 'No description of the location' }
        </Typography>
      : <TextField
          label='Location'
          fullWidth
          className={classes.txtfield}
        />
      }
    </div>
  </div>)
}

export default withStyles(styles)(FlightMap)
