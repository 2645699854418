import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
//
// Returns a GeolocationCoordinate object
//
// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates
//
function useGeolocation() {
  const [ coords, setCoords ] = useState(null)

  useEffect(() => {
    let watchId = ''
    if ('geolocation' in navigator) {
      watchId = navigator.geolocation.watchPosition(pos => {
        setCoords(pos.coords)
      }, (err) => {
        console.log(`Navigator Geolocation Error (${err.code}): ${err.message}`)
        toast.error('MyDroneFleets could not identify your location.')
      }, {
        // docs: https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
        maximumAge: 30000, // allow caching of position for the past 30 seconds
        timeout:    27000, // timeout in 27 seconds (after that component refresh is a new try)
        enableHighAccuracy: false, // save battery because watching non stop
      })
    } else {
      toast.warning('Please allow MyDroneFleets access to your location on your browser.')
    }
    return (() => {
      if (watchId)
        navigator.geolocation.clearWatch(watchId)
    })
  }, [])

  return coords
}

export default useGeolocation
