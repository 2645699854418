import { Grid } from '@material-ui/core'

function Row({ l, v, half }) {
  if (half)
    return (<>
      <Grid item xs={3}>{l}</Grid>
      <Grid item xs={9}>{v || '-'}</Grid>
    </>)
  return (<>
    <Grid item xs={6}>{l}</Grid>
    <Grid item xs={6}>{v || '-'}</Grid>
  </>)
}

export default Row
