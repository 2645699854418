import { getAccessToken } from './accounts'

export function standardHeaders(headers, { getState }) {
  const user = getState().oidc?.user
  if (user)
    headers.set('Authorization', `Bearer ${user.access_token}`)
  return headers
}

// For now, works for strings and arrays (pass in strings for booleans)
export function makeQueryString(queryParams = {}, searchFields, customCallback) {
  const paramsArray = []
  searchFields.forEach(field => {
    if (queryParams[field]) {
      const data = encodeURIComponent(queryParams[field])
      if (data) // so that backend does not see any empty strings
        paramsArray.push(`${field}=${data}`)
    }
  })
  if (customCallback)
    paramsArray.push(customCallback())
  const result = paramsArray.length > 0 ? ('?' + paramsArray.join('&')) : ''
  // console.log('makeQueryString', result)
  return result
}

export function makeWssUrl(baseUrl, queryParams = {}) {
  return baseUrl + makeQueryString(queryParams,
    Object.keys(queryParams), () => (`access_token=${getAccessToken()}`))
}
