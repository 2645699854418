import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

})

function CfmsControlPanelViolationDialog({ classes, open, violation, onClose }) {
  const [ airspaces, ] = useState({})
  
  // FIXME TODO: Replace axios call with RTK Query wrapper in /api/airspace.js
  // const fetchAirspace = useCallback(
  //   async (violation) => {
  //     console.info(violation)
  //     const airspaceResponse = (
  //       await axios({
  //         method: 'GET',
  //         url: `${AIRSPACE_API}/nfzs/${violation.airspace_id}`
  //       })
  //     ).data
  //     const airspace = airspaceResponse.data.nfz
  //     setAirspaces(prevAirspaces => ({
  //       ...prevAirspaces,
  //       [violation.airspace_id]: airspace
  //     }))
  // }, [])

  // useEffect(()=>{
  //   if(violation)
  //     fetchAirspace(violation)
  // }, [violation, fetchAirspace])

  if (!violation)
    return null
  return (
    <Dialog
      open={open}
      onClose={onClose}>
      <DialogTitle>Current Violation</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>Intersection Type</Grid>
          {/* which one? Abhishek's code refer to both violatingType and violation_type */}
          <Grid item xs={6}>{violation.violatingType || violation.violation_type || 'Unknown'}</Grid>
          <Grid item xs={6}>Started at</Grid>
          <Grid item xs={6}>{new Date(violation.start_time)?.toLocaleString() || '-'}</Grid>
          <Grid item xs={6}>Ended at</Grid>

          <Grid item xs={6}>{violation.end_time? new Date(violation.end_time)?.toLocaleString() : '-'}</Grid>
          <Grid item xs={12}>&nbsp;</Grid>
          <Grid item xs={6}>Vehicle</Grid>
          <Grid item xs={6}>{violation.vehicle_info?.type || '-'}</Grid>
          <Grid item xs={6}>ID</Grid>
          <Grid item xs={6}>{violation.vehicle_info?.id || '-'}</Grid>
          <Grid item xs={6}>Violated Airspace</Grid>
          <Grid item xs={6}>{airspaces[violation.airspace_id] ? airspaces[violation.airspace_id].name : 'Unknown Airspace ' + violation.airspace_id}</Grid>
          <Grid item xs={6}>Area Type</Grid>
          <Grid item xs={6}>{violation.violation_area_type || '-'}</Grid>
          <Grid item xs={6}>Alert Type</Grid>
          <Grid item xs={6}>{violation.alert_type || '-'}</Grid>
          <Grid item xs={6}>Event Type</Grid>
          <Grid item xs={6}>{violation.event_type || '-'}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(CfmsControlPanelViolationDialog)
