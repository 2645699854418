import { useState, useMemo } from 'react'
import { format } from 'date-fns'
import { getDecodedAccessToken } from '../../../api/accounts'
import { history } from '../../../utils/store'
import { commonFormat, frzIcon } from '../../../utils'
import FRZListMap from './FRZListMap'
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Add,
  ExpandLess,
  ExpandMore,
  Event,
  FilterList,
  Visibility,
  Warning,
  Gradient,
  Layers,
  Map,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import PublicAirspace from '../../../components/Airspace/Public'
import PrivateAirspace from '../../../components/Airspace/Private'
import MyLocationMarker from '../../../components/Map/MyLocationMarker'
import { useRef } from 'react'
import TogglePanelControls from '../../../components/Map/TogglePanelControls'
import usePermissions from '../../../components/usePermissions'

const styles = theme => ({
  root: {
    height: '100vh',
    width: '100wh'
  },
  mapOverlay: {
    top: 0,
    position: 'absolute',
    zIndex: 999,
  },
  listContainer: {
    position: 'fixed',
    right: 0,
    margin: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(4)}px)`,
    width: theme.spacing(36),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    overflow: 'scroll'
  },
  controlsContainer: {
    position: 'fixed',
    right: theme.spacing(38),
    margin: theme.spacing(2)
  },
  iconBtn: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    marginLeft: theme.spacing(1),
  }
})

function MapView({ classes, frzs, company_ids, filter, setFilter, setSelectedFrzId, selectedFrzId }) {

  const { user_id, company_id, app_roles } = getDecodedAccessToken()
  const mdfRoles = app_roles?.my_drone_fleets

  const mapRef = useRef()
  const [ showOnMap, setShowOnMap ] = useState(['pending-approval-frz', 'approved-frz'])
  const [ myLocation, setMyLocation ] = useState('disabled') // finding, enabled

  const approvalRequiredFrzs = frzs
    .filter(frz => frz.status === 'pending-approval' && Array.isArray(frz.approval_chain))
    .filter(frz => {
      const next_approver_index = frz.approval_chain.findLastIndex(step => step.approved_on) + 1
      const next_approver = frz.approval_chain[next_approver_index]
      return next_approver &&
        (next_approver.approver_user_id === user_id ||
          (company_id === frz.company_id &&
          next_approver.approver_mdf_role?.some((r) => mdfRoles.includes(r))))
    })
    .sort((a, b) => b.validity[0].start - a.validity[0].start)
  
  const [ collapseRequired, setCollapseRequired ] = useState(!approvalRequiredFrzs)
  const [ collapsePending,  setCollapsePending  ] = useState(false)
  const [ collapseApproved, setCollapseApproved ] = useState(false)
  const [ collapseDraft,    setCollapseDraft    ] = useState(false)

  const mapFrzs = useMemo(() => frzs.filter(frz => showOnMap.includes(frz.status + '-frz')), [showOnMap, frzs])
  const frzsList = (frzs, setCollapse, collapse, title, empty) => (<>
    <Box position='sticky' top={0} bgcolor='background.paper' zIndex={1}>
      <ListItem button onClick={() => { setCollapse(c => !c) }}>
        <ListItemText secondary={title} />
        {collapse ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
    </Box>
    <Collapse in={!collapse}>
    {frzs.length === 0 && 
    <ListItem>
      <ListItemText secondary={empty}/>
    </ListItem>}
    {frzs.map((frz) => {
      return <ListItem key={frz.frz_id} button
        selected={frz.frz_id === selectedFrzId}
        autoFocus={frz.frz_id === selectedFrzId}
        onClick={() => { setSelectedFrzId(id => frz.frz_id === id ? '' : frz.frz_id) }}>
        <ListItemText
          primary={frz.name}
          secondary={
            <>
            Start Time: {format(new Date(frz.validity[0].start), commonFormat)}
            <br />
            Pilots: {frz.pilots.map((p) => p.name).join(',')}
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => { history.push(`/zones/${frz.frz_id}`) }}>
            <Visibility />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    })}
    </Collapse>
  </>)
  const canReserveAirspace = usePermissions('zone-reservation-write')
  return (
    <div className={classes.root}>
      <FRZListMap
        frzs={mapFrzs}
        selectedFrzId={selectedFrzId}
        setSelectedFrzId={setSelectedFrzId}
        mapRef={mapRef}
      >
        { myLocation !== 'disabled' &&
          <MyLocationMarker
            onSuccess={() => { setMyLocation('enabled') }}
            onFailure={() => { setMyLocation('disabled') }}
          />
        }
        { (showOnMap.includes('public-nfz') || showOnMap.includes('public-test-estate')) &&
          <PublicAirspace
            showStaticNfz={showOnMap.includes('public-nfz')}
            showTempNfz={showOnMap.includes('public-nfz')}
            showTestEstate={showOnMap.includes('public-test-estate')}
          />
        }
        { (showOnMap.includes('private-nfz') || showOnMap.includes('private-ready-to-use')) &&
          <PrivateAirspace
            company_ids={company_ids}
            showReadyToUse={showOnMap.includes('private-ready-to-use')}
          />
        }
      </FRZListMap>
      <div className={classes.mapOverlay}>
        <div className={classes.listContainer}>
          <Box pt={2} px={2}>
            <Box
              display='flex'
              alignItems='center'
              flexDirection='row'
              justifyContent='space-between'
              p={0.5}>
              <Typography variant='h5'>Reservations</Typography>
              <IconButton onClick={() => { history.push('/zones?view=calendar') }}>
                <Event />
              </IconButton>
          
              {canReserveAirspace && 
                <IconButton onClick={() => { history.push('/zones/new') }}>
                  <Add />
                </IconButton>}
            </Box>
            <Box
              mt={1}
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'>
              <TextField
                label='Search a reservation...'
                fullWidth
                variant='filled'
                size='small'
                value={filter.name}
                onChange={e => setFilter({ ...filter, name: e.target.value })}
              />
              <IconButton className={classes.iconBtn}>
                <FilterList />
              </IconButton>
            </Box>
            <List>
              {frzsList(
                approvalRequiredFrzs,
                setCollapseRequired,
                collapseRequired,
                'Approval Required',
                'No reservations requiring approval.'
              )}
              {frzsList(
                frzs.filter(f => f.status === 'pending-approval' && !approvalRequiredFrzs.includes(f)),
                setCollapsePending,
                collapsePending,
                'Pending Approval',
                'No reservations pending approval.'
              )}
              {frzsList(
                frzs.filter(f => f.status === 'approved'),
                setCollapseApproved,
                collapseApproved,
                'Approved',
                'No reservations approved.'
              )}
              {frzsList(
                frzs.filter(f => f.status === 'draft'),
                setCollapseDraft,
                collapseDraft,
                'Draft',
                'No reservations drafted.'
              )}
            </List>
          </Box>
        </div>
        <div className={classes.controlsContainer}>
          <TogglePanelControls
            enabled={showOnMap}
            setEnabled={setShowOnMap}
            controls={[
              [
                {
                  title: 'Public Airspace (Restricted)',
                  icon: <Warning />,
                  value: 'public-nfz'
                },
                {
                  title: 'Public Airspace (Drone Friendly)',
                  icon: <Gradient />,
                  value: 'public-test-estate'
                },
                {
                  title: 'Private Airspace (Restricted)',
                  icon: <Map />,
                  value: 'private-nfz'
                },
                {
                  title: 'Private Airspace (Ready To Use)',
                  icon: <Layers />,
                  value: 'private-ready-to-use'
                }
              ],
              [
                {
                  title: 'Pending Approval Airspace Reservation',
                  icon: frzIcon('pending-approval'),
                  value: 'pending-approval-frz'
                },
                {
                  title: 'Approved Airspace Reservation',
                  icon: frzIcon('approved'),
                  value: 'approved-frz'
                }
              ]
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(MapView)
