import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useLazyGetTrackerByIdQuery,
  useLazyGetTrackersQuery,
} from '../../api/tracker'
import { useUpdateFlightMutation } from '../../api/aircraft'
import { useLazyGetUsersQuery } from '../../api/accounts'
import TopXCloseButton from '../../components/TopXCloseButton'
import TextBox from '../../components/Inputs/TextBox'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'

function StartFlightDialog({ flight, requireTracker, open, onClose }) {
  const [ trackers, setTrackers ] = useState([])
  const [ pilots,   setPilots   ] = useState([])
  const [ trackersError, setTrackersError ] = useState('')
  const [ pilotsError,   setPilotsError   ] = useState('')
  const [ getTrackers  ] = useLazyGetTrackersQuery()
  const [ getTracker   ] = useLazyGetTrackerByIdQuery()
  const [ getUsers     ] = useLazyGetUsersQuery({status: 'active'})
  const [ updateFlight, { isLoading } ] = useUpdateFlightMutation()

  useEffect(() =>{
    async function getInfo(){
      setPilots(flight.pilots.map(p => ({user_id: p.id, name: p.name, username: p.username})))
      const trackerResults = await Promise.all(flight.drone.tracker_ids?.map(getTracker) ?? [])
      setTrackers(trackerResults.map(result =>{
        let tracker = {...result.data.data.tracker}          
        tracker.tracker_name = tracker.name?.length === 0 ? `Tracker x${tracker.imei.slice(-4)}` : tracker.name
        return tracker
      }))
      setPilotsError('')
      setTrackersError('')
    }
    getInfo()
  }, [flight, getTracker])

  useEffect(() => setTrackersError(''), [trackers, requireTracker])
  useEffect(() => setPilotsError(''), [pilots])

  function validate(tracker = false) {
    let err = false
    if (tracker && requireTracker)
      setTrackersError(trackers.length > 0 ? '' : (err = true && 'Must have at least one tracker'))
    setPilotsError(pilots.length > 0 ? '' : (err = true && 'Must have at least one pilot'))
    return !err
  }

  function save() {
    if (!validate()) return
    return updateFlight({
      flight_id: flight.flight_id,
      pilots: pilots.map(p => ({ id: p.user_id, name: p.name, username: p.username })),
      drone: {
        ...flight.drone,
        tracker_ids: trackers.map(t => t.tracker_id)
      }
    }).unwrap().then(result => {
      if (result.status !== 'success')
        throw result
      toast.success('Preflight updated')
      return true
    }).catch(err => {
      toast.error('Failed to update preflight')
      console.log('Failed to update preflight', err)
      return false
    })
  }

  async function startFlight() {
    if (!validate(true))
      return
    return updateFlight({
      flight_id: flight.flight_id,
      status: 'flying',
      pilots: pilots.map(p => ({id: p.user_id, name: p.name, username: p.username})),
      drone: {
        ...flight.drone,
        tracker_ids: trackers.map(t => t.tracker_id)
      },
      date:       Date.now(),
    }).unwrap().then(result => {
      if (result.status !== 'success') throw result
      return true
    }).catch(err => {
      toast.error('Failed to start flight')
      console.log('Failed to start flight', err)
      return false
    })
  }

  return (
    <Dialog open={open} fullWidth maxWidth='sm'>
      <TopXCloseButton onClick={onClose}/>
      <DialogTitle>Start Flight</DialogTitle>
      <DialogContent>
        <TextBox
          multiple
          label={`Select Tracker mounted on ${flight.drone?.name}` + (requireTracker ? '' : ' (optional)')}
          get={getTrackers}
          getKey='trackers'
          dataId='tracker_id'
          dataLabel='name'
          value={trackers}
          onChange={setTrackers}
          error={!!trackersError}
          helperText={trackersError}
        />
        <Box height={20} />
        <TextBox
          multiple
          label={`Confirm Drone Pilot(s) operating ${flight.drone?.name}`}
          get={() => getUsers()}
          getKey='users'
          dataId='user_id'
          dataLabel='name'
          value={pilots}
          onChange={setPilots}
          error={!!pilotsError}
          helperText={pilotsError}
        />
        <br />
        <Typography variant='body1'>
          <b>Reminder</b><br />
          Please operate <b>{flight.drone?.name}</b> drone
          within <b>{flight.location}</b>,
          between <b>{flight.geofence_min_alt}</b> meters
          and <b>{flight.geofence_max_alt}</b> meters AMSL.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={async () => (await save()) && onClose()}
          disabled={isLoading}>
          Save
        </Button>
        <Button
          variant='contained'
          color='secondary'
          onClick={async () => (await startFlight()) && onClose()}
          disabled={isLoading}>
          Start Flight
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StartFlightDialog