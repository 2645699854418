import { Scatter } from 'react-chartjs-2'
import wheel from '../../utils/colour-wheel'
import ChartOptions from './ChartOptions'

export default function MovementChart({ trackers }) {

  if (!trackers)
    return null

  const
    vDataset = [],
    aDataset = [],
    bearDataset = []

  for (const imei in trackers) {
    let col = wheel.nextColour()
    vDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.v]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    aDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.a]),
      backgroundColor: col,
      borderColor: col
    })
    col = wheel.nextColour()
    bearDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.bear]),
      backgroundColor: col,
      borderColor: col
    })
  }

  const vOptions = ChartOptions()
  vOptions.plugins.title.text = 'Velocity'

  const aOptions = ChartOptions()
  aOptions.plugins.title.text = 'Acceleration'

  const bearOptions = ChartOptions()
  bearOptions.plugins.title.text = 'Bearing (Direction tracker is traveling)'

  return (
    <>
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: vDataset}}
        options={vOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: aDataset}}
        options={aOptions}
      />
      <Scatter
        type='scatter'
        width={1000}
        height={625}
        data={{datasets: bearDataset}}
        options={bearOptions}
      />
    </>
  )
}
