
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { standardHeaders } from './api-utils'

const METEOROLOGY_HTTP_API = new URL(process.env.REACT_APP_METEOROLOGY_HTTP_API).href

export const meteorologyApi = createApi({
  reducerPath: 'meteorologyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: METEOROLOGY_HTTP_API,
    prepareHeaders: standardHeaders,
  }),
  tagTypes: ['Weather'],
  endpoints: (builder) => ({
    weatherForecast: builder.mutation({
      query: ({ dataSource, endTime, address, geo }) => ({
          url: '/weather/forecast',
          method: 'POST',
          body: {
            data_source: dataSource,
            end_time: endTime,
            address,
            geo
          }
      }),
      invalidatesTags: ['Weather']
    }),
  }),
})

export const {
  useWeatherForecastMutation
} = meteorologyApi
