import { useMemo, useEffect } from 'react'
import { toast } from 'react-toastify'
import { getDecodedAccessToken } from '../api/accounts'
//
// Feature Matrix Documentation 
// https://docs.google.com/document/d/1xM1PQ00IQaAXS48JSkyoZxg0nPk9rLfRSi5FHyo_9HI/edit#
//
const feature_matrix = {
  'zone-reservation-write': ['pilot', 'safety-officer', 'accountable-manager', 'instructor'],
  // drone details editing
  'drone-self-write':       ['pilot', 'accountable-manager', 'safety-officer', 'instructor'],
  // drone serviceable editing
  'drone-operation-write':  ['pilot', 'accountable-manager', 'safety-officer', 'instructor', 'technician'],
  // airspace layers
  'airspace-self-write' :   ['pilot', 'accountable-manager', 'safety-officer', 'instructor', 'technician', 'spotter', 'gcs-pilot', 'engineer'],
  'developer-main-read':    ['technician'],
  'developer-inject-write': ['technician', 'accountable-manager', 'instructor', 'safety-officer'],

  // Project specific
  'xprize-flight-write':    ['pilot', 'gcs-pilot', 'accountable-manager', 'instructor'],
  'rsaf-playback-read':     ['accountable-manager']
}

// Returns an array of roles that satisfies the feature, 
// or a falsy value if none of the roles match 
function usePermissions(feature, options) {
  const warn = options?.warn || false

  const hasPermission = useMemo(() => {
    const mdfRoles = getDecodedAccessToken().app_roles?.my_drone_fleets

    if (!mdfRoles || mdfRoles.length === 0)
      return false
    const roles = feature_matrix[feature]
    if (!roles)
      throw new Error('Feature not found in feature matrix:', feature)
    const matchingRoles = []
    mdfRoles.forEach(role => {
      if (roles.includes(role))
        matchingRoles.push(role)
    })
    return matchingRoles.length > 0 ? matchingRoles : false
  }, [feature])

  useEffect(() => {
    if (warn && !hasPermission) {
      toast.warning(`You do not have permissions to access this feature.
        Speak to your company administrator to be given a suitable role.`, {
        autoClose: false
      })
    }
  }, [warn, hasPermission])

  return hasPermission
}

export default usePermissions
