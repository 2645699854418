import {
  Box,
  Button,
} from '@material-ui/core'

function Pill({ text }) {
  return (
    <Box
      height={120}
      display='flex'
      justifyContent='center'
      alignItems='center'>
      <Button
        variant='outlined'
        style={{
          paddingLeft: '24px',
          paddingRight: '24px',
          border: '1px solid white',
          borderRadius: 32,
        }}>
        { text }
      </Button>
    </Box>
  )
}

export default Pill
