import { useState } from 'react'
import advisoryData from './advisoryData' // to be made dynamic bit by bit
import ExternalLink from '../../components/ExternalLink'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    width: '100%',
    height: '100vh',
  },
  panel: {
    padding: theme.spacing(1),
    height: '45vh',
  },
  heading: {
  },
  date: {
    color: theme.palette.text.secondary,
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  buttonLink: {
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
  },
})

function Advisories({ classes }) {
  const [ expanded, setExpanded ] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  function renderAccordion(advice) {
    return <Accordion
      key={advice.id}
      expanded={expanded === advice.id}
      onChange={handleChange(advice.id)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.date}>{advice.date}</Typography>
        <Typography className={classes.heading}>{advice.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Typography>{advice.content}</Typography>
          { advice.source && <>
            <br />
            <ExternalLink href={advice.source} className={classes.buttonLink}>
              Read More
            </ExternalLink>
          </> }
          <br />
          <br />
          { advice.author &&
            <Typography variant='caption'>by <b>{ advice.author }</b> </Typography>
          }
          { advice.location &&
            <Typography variant='caption'>at <b>{ advice.location }</b> </Typography>
          }
          { advice.severity &&
            <Typography variant='caption'>Severity: <b>{ advice.severity }</b> </Typography>
          }
        </div>
      </AccordionDetails>
    </Accordion>
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.panel}>
        <Typography variant='h6'>Advisories from Regulator</Typography>
          { advisoryData.regulator.map(renderAccordion) }
      </Grid>
      <Grid item xs={6} className={classes.panel}>
        <Typography variant='h6'>Advisories from Airspace Manager</Typography>
          { advisoryData.airspace.map(renderAccordion) }
      </Grid>
      <Grid item xs={6} className={classes.panel}>
        <Typography variant='h6'>Nearby Incidents</Typography>
          { advisoryData.incidents.map(renderAccordion) }
      </Grid>
      <Grid item xs={6} className={classes.panel}>
        <Typography variant='h6'>Nearby Environment</Typography>
          { advisoryData.environment.map(renderAccordion) }
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Advisories)
