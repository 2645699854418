import { useState } from 'react'

import TopXCloseButton from '../../../components/TopXCloseButton'
// import GridTitle from '../../components/GridTitle'
// import GridLabel from '../../components/GridLabel'
// import GridField from '../../components/GridField'
import {
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Edit
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'relative'
  },
  title: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
  },
  editBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actions: {
    position: 'absolute',
    top: theme.spacing(5),
    right: 0,
  },
})

function FlightPostFlight({ classes, flight }) {

  const [ edit, setEdit ] = useState(false)

  return edit ? (
    <div className={classes.root}>
      <TopXCloseButton onClick={e => setEdit(false)} />
      <div className={classes.title}>
        <Typography variant='h6'>PostFlight Information</Typography>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <IconButton onClick={e => setEdit(true)} className={classes.editBtn}>
        <Edit />
      </IconButton>
      <div className={classes.title}>
        <Typography variant='h6'>PostFlight Information</Typography>
      </div>
      <Grid container style={{ padding: '8px 16px' }}>
      <Grid item sm={6}>
        <Typography variant='h6'>
          Results
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography variant='h6'>
          Artefacts
        </Typography>
      </Grid>
    </Grid>
      {/*<div className={classes.actions}>
        <Button variant='outlined'>Playback Logs</Button>
      </div>*/}
    </div>
  )
}

export default withStyles(styles)(FlightPostFlight)
