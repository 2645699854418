import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  label: {
    display: 'flex',
    alignItems: 'center'
  },
})

function GridLabel({ classes, children }) {
  return (
    <Grid item xs={12} md={4} className={classes.label}>
      <Typography variant='body1'>{children}</Typography>
    </Grid>
  )
}

export default withStyles(styles)(GridLabel)
