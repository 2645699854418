import { booleanPointInPolygon, point } from "@turf/turf";
import { useCallback, useEffect, useRef } from "react";

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

export function useClickRotation(candidatesPool, getId, currentSelected,  filterCandidates, getGeometry, setSelectedCandidate){
  return useCallback((e)=>{
    if(candidatesPool){
      const selected = [
        ...filterCandidates(candidatesPool, getGeometry, e.latlng)
      ]
      if(selected?.length === 0) return
      let next = selected[0]
      if(currentSelected){
        const selectedIndex = selected.findIndex(nfz => getId(currentSelected) === getId(nfz))
        next = selected[(selectedIndex + 1) % selected.length]
      }
      setSelectedCandidate(next, getId(next))
    }
  }, [candidatesPool, currentSelected, filterCandidates, getGeometry, getId ,setSelectedCandidate])
}
export function getPolygonsAtPoint(items, getPolygon, {lat, lng}) {
  return items.filter(item => {
    const polygon = getPolygon(item)
    return (polygon.geometry.type === 'Polygon' || polygon.geometry.type === 'MultiPolygon')
      && booleanPointInPolygon(point([lng, lat]), polygon)
  })
}