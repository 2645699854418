import { useState, useEffect } from 'react'
import { Route, Switch }       from 'react-router-dom'
import { ConnectedRouter }     from 'connected-react-router'
import { history }             from '../utils/store'

// import Zones        from '../Zones'
import Airspace     from '../Airspace'
import Landing      from '../Zones/Landing'
import Reservation  from '../Zones/Reservation'
import Approval     from '../Zones/Approval'
import Dashboard    from '../Dashboard'
import Playback     from '../Playback'
import Trackers     from '../Trackers'
import Drones       from '../Drones'
import Docks        from '../equipment/docks'
import Flights      from '../Drones/Flights'
import Violations   from '../Conformance/Violations'
import Developer    from '../Developer'
import InjectTracks from '../Developer/InjectTracks'
import Advisories   from '../prototypes/Advisories'
// import Communication from '../prototypes/Communication'
import External     from '../prototypes/External'
import Reports      from '../prototypes/Reports'

import AccountListItem      from './Drawer/AccountListItem'
import DeveloperListItem    from './Drawer/DeveloperListItem'
import SubscriptionListItem from './Drawer/SubscriptionListItem'

import Logo from '../assets/LogoMyDroneFleetsNew.png'
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  Assessment,
  ChevronLeft,
  ChevronRight,
  Gavel,
  Language,
  Replay,
  PostAdd,
  Public,
  SettingsOverscan,
  WbSunny,
} from '@material-ui/icons'
import {
  Dock,
} from '@mui/icons-material'
// import MyNotifIcon      from './Icons/MyNotifIcon'
// import MySettingIcon    from './Icons/MySettingIcon'
import DroneMarkerIcon  from './Icons/DroneMarkerIcon'
import { withStyles }   from '@material-ui/core/styles'
import TrackerIcon from './Icons/TrackerIcon'

const bigDrawerWidth   = 240
const smallDrawerWidth = 56

const styles = theme => ({
  drawer: {
    width:      bigDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: bigDrawerWidth,
    transition: theme.transitions.create('width', {
      easing:   theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing:   theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width:      smallDrawerWidth,
  },
  expandBtn: {
    zIndex:     10000,
    position:   'fixed',
    bottom:     theme.spacing(1),
    left:       0,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
})

function DrawerView({ classes }) {
  const [ selectedMenuIndex, setSelectedMenuIndex ] = useState(0)
  const [ drawerOpen,        setDrawerOpen        ] = useState(false) // used to be window.innerWidth > 1024, but can't access top left map controls until map auto resize...

  const menuData = Object.freeze([
    { name: 'Airspace Editor', link: '/airspace',        icon: <Language />      },
    { name: 'Book Airspace',   link: '/zones',           icon: <SettingsOverscan /> },
    { name: 'Live Airspace',   link: '/dashboard',       icon: <Public />        },
    { name: 'Playback',        link: '/playback',        icon: <Replay />        },
    { divider: true },
    { name: 'My Drones',       link: '/drones',          icon: <DroneMarkerIcon /> },
    { name: 'My Trackers',     link: '/trackers',        icon: <TrackerIcon />   },
    { name: 'My Docks',        link: '/equipment/docks', icon: <Dock />   },
    { name: 'My Violations',   link: '/violations',      icon: <Gavel />         },
    { name: 'Advisories',      link: '/advisories',      icon: <WbSunny />       },
    { name: 'Reports',         link: '/reports',         icon: <Assessment />    },
    { name: 'External',        link: '/external',        icon: <PostAdd />       },
    { divider: true },
  ])

  useEffect(() => {
    setSelectedMenuIndex(
      menuData.findIndex(m => window.location.pathname.includes(m.link))
    )
  }, [menuData])

  const menuListItems = menuData.map((item, index) => {
    if (item.divider) {
      return <Divider key={index} />
    } else {
      return (
        <a key={index} href={item.link} className={classes.link}>
          <ListItem
            onClick={() => setSelectedMenuIndex(index)}
            style={{ width: bigDrawerWidth }}
            selected={index === selectedMenuIndex}
            button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        </a>
      )
    }
  })

  // TODO: Link directly to mdf app when accounts is ready, e.g. /apps/mydronefleets
  const externListItems = <>
    <DeveloperListItem />
    <SubscriptionListItem
      width={bigDrawerWidth}
    />
    <AccountListItem
      width={bigDrawerWidth}
      drawerOpen={drawerOpen}
      history={history}
    />
  </>

  return (
    <ConnectedRouter history={history}>
      <div style={{ display: 'flex' }}>
        <Drawer
          className={`${classes.drawer} ${ drawerOpen ? classes.drawerOpen : classes.drawerClose }`}
          classes={{ paper: `${ drawerOpen ? classes.drawerOpen : classes.drawerClose }` }}
          variant='permanent'
          anchor='left'>
          <img src={Logo} width='100%' height={drawerOpen ? 80 : 28} alt='MyDroneFleets Logo' />
          <div style={{ height: 'calc(100% - 124px)', overflowX: 'hidden', overflowY: 'auto' }}>
            <List>{menuListItems}</List>
            <List>{externListItems}</List>
            <Divider />
          </div>
          <IconButton
            className={classes.expandBtn}
            onClick={() => setDrawerOpen(!drawerOpen)}>
            {drawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Drawer>
        <Switch>
          {/*<Route exact path='/zones' component={Zones} />*/}
          <Route exact path='/zones'          component={Landing}     />
          <Route exact path='/zones/new'      component={Reservation} />
          <Route path='/zones/:zone_id/edit'  component={Reservation} />
          <Route path='/zones/:zone_id'       component={Approval}    />
          <Route exact path='/dashboard'>
            <Dashboard shrinkDrawer={!drawerOpen} />
          </Route>
          <Route path='/airspace'    component={Airspace} />
          <Route path='/playback'    component={Playback} />
          <Route path='/trackers'    component={Trackers} />
          <Route path='/drones'      component={Drones}   />
          <Route path='/equipment/docks' component={Docks} />
          <Route path='/flights/:id' component={Flights}  />
          <Route path='/flights'     component={Flights}  />

          <Route exact path='/violations' component={Violations} />
          <Route exact path='/advisories' component={Advisories} />
          <Route exact path='/reports'    component={Reports} />
          <Route exact path='/external'   component={External} />
          <Route exact path='/developer'  component={Developer} />
          <Route exact path='/developer/inject-telemetry' component={InjectTracks} />
        </Switch>
      </div>
    </ConnectedRouter>
  )
}

export default withStyles(styles)(DrawerView)
