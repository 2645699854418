import {
  Grid,
  Typography,
} from '@material-ui/core'

function GridTitle({ children }) {
  return (
    <Grid item xs={12}>
      <Typography variant='h6'>{children}</Typography>
    </Grid>
  )
}

export default GridTitle
