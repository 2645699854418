let wheelIndex = 0

const colourWheel = [
  '#f44336', '#3f51b5', '#009688', '#ffeb3b',
  '#e91e63', '#2196f3', '#4caf50', '#ffc107',
  '#9c27b0', '#03a9f4', '#8bc34a', '#ff9800',
  '#673ab7', '#00bcd4', '#cddc39', '#ff5722'
]

function nextColour() {
  wheelIndex = (wheelIndex + 1) % colourWheel.length
  return colourWheel[wheelIndex]
}

function thisColour() {
  return colourWheel[wheelIndex]
}

const exportMe = {
  thisColour,
  nextColour  
}
export default exportMe
