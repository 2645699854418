
import { Button } from '@material-ui/core'

// To get a uniform look across the site
function ActionButton({ label, startIcon, endIcon, onClick = () => {} }) {

  return (
    <Button
      variant='outlined'
      color='inherit'
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}>
      {label}
    </Button>
  )
}


export default ActionButton
