import { StyledDateTimePicker } from '../../../components/StyledPickers'
import {
  Grid,
} from '@material-ui/core'

function ValidityPicker({ validity, setValidity, vStartError, vEndError }) {
  function handleChangeStart(newDate) {
    setValidity({
      start: newDate,
      end: validity.end,
    })
  }

  function handleChangeEnd(newDate) {
    setValidity({
      start: validity.start,
      end: newDate,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <StyledDateTimePicker
          label='Start'
          value={validity.start}
          error={!!vStartError}
          helperText={vStartError}
          onChange={handleChangeStart}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledDateTimePicker
          label='End'
          value={validity.end}
          error={!!vEndError}
          helperText={vEndError}
          onChange={handleChangeEnd}
        />
      </Grid>
    </Grid>
  )
}

export default ValidityPicker
