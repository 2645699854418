import { format } from 'date-fns'
import { commonFormat } from '../../../utils'

import Nfz from '../../../components/Text/Nfz'
import User from '../../../components/Text/User'
import Drone from '../../../components/Text/Drone'
import Flight from '../../../components/Text/Flight'
import Tracker from '../../../components/Text/Tracker'

import {
  ListItem,
  Typography,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  listitem: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.map.orange,
    border: '1px solid black',
    '&:hover': {
      backgroundColor: theme.palette.map.yellow,
    },
  },
  name: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    backgroundColor: theme.palette.map.darkBlue,
  },
  keep: {
    display: 'flex',
  },
  flight: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.map.magenta,
  },
  area: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.map.blue,
  },
  box: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.map.lightBlue,
  },
  ack: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.success.main,
  }
})

  //   [ua_id]: {
  //     ua_telem_type:
  //     company_id:
  //     flight_id:
  //     areas: {
  //       [area_id]: {
  //         area_type:
  //         keep_in: {
  //           warning_since: created_on (for first WARNING-KEEPIN)
  //           breached_since: created_on (for first BREACHED-KEEPIN)
  //         },
  //         keep_out: {
  //           warning_since: created_on (for first WARNING-KEEPOUT)
  //           breached_since: created_on (for first BREACHED-KEEPOUT)
  //         },
  //       },
  //     },
  //     acknowledged: { [l_a_by]: l_a_on },
  //   }
  // }

function RsafViolatingUa({ classes, ua_id, summary, onDroneClick, onTrackerClick }) {
  const name = summary.ua_telem_type === 'aircraft' ? <Drone   id={ua_id} name /> :
               summary.ua_telem_type === 'tracker'  ? <Tracker id={ua_id} name /> :
               'Unknown aircraft'

  function handleClick() {
    summary.ua_telem_type === 'aircraft' && onDroneClick(ua_id)
    summary.ua_telem_type === 'tracker'  && onTrackerClick(ua_id)
  }

  return (
    <ListItem button className={classes.listitem} onClick={handleClick}>
      <div>
        <div>
          <div className={classes.name}>
            <Typography variant='subtitle1'>{name}</Typography>
          </div>
          { summary.flight_id &&
            <div className={classes.flight}>
              <Flight id={summary.flight_id} permits />
            </div>
          }
        </div>
        { Object.keys(summary.areas).map(area_id => (
          <div key={area_id}>
            <div className={classes.area}>
              { summary.areas[area_id].area_type === 'flight' ?
                <Flight id={area_id} location />
              : <>
                  <Nfz id={area_id} name /> ({ summary.areas[area_id].area_type })
                </>
              }
            </div>
            { Object.keys(summary.areas[area_id].keep_in).length > 0 &&
              <div style={{ display: 'flex' }}>
                <div className={classes.box}>
                  <Typography variant='subtitle2'>KEEP IN</Typography>
                </div>
                <div style={{ padding: '8px' }}>
                  { summary.areas[area_id].keep_in.warning_since &&
                    <Typography variant='body2'>
                      Warning since {format(new Date(summary.areas[area_id].keep_in.warning_since), commonFormat)}
                    </Typography>
                  }
                  { summary.areas[area_id].keep_in.breached_since &&
                    <Typography variant='body2'>
                      BREACHED since {format(new Date(summary.areas[area_id].keep_in.breached_since), commonFormat)}
                    </Typography>
                  }
                </div>
              </div>
            }
            { Object.keys(summary.areas[area_id].keep_out).length > 0 &&
              <div style={{ display: 'flex' }}>
                <div className={classes.box}>
                  <Typography variant='subtitle2'>KEEP OUT</Typography>
                </div>
                <div style={{ padding: '8px' }}>
                  { summary.areas[area_id].keep_out.warning_since &&
                    <Typography variant='body2'>
                      Warning since {format(new Date(summary.areas[area_id].keep_out.warning_since), commonFormat)}
                    </Typography>
                  }
                  { summary.areas[area_id].keep_out.breached_since &&
                    <Typography variant='body2'>
                      BREACHED since {format(new Date(summary.areas[area_id].keep_out.breached_since), commonFormat)}
                    </Typography>
                  }
                </div>
              </div>
            }
          </div>
        )) }
        { Object.keys(summary.acknowledged).length > 0 &&
          <div className={classes.ack}>
            <Typography variant='body2'>Acknowledged by</Typography>
            { Object.keys(summary.acknowledged).map(user_id => (
              <Typography variant='body2' key={user_id}>
                <User id={user_id} name /> on {
                format(new Date(summary.acknowledged[user_id]), commonFormat)}
              </Typography>
            )) }
          </div>
        }
      </div>
    </ListItem>
  )
}

export default withStyles(styles)(RsafViolatingUa)
