// Based on Autocomplete
// - https://v4.mui.com/components/autocomplete/
//
// Requirements
// - Use Free Solo but provide a Combo Box like experience (done)
// - Grouped - even when not specified, group alphabetically (done)
// - Asynchronous loading of options - i.e. only load the options when user click into the text field (done)
// - Creatable with Dialog Box (means people can type in new data and a Dialog will pop up to create something minimal) (callback only)
// - Multiple Values (canonical way to display this is Chips or tags) (done)
// - Virtualised for performance (using react-window package) (outstanding)
// - New mode where data can be loaded repeatedly
//
import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import {
  CircularProgress,
  TextField,
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

const filter = createFilterOptions()

//
// TextBox API
//
function TextBox({
  // Required fields (contolled TextField)
  value,     // required controlled field
  onChange,  // callback to change controlled field

  // Dynamic Loading
  //
  // Can provide options (a static list), get, or both. Results is mixed together. Can
  // provide a getById callback for Edit scenarios.
  //
  // Both get and getById will be treated as Lazy RTK Query promise if it has a function
  // called "unwrap", otherwise it is expected to resolve immediately (i.e. if it's your
  // own promise, resolve it within the callback function).
  // 
  // Current get is expected to load all options once only, unlike mui.com sample that tries
  // on each open.
  //
  options,    // Static options (added to the backend data)
  get,        // Async RTK Lazy Query to load the data for entries
  getKey,     // Response key (e.g. 'users'). data?.data?.getKey must be an array of objects
  getById,    // Async RTK Lazy Query to load the data for one entry only
  getByIdKey, // Response key (e.g. 'user'). data?.data?.getByIdKey must be an objects
  dataId,     // ID used to id the data (e.g. 'user_id'), skip to use value
  dataLabel,  // ID used to label the data (e.g. 'name'), skip to use value
  groupKey = 'firstLetter', // Object key (e.g. 'status') to group data together
  findDefaultValue = false, // If set to true, passively makes calls before any user interaction and tries to match using data label
  // Optional fields for TextField
  label,
  placeholder,
  helperText,
  error,
  style,
  variant,
  groupBy,     // Overrides groupKey to implement custom grouping logic
  sortOptions, // Custom sort function for options, defaults to ascending order of groupKey
  // Creation when missing
  onCreate,   // callback to handle creation (enables Add "xx" option)
  addSelectAll,

  // Multiple
  multiple,
  filterSelected=true
}) {
  const [ open,  setOpen  ] = useState(false)
  const [ opts,  setOpts  ] = useState([])
  const [ step,  setStep  ] = useState(0)
  const [ input, setInput ] = useState('') // internal to Autocomplete

  // Default grouping
  const createFirstLetter = useCallback(array => (array.map(object => {
    const firstLetter = object && object[dataLabel] ?
      object[dataLabel].charAt(0).toUpperCase() : ' '
    return { ...object,
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
    }
  })), [dataLabel])

  // step 0 = init
  //      1 = loading the first entry (skip if getById not defined)
  //      2 = ready for viewing (empty or 1st one loaded)
  //      3 = loading the rest of the entries (skip if get not defined)
  //      4 = ready for selection (empty or fully loaded)

  useEffect(() => {
    // Load the first one, if given a way to load it
    if (step === 0) {
      if (getById) {
        setStep(1)
        const getByIds = Array.isArray(getById) ? getById : [getById]
        if (getByIds.every(g => g().unwrap)) {
          const results = Promise.all(getByIds.map(g => g().unwrap()))
          results.then(res => {
            const values = res.map(fulfilled => {
              // console.log('fulfilled', fulfilled, 'options', options)
              if (fulfilled.status === 'success') {
                const object = fulfilled.data[getByIdKey]
                assertObject(object, `GET ${getByIdKey} should return an object`)
                setOpts(createFirstLetter(options ? [ ...options, object ] : [ object ]))
                return object
              } else {
                toast.error(`Fail loading ${getByIdKey}`)
                console.log(`Fail loading ${getByIdKey}`, fulfilled)
                return null
              }
            }).filter(r => r !== null)
            onChange(multiple ? values : values[0])
            setStep(2)
          }).catch(rejected => {
            toast.error(`Error loading ${getByIdKey}`)
            console.log(`Error loading ${getByIdKey}`, rejected)
          })
        } else {
          getByIds.forEach(g => assertObject(g[getByIdKey], `getById ${getByIdKey} should provide an object`))
          // console.log('setting immediately resolved result', resuclt)
          setOpts(createFirstLetter([ ...(options ?? []), ...getByIds ]))
          onChange(multiple ? getByIds.map(g => g[getByIdKey]) : getByIds[0][getByIdKey])
          setStep(2)
        }
      } else {
        // console.log('setting default options', options)
        if (options) {
          setOpts(createFirstLetter(options))
          const match = options.find(option => option[dataId] === value)
          match && setInput(match[dataLabel])
          // console.log('dataId', dataId, 'dataLabel', dataLabel, 'value', value, 'match', match)
        }
        setStep(2)
      }
    }

    if (((findDefaultValue && input?.length > 0) || open) && step === 2) {
      if (get) {
        setStep(3)
        get().unwrap()
        .then(fulfilled => {
          // console.log('fulfilled', fulfilled, 'options', options)
          if (fulfilled.status === 'success') {
            const array = fulfilled.data[getKey]
            assertArray(array, `GET ${getKey} should returns an array`)
            setOpts(createFirstLetter(options ? [ ...options, ...array ] : array))
            setStep(4)
          } else if (fulfilled.entities) {
            // This is a transformed entity, some day we will transform all of them
            const array = Object.values(fulfilled.entities)
            setOpts(createFirstLetter(options ? [ ...options, ...array ] : array))
            setStep(4)
          } else {
            toast.error(`Fail loading ${getKey}`)
            console.log(`Fail loading ${getKey}`, fulfilled)
          }
        })
        .catch(rejected => {
          toast.error(`Error loading ${getKey}`)
          console.log(`Error loading ${getKey}`, rejected)
        })
      } else {
        setStep(4)
      }
    }
  }, [open, step, value, options, get, getKey, getById, getByIdKey, dataId, dataLabel, createFirstLetter, findDefaultValue, input, multiple, onChange])
  
  const valueDataId = value ? value[dataId] : null

  useEffect(()=>{
    if(opts.length > 0 && findDefaultValue && valueDataId){
      const targetValue = opts.find((o)=> o[dataId] === valueDataId)
      onChange(targetValue)
    }
  }, [opts, dataLabel, findDefaultValue, onChange, valueDataId, dataId])
  
  const loading = step === 1 || step === 3
  // console.log('open', open, 'step', step, 'opts', opts)

  useEffect(() => {
    if (!groupBy) opts.sort(sortOptions || ((a, b) => -b[groupKey].localeCompare(a[groupKey])))
    else if (sortOptions) opts.sort(sortOptions)
  }, [opts, groupKey, groupBy, sortOptions])
  useEffect(()=>{
    if(filterSelected && multiple)
      setOpts((o)=>{
        return o?.map((v)=>{ 
          const entry = value?.find((a) => a[dataId] === v[dataId])
          v._hidden = !!entry
          return v
        })
    })
  }, [value, filterSelected, dataId, multiple])
  return (
    <Autocomplete
      fullWidth
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple={multiple}
      style={style}
      open={open}
      onOpen={() => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      options={opts.filter((v) => !v?._hidden)}
      loading={loading}
      value={value}
      inputValue={input}
      onInputChange={(e, v) => { setInput(v) }}
      onChange={(evt, newValue) => {
        // console.log('handleChange', newValue)
        if (typeof newValue === 'string') {
          // timeout to avoid instant validation of the callback dialog's form.
          onCreate && setTimeout(() => {
            onCreate(newValue)
          })
        } else if (newValue?.length > 0 && Array.isArray(newValue) && newValue[0].selectAll) {
          onChange && setTimeout(() => {
            onChange(opts)
          })
        } else if (newValue?.inputValue) {
          onCreate && onCreate(newValue.inputValue)
        } else {
          onChange && onChange(newValue)
        }
      }}
      getOptionLabel={option => (option[dataLabel] || '')}
      getOptionSelected={(option, value) => dataId ? option[dataId] === value : option === value}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        if (addSelectAll && options.length !== 0){
          filtered.unshift({
            selectAll: true,
            [dataLabel]: `Select All`
          })
        }
        if (onCreate && params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            [dataLabel]: `Add "${params.inputValue}"`,
          })
        }
        return filtered
      }}
      groupBy={groupBy || ((option) => groupKey ? option[groupKey] : option.firstLetter)}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant={variant || 'outlined'}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>
              {loading ? <CircularProgress color='inherit' size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          }}
        />
      )}
      filterSelectedOptions={true}
    />
  )
}

// warning only (TBD better handling)
function assertObject(obj, warning) {
  if (obj && typeof obj !== 'object')
    console.warn(warning)
}
function assertArray(arr, warning) {
  if (!Array.isArray(arr))
    console.warn(warning)
}

export default TextBox
