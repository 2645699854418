import { Link } from 'react-router-dom'
import TooltipIcon from '../../components/TooltipIcon' 
import {
  IconButton,
} from '@material-ui/core'
import {
  PinDrop,
} from '@material-ui/icons'
import theme from '../../utils/site-theme'

// Only droneId or trackerId should be provided.
// If both are given, tracker takes precedence.
//
function InjectButton({ droneId, trackerId }) {
  const url = trackerId ? `/developer/inject-telemetry?tracker=${trackerId}` :
    droneId ? `/developer/inject-telemetry?drone=${droneId}` : ''

  return (
    <Link target={trackerId || droneId} to={url}>
      <IconButton size='small'>
        <TooltipIcon
          title='Inject Telemetry (for Developers)'
          icon={<PinDrop />}
          colour={theme.palette.secondary.main}
        />
      </IconButton>
    </Link>
  )
}

export default InjectButton
