import { useState } from 'react'
import { useInterval } from 'usehooks-ts'
import DroneMarker from './DroneMarker'

const BLINK_DURATION = 626
const BLINK_COLOR = { drone: { stroke: '#e93c2c' } }

function BlinkingDroneMarker({ onClick, ...rest }) {
  const [ blinkColor, setBlinkColor ] = useState(null)

  useInterval(() => {
    setBlinkColor(!blinkColor ? BLINK_COLOR : null)
  }, BLINK_DURATION)

  return <DroneMarker onClick={onClick} {...rest} iconColor={blinkColor} />
}

export default BlinkingDroneMarker
