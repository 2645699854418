import { prettyXprizeFlightId } from '../../../plugins/xprize/xprize-utils'
import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    width: theme.spacing(24),
    paddingLeft: theme.spacing(1)
  },
  red: {
    padding:         theme.spacing(1),
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.tracker.red,
  },
  yellow: {
    padding:         theme.spacing(1),
    color:           theme.palette.common.black,
    backgroundColor: theme.palette.tracker.yellow,
  },
  green: {
    padding:         theme.spacing(1),
    color:           theme.palette.common.white,
    backgroundColor: theme.palette.tracker.green,
  },
  limeGreen: {
    padding:         theme.spacing(1),
    color:           theme.palette.common.black,
    backgroundColor: theme.palette.tracker.limeGreen,
  },
})

function FlightColumn({ classes, id, drone, lastTelem }) {

  const durMin = ((Date.now() - new Date(lastTelem?.t).getTime()) / 60000) || 6
  const injected = lastTelem && (!(lastTelem.batt_duration > 0) && lastTelem.lat !== '' && lastTelem.lng !== '')

  return (
    <div className={classes.root}>
      <Typography variant='body2' className={
        durMin < 1 ? (injected ? classes.limeGreen : classes.green) :
        durMin < 5 ? classes.yellow : classes.red}>
        { prettyXprizeFlightId(id)}
      </Typography>
      <Typography variant='body2' style={{ paddingLeft: '8px' }}>
        <b>{drone}</b>
        <br />
        { lastTelem ?
          <>Alt: {(lastTelem.alt / 100).toFixed(2)} m</> :
          <>No Telemetry</>
        }
      </Typography>
    </div>
  )
} 

export default withStyles(styles)(FlightColumn)
