import { useState } from 'react'
import { useGetFlightsQuery } from '../../../api/aircraft'
import usePermissions from '../../../components/usePermissions'
import Pill from '../../../components/Pill'

import { prettyXprizeFlightId } from './xprize-utils'
import XprizeFlightsDialog from './XprizeFlightsDialog'
import {
  Button,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    padding: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flights: {
    padding: theme.spacing(1),
  },
  flight: {
    border: '1px solid grey',
  }
})

function XprizeFlights({
  classes,
  xprizeCompanies,
  tracks,
  selectedFlightId,
  setSelectedFlightId,
}) {
  const [ action, setAction ] = useState('') // '', 'start', 'stop' (pause resume later)

  const { data } = useGetFlightsQuery({
    company_ids: xprizeCompanies.map(c => c.company_id),
    status: 'flying'
  }, {skip: !xprizeCompanies || xprizeCompanies.length === 0})

  const canStartStopFlight = usePermissions('xprize-flight-write')

  const activeFlightIds = data?.ids || []
  const activeFlights = data?.entities || {}
  const lastTelem = {}

  Object.keys(activeFlights).forEach(flight_id => {
    const af = activeFlights[flight_id]
    lastTelem[flight_id] = Array.isArray(af.drone?.tracker_ids) ?
      af.drone.tracker_ids.reduce(
        (a, v) => (a || tracks[v]), tracks[af.drone.id]
      ) :
    tracks[af.drone?.id] // lastTelem[f] = undefined if no telems at all
  })

  // Option 1: By telem recency then by takeoff
  // const sortedFlights = Object.values(activeFlights).sort((a, b) => {
  //   // The ones with no telemetry comes first
  //   if (lastTelem[a.flight_id] && !lastTelem[b.flight_id])
  //     return -1
  //   if (!lastTelem[a.flight_id] && lastTelem[b.flight_id])
  //     return 1
  //   // Since both have telem or both don't have telem, sort by takeoff time
  //   if (a.path?.takeoff?.t && (!b.path?.takeoff?.t || a.path.takeoff.t > b.path.takeoff.t))
  //     return -1
  //   if (b.path?.takeoff?.t && (!a.path?.takeoff?.t || b.path.takeoff.t > a.path.takeoff.t))
  //     return 1
  //   // They both haven't taken off, don't care
  //   return 0
  // })

  // Option 2: By altitude
  const sortedFlights = Object.values(activeFlights).sort((a, b) => {
    // The ones with no telemetry still comes first
    if (!lastTelem[b.flight_id])
      if (!lastTelem[a.flight_id])
        return 0
      else
        return -1
    if (!lastTelem[a.flight_id])
      return 1
    // Now sort only by altitude, No altitude treated as 0
    if (lastTelem[a.flight_id].alt < lastTelem[b.flight_id].alt)
      return 1
    if (lastTelem[a.flight_id].alt > lastTelem[b.flight_id].alt)
      return -1
    // They both on the ground, don't care
    return 0
  })

  function handleClose() {
    setSelectedFlightId('')
    setAction('')
  }
  return (
    <div className={classes.container}>
      <div className={classes.buttons}>
        <Button>Flights</Button>
        { canStartStopFlight && <>
          <Button variant='contained' color='secondary'
            onClick={() => { setAction('start') }}>
            Start
          </Button>
          <Button variant='contained' color='secondary'
            onClick={() => { setAction('stop') }}>
            Stop
          </Button>
        </>}
      </div>
      { activeFlightIds.length === 0 &&
        <div className={classes.flights}>
          <Pill text='No Active Flights' />
        </div>
      }
      { activeFlightIds.length > 0 &&
        <div className={classes.flights}>
          <Typography variant='overline'>Active Flights</Typography>
          <List>
            { sortedFlights.map(sf => (
              <ListItem dense disableGutters button className={classes.flight}
                key={sf.flight_id}
                selected={sf.flight_id === selectedFlightId}
                onClick={e => { setSelectedFlightId(sf.flight_id) }}>
                <FlightListItemPrimaryText id={sf.flight_id}
                    lastTelem={lastTelem[sf.flight_id]} />
                <FlightListItemSecondaryText drone={sf.drone.name}
                    lastTelem={lastTelem[sf.flight_id]} />
              </ListItem>
            )) }
          </List>
        </div>
      }
      { !!action &&
        <XprizeFlightsDialog
          open={!!action}
          onClose={handleClose}
          xprizeCompanies={xprizeCompanies}
          tracks={tracks}
          action={action}
          activeFlights={activeFlights}
        />
      }
    </div>
  )
}

const txtStyles = theme => ({
  red: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.tracker.red,
  },
  yellow: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.tracker.yellow,
  },
  green: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.tracker.green,
  },
  limeGreen: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.tracker.limeGreen,
  }
})

function FlightListItemPrimaryText({ id, lastTelem }) {
  const classes = makeStyles(txtStyles)()
  const durMin = ((Date.now() - new Date(lastTelem?.t).getTime()) / 60000) || 6
  const injected = lastTelem && (!(lastTelem.batt_duration > 0) && lastTelem.lat !== '' && lastTelem.lng !== '')
  return (
    <span className={
      durMin < 1 ? (injected ? classes.limeGreen : classes.green) :
      durMin < 5 ? classes.yellow : classes.red}>
      { prettyXprizeFlightId(id) }
    </span>
  )
}

function FlightListItemSecondaryText({ drone, lastTelem }) {
  return (<span style={{ paddingLeft: '8px' }}>
    <b>{ drone }</b>
    <br />
    { lastTelem ?
      <>Alt: { (lastTelem.alt / 100).toFixed(2) } m</> :
      <>No Telemetry</>
    }
  </span>)
}

export default withStyles(styles)(XprizeFlights)
