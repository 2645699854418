import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router'
import { FeatureGroup } from 'react-leaflet'
import { circle } from '@turf/turf'

import { getDecodedAccessToken } from '../api/accounts'
import {
  useGetPublicNFZsQuery,
  useGetPrivateNFZsQuery,
} from '../api/airspace'
import { useClickRotation, getPolygonsAtPoint} from '../utils/hooks'

import useNfzValidity        from '../components/useNfzValidity'
import TogglePanelControls   from '../components/Map/TogglePanelControls'

import AirspaceMap           from './components/AirspaceMap'
import AirspaceDetailsPanel  from './components/AirspaceDetailsPanel'
import AirspaceListPanel     from './components/AirspaceListPanel'
import CreateDraftAreaDialog from './components/CreateAirspaceDialog'
import EditDraftAreaDialog   from './components/EditAirspaceDialog'
import DeleteDraftAreaDialog from './components/DeleteAirspaceDialog'
import DmsPanel              from './components/DmsPanel'
import TopActionPanel        from './components/TopActionPanel'
import EditorToolbar         from '../Zones/Editor/components/EditorToolbar'

import {
  Gradient,
  Layers,
  Map,
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import L from 'leaflet'

const styles = theme => ({
  root: {
    width: '100%',
  },
  map: {
    zIndex: 1,
  },
  mapOverlay: {
    top: 0,
    position: 'absolute',
    zIndex: 10,
  },
  airspaceListPanelContainer: {
    position: 'fixed',
    right: 0,
    margin: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(4)}px)`,
    width: theme.spacing(36)
  },
  airspaceControlsPanelContainer: {
    position: 'fixed',
    top:  theme.spacing(1.25),
    left: theme.spacing(16),
  }
})

function Airspace({ classes }) {
  const [ nfzValidity ] = useNfzValidity('Airspace')
  const { data: publicNfzsData } = useGetPublicNFZsQuery({
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
  })

  const company_id = useMemo(() => getDecodedAccessToken().company_id, [])
  const { data: privateNfzsData } = useGetPrivateNFZsQuery({
    company_ids:    company_id,
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
  })

  const drawingFgRef = useRef()
  const [ map, setMap ] = useState() // ref to MapContainer
  const [ drawHandler, setDrawHandler ] = useState()
  const [ editHandler, setEditHandler ] = useState()
  const [ selectedNfz, setSelectedNfz ] = useState()
  const [ feature,     setFeature    ] = useState()
  const [ vertices,    setVertices    ] = useState([])
  const [ showCreate,  setShowCreate  ] = useState(false)
  const [ showEdit,    setShowEdit    ] = useState(false)
  const [ showDelete,  setShowDelete  ] = useState(false)
  const [ editNfz,     setEditNfz     ] = useState()
  const [ mode,        setMode        ] = useState('normal') // normal, create, edit
  const [ showOnMap,   setShowOnMap   ] = useState([
    'public-nfz',
    'public-test-estate',
    'private-nfz',
    'private-ready-to-use'
  ])

  useEffect(() => {
    if (mode === 'normal' || mode === 'create') {
      drawingFgRef.current?.clearLayers()
      setEditNfz(null)
    } else if (mode === 'edit') {
      if (!drawingFgRef) return
      drawingFgRef.current.clearLayers()
      L.geoJSON(editNfz.geometry).eachLayer(l => l.addTo(drawingFgRef.current))
    }
  }, [mode, editNfz])

  useEffect(() => {
    if (!selectedNfz)
      return
    if (selectedNfz.owner?.company_id) {
      setSelectedNfz(privateNfzsData?.entities[selectedNfz.nfz_id])
    } else {
      setSelectedNfz(publicNfzsData?.entities[selectedNfz.nfz_id])
    }
  }, [privateNfzsData, publicNfzsData, selectedNfz])

  const onClickLayer = useClickRotation((Object.values(publicNfzsData?.entities ?? {}) ?? []).concat(Object.values(privateNfzsData?.entities ?? {})),(f)=>{
    return f.nfz_id
  }, selectedNfz, getPolygonsAtPoint, (c)=> ({type: 'Feature', geometry: c.geometry}) , (selected)=>{
    setSelectedNfz(selected)
  })

  const onSetVertices = useCallback(newVertices => {
    if (editHandler?.enabled()) {
      editHandler.disable()
      drawingFgRef.current.clearLayers()
      if (newVertices.length === 0) return
      L.polygon(newVertices).addTo(drawingFgRef.current)
      editHandler.enable()
    } else {
      // Trigger draw if edit handler is not enabled
      if (!drawHandler) return
      const isFirstPoint = vertices.length === 0 && newVertices.length === 1
      if (isFirstPoint) map.flyTo(newVertices[0])
      drawHandler.disable()
      if (newVertices.length > 0) {
        drawHandler.enable()
        newVertices.forEach(v => {
          drawHandler._currentLatLng = v // Internally used by leaflet draw
          drawHandler.addVertex(v)
        })
      }
      if (newVertices.length >= 3) {
        const last = newVertices[newVertices.length - 1]
        if (last.lat === newVertices[0].lat && last.lng === newVertices[0].lng)
          drawHandler.completeShape()
      }
    }
  }, [vertices, drawHandler, editHandler, map])
  const nfzs = useMemo(()=> !privateNfzsData ? {} : privateNfzsData.entities, [privateNfzsData])
  return (
    <div className={classes.root}>
      <AirspaceMap
        setMap={setMap}
        nfzs={nfzs}
        className={classes.map}
        selectedNfz={mode === 'normal' && selectedNfz}
        onClickLayer={onClickLayer}
        privateNfzFilter={editNfz && (nfz => nfz.nfz_id !== editNfz.nfz_id)}
        showPublicNfz={showOnMap.includes('public-nfz')}
        showPublicTestEstate={showOnMap.includes('public-test-estate')}
        showPrivateNfz={showOnMap.includes('private-nfz')}
        showPrivateReadyToUse={showOnMap.includes('private-ready-to-use')}
      >
        <FeatureGroup
          ref={drawingFgRef}
          eventHandlers={{
            layeradd: e => {
              e.sourceTarget.eachLayer(l => l !== e.layer && e.sourceTarget.removeLayer(l))
              const feature = e.layer.toGeoJSON()
              // Parse circle to geojson polygon
              if (feature.geometry.type === 'Point') {
                const polygonizedCircle = circle(feature,
                  e.layer.getRadius() / 1000, { steps: 32, unit: 'kilometers' })
                feature.geometry = polygonizedCircle.geometry
                feature.isCircle = true
              }
              setFeature(feature)
            },
            layerremove: e => {
              if (e.sourceTarget.getLayers().length === 0) setFeature(null)
            }
          }}>
          {(mode === 'create' || mode === 'edit') && <EditorToolbar
            onMounted={draw => {
              setDrawHandler(draw._toolbars.draw._modes.polygon.handler)
              setEditHandler(draw._toolbars.edit._modes.edit.handler)
            }}
            draw={{
              polygon: true,
              circle: true,
              rectangle: false,
              polyline: false,
              marker: false,
              circlemarker: false,
            }}
            onDrawVertex={e => setVertices(e.layers.getLayers().map(l => l.getLatLng()))}
            onEditStart={() => {
              const layer = drawingFgRef.current.getLayers()[0]
              setVertices(layer.getLatLngs?.()?.[0] ?? [layer.getLatLng?.()] ?? [])}
            }
            onEditVertex={e => setVertices(e.layers.getLayers().filter(l => l._prev).map(l => l.getLatLng()))}
            onEditMove={e => setVertices([e.layer.getLatLng()])} // Only for circle tool
            onToolbarClosed={() => setVertices([])}
          />}
        </FeatureGroup>
      </AirspaceMap>
      <div className={classes.mapOverlay}>
        <div className={classes.airspaceControlsPanelContainer}>
          <TogglePanelControls
            enabled={showOnMap}
            setEnabled={setShowOnMap}
            controls={[
              [
                {
                  title: 'Public Airspace (Restricted)',
                  icon: <Warning />,
                  value: 'public-nfz'
                },
                {
                  title: 'Public Airspace (Drone Friendly)',
                  icon: <Gradient />,
                  value: 'public-test-estate'
                },
                {
                  title: 'Private Airspace (Restricted)',
                  icon: <Map />,
                  value: 'private-nfz'
                },
                {
                  title: 'Private Airspace (Ready To Use)',
                  icon: <Layers />,
                  value: 'private-ready-to-use'
                }
              ]
            ]}
          />
        </div>
        {mode === 'normal' && <>
          <div className={classes.airspaceListPanelContainer}>
            <AirspaceListPanel
              publicNfzs={publicNfzsData && Object.values(publicNfzsData.entities)}
              privateNfzs={privateNfzsData && Object.values(privateNfzsData.entities)}
              onSelectNfz={nfz => setSelectedNfz({
                ...nfz,
                properties: {
                  ...nfz.properties,
                  zoom: true
                }
              })}
              selectedNfzId={selectedNfz?.nfz_id}
              onEditNfz={nfz => {
                setEditNfz(nfz)
                setMode('edit')
              }}
              onDeleteNfz={() => setShowDelete(true)}
              onAddNfz={() => setMode('create')}
            />
          </div>
          {selectedNfz && <div className={classes.airspaceDetailsPanelContainer}>
            <AirspaceDetailsPanel nfz={selectedNfz} onClose={() => setSelectedNfz(null)}/>
          </div>}
        </>}
        { mode === 'create' && <>
          <TopActionPanel
            actionLabel='Create Airspace'
            onAction={() => setShowCreate(true)}
            onCancel={() => setMode('normal')}
            disabled={!feature}
          />
        </> }
        { mode === 'edit' && <>
          <TopActionPanel
            actionLabel='Confirm Area'
            onAction={() => setShowEdit(true)}
            onCancel={() => setMode('normal')}
            disabled={!feature}
          />
        </> }
        { ['create', 'edit'].includes(mode) && <DmsPanel
          vertices={vertices}
          setVertices={onSetVertices}
          disableAdd={editHandler?.enabled() && feature?.isCircle}
        />}
      </div>
      <CreateDraftAreaDialog
        open={showCreate}
        onClose={() => setShowCreate(false)}
        feature={feature}
        onSuccess={() => {
          setShowCreate(false)
          setMode('normal')
        }} />
      { mode === 'edit' && editNfz &&
        <EditDraftAreaDialog
          open={showEdit}
          onClose={() => setShowEdit(false)}
          nfz={editNfz}
          feature={feature}
          onSuccess={() => {
            setShowEdit(false)
            setMode('normal')
          }}
        />
      }
      <DeleteDraftAreaDialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        nfz={selectedNfz}
        onSuccess={() => {
          setShowDelete(false)
          setSelectedNfz(null)
        }}
      />
    </div>
  )
}

export default withRouter(withStyles(styles)(Airspace))
