
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  status: ({ online, appearOnMap, fake }) => ({
    width:  theme.spacing(2),
    height: theme.spacing(2),
    border: 'transparent',
    borderRadius: theme.spacing(4),
    backgroundColor: online ?
      (appearOnMap ?
        (fake ?
          theme.palette.tracker.limeGreen :
          theme.palette.tracker.green) :
        theme.palette.tracker.yellow) :
      theme.palette.tracker.red,
  }),
})

function Status({ classes, online, appearOnMap }) {
  return <div className={classes.status} />
}

export default withStyles(styles)(Status)
