import { useState } from 'react'
import { useLocation } from 'react-router'
import ExternalLink from './ExternalLink'
import userManager from '../utils/user-manager'

import DemoCard from '../Dashboard/plugins/demo/DemoCard'
import BaseMap  from '../Dashboard/BaseMap'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: theme.spacing(8),
    zIndex: 2,
    backgroundColor: '#FFFFFFBB',
  },
  loginButton: {
    borderRadius: theme.spacing(1),
    height:       theme.spacing(5),
    paddingLeft:  theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginRight:  theme.spacing(2),
    backgroundColor: '#e93c2c',
  },
  introText: {
    position: 'absolute',
    maxWidth: '90%',
    bottom: 0,
    left: 0,
    zIndex: 1,
    color: theme.palette.common.white,
    backgroundColor: '#00000099',
    marginBottom: theme.spacing(3),
    padding:      theme.spacing(1, 4, 1, 5),
    border: '2px transparent',
    borderRadius: '0 32px 32px 0',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    letterSpacing: '4px',
    fontFamily: 'monospace',
    fontWeight: 'bold'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    padding: theme.spacing(0, 5),
    color: theme.palette.common.black,

  },
})

const Login = ({ classes, user, router }) => {
  const [ selectedTrackerId, setSelectedTrackerId ] = useState('')
  const [ showCard, setShowCard ] = useState(false)
  const [ cardData, setCardData ] = useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  function handleClose() {
    setShowCard(false)
    setCardData(null)
    setSelectedTrackerId('')
  }

  function handleTrackMarkerClicked(track) {
    setShowCard(true)
    setCardData(track)
    setSelectedTrackerId(track.ua_id)
  }

  const showOnMap = {
    'space-static-nfz': true,
    'space-temp-nfz'  : true,
    'plugins-demo'    : true,
  }
  if (showCard)
    showOnMap['tracker-card'] = true

  return (
    <div>
      <BaseMap
        fullWidth
        hideMapControls
        showOnMap={showOnMap}
        selectedTrackerId={selectedTrackerId}
        onTrackMarkerClicked={handleTrackMarkerClicked}
      />
      <DemoCard
        data={cardData}
        showCard={showCard}
        onClose={handleClose}
      />
      <div className={classes.header}>
        <div style={{ flexGrow: 1, paddingLeft: 48 }}>
          <img src='/mydronefleets-logo.png' width='200' alt='logo' />
        </div>
        <Button
          onClick={(event) => {
            event.preventDefault()
            userManager.signinRedirect({ state: query.get('orig_url') })
          }}
          variant='contained'
          color='primary'
          className={classes.loginButton}>
          Login
        </Button>
      </div>
      <div className={classes.introText}>
        <Typography variant='h4'>
          Airspace Visualizer
        </Typography>
        <Typography variant='body2' className={classes.subtitle}>
          COMMON OPERATING PICTURE
        </Typography>
        <Typography variant='body2'>
          <b>Important</b>: The tracks on this screen are simulated.
          Login to see your drones and trackers.
        </Typography>
      </div>
      <div className={classes.footer}>
        <Typography variant='caption'>
          Garuda Robotics &copy; {new Date().getFullYear()} | <ExternalLink
            href='https://garuda.io/terms'>Terms</ExternalLink> | <ExternalLink
            href='https://garuda.io/privacy'>Privacy</ExternalLink>
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(Login)
