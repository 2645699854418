import { useLazyGetUsersQuery } from '../../../api/accounts'
import TextBox from '../../../components/Inputs/TextBox'

function PilotSelect({ company_ids, pilots, setPilots, error }) {

  const [ getUsers ] = useLazyGetUsersQuery()

  // TODO: Group by Companies
  // TODOTODO: Filter by Companies (DTS Admin often work against just one
  //           or handful of companies at a time)
  return (
    <TextBox
      multiple
      get={() => getUsers({ company_ids, status: 'active' })}
      getKey='users'
      dataId='user_id'
      dataLabel='name'
      value={pilots}
      error={!!error}
      helperText={error}
      onChange={pilots => setPilots(pilots)}
      findDefaultValue={true}
    />
  )
}

export default PilotSelect
