import {
  Typography,
} from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.dark,
    border: 'transparent',
    width: theme.spacing(36),
    height: theme.spacing(6),
    padding: theme.spacing(0, 2),
    cursor: 'pointer',
  },
  open: {
    borderRadius: '16px 16px 0 0',
  },
  close: {
    borderRadius: '16px',
  },
})

function ActiveHeader({ classes, open, onToggle }) {

  return (
    <div onClick={onToggle}
      className={classes.header + ' ' + (open ? classes.open : classes.close)}>
      <Typography variant='body1'>
        Flight Control Panel
      </Typography>
      { open ? <ExpandLess /> : <ExpandMore /> }
    </div>
  )
}

export default withStyles(styles)(ActiveHeader)
