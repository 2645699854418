import { useState, useMemo, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useCreateNFZMutation } from '../../api/airspace'
import { useGetMyCompanyQuery } from '../../api/accounts'
import { meterToFeet2dp } from '../../utils'
import TopXCloseButton from '../../components/TopXCloseButton'
import { StyledDateTimePicker } from '../../components/StyledPickers'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

function CreateAirspaceDialog({ open, onClose, feature, onSuccess }) {
  const [ name, setName ] = useState('Unnamed Zone')
  const [ description, setDescription ] = useState('')
  const [ altitudeMin, setAltitudeMin ] = useState(0)
  const [ altitudeMax, setAltitudeMax ] = useState(120)
  const [ altitudeRef, setAltitudeRef ] = useState('WGS84')
  const [ validity, setValidity ] = useState(null)
  const [ createNfz, { isLoading } ] = useCreateNFZMutation()
  const { data: companyData } = useGetMyCompanyQuery()
  const myCompany = companyData?.data?.company || {}

  const handleCreate = () => createNfz({
    type: validity ? 'temp' : 'static',
    restriction: 'ready-to-use',
    name: name.trim(),
    description: description.trim(),
    geometry: feature.geometry,
    altitude: parseInt(altitudeMax),
    min_altitude: parseInt(altitudeMin),
    altitude_reference: altitudeRef,
    data_provider: {
      name: 'MyDroneFleets Airspace Editor',
    },
    owner: {
      name: myCompany.name,
      company_id: myCompany.company_id
    },
    ...(validity && { validity: [validity] })
  }).then(result => {
    if (result.data.status !== 'success') throw Error(JSON.stringify(result.data))
    toast.success(`Ready to use airspace '${name}' created`)
    onSuccess()
  }).catch(e => {
    toast.error(`Failed to create ready to use airspace`)
    console.error(e.message)
  })

  const hasError = useMemo(() => validity && validity.start > validity.end, [validity])

  function strMeterToFeet(str) {
    const alt = parseFloat(str)
    return !isNaN(alt) ? meterToFeet2dp(alt) : '-'
  }

  const [ nameErr, setNameErr ] = useState('')
  const [ altitudeMinErr, setAltitudeMinErr ] = useState('')
  const [ altitudeMaxErr, setAltitudeMaxErr ] = useState('')

  const validate = useCallback(() => {
    setNameErr('')
    setAltitudeMinErr('')
    setAltitudeMaxErr('')
    let hasError = false
    if (!name) {
      hasError = true
      setNameErr('Name is required.')
    }
    if (isNaN(parseFloat(altitudeMin))) {
      hasError = true
      setAltitudeMinErr('Min Altitude is required.')
    }
    if (isNaN(parseFloat(altitudeMax))) {
      hasError = true
      setAltitudeMaxErr('Max Altitude is required.')
    }
    if (!hasError && parseFloat(altitudeMin) > parseFloat(altitudeMax)) {
      hasError = true
      setAltitudeMinErr('Min Altitude must be below Max Altitude.')
    }
    return !hasError
  }, [name, altitudeMin, altitudeMax])

  useEffect(() => {
    validate()
  }, [validate, name, altitudeMin, altitudeMax])

  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth='xs'
    fullWidth>
    <TopXCloseButton onClick={onClose} />
    <DialogTitle>Create Ready To Use Airspace</DialogTitle>
    <DialogContent>
      <TextField
        label='Name'
        fullWidth
        variant='filled'
        size='small'
        value={name}
        onChange={e => setName(e.target.value)}
        error={!!nameErr}
        helperText={nameErr}
      />
      <Box height={10}/>
      <TextField
        label='Description (optional)'
        fullWidth
        variant='filled'
        size='small'
        multiline
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <Box height={10}/>
      <FormLabel>Altitude</FormLabel>
      <RadioGroup row value={altitudeRef}>
        <FormControlLabel
          value='AGL'
          control={<Radio />}
          label='AGL'
          onClick={() => setAltitudeRef('AGL')}
        />
        <FormControlLabel
          value='WGS84'
          control={<Radio />}
          label='AMSL'
          onClick={() => setAltitudeRef('WGS84')}
        />
      </RadioGroup>
      <Box display='flex'>
        <TextField
          label='Min'
          variant='filled'
          type='number'
          size='small'
          value={altitudeMin}
          onChange={e => setAltitudeMin(e.target.value)}
          error={!!altitudeMinErr}
          InputProps={{
            endAdornment: <InputAdornment position='start'>m, {strMeterToFeet(altitudeMin)}ft</InputAdornment>
          }}
          helperText={altitudeMinErr}
        />
        <Box width={20} />
        <TextField
          label='Max'
          variant='filled'
          type='number'
          size='small'
          value={altitudeMax}
          onChange={e => setAltitudeMax(e.target.value)}
          error={!!altitudeMaxErr}
          InputProps={{
            endAdornment: <InputAdornment position='start'>m, {strMeterToFeet(altitudeMax)}ft</InputAdornment>
          }}
          helperText={altitudeMaxErr}
        />
      </Box>
      <Box height={10}/>
      <FormLabel>Validity Period</FormLabel>
      <RadioGroup row>
        <FormControlLabel
          checked={!validity}
          control={<Radio />}
          label='Permanent'
          onClick={() => setValidity(null)}
        />
        <FormControlLabel
          checked={!!validity}
          control={<Radio />}
          label='Custom Range'
          onClick={() => setValidity({
            start: Date.now() / 1000,
            end: Date.now() / 1000 + 3600
          })}
        />
      </RadioGroup>
      { validity && <>
        <StyledDateTimePicker
          value={new Date(validity.start * 1000)}
          onChange={d => setValidity(v => {
            const start = Math.trunc(d.getTime() / 1000)
            return {
              ...v,
              start,
              ...(start > v.end && { end: start + 3600 })
            }
          })}
          label='Start'
        />
        <Box height={10}/>
        <StyledDateTimePicker
          value={new Date(validity.end * 1000)}
          minDate={new Date(validity.start * 1000)}
          onChange={d => setValidity(v => ({
            ...v,
            end: Math.trunc(d.getTime() / 1000)
          }))}
          label='End'
          error={validity.start > validity.end}
          helperText={validity.start > validity.end && 'End time must be after start time' }
        />
      </>}
    </DialogContent>
    <DialogActions>
      <Button
        variant='outlined'
        onClick={onClose}
        disabled={isLoading}>
        Edit Area
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => validate() && handleCreate()}
        disabled={isLoading || hasError}>
        Create
      </Button>
    </DialogActions>
  </Dialog>
}

export default CreateAirspaceDialog
