import { useState } from 'react'
import L from 'leaflet'
import { useMapEvents, Marker, Popup, CircleMarker } from 'react-leaflet'
import shipSvg from '../../../assets/map/ais/container-ship.svg'
import theme from '../../../utils/site-theme'

// There's roughly 1000 ships
function VesselMarker({ vesselGeoJson }) {
  const coords = vesselGeoJson.geometry.coordinates // lng, lat
  const center = [coords[1], coords[0]]

  const [ show, setShow ] = useState(false)

  const map = useMapEvents({
    moveend() {
      setShow(map.getZoom() >= 15 && map.getBounds().contains(center))
    },
  })

  if (!show)
    return null

  const shipIcon = new L.icon({
    iconUrl:  shipSvg,
    iconSize: [25, 25],
  })
  return (<>
    <CircleMarker
      radius={25}
      center={center}
      pathOptions={{
        color: theme.palette.map.yellow,
        opacity: 1,
        weight: 2,
        fill: true,
        fillOpacity: 0.5,
      }}
    />
    <Marker
      position={center}
      icon={shipIcon}>
      <Popup>
        Ops Restriction: Vessel<br />
        <b>{vesselGeoJson.name}</b>
      </Popup>
    </Marker>
  </>)
}

export default VesselMarker
