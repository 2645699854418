import { useEffect, useMemo } from 'react'
import { useMap } from 'react-leaflet'
import {
  CircleMarker,
  Popup,
} from 'react-leaflet'

const startOptions = { color: 'lime'}
const endOptions   = { color: 'red' }

function TrackMarker({ track, start, end }) {
  const map = useMap()
  const position = useMemo(() => {
    return track ? [parseFloat(track.lat), parseFloat(track.lng)] : null
  }, [track])

  useEffect(() => {
    if (map && position && start)
      map.flyTo(position)
  }, [map, position, start])

  if (!track)
    return null

  return (
    <CircleMarker
      center={position}
      pathOptions={start ? startOptions : end ? endOptions : {}}
      radius={start ? 26 : end ? 20 : 6}>
      <Popup>
        Lat: {track.lat}
        <br />
        Lng: {track.lng}
        <br />
        Alt: {track.alt}
        <br />
        Speed: {track.v}
        <br />
        Acceleration: {track.a}
        <br />
        HDOP: {track.hdop}
        <br />
        VDOP: {track.vdop}
        <br />
        Yaw: {track.yaw}
        <br />
        Pitch: {track.pit}
        <br />
        Roll: {track.rol}
        <br />
        Battery: {track.bat}
        <br />
        RSSI: {track.rssi}
        <br />
      </Popup>
    </CircleMarker>
  )
}

export default TrackMarker
