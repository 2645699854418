import { useState, useEffect} from 'react'
import {
  TextField,
  IconButton,
} from '@material-ui/core'
import {
  Check,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import usePermissions from '../../../components/usePermissions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  nameContainer: {
    width: '100%',
    marginRight: theme.spacing(8),
  },
  name: {
    fontSize: theme.spacing(6),
    fontWeight: 'bolder',
  },
  check: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(4),
  }
})

function DroneName({ classes, name, onSave }) {
  const [ _name,     setName      ] = useState('')
  const [ editCheck, setEditCheck ] = useState(false) // for editing name
  const canModifyDrones = usePermissions('drone-operation-write')

  useEffect(() => {
    if (name)
      setName(name)
  }, [name])

  function handleSave() {
    onSave({ name: _name })
  }

  return (
    <div className={classes.root}>
      <div className={classes.nameContainer}>
        {canModifyDrones ? <TextField
          value={_name}
          fullWidth
          InputProps={{ classes: { input: classes.name } }}
          onChange={e => { 
            setName(e.target.value)
            setEditCheck(true)
          }}
        />
        : <div className={classes.name}>{_name}</div>}
      </div>
      <div className={classes.check}>
        <IconButton
          style={{ visibility: editCheck ? 'visible' : 'hidden' }}
          onClick={handleSave}>
          <Check className='checkIconBtn'/>
        </IconButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(DroneName)
