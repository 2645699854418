import { useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import isEqual from 'fast-deep-equal'
// Note: react-leaflet-draw, though a nice wrapper, required React 18 now
//       To be revised when upgrading to React 18 & MUI v5
//       Now, learn to integrate React env and Draw Control via
// https://github.com/alex3165/react-leaflet-draw/blob/master/src/EditControl.js
import L from 'leaflet'
import Draw from 'leaflet-draw'  // eslint-disable-line
import 'leaflet-draw/dist/leaflet.draw.css'
import {
  useLeafletContext,
} from '@react-leaflet/core'


const eventHandlers = {
  onEdited:        'draw:edited',
  onDrawStart:     'draw:drawstart',
  onDrawStop:      'draw:drawstop',
  onDrawVertex:    'draw:drawvertex',
  onEditStart:     'draw:editstart',
  onEditMove:      'draw:editmove',
  onEditResize:    'draw:editresize',
  onEditVertex:    'draw:editvertex',
  onEditStop:      'draw:editstop',
  onDeleted:       'draw:deleted',
  onDeleteStart:   'draw:deletestart',
  onDeleteStop:    'draw:deletestop',
  onToolbarClosed: 'draw:toolbarclosed'
}

//
// Usage: https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html
//
function EditorToolbar(props) {
  const context = useLeafletContext()
  const drawRef = useRef()
  const propsRef = useRef(props)

  function onDrawCreate(e) {
    const { onCreated } = props
    const container = context.layerContainer || context.map
    container.addLayer(e.layer)
    onCreated && onCreated(e)
  }

  useEffect(() => {
    const { map } = context
    const { onMounted } = props
    for (const key in eventHandlers) {
      map.on(eventHandlers[key], (evt) => {
        let handlers = Object.keys(eventHandlers).filter(
          (handler) => eventHandlers[handler] === evt.type
        )
        if (handlers.length === 1) {
          let handler = handlers[0]
          props[handler] && props[handler](evt)
        }
      })
    }
    map.on(L.Draw.Event.CREATED, onDrawCreate)
    drawRef.current = createDrawElement(props, context)
    map.addControl(drawRef.current)
    onMounted && onMounted(drawRef.current)

    return () => {
      map.off(L.Draw.Event.CREATED, onDrawCreate)
      for (const key in eventHandlers) {
        if (props[key]) {
          map.off(eventHandlers[key], props[key])
        }
      }
      drawRef.current.remove(map)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isEqual(props.draw, propsRef.current.draw) &&
      isEqual(props.edit, propsRef.current.edit) &&
      props.position === propsRef.current.position)
      return

    const { map } = context

    drawRef.current.remove(map)
    drawRef.current = createDrawElement(props, context)
    drawRef.current.addTo(map)

    const { onMounted } = props
    onMounted && onMounted(drawRef.current)

    return () => {
      drawRef.current.remove(map)
    }
    // eslint-disable-next-line
  }, [props.draw, props.edit, props.position])

  return null
}

function createDrawElement(props, context) {
  const { layerContainer } = context
  const { draw, edit, position } = props
  const options = {
    edit: {
      ...edit,
      featureGroup: layerContainer,
    },
  }

  if (draw) {
    options.draw = { ...draw }
  }

  if (position) {
    options.position = position
  }

  return new L.Control.Draw(options)
}

EditorToolbar.propTypes = {
  ...Object.keys(eventHandlers).reduce((acc, val) => {
    acc[val] = PropTypes.func
    return acc
  }, {}),
  onCreated: PropTypes.func,
  onMounted: PropTypes.func,
  draw: PropTypes.shape({
    polyline: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    polygon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    rectangle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    circle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    marker: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  }),
  edit: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    remove: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    poly: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    allowIntersection: PropTypes.bool,
  }),
  position: PropTypes.oneOf([
    'topright',
    'topleft',
    'bottomright',
    'bottomleft',
  ]),
  leaflet: PropTypes.shape({
    map: PropTypes.instanceOf(Map),
    layerContainer: PropTypes.shape({
      addLayer: PropTypes.func.isRequired,
      removeLayer: PropTypes.func.isRequired,
    }),
  }),
}

export default EditorToolbar