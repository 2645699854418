import { useEffect, useState } from 'react'
import {
  useCreateDroneModelMutation,
  useLazyGetDroneModelsQuery,
} from '../../../api/aircraft'
import TextBox from '../../../components/Inputs/TextBox'
import CustomDroneModel from './CustomDroneModel'
import {
  Button,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  EditOutlined,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import usePermissions from '../../../components/usePermissions'
import { toast } from 'react-toastify'

const styles = theme => ({
  root: {
    position: 'relative',
  },
  editBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
  }
})

function DroneModel({ classes, droneModel, onSave }) {
  const [ getDroneModels      ] = useLazyGetDroneModelsQuery()
  const [ droneModels, setDroneModels ] = useState(null)
  const [ editing, setEditing ] = useState(false)
  const [ _model,  setModel   ] = useState(droneModel)
  const [ custom,  setCustom  ] = useState(null)
  const [ createDroneModel ] = useCreateDroneModelMutation()
  const canModifyDrones = usePermissions('drone-operation-write')

  useEffect(() => {
    // load drone models
    getDroneModels()
    .unwrap()
    .then(fulfilled => {
      if (fulfilled.status === 'success')
        setDroneModels(fulfilled.data.drone_models)
      else
        console.log(fulfilled)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!droneModels || !editing) {
      setCustom(null)
      return
    }
    
    // check if drone name exists in drone models
    const names = droneModels.map(model => model.name)
    if (!names.includes(_model?.name))
      setCustom(_model)
    else
      setCustom(null)
  }, [_model, droneModels, editing])

  if (!droneModel)
    return null

  async function handleSave() {
    if (custom) {
      const customModel = await createDroneModel({
        ...custom,
        custom: {
          source_drone_model_id: droneModel.drone_model_id
        }
      }).then(result => {
        if (result?.data?.status !== 'success') throw result
        return result.data.data.drone_model
      }).catch(e => {
        console.info(e)
        toast.error('Failed to create custom drone model')
        return null
      })
      if (!customModel) return
      onSave({ drone_model_id: customModel.drone_model_id })
    } else {
      onSave({ drone_model_id: _model.drone_model_id })
    }
    setEditing(false)
  }

  return (
    <div className={classes.root}>
      { !editing && canModifyDrones &&
        <div className={classes.editBtn}>
          <IconButton onClick={e => { setEditing(true) }}>
            <EditOutlined />
          </IconButton>
        </div>
      }
      { editing && !custom && <>
        <Typography gutterBottom>Change drone model to:</Typography>
        <TextBox
          get={() => getDroneModels({ providers: true })}
          getKey='drone_models'
          dataId='drone_models_id'
          dataLabel='name'
          value={_model}
          // error={!!error}
          // helperText={error}
          onChange={setModel}
          onCreate={(value) => { setCustom({ name: value }) }}
        />
        <br />
      </> }
      { editing && custom &&
        <CustomDroneModel
          droneModel={_model}
          customDroneModel={custom}
          setCustomDroneModel={setCustom}
        />
      }
      { _model && !custom &&
        <Grid container>
          <Grid item xs={4}>
            Name
          </Grid>
          <Grid item xs={8}>
            {_model.name || '-'}
          </Grid>
          <Grid item xs={4}>
            Category
          </Grid>
          <Grid item xs={8}>
            {_model.category || '-'}
          </Grid>
          <Grid item xs={4}>
            Weight
          </Grid>
          <Grid item xs={8}>
            {_model.weight || '-'} kg
          </Grid>
          <Grid item xs={4}>
            Dimensions
          </Grid>
          <Grid item xs={8}>
            {_model.properties?.dimensions?.width || '-'} mm &times;
            {_model.properties?.dimensions?.length || '-'}mm &times;
            {_model.properties?.dimensions?.height || '-'}mm (W &times; L
            &times; H)
          </Grid>
          <Grid item xs={4}>
            Max Flight Time
          </Grid>
          <Grid item xs={8}>
            {_model.properties?.max_flight_time || '-'} minutes
          </Grid>
          <Grid item xs={4}>
            RC Frequency
          </Grid>
          <Grid item xs={8}>
            {_model.properties?.rc_frequency || '-'}
          </Grid>
        </Grid>
      }
      { editing &&
        <div className={classes.saveBtn}>
          <Button variant='outlined' onClick={e => { setEditing(false) }}>
            Cancel
          </Button>
          <Button variant='contained' color='secondary' onClick={handleSave}>
            Save
          </Button>
        </div>
      }
    </div>
  )
}

export default withStyles(styles)(DroneModel)
