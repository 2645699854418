

import { useCallback, useEffect, useRef, useState } from 'react'
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { GeomanControls } from 'react-leaflet-geoman-v2'
import * as turf from '@turf/turf'
import {
  useMap,
  FeatureGroup,
  GeoJSON
} from 'react-leaflet'
import { toast } from 'react-toastify'
import { usePrevious } from '../../../utils/hooks'
import theme from '../../../utils/site-theme'

function EditFeatureGroupGeoman({
  geometry,
  onSave,
  templatePolygons,
  featureStyle,
  setSelectingTemplate
}) {
  const map = useMap()
  const featureGroupRef = useRef(null)
  const [ showTemplates, setShowTemplates ] = useState(false)
  const prevGeometry = usePrevious(geometry)
  useEffect(()=>{
    if(setSelectingTemplate)
    setSelectingTemplate(showTemplates)
  }, [showTemplates, setSelectingTemplate])
  const keepLastLayer = useCallback(() => {
    const fgroup = featureGroupRef.current

    if (fgroup) {
      const layers = fgroup.getLayers()
      map?.pm?.addControls({ editControls: layers.length > 0 })
      if (layers.length === 0) return
      
      // Short term hack: we know there are 2 polygons in the FG if a new one is drawn
      // or just 1 polygon if nothing existed. We only want the last one to survive.
      for (let i = 0; i < layers.length - 1; i++)
        fgroup.removeLayer(layers[i])
    
      const layer = fgroup.getLayers()[0]
      layer.options.pmIgnore = false
      L.PM.reInitLayer(layer)
      layer.bringToFront()
    }
  }, [featureGroupRef, map])

  const loadPolygon = useCallback((polygon) => {
    const fgroup = featureGroupRef.current
    if (!fgroup) return
    L.geoJSON(polygon, {
      style: featureStyle,
      onEachFeature: (feature, layer) => {
        layer.options.pmIgnore = false
        L.PM.reInitLayer(layer)
      },
    }).invoke('addTo', fgroup)
    keepLastLayer()
  }, [featureGroupRef, featureStyle, keepLastLayer])

  const onButtonClick = useCallback((e) => {
    setShowTemplates(false)
    if(e.btnName === 'custom_save'){
      const layer = featureGroupRef.current.getLayers()[0]
      let feature = layer.toGeoJSON()
      if(feature?.type === 'FeatureCollection') {
        feature = feature.features[0]
      }
      // convert circle drawing to polygon
      if (feature?.geometry?.type === 'Point') {
        const bufferedFeature = turf.buffer(feature, layer.getRadius() / 1000, { unit: 'kilometers' })
        return onSave(bufferedFeature.geometry)
      }
      onSave(feature.geometry)
    } else if (e.btnName === 'custom_load') {
      setShowTemplates(!e.button.toggleStatus)
    }
  }, [featureGroupRef, onSave])

  const onActionClick = useCallback((e) => {
    if (e.btnName === 'custom_load') {
      setShowTemplates(false)
    }
  }, [])

  useEffect(() => {
    L.PM.setOptIn(true)
    if (!(map?.pm)) return
    if (!map.pm.Toolbar.getButtons()['custom_load']) {
      // TODO: find a suitable icon
      map.pm.Toolbar.createCustomControl({
        name: 'custom_load',
        title: 'Load template',
        block: 'custom',
        toggle: true,
        className: 'custom-load-icon-leaflet',
        disableOtherButtons: true,
        disabled: false,
        actions: ['cancel']
      })
    }
    if (!map.pm.Toolbar.getButtons()['custom_save']) {
      map.pm.Toolbar.createCustomControl({
        name: 'custom_save',
        block: 'custom',
        className: 'custom-save-icon-leaflet',
        title: 'Save changes',
        toggle: false,
        disableOtherButtons: false,
        disableByOtherButtons: false,
        disabled: false
      })
    }
    if(map?.pm)
      map.pm.addControls({ customControls: true, editControls: false })

    return () => {
      try {
        if (map?.pm?.globalCutModeEnabled())
          map.pm.disableGlobalCutMode()
        if (map?.pm?.globalDragModeEnabled())
          map.pm.disableGlobalDragMode()
        if (map?.pm?.globalEditModeEnabled())
          map.pm.disableGlobalEditMode()
        if (map?.pm?.globalRemovalModeEnabled())
          map.pm.disableGlobalRemovalMode()
        if (map?.pm?.globalRotateModeEnabled())
          map.pm.disableGlobalRotateMode()
        if (map?.pm) {
          map.pm.addControls({ customControls: false })
          map.pm.removeControls()
        }
      } catch(e) {
        console.log('ignoring unmounting error', e)
      }
    }
  }, [map])
  useEffect(() => { 
    if(prevGeometry && !geometry){
      let fgroup = featureGroupRef.current
      const layers = fgroup.getLayers()
      for(let i = 0; i  < layers.length; i++){
        fgroup.removeLayer(layers[i])
      }
    }
    geometry && loadPolygon(geometry) 
  }, [geometry, loadPolygon, prevGeometry])

  useEffect(() => {
    if (map?.pm)
      map.pm.addControls({ custom_load: !!templatePolygons })
  }, [map, templatePolygons])

  const onFeaturesChanged = (e, a) => {
    if (!e.enabled) {
      keepLastLayer()
    }
  }
  
  return (<>
    <FeatureGroup ref={featureGroupRef}>
      <GeomanControls
        options={{
          position: 'topleft',
          editPolygon: true,
          drawCircle: true,
          drawPolyline: false,
          drawCircleMarker: false,
          drawText: false,
          drawMarker: false,
          rotateMode: false
        }}
        onDrawEnd={onFeaturesChanged}
        onGlobalDrawModeToggled={onFeaturesChanged}
        onGlobalEditModeToggled={onFeaturesChanged}
        onGlobalDragModeToggled={onFeaturesChanged}
        onGlobalCutModeToggled={onFeaturesChanged}
        onGlobalRemovalModeToggled={onFeaturesChanged}
        onGlobalRotateModeToggled={onFeaturesChanged}
        onButtonClick={onButtonClick}
        onActionClick={onActionClick}
      />
    </FeatureGroup>
    <FeatureGroup>
      {showTemplates && templatePolygons?.map(f => 
        <GeoJSON
          key={f.nfz_id}
          onEachFeature={(feature, layer) => {
            layer.on('click', () => {
              if(templatePolygons){
                toast.success(`Selected ready to use polygon. Edit or make changes on the existing polygon.`)
              }
              loadPolygon(feature)
              // Hack to cancel load template
              map.pm.enableDraw('Line')
              map.pm.disableDraw('Line')
              setShowTemplates(false)
            })
          }}
          style={{color: theme.palette.map.green}}
          data={f.geometry}
        />)}
    </FeatureGroup>
  </>)
}

export default (EditFeatureGroupGeoman)