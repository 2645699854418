import { format } from 'date-fns'
import { commonFormat, prettyDuration } from '../../utils'
import { useGetFlightByIdQuery } from '../../api/aircraft'

function Flight({ id, drone, pilots, date, location, duration, permits, ...rest }) {

  const { data, error, isLoading } = useGetFlightByIdQuery(id, {
    skip: !id
  })

  if (!id || isLoading)
    return ''
  if (error)
    return '(Unavailable Flight)'
  if (data?.status !== 'success')
    return '(Invalid Flight)'

  const flight = data?.data?.flight || {}
  return <span {...rest}>
    Flight
    { drone    && ` of ${flight.drone?.name || 'unknown drone'}` }
    { pilots   && ` by ${flight.pilots?.map(p => p.name).join(', ') || 'unknown pilots'}` }
    { date     && ` on ${flight.date ? format(new Date(flight.date), commonFormat) : 'unknown date'}` }
    { location && ` at ${flight.location || 'unknown location'}` }
    { duration && ` (duration: ${prettyDuration(flight.duration) || 'unknown duration'})` }
    { permits  && ` with permits from ${flight.permits?.map(p => `${p.authority} (${p.ref})`).join(', ') || 'unknown authority'}`}
  </span>
}

export default Flight
