import { useState, useEffect, useMemo } from 'react'
import { useMap } from 'react-leaflet'
import { getDecodedAccessToken, isController } from '../../../api/accounts'
import { useGetPrivateNFZsQuery } from '../../../api/airspace'
import useNfzValidity from '../../../components/useNfzValidity'
import RsafEditFeatureGroup from './RsafEditFeatureGroup'
import RsafCreateGeofenceDialog from './RsafCreateGeofenceDialog'

function RsafMapComponents({ classes, selectedRsafGeofenceId, setSelectingTemplate }) {
  const company_id = useMemo(() => getDecodedAccessToken().company_id, [])
  const [ geometry, setGeometry ] = useState(null)  // the geometry of the geofence
  const [ open,     setOpen     ] = useState(false)

  const [ nfzValidity ] = useNfzValidity('RsafMapComponents')
  const { data: nfzData } = useGetPrivateNFZsQuery({
    company_ids:    company_id,
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
    restriction:    ['operation-restriction', 'ready-to-use'],
  })
// console.log('RsafMapComponents nfzData', nfzData)

  const map = useMap()
  useEffect(() => {
    if (map && nfzData && selectedRsafGeofenceId) {
      const geofence = nfzData.entities[selectedRsafGeofenceId]
      if (!geofence)
        return console.log('invalid geofence nfz_id', selectedRsafGeofenceId)
      const coords = geofence.geometry.coordinates
      const latLngBounds = coords.map(coord => coord.map(c => [ c[1], c[0] ]))
      map.flyToBounds(latLngBounds)
    }
  }, [map, nfzData, selectedRsafGeofenceId])

  if (!isController())
    return null

  function handleCreateGeofence(geom) {
    console.log('handleCreateGeofence', geom)
    setGeometry(geom)
    setOpen(true)
  }
  // function handleEditGeofence(geom) {
  //   console.log('handleEditGeofence', geom)
  //   // TODO: delete the old geometry?
  //   setGeometry(geom)
  // }
  // function handleDeleteGeofence(geom) {
  //   console.log('handleDeleteGeofence', geom)
  //   setGeometry(null)
  // }

  return (<>
    <RsafEditFeatureGroup
      geometry={geometry}
      templatePolygons={nfzData && Object.values(nfzData.entities).filter(n => n.restriction === 'ready-to-use')}
      onSave={geometry => {
        if (!geometry) return
        handleCreateGeofence(geometry)
      }}
      setSelectingTemplate={setSelectingTemplate}
      // onEdited={handleEditGeofence}
      // onDeleted={handleDeleteGeofence}
    />
    { open &&
      <RsafCreateGeofenceDialog
        open={open}
        onClose={() => setOpen(false)}
        geometry={geometry}
        onSuccess={() => setGeometry(null)}
      />
    }
  </>)
}

export default RsafMapComponents
