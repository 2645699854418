import { useState } from 'react'

import CfmsControlPanelViolationDialog from './CfmsControlPanelViolationDialog'

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  Assignment,
  Battery30,
  Error,
  Flight,
  Gavel,
  Help,
  Info,
  Layers,
  Nature,
  Security,
  ThumbUp,
  TrendingDown,
  TrendingUp,
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  text: {
    color: theme.palette.common.white
  },
  subtitle: {
    paddingLeft: theme.spacing(2),
    paddingTop:  theme.spacing(2),
  }
})

function CfmsControlPanelViolationsList({ classes, showViolation, violations }) {

  const [ open, setOpen ] = useState(false)
  const [ selectedViolation, setSelectedViolation ] = useState(null)

  if (!showViolation)
    return null

  if (!violations || violations.length === 0)
    return <Typography variant='overline' className={classes.subtitle}>
      No Violations <ThumbUp />
    </Typography>

  const violationListItems = violations.map((violation, i) => {
    const avatar = {
      EXIT_GEOFENCE:  <Layers />,
      NONE:           <Help />,
      // Intersection Types
      EMERGENCY_AREA:         <Layers />,
      NO_FLY:                 <Layers />,
      TEMP_NO_FLY:            <Layers />,
      DRONE_COLLISION:        <Layers />,
      HEIGHT_EXCEEDED:        <Nature />,
      PERMIT_EXCEEDED:        <Assignment />,
      PERMIT_HEIGHT_EXCEEDED: <Nature />,
      PERMIT_INVALID:         <Assignment />,
      PERMIT_EXPIRE:          <Assignment />,
      TRACK_DROP:             <TrendingDown />,
      LOW_BATT_LIFE:          <Battery30 />,
      // Area Types
      ACTIVITY_PERMIT: <Assignment />,
      HEIGHT:          <Nature />,
      // EMERGENCY_AREA (duplicate)
      TRACK:           <TrendingUp />,
      PROHIBITED:      <Security />,
      DANGER:          <Security />,
      AERODROME:       <Flight />,
      PROTECTED:       <Security />,
      RESTRICTED:      <Security />,
      TEMP_AREA:       <Layers />,
      // NONE (duplicate)
      // Alert Type
      WARN:   <Warning />,
      BREACH: <Error />,
      NOTIFY: <Info />,
    }
    const typeAvatar  = avatar[violation.violation_type]      || <Gavel />
    const areaAvatar  = avatar[violation.violation_area_type] || <Layers />
    const alertAvatar = avatar[violation.alert_type]          || <Help />

    return (
      <ListItem key={i} onClick={() => {
        setSelectedViolation(violation)
        setOpen(true)}}>
        <ListItemIcon>
          <Tooltip title={violation.alert_type}>
            {alertAvatar}
          </Tooltip>
        </ListItemIcon>
        <ListItemText
          classes={{ secondary: classes.text }}
          primary={
            <Grid container>
              <Grid item xs={12}>{violation.vehicle_info.id}</Grid>
              <Grid item xs={2}>{typeAvatar}</Grid>
              <Grid item xs={10}>{violation.violation_type}</Grid>
              <Grid item xs={2}>{areaAvatar}</Grid>
              <Grid item xs={10}>{violation.violation_area_type}</Grid>
              <Grid item xs={12}>{new Date(violation.start_time).toLocaleString()}</Grid>
            </Grid>
          }
        />
      </ListItem>
    )
  })

  return (<>
    <Typography variant='overline' className={classes.subtitle}>
      Current Violations
    </Typography>
    <List dense>
      {violationListItems}
    </List>
    <CfmsControlPanelViolationDialog 
      open={open}
      violation={selectedViolation}
      onClose={() => setOpen(false)}
    />
  </>)
}

export default withStyles(styles)(CfmsControlPanelViolationsList)
