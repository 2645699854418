import { withStyles } from '@material-ui/core/styles'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import { useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enGB from 'date-fns/locale/en-GB'

const localizer = dateFnsLocalizer({
  format: format,
  parse: parse,
  startOfWeek: startOfWeek,
  getDay: getDay,
  locales: {'en-GB': enGB}
})

const styles = theme => ({
  root: {
    color: 'white',
    marginTop: theme.spacing(1),
  },
  calendar: {
    height: '100vh',
    // TODO figure out how to style the calendar
    color: 'white',
    backgroundColor: '#313347',
    padding: theme.spacing(2),
    '& .rbc-month-view': {
      '& .rbc-off-range-bg': {
        backgroundColor: '#f5f5f5',
        opacity: 0.3,
      },
    },
    '& .rbc-month-header': {
      backgroundColor: 'inherit',
      '& .rbc-btn': {
        color: 'grey',
      },
    },
    '& .rbc-header': {
      backgroundColor: 'inherit',
      '& .rbc-header-label': {
        color: 'grey',
      },
    },
    '& .rbc-btn-group > button:not(.rbc-active):not(:hover):not(:focus)': {
      color: 'white',
    },
    '& .rbc-btn-group .rbc-active, .rbc-btn-group button:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    '& .rbc-btn-group button:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white'
    }
  },
  eventDraft: {
    backgroundColor: '#D3D3D3', // Grey
    color: 'black',
  },
  eventApproved: {
    backgroundColor: '#246FF1', // Blue
  },
  eventPendingApproval: {
    color: 'black',
    backgroundColor: '#4DDBFF', // Light Blue
  },
  eventRejected: {
    backgroundColor: '#000000', // Black
  },
  today: {
    backgroundColor: 'lightblue',
    color: 'black',
  },
})

function CalendarView({
  classes,
  entries,
  entriesToEvent,
  onEventSelected,
  getDayComponent,
  getMonthComponent,
  getWeekComponent,
  getAgendaComponent
}) {
  const [selected, setSelected] = useState()
  if(!entriesToEvent){
    throw Error('entriesToEvent required for CalendarView component')
  }

  const events = entries.map(entriesToEvent)
  
  
  useEffect(function(){
    if(onEventSelected && selected){
      onEventSelected(selected)
    }
  }, [selected, onEventSelected])
  
  const components = {}
  if(getDayComponent){
    components.day = { event : getDayComponent }
  }
  if(getMonthComponent){
    components.month = { event: getMonthComponent }
  }
  if(getWeekComponent){
    components.week = { event: getWeekComponent }
  }
  if(getAgendaComponent){
    components.agenda = { event : getAgendaComponent }
  }

  const getColorByStatus = status => {
    switch (status) {
      case 'draft':
        return classes.eventDraft
      case 'approved':
        return classes.eventApproved
      case 'pending-approval':
        return classes.eventPendingApproval
      case 'rejected':
        return classes.eventRejected
      default:
        return null
    }
  };

  function handleSelectEvent(event) {
    console.log('setting event', event)
    setSelected(event)
  }

  console.log('components', components)

  return (
    <div className={classes.root}>
      <Calendar
        className={classes.calendar}
        localizer={localizer}
        defaultView='week'
        events={events}
        startAccessor='start'
        endAccessor='end'
        eventPropGetter={event => ({
          className: getColorByStatus(event.status),
        })}
        components={{
          month: {
              event: (props) => { 
                return (
                  <div onClick={() => handleSelectEvent(props.event)}>
                    {components.month.event(props)}
                  </div>
                ) 
              }
          },
          week: {
            event: (props) => {
              return (
                <div onClick={() => handleSelectEvent(props.event)}>
                  {components.week.event(props)}
                </div>
              ) 
            }
          },
          day: {
            event: (props) => {
              return (
                <div onClick={() => handleSelectEvent(props.event)}>
                  {components.day.event(props)}
                </div>
              ) 
            }
          },
          agenda: {
            event: (props) => {
              return (
                <div onClick={() => handleSelectEvent(props.event)}>
                  {components.agenda.event(props)}
                </div>
              ) 
            }
          }
        }}
      />
    </div>
  )
}

export default withStyles(styles)(CalendarView)
