import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  panel: {
    height: theme.spacing(6),
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    textAlign: 'center'
  },
  toggleButton: {
    width: theme.spacing(7),
    borderTop: '0',
    borderBottom: '0'
  },
  toggleButtonGroup: {
    '& button:first-child': {
      borderLeft: '0',
    },
    '& button:last-child': {
      borderRight: '0',
    },
  }
})

function TogglePanelControls({ classes, enabled, setEnabled, controls }) {
  const toggle = value => e => {
    const pos = enabled.indexOf(value)
    if (pos < 0)
      setEnabled([...enabled, value])
    else {
      const newShow = enabled.slice()
      newShow.splice(pos, 1)
      setEnabled(newShow)
    }
  }

  return <div className={classes.root}>
    {
      controls.map((group, i) => <div key={i} className={classes.panel}>
        <ToggleButtonGroup className={classes.toggleButtonGroup} value={enabled}>
          {group.map((btn, j) => (
            <ToggleButton key={j}
              className={classes.toggleButton}
              value={btn.value}
              onClick={toggle(btn.value)}>
              <Tooltip arrow title={btn.title}>{btn.icon}</Tooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>)
    }
  </div>
}

export default withStyles(styles)(TogglePanelControls)