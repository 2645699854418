import { useState } from 'react'
import {
  LinearProgress,
  Switch,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
  },
  lbl: {
    textAlign: 'left'
  },
  track: {
    backgroundColor: theme.palette.background.paper
  },
})

function FollowToggle({onToggle, classes, disabled}) {
  const [checked, setChecked] = useState(false)
  const handleToggle = (event) => {
    setChecked(!!event.target.checked)
    onToggle(event.target.checked)
  }

  return (
    <div className={classes.root}>
      { disabled ? <div style={{ width: '100%' }}>
        <Typography variant='body2'>
          Waiting for tracks...
        </Typography>
        <LinearProgress
          color='secondary'
          variant='indeterminate'
          className={classes.progress}
        />
      </div> : <>
        <Typography variant='body1' className={classes.lbl}>
          Follow
        </Typography>
        <Switch
          checked={checked}
          onChange={handleToggle}
          classes={{
            track: classes.track,
          }}
        />
      </> }
    </div>
  )
}

export default withStyles(styles)(FollowToggle)
