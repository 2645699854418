import { useGetTrackerByIdQuery } from '../../api/tracker'
import { trackerName } from '../../utils'

function Tracker({ id, name, imei, imsi, iccid, serial, ...rest}) {

  const { data, error, isLoading } = useGetTrackerByIdQuery(id, {
    skip: !id
  })

  if (!id || isLoading)
    return ''
  if (error)
    return '(Unavailable Tracker)'
  if (data?.status !== 'success')
    return '(Invalid Tracker)'

  const tracker = data?.data?.tracker || {}
  return <span {...rest}>
    { name   && trackerName(tracker) }
    { imei   && ` (IMEI: ${tracker.imei})` }
    { imsi   && ` (IMSI: ${tracker.imsi})` }
    { iccid  && ` (ICCID: ${tracker.iccid})` }
    { serial && ` (S/N: ${tracker.serial_number})` }
  </span>
}

export default Tracker
