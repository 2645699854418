import Media        from '../../components/Media'
import FollowToggle from './FollowToggle'
import InjectButton from './InjectButton'
import Flight       from './Flight'
import Track        from './Track'
import Violation    from './Violation'
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    color: 'black',
    overflow: 'auto',
  },
})

function SingleDrone({ classes, drone, flights, telem, onFollowDroneClick }) {
  if (!drone)
    return null

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={4}>
          <Box display='flex' height='100%' alignItems='center'>
            <Media mediaId={drone.avatar || drone.model?.avatar || ''}
              size='preview'
              style={{ width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center' }}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='h6'>{drone.name}</Typography>
          <Typography variant='caption'>ua_id: {drone.drone_id}</Typography>
          <Divider className={classes.divider} />
          <Flight
            hasAvatar={true}
            flights={flights}
          />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FollowToggle
              onToggle={onFollowDroneClick}
              disabled={!telem}
            />
            <InjectButton droneId={drone.drone_id} />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Track
        telem={telem}
        droneId={drone.drone_id}
      />
      <Divider className={classes.divider} />
      <Violation
        violations={drone.violations}
      />
    </div>
  )
}

export default withStyles(styles)(SingleDrone)
