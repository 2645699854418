import { createContext } from 'react'
import { useGetMySubscriptionsQuery } from '../api/accounts'

export const AccountsContext = createContext({})

function AccountsContextProvider({ children }) {
  const { data, isLoading, error } = useGetMySubscriptionsQuery()

  if (!isLoading) {
    if (error) {
      console.log('Error fetching subscriptions', error)
    }
    else if (data?.status !== 'success') {
      console.log('Failed fetching subscriptions', data)
    }
  }
  const accounts = {
    // TODO: Consolidate accounts into a single React Context here
    subscriptions: data?.data?.subscriptions || []
  }

  return (
    <AccountsContext.Provider value={accounts}>
      {children}
    </AccountsContext.Provider>
  )
}

export default AccountsContextProvider
