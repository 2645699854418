import { Fragment } from 'react'
import { meterToFeet2dp } from '../../utils'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Box,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    top: 8,
    left: 120,
    right: 320,
    zIndex: 2
  }
})

function AirspaceDetailsPanel({ nfz, onClose, classes }) {

  return (
    <div className={classes.root}>
      <Box bgcolor='background.paper' padding={1} borderRadius={10} paddingRight={5}>
        <TopXCloseButton onClick={onClose}/>
        <Box display='flex'>
          <Box padding={1} flexGrow={1} flexBasis={0} flexShrink={1}>
            <Typography variant='body1'><b>{nfz.name}</b></Typography>
            <Typography variant='body2'>{nfz.description}</Typography>
          </Box>
          <Box width='1px' bgcolor='text.primary' margin={1} sx={{opacity: 0.5}}/>
          <Box display='flex' flexGrow={1} flexBasis={0} flexShrink={1}>
            <Box padding={1}>
              <Typography variant='body1'>Altitude ({nfz.altitude_reference === 'AGL' ? 'AGL' : 'AMSL'})</Typography>
              <Box paddingBottom={0.5} paddingLeft={0.2}>
                <Typography variant='body2' noWrap>Min: {nfz.min_altitude}m {meterToFeet2dp(nfz?.min_altitude) ?? '-'} ft</Typography>
                <Typography variant='body2'noWrap>Max: {nfz.altitude}m {meterToFeet2dp(nfz?.altitude) ?? '-'} ft</Typography>
              </Box>
            </Box>
            <Box padding={1}>
              <Typography variant='body1' noWrap>Validity Period</Typography>
              { nfz.type === 'static' ?
                <Typography variant='body2'>Permanent</Typography>
                : nfz.validity.map((v, i) => <Fragment key={i}>
                  <Typography variant='body2'>{new Date(v.start * 1000).toLocaleString()
                  } - {new Date(v.end * 1000).toLocaleString()}</Typography>
                </Fragment>)
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default withStyles(styles)(AirspaceDetailsPanel)