import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Toast() {
  return (
    <ToastContainer
      position='top-right'
      hideProgressBar={true}
      transition={Slide}
      autoClose={6500}
      newestOnTop={true}
    />
  )
}

export default Toast

// import { ToastContainer, Slide } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import { withStyles } from '@material-ui/core/styles'

// const styles = theme => ({
//   dark: {
//     border: 'solid 8px ' + theme.palette.background.default,
//     backgroundColor: theme.palette.background.paper,
//     fontWeight: 'bold',
//   },
// })

// function Toast({ classes }) {
//   return (
//     <ToastContainer
//       position='top-right'
//       theme='dark'
//       toastClassName={classes.dark}
//       hideProgressBar={true}
//       transition={Slide}
//       autoClose={6500}
//       newestOnTop={true}
//     />
//   )
// }

// export default withStyles(styles)(Toast)
