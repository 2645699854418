import JsxMarker from './JsxMarker'
import { droneCategoryToSVG, arrowCategorytoSVG } from '../../utils/svg-icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    position: 'absolute',
    width: '120px',
    height: '120px',
    transform: ({ yaw }) => {
      return `rotate(${yaw}deg)`
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drone: {
    position: 'absolute',
    fill:   ({ iconColor }) => iconColor && iconColor.drone ? iconColor.drone.fill   : 'white',
    stroke: ({ iconColor }) => iconColor && iconColor.drone ? iconColor.drone.stroke : 'black',
  },
  arrow: {
    position: 'absolute',
    fill:   ({ iconColor }) => iconColor && iconColor.arrow ? iconColor.arrow.fill   : 'black',
    stroke: ({ iconColor }) => iconColor && iconColor.arrow ? iconColor.arrow.stroke : 'black',
  }
})


function DroneMarker({ lat, lng, classes, onClick, ...rest }) {

  if (!(lat && lng))
    return null
  return (
    <JsxMarker className={classes.icon} position={{lat, lng}} onClick={onClick} {...rest}>
      <div className={classes.iconContainer}>
        <svg className={classes.drone} width='35px' height='35px' viewBox='0 0 250 250'>
          <path d={droneCategoryToSVG('Quadcopter')}
            strokeWidth='10px'
          />
        </svg>
        <svg className={classes.arrow} width='50px' height='50px' viewBox='0 0 600 600'>
          <path d={arrowCategorytoSVG('Quadcopter')}
            strokeWidth='12px'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </JsxMarker>
  )
}

export default withStyles(styles)(DroneMarker)