import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

export function StyledDateTimePicker(props) {
  return (
    <DateTimePicker
      fullWidth
      inputVariant='filled'
      sx={{
        '& .MuiFormLabel-root': { color: 'white' },
        '& .MuiInputBase-input': { color: 'white' },
        '& .MuiButtonBase-root': { color: 'white' },
      }} // TODO: disabled styling
      format='dd MMM yyyy (eee) hh:mm bbb'
      {...props}
    />
  )
}

export function StyledDatePicker(props) {
  return (
    <DatePicker
      fullWidth
      inputVariant='filled'
      sx={{
        '& .MuiFormLabel-root': { color: 'white' },
        '& .MuiInputBase-input': { color: 'white' },
        '& .MuiButtonBase-root': { color: 'white' },
      }}
      format='dd MMM yyyy'
      {...props}
    />
  )
}
