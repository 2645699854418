import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'

import { useGetElevationQuery } from '../../../api/geospatial'
import { commonFormat, meterToFeet2dp } from '../../../utils'
import { getApprovedGeometry, getValidity } from '../../../utils/frz'
import { frzTypes } from '../../../utils/frz-type'
import { usePrevious } from '../../../utils/hooks'

import NewDrone from '../../../Drones/NewDrone'
import FrzMap from '../../components/FrzMap'
import ZonePicker from './ZonePicker'
import PilotSelect from './PilotSelect'
import DroneSelect from './DroneSelect'
import AltitudeText from './AltitudeText'
import ValidityPicker from './ValidityPicker'
import AdvancedFields from './AdvancedFields'

import { booleanEqual } from '@turf/turf'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '90vw',
    maxWidth: 'fill-available',
    margin: theme.spacing(2),
    display: 'block'
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width: '100%'
  },
})

function ReservationDetails({ classes, company_ids, setFrz, setFrzOk, frz, approverEditing }) {
  const [ name,      setName      ] = useState(frz?.name || '')
  const [ type,      setType      ] = useState(frz?.type || 'unspecified')
  const [ desc,      setDesc      ] = useState(frz?.desc || '')
  const [ pilots,    setPilots    ] = useState(frz?.pilots || [])
  const [ aircrafts, setAircrafts ] = useState(frz?.aircrafts || [])
  const [ zone,      setZone      ] = useState(frz?.source_nfz || frz?.zone || null) // Zone is taking geometry from layer,
  const [ geometry,  setGeometry  ] = useState(getApprovedGeometry(frz) || null) // geometry is custom geometry
  const [ minAlt,    setMinAlt    ] = useState(frz ? frz?.altitude?.min - (frz?.altitude?.ground_point?.alt || 0): '')
  const [ maxAlt,    setMaxAlt    ] = useState(frz ? frz?.altitude?.max - (frz?.altitude?.ground_point?.alt || 0): '')
  const [ altGround, setAltGround ] = useState(frz?.altitude?.ground_point)
  const [ altRef,    setAltRef    ] = useState(frz?.altitude?.ground_point ? 'AGL' : 'AMSL')
  const [ validity,  setValidity  ] = useState(getValidity(frz))
  
  const [ zoneError,      setZoneError      ] = useState('')
  const [ nameError,      setNameError      ] = useState('')
  const [ aircraftsError, setAircraftsError ] = useState('')
  const [ minAltError,    setMinAltError    ] = useState('')
  const [ maxAltError,    setMaxAltError    ] = useState('')
  const [ altGroundError, setAltGroundError ] = useState('')
  const [ vStartError,    setVStartError    ] = useState('')
  const [ vEndError,      setVEndError      ] = useState('')
  const [ pilotsError,    setPilotsError    ] = useState('')

  const [ formDirty, setFormDirty ] = useState(false)

  const [ newDroneOpen, setNewDroneOpen ] = useState(false)
  const [ nameModified, setNameModified ] = useState(false)
  const [ selectGround, setSelectGround ] = useState(false)
  const { data: altGroundData } = useGetElevationQuery(altGround, { skip: !altGround })

  useEffect(() => {
    if (!altGroundData) return
    const alt = altGroundData?.data?.[0]
    if (isNaN(alt)) return
    setAltGround(g => ({
      ...g,
      alt: Math.round(alt * 10) / 10
    }))
  }, [altGroundData])

  const validateForm = useCallback(() => {
    let hasError = false

    if (!formDirty)
      setFormDirty(true)
    if (name)
      setNameError('')
    else {
      hasError = true
      setNameError('Reservation Name is required')
    }
    if (aircrafts.length > 0)
      setAircraftsError('')
    else {
      hasError = true
      setAircraftsError('At least 1 drone is required')
    }
    if (geometry || zone)
      setZoneError('')
    else {
      hasError = true
      setZoneError('Zone is required (pick one here, or draw one on the map')
    }

    if (!minAlt) {
      hasError = true
      setMinAltError('Min altitude is required')
    } else if (!isFinite(minAlt)) {
      hasError = true
      setMinAltError('Min altitude must be a number')
    } else if (!maxAlt) {
      hasError = true
      setMaxAltError('Max altitude is required')
    } else if (!isFinite(maxAlt)) {
      hasError = true
      setMaxAltError('Max altitude must be a number')
    }
    else if (Number(maxAlt) < Number(minAlt)) {
      hasError = true
      setMinAltError(' ') // highlight both fields
      setMaxAltError('Max must be greater than min altitude')
    }
    else {
      setMinAltError('')
      setMaxAltError('')
    }
    if (altRef === 'AGL' && !altGround?.alt) {
      hasError = true
      setAltGroundError('Select a ground point for AGL altitude')
    }
    else {
      setAltGroundError('')
    }
    if (!validity.start) {
      hasError = true
      setVStartError('Start date / time is required')
    }
    else if (!validity.end) {
      hasError = true
      setVEndError('End date / time is required')
    }
    else if (validity.end < validity.start) {
      hasError = true
      setVStartError(' ') // highlight both fields
      setVEndError('End date / time must be after Start date / time')
    }
    else {
      setVStartError('')
      setVEndError('')
    }
    if (pilots.length === 0) {
      setPilotsError('At least 1 pilot is required')
    } else {
      setPilotsError('')
    }

    // validate nfz validity
    if (zone && zone.type === 'temp') {
      const valid = zone.validity?.some(zv => {
        const vStartUnix = validity.start.getTime() / 1000
        const vEndUnix = validity.end.getTime() / 1000
        return vStartUnix >= zv.start && vEndUnix <= zv.end
      }) ?? false
      if (!valid) {
        hasError = true
        setZoneError('Zone is not valid for the selected date / time')
      }
    }
    if (zone) {
      const relMinAlt = zone.altitude_reference === 'AGL' ? (zone.min_altitude + altRef.alt) : zone.min_altitude
      const relMaxAlt = zone.altitude_reference === 'AGL' ? (zone.altitude     + altRef.alt) : zone.altitude
console.log('zone', zone, 'relMinAlt', relMinAlt, 'relMaxAlt', relMaxAlt, 'minAlt', minAlt, 'maxAlt', maxAlt)
      if (relMinAlt > minAlt)
        setMinAltError(`Min Alt is below zone's altitude range (min ${zone.min_altutude} m ${zone.altitude_reference})`)
      if (relMaxAlt < maxAlt)
        setMaxAltError(`Max Alt is above zone's altitude range (max ${zone.altitude} m ${zone.altitude_reference})`)
    }
    setFrzOk(!hasError)

    if (hasError)
      return
    const frzDetails = {
      type,
      name,
      desc,
      pilots,
      aircrafts,
      zone,
      geometry,
      altitude: {
        min: parseFloat(minAlt) + (altGround?.alt ?? 0),
        max: parseFloat(maxAlt) + (altGround?.alt ?? 0),
        ellipsoid: 'WGS84',
        ground_point: altGround
      },
      validity,
    }

    if (frz?.frz_id) {
      frzDetails.frz_id = frz?.frz_id
    }

    setFrz((frz) => {
      return {
        ...frz,
        ...frzDetails
      }
    })
  }, [
    type,
    name,
    desc,
    pilots,
    aircrafts,
    zone,
    geometry,
    minAlt,
    maxAlt,
    altGround,
    altRef,
    validity,
    setFrz,
    setFrzOk,
    frz?.frz_id,
    formDirty,
  ])

  useEffect(() => {
    if (!nameModified) {
      if (pilots && pilots.length > 0)
        setName(pilots.map(p => p.name).join(', ') + '\'s Airspace')
      else
        setName('My Airspace Reservation')
    }
  }, [pilots, nameModified])

  useEffect(() => {
    if (formDirty)
      validateForm()
  }, [pilots, aircrafts, formDirty, validateForm])

  useEffect(() => {
    if (frz?.frz_id) {
      validateForm()
    }
  }, [frz?.frz_id, validateForm])

  useEffect(() => {
    const country = frzTypes[type]?.country
    if (country) {
      if (!minAlt)
        setMinAlt('0')
      if (!maxAlt)
        setMaxAlt(country === 'SG' ? 60
          : 120 // stop gap - should be AGL (applicable to MY, AU, and others following FAA Part 107: https://www.jouav.com/blog/how-high-can-a-drone-fly.html)
        )
    }
  }, [type, minAlt, maxAlt])

  useEffect(() => {
    if ((!zone && geometry)) { // original drawing
      setZone({ name: 'Custom Zone', custom: true })
    } else if (zone?.geometry && geometry && frz && !booleanEqual(getApprovedGeometry(frz), zone?.geometry ?? zone?.source_nfz?.geometry)) {
      setZone({ name: 'Custom Zone', custom: true })
    } else if (zone?.geometry && geometry && !booleanEqual(zone?.geometry, geometry)) { //reuse template but edited
      setZone({ name: 'Custom Zone', custom: true })
    }
  }, [zone, geometry, frz])

  const prevAltRef = usePrevious(altRef)
  const prevZone   = usePrevious(zone)
  const prevGround = usePrevious(altGround)
  useEffect(() => {
    if (zone) {
      if ((!prevZone && zone) || (prevZone.nfz_id !== zone.nfz_id))
        setAltRef(zone?.altitude_reference === 'AGL' ? 'AGL' : 'AMSL')
      if (!minAlt)
        setMinAlt(zone.min_altitude + '')
      if (!maxAlt)
        setMaxAlt(zone.altitude + '')

      validateForm()
      // console.info(zone)
    }
  }, [zone, minAlt, maxAlt, altRef, altGround?.alt, prevAltRef, prevGround?.alt, prevZone, validateForm])

  useEffect(() => { setSelectGround(false) }, [altRef])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {!approverEditing && <Grid item xs={12} lg={6}>
          <div className={classes.title}>
            <Typography variant='h6'>{name}</Typography>
          </div>
          <FrzMap
            company_ids={company_ids}
            geometry={geometry || zone?.geometry}
            setGeometry={geom => {
              setGeometry(geom)
              setZone(null)
            }}
            showDrawControls={true}
            showEditControls={geometry || zone?.geometry}
            selectMode={selectGround}
            groundPoint={altGround}
            onSelectPoint={e => {
              setAltGround({
                lat: e.lat,
                lng: e.lng,
                alt: 0
              })
              setSelectGround(false)
            }}
          />
        </Grid>}

        <Grid item xs={12} lg={6} container spacing={2}>
          <GridLabel>Zone</GridLabel>
          <GridField>
            <ZonePicker
              zone={zone}
              setZone={z => {
                setZone(z)
                setGeometry(null)
              }}
              error={zoneError}
            />
            { zone && !zone?.custom &&
              <>
                <Typography>Zone Details: </Typography>
                <Typography>
                  Altitude({zone?.altitude_reference === 'AGL' ? 'AGL' : 'AMSL'}): {zone?.min_altitude || 0} - {zone?.altitude} m
                </Typography>
                { zone.validity.length > 0 &&
                  <Typography>
                    Validity Period: { format(new Date(zone.validity[0]?.start * 1000), commonFormat)} to {format(new Date(zone.validity[0]?.end * 1000), commonFormat)}
                  </Typography>
                }
              </>
            }
          </GridField>
          <GridLabel>Altitude</GridLabel>
          <GridField>
            <RadioGroup row value={altRef} onChange={e => {
              setAltRef(e.target.value)
              validateForm()
            }}>
              <FormControlLabel control={<Radio />} label='AMSL' value='AMSL' />
              <FormControlLabel control={<Radio />} label='AGL' value='AGL' />
            </RadioGroup>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AltitudeText
                  label='Min'
                  altitude={minAlt}
                  setAltitude={setMinAlt}
                  error={minAltError}
                  onBlur={validateForm}
                  disabled={!altGround?.alt && altRef === 'AGL'}
                />
              </Grid>
              <Grid item xs={6}>
                <AltitudeText
                  label='Max'
                  altitude={maxAlt}
                  setAltitude={setMaxAlt}
                  error={maxAltError}
                  onBlur={validateForm}
                  disabled={!altGround?.alt && altRef === 'AGL'}
                />
              </Grid>
            </Grid>
            <Box height={10} />
            { altRef === 'AGL' &&
              <Box display='flex' alignItems='center'>
                <div>
                  <Button variant='outlined' onClick={() => setSelectGround(true)} disabled={selectGround}>
                    {!selectGround ? 'Select Ground Point' : 'Select a ground point on the map'}
                  </Button>
                  <br />
                  { altGroundError &&
                    <Typography color='error' variant='caption' style={{ paddingLeft: 5, paddingTop: 5 }}>
                      {altGroundError}
                    </Typography>
                  }
                </div>
                <Box width={10} />
                { !selectGround && altGround &&
                  <Typography>
                    Selected ground level altitude: {altGround.alt !== undefined
                    ? `${altGround.alt}m, ${meterToFeet2dp(altGround.alt)}ft`
                    : <CircularProgress color='inherit' size={20} />}
                  </Typography>
                }
              </Box>}
          </GridField>
          <GridLabel>Date / Time</GridLabel>
          <GridField>
            <ValidityPicker
              validity={validity}
              setValidity={setValidity}
              vStartError={vStartError}
              vEndError={vEndError}
            />
          </GridField>
          { !approverEditing && <>
              <GridLabel>Pilot(s)</GridLabel>
              <GridField>
                <PilotSelect
                  company_ids={company_ids}
                  pilots={pilots}
                  setPilots={(v) => {
                    setPilots(v)
                    validateForm()
                  }}
                  error={pilotsError}
                />
              </GridField>
              <GridLabel>Drone(s)</GridLabel>
              <GridField>
                <Box display='flex' width='100%'>
                  {/*TODO: This is only a hack to refresh data after adding a new drone.*/}
                  { !newDroneOpen &&
                    <DroneSelect
                      company_ids={company_ids}
                      drones={aircrafts}
                      setDrones={(v) => {
                        setAircrafts(v)
                        validateForm()
                      }}
                      error={aircraftsError}
                    />
                  }
                  <Box width={10} />
                  <Button onClick={() => setNewDroneOpen(true)} variant='outlined'>
                    <Add />
                  </Button>
                </Box>
                <NewDrone
                  open={newDroneOpen}
                  setOpen={setNewDroneOpen}
                  onClose={() => setNewDroneOpen(false)}
                />
              </GridField>
            </>
          }
          { !approverEditing && <>
              <GridLabel>Additional Comments</GridLabel>
              <GridField>
                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  variant='outlined'
                  value={desc}
                  placeholder='Eg. purpose of the reservation + special requests. Optional.'
                  onChange={e => { setDesc(e.target.value) }}
                />
              </GridField>
              <Grid item xs={12}>
                <AdvancedFields
                  name={name}
                  setName={name => {
                    setNameModified(true)
                    setName(name)
                  }}
                  nameError={nameError}
                  type={type}
                  setType={setType}
                />
              </Grid>
            </>
          }
        </Grid>
      </Grid>

    </div>
  )
}

function GridLabel({ children }) {
  return (
    <Grid item xs={12} sm={2} style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant='body1'>
        {children}
      </Typography>
    </Grid>
  )
}
function GridField({ children }) {
  return (
    <Grid item xs={12} sm={10} style={{ width: '100%' }}>
      {children}
    </Grid>
  )
}

export default withStyles(styles)(ReservationDetails)
