import { Button } from '@material-ui/core'
import { downloadCsv } from '../../utils'

function makeCSV(content) {
  let csv = 'ver,IMEI,IMSI,bat,lat,lng,hdop,alt,vdop,v,a,bear,rol,pit,yaw,head,rssi,volt,temp,log\n'

  content.forEach(t => {
    csv += [t.ver, t.IMEI, t.IMSI, t.bat,
    t.lat, t.lng, t.hdop, t.alt, t.vdop,
    t.v, t.a, t.bear, t.rol, t.pit, t.yaw, t.head,
    t.rssi ? t.rssi.slice(0, -3) : 0,
    t.volt || 0, t.temp || 0, t.log].join(',')
    csv += '\n'
  })
  return csv
}

export default function DownloadCSV(props) {
  const { telemetryArray, fileName } = props

  const handleClick = (event) => {
    event.preventDefault()
    downloadCsv(fileName +'.csv', [ makeCSV(telemetryArray) ])
  }

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={handleClick}>
      Download CSV
    </Button>
  )
}
