import { Fragment, useMemo, useState } from 'react'
import Coordinate from 'coordinate-parser'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  KeyboardReturn,
  Clear,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex:   1,
    bottom:   theme.spacing(2),
  },
  // positions: {
  //   width: theme.spacing(20),
  // },
})
function DmsPanel({ classes, vertices, setVertices, disableAdd }) {
  const [ coord,   setCoord   ] = useState('')
  const [ valid,   setValid   ] = useState(false)

  function parseCoord(e) {
    const txt = e.target.value
    setCoord(txt)
    try {
      new Coordinate(txt)
      setValid(true)
    } catch(e) {
      setValid(false)
    }
  }
  function addVertex() {
    const parsed = new Coordinate(coord)
    setVertices([...vertices, {
      lat: parsed.getLatitude(),
      lng: parsed.getLongitude()
    }])
    setCoord('')
    setValid(false)
  }
  function move(i, direction) {
    const po = [ ...vertices ]
    const c = po[i]
    if (direction === 'up') {
      po[i] = po[i - 1]
      po[i - 1] = c
    } else {
      po[i] = po[i + 1]
      po[i + 1] = c
    }
    setVertices(po)
  }
  function remove(i) {
    const po = [ ...vertices ]
    po.splice(i, 1)
    setVertices(po)
  }
  function lookForEnter(e) {
    if (e.key === 'Enter') {
      try { if (valid) addVertex() } catch(err) {}
      e.preventDefault()
    }
  }
  function closeVertices() {
    setVertices([ ...vertices, vertices[0] ])
  }

  const isComplete = useMemo(() => {
    const last = vertices[vertices.length - 1]
    return vertices.length >=3
      && vertices[0].lat === last.lat
      && vertices[0].lng === last.lng
  }, [vertices])

  return (
    <Box
      position='fixed'
      display='flex'
      bgcolor='background.paper'
      right='50%'
      bottom={16}
      padding={1}
      borderRadius={4}
      sx={{ transform: 'translate(50%)' }}>
      <Grid container alignItems='center'>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' style={{ padding: '16px' }}>
            Draw the airspace's area using the Polygon or Circle
            tool, or start adding coordinate of your polygon
            corners here. 
            <br/><br/>
            Enter your coordinates in this format: <br/>DD°MM'SS"N DD°MM'SS"W 
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container>
          <Grid item xs={4}><b><u>Latitude</u></b></Grid>
          <Grid item xs={4}><b><u>Longitude</u></b></Grid>
          <Grid item xs={4}> </Grid>
          { vertices.map((pos, i) => (
            <Fragment key={i}>
              <Grid item xs={4}>{pos.lat.toFixed(7)}</Grid>
              <Grid item xs={4}>{pos.lng.toFixed(7)}</Grid>
              <Grid item xs={1}>
                { i > 0 &&
                  <KeyboardArrowUp onClick={e => { move(i, 'up') }} />
                }
              </Grid>
              <Grid item xs={1}>
                { i < vertices.length - 1 &&
                  <KeyboardArrowDown onClick={e => { move(i, 'down') }} />
                }
              </Grid>
              <Grid item xs={2}>
                <Clear onClick={ e => { remove(i) }} />
              </Grid>
            </Fragment>
          )) }
          <Grid item xs={8}>
            <TextField fullWidth
              color='secondary'
              variant='standard'
              label='Coordinate'
              value={coord}
              onChange={parseCoord}
              onKeyDown={lookForEnter}
            />
          </Grid>
          <Grid item xs={4}>
            <Button color='secondary'
              variant='contained'
              disabled={!valid || isComplete || disableAdd}
              onClick={addVertex}
              startIcon={<KeyboardReturn />}>
              Add
            </Button>
            { vertices.length >= 3 &&
              <Button color='secondary'
                variant='contained'
                onClick={closeVertices}
                disabled={isComplete || disableAdd}>
                Done
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
export default withStyles(styles)(DmsPanel)
