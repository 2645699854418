import { useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useUpdateNFZMutation } from '../../api/airspace'
import { meterToFeet1dp } from '../../utils'
import TopXCloseButton from '../../components/TopXCloseButton'
import { StyledDateTimePicker } from '../../components/StyledPickers'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

function EditAirspaceDialog({ open, onClose, nfz, feature, onSuccess }) {
  const [ name, setName ] = useState(nfz.name)
  const [ description, setDescription ] = useState(nfz.description)
  const [ altitudeMin, setAltitudeMin ] = useState(nfz.min_altitude)
  const [ altitudeMax, setAltitudeMax ] = useState(nfz.altitude)
  const [ altitudeRef, setAltitudeRef ] = useState(nfz.altitude_reference)
  const [ validity, setValidity ] = useState(nfz.validity?.[0])
  const [ updateNfz, { isLoading } ] = useUpdateNFZMutation()

  const handleUpdate = () => updateNfz({
    ...nfz,
    name: name.trim(),
    description: description.trim(),
    geometry: feature.geometry,
    altitude: altitudeMax,
    min_altitude: altitudeMin,
    altitude_reference: altitudeRef,
    ...(validity && { validity: [validity] })
  }).then(result => {
    if (result.data.status !== 'success') throw Error(JSON.stringify(result.data))
    toast.success(`Airspace '${name}' updated`)
    onSuccess()
  }).catch(e => {
    toast.error(`Failed to update airspace`)
    console.error(e.message)
  })

  const hasError = useMemo(() => validity && validity.start > validity.end, [validity])

  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth='xs'
    fullWidth>
    <TopXCloseButton onClick={onClose} />
    <DialogTitle>Update Airspace</DialogTitle>
    <DialogContent>
      <TextField
        label='Name'
        fullWidth
        variant='filled'
        size='small'
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Box height={10}/>
      <TextField
        label='Description (optional)'
        fullWidth
        variant='filled'
        size='small'
        multiline
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <Box height={10}/>
      <FormLabel>Altitude</FormLabel>
      <RadioGroup row value={altitudeRef}>
        <FormControlLabel
          value='AGL'
          control={<Radio />}
          label='AGL'
          onClick={() => setAltitudeRef('AGL')}
        />
        <FormControlLabel
          value='WGS84'
          control={<Radio />}
          label='AMSL'
          onClick={() => setAltitudeRef('WGS84')}
        />
      </RadioGroup>
      <Box display='flex'>
        <TextField
          label='Min'
          variant='filled'
          type='number'
          size='small'
          value={altitudeMin}
          onChange={e => setAltitudeMin(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position='start'>m, {meterToFeet1dp(altitudeMin)}ft</InputAdornment>
          }}
        />
        <Box width={20} />
        <TextField
          label='Max'
          variant='filled'
          type='number'
          size='small'
          value={altitudeMax}
          onChange={e => setAltitudeMax(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position='start'>m, {meterToFeet1dp(altitudeMax)}ft</InputAdornment>
          }}
        />
      </Box>
      { validity && <>
        <Box height={10} />
        <FormLabel>Validity Period</FormLabel>
        <br />
        <StyledDateTimePicker
          value={new Date(validity.start * 1000)}
          onChange={d => setValidity(v => {
            const start = Math.trunc(d.getTime() / 1000)
            return {
              ...v,
              start,
              ...(start > v.end && { end: start + 3600 })
            }
          })}
          label='Start'
        />
        <Box height={10} />
        <StyledDateTimePicker
          value={new Date(validity.end * 1000)}
          minDate={new Date(validity.start * 1000)}
          onChange={d => setValidity(v => ({
            ...v,
            end: Math.trunc(d.getTime() / 1000)
          }))}
          label='End'
          error={validity.start > validity.end}
          helperText={validity.start > validity.end && 'End time must be after start time' }
        />
      </>}
    </DialogContent>
    <DialogActions>
      <Button
        variant='outlined'
        onClick={onClose}
        disabled={isLoading}>
        Edit Area
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={handleUpdate}
        disabled={isLoading || hasError}>
        Save
      </Button>
    </DialogActions>
  </Dialog>
}

export default EditAirspaceDialog
