import { useLazyGetPublicNFZsQuery, useLazyGetPrivateNFZsQuery } from '../../../api/airspace'
import TextBox from '../../../components/Inputs/TextBox'
import useNfzValidity from '../../../components/useNfzValidity'

function ZonePicker({ zone, setZone, error }) {
  const [getPublicNFZs] = useLazyGetPublicNFZsQuery()
  const [getPrivateNFZs] = useLazyGetPrivateNFZsQuery()

  const [ nfzValidity ] = useNfzValidity('Airspace')
  return (
    <TextBox
      get={() => ({
        unwrap: () => Promise.all([
          getPrivateNFZs({ restriction: 'ready-to-use', 
            validity_start: nfzValidity.start,
            validity_end:   nfzValidity.end
          }).unwrap(),
          getPublicNFZs({ restriction: 'test-estate' }).unwrap()
        ])
        .then(([a, b]) => ({
          ids: a.ids.concat(b.ids),
          entities: { ...a.entities, ...b.entities }
        }))
      })}
      dataId='nfz_id'
      dataLabel='name'
      groupBy={o => o.owner?.company_id ? 'Private Ready To Use' : 'Public Test Estates'}
      sortOptions={(a, b) => {
        if (a.owner?.company_id && !b.owner?.company_id) return -1
        else if (!a.owner?.company_id && b.owner?.company_id) return 1
        else return a.name.localeCompare(b.name)
      }}
      value={zone}
      error={!!error}
      helperText={error}
      placeholder='Select a predefined zone, or plot your own custom zone'
      onChange={nfz => setZone(nfz || null)}
    />
  )
}

export default ZonePicker
