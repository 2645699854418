import { withRouter } from 'react-router'
import { useGetMySubscriptionsQuery } from '../../api/accounts'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import usePermissions from '../../components/usePermissions'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  control: {
    marginLeft: theme.spacing(1),
  },
})

function MainControls({ classes, history, drones, onCreate }) {
  const { data } = useGetMySubscriptionsQuery()
  const subActive = data?.data?.subscriptions?.findIndex(s => s.app === 'my_drone_fleets') >= 0
  const serviceableCount = drones?.filter(d => !!d.serviceable).length || 0
  const canModifyDrones = usePermissions('drone-self-write')

  return (
    <div className={classes.root}>
      <div>
        <Typography variant='h5'>
          My Drones
        </Typography>
        <Typography variant='body1'>
          Total {drones?.length} (Serviceable: {serviceableCount})
        </Typography>
      </div>
      <div>
        <Button
          className={classes.control}
          variant='outlined'
          color='inherit'
          onClick={e => { history.push('/flights') }}>
          Recent Flights
        </Button>
        { subActive &&
          <>
            { canModifyDrones && <Button
              className={classes.control}
              variant='outlined'
              color='inherit'
              onClick={onCreate}>
              New Drone
            </Button>}
            <Button
              className={classes.control}
              variant='outlined'
              color='inherit'
              onClick={e => { history.push('/flights/new') }}>
              New Flight
            </Button>
          </>
        }
      </div>
    </div>
  )
}

export default withRouter(withStyles(styles)(MainControls))
