import { useState } from 'react'

import CfmsControlPanelViolationsList from './CfmsControlPanelViolationsList'
import CfmsControlPanelFlightsList    from './CfmsControlPanelFlightsList'
import CfmsControlPanelNfzValidity    from './CfmsControlPanelNfzValidity'
import {
  Tooltip,  
  Typography,
} from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import {
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/lab'
import {
  Warning,    // No Fly Zones
  TrendingUp, // Tracks
  Gavel,      // Violation
  Flight,     // Flight
} from '@material-ui/icons'
import { withStyles }     from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    top:    0,
    right:  0,
    marginRight: theme.spacing(2),
    marginTop:   theme.spacing(8),
    color: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.primary.dark,
    border: 'transparent',
    width:  theme.spacing(32),
    height: theme.spacing(6),
    cursor: 'pointer',
  },
  headerOpen: {
    borderRadius: '16px 16px 0 0',
  },
  headerClosed: {
    borderRadius: '16px',
  },
  body: {
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    width: theme.spacing(32),
  },
  bodyOpen: {
    maxHeight: `calc(100vh - ${theme.spacing(23)}px)`
  },
  bodyClosed: {
    maxHeight: 0
  },
  container: {
    lineHeight:    1.8,
    paddingLeft:   theme.spacing(2),
    paddingRight:  theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  text: {
    color: theme.palette.common.white
  }
})

function CfmsControlPanel({
  classes,
  cfmsFlights,    // to remove and put into modules later
  cfmsViolations, // to remove and put into modules later
  onToggleShowOnMap,
  onUpdateNfzValidity,
}) {
  const [ open, setOpen ] = useState(true)
  const [ enabled, setEnabled ] = useState([]) // default turn off everything

  function handleEnableChanged(event, values) {
    setEnabled(values)
    onToggleShowOnMap(values)
  }

  return (
    <div className={classes.root}>
      <div
        onClick={e => { setOpen(!open) }}
        className={classes.header + ' ' + (open ? classes.headerOpen : classes.headerClosed)}>
        <Typography variant='body1'>CFMS Control Panel</Typography>
        { open ? <ExpandLess /> : <ExpandMore /> }
      </div>
      <div className={classes.body + ' ' + (open ? classes.bodyOpen : classes.bodyClosed)}>
        <div className={classes.container}>
          <Typography variant='overline'>Show on Map</Typography>
          <ToggleButtonGroup
            size='large'
            value={enabled}
            onChange={handleEnableChanged}>
            <ToggleButton value='nfz'>
              <Tooltip title='No Fly Zones'><Warning /></Tooltip>
            </ToggleButton>
            <ToggleButton value='flights'>
              <Tooltip title='Flights'><Flight /></Tooltip>
            </ToggleButton>
            <ToggleButton value='tracks'>
              <Tooltip title='Tracks'><TrendingUp /></Tooltip>
            </ToggleButton>
            <ToggleButton value='violations'>
              <Tooltip title='Violations'><Gavel /></Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <CfmsControlPanelNfzValidity
          showNfz={enabled.includes('nfz')} />
        <CfmsControlPanelFlightsList
          showFlights={enabled.includes('flights')}
          flights={cfmsFlights} />
        <CfmsControlPanelViolationsList
          showViolation={enabled.includes('violations')}
          violations={cfmsViolations} />
      </div>
    </div>
  )
}

export default withStyles(styles)(CfmsControlPanel)
