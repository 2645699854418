
import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import {
  Archive,
  FlightLand,
  FlightTakeoff,
  LiveTv,
} from '@material-ui/icons'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const StatusConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  active: {},
  completed: {},
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.background.default,
    borderRadius: 1,
  },
}))(StepConnector)

const iconStyles = theme => ({
  icon: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: theme.palette.background.paper,
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
  },
  completed: {
    backgroundColor: theme.palette.background.default,
  }
})

function StatusIcon({ icon, active, completed }) {
  const classes = makeStyles(iconStyles)()
  const icons = {
    1: <FlightTakeoff />,
    2: <LiveTv />,
    3: <FlightLand />,
    4: <Archive />,
  }
  return (<div className={classes.icon +
    (active ? (' ' + classes.active) : '') +
    (completed ? (' ' + classes.completed) : '')}>
    { icons[String(icon)] }
  </div>)
}

function StatusStepper({ status }) {
  const steps = {
    preflight:  'Pre-Flight',
    flying:     'Flying',
    postflight: 'Post-Flight',
    archived:   'Archived'
  }
  const activeStep = Object.keys(steps).indexOf(status)

  return (
    <Stepper activeStep={activeStep} alternativeLabel connector={<StatusConnector />}>
      { Object.keys(steps).map(s => (
        <Step key={s}>
          <StepLabel StepIconComponent={StatusIcon}>
            {steps[s]}
          </StepLabel>
        </Step>
      )) }
    </Stepper>
  )
}

export default StatusStepper
