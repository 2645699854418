import { format } from 'date-fns'
import useNfzValidity from '../../../components/useNfzValidity'
import {
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  subtitle: {
    paddingLeft: theme.spacing(2),
    paddingTop:  theme.spacing(2),
  },
  container: {
    display: 'flex',
    padding: theme.spacing(2)
  }
})

function CfmsControlPanelNfzValidity({ classes, showNfz }) {
  const [ nfzValidity, setNfzValidity ] = useNfzValidity('CfmsControlPanelNfzValidity')

  if (!showNfz)
    return null

  const startDate = new Date(nfzValidity?.start * 1000)
  const endDate   = new Date(nfzValidity?.end * 1000)

  function handleChangeStartDate(e) {
    const [year, month, day] = e.target.value.split('-')
    const newStartDate = new Date(year, Number(month) - 1, day) // beginning of day
    setNfzValidity({ start: newStartDate.getTime() / 1000, end: nfzValidity.end })
  }
  function handleChangeEndDate(e) {
    const [year, month, day] = e.target.value.split('-')
    const newEndDate = new Date(year, Number(month) - 1, day) // beginning of day
    setNfzValidity({ start: nfzValidity.start, end: newEndDate.getTime() / 1000 })
  }

  return (<>
    <Typography variant='overline' className={classes.subtitle}>
      NFZ Validity
    </Typography>
    <div className={classes.container}>
      <TextField
        label='From'
        type='date'
        value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
        onChange={handleChangeStartDate}
      />
      <TextField
        label='To'
        type='date'
        value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
        onChange={handleChangeEndDate}
      />
    </div>
  </>)
}

export default withStyles(styles)(CfmsControlPanelNfzValidity)
