import { useState } from 'react'
import { format }   from 'date-fns'
import { toast }    from 'react-toastify'

import { useGetFlightsQuery } from '../../api/aircraft'
import { downloadCsv, prettyDuration, commonFormat } from '../../utils'
import DataTable       from '../../components/DataTable'
import WaitDialog      from '../../components/WaitDialog'
import TopXCloseButton from '../../components/TopXCloseButton'
import FlightDetails from './components/FlightDetails'
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core'
import {
  AirplanemodeInactive,
  Archive,
  FlightLand,
  FlightTakeoff,
  LiveTv,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  iconMsg: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }
})

function DroneFlights({ classes, drone, onClose }) {
  const [ details, setDetails ] = useState(null)
  const { data, error, isLoading } = useGetFlightsQuery(
    { drone: drone.drone_id }, { skip: !drone }
  )
  if (isLoading)
    return <WaitDialog msg='Loading...' onClose={onClose} />
  else if (error)
    toast.error(JSON.stringify(error?.data?.data))

  const flightIds = data?.ids || []
  const flights = data?.entities || {}
  if (flightIds.length === 0) {
    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth='sm'>
        <TopXCloseButton onClick={onClose} />
        <DialogContent>
          <div className={classes.iconMsg}>
            <Avatar><AirplanemodeInactive /></Avatar>
            <Typography>
              No flights found for {drone.name}.<br />
              Did you create flight records for this drone?
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
  return (<>
    <Dialog open={true} onClose={onClose} fullWidth maxWidth='lg'>
      <TopXCloseButton onClick={onClose} />
      <DownloadCsvButton name={drone.name} flights={flights} />
      <DialogTitle>
        Flights by {drone.name}
      </DialogTitle>
      <DialogContent>
        <DataTable
          data={Object.values(flights)}
          columns={[
            { label: 'Drone', render: f => f.drone.name },
            { label: 'Pilots', render: f => f.pilots?.map(p => p.name).join(', ') },
            { label: 'Location', key: 'location' },
            { label: 'Date', render: f => new Date(f.date).toLocaleString() },
            { label: 'Duration', render: f => (
              (f.duration?.hours ? (f.duration?.hours + ':') : '') +
              f.duration?.minutes.toString().padStart(2, '0') + ':' +
              f.duration?.seconds.toString().padStart(2, '0')
            ) },
            { label: 'Status', render: f => <StatusIcon status={f.status} /> },
            { label: '', render: f => (<div className={classes.actions}>
              <Button variant='outlined'
                onClick={() => setDetails(f)}>Details</Button>
              <Button variant='outlined'
                onClick={() => { toast.warning('Coming Soon') }}>Playback</Button>
              { f.logs && <Button variant='outlined'
                onClick={() => { toast.warning('Coming Soon') }}>Logs</Button>
              }
            </div>) }
          ]}
        />
      </DialogContent>
    </Dialog>
    { details &&
      <FlightDetails flight={details} onClose={() => setDetails(null)} />
    }
  </>)
}

function DownloadCsvButton({ name, flights }) {
  function handleClick(e) {
    e.preventDefault()
    const flightData = Object.values(flights)
    if (flightData.length === 0)
      return

    const blob = []
    function poseToString(pose) {
      if (!pose) return ''
      return `${pose.t || ''}:${pose.lat || ''}:${pose.lng || ''}:${pose.alt ||
        ''}:${pose.agl || ''}:${pose.yaw || ''}:${pose.pitch || ''}:${pose.roll || ''}`
    }
    function escape(v) {
      return v.includes(',') || v.includes('\n') || v.includes('"') ?
        `"${v.replace(/"/g, '""')}"` : v
    }
    flightData.forEach(f => {
      blob.push({
        drone_name:     f.drone?.name,
        pilot_names:    f.pilots?.map(p => p.name).join(' '),
        date:           f.date ? format(new Date(f.date), commonFormat) : '',
        location:       f.location,
        purpose:        f.purpose?.join(' '),
        permits:        f.permits?.map(p => `${p.authority || ''}:${p.ref || ''}`).join(' '),
        policies:       f.policies?.map(p => `${p.id || ''}:${p.provider || ''}:${p.type || ''}:${p.coverage || ''}(${p.premium_currency || ''}${p.premium_amount || ''}<${p.insured_currency || ''}${p.insured_amount || ''}`).join(' '),
        configurations: f.configurations?.map(c => `${c.subsystem || ''}:${c.component_name || ''}(${c.install_date || ''})`).join(' '),
        status:         f.status,
        duration:       f.duration && prettyDuration(f.duration),
        max_distance:   f.distance,
        max_alt:        f.max_alt,
        max_agl:        f.max_agl,
        serviceable:    f.serviceable ? 'Serviceable' : 'BER',
        notes:          f.notes,
        logs:           f.logs?.map(l => `${l.type || ''}:${l.media_id || ''}`).join(' '),
        // == Advanced (not for reports)
        flight_id:      f.flight_id,
        drone_id:       f.drone?.id,
        company_id:     f.company_id,
        tracker_ids:    f.drone?.tracker_ids?.join(' '),
        battery_ids:    `${f.drone?.battery_ids?.join(' ') || ''} ${f.batteries?.map(b => b.id).join(' ') || ''}`.trim(),
        payload_ids:    `${f.drone?.payload_ids?.join(' ') || ''} ${f.cameras?.map(c => c.id).join(' ') || ''} ${f.payloads?.map(p => p.id).join(' ') || ''}`.trim(),
        pilot_ids:      f.pilots?.map(p => p.id).join(' '),
        deployment_id:  f.deployment_id,
        session_id:     f.session_id,
        config_ids:     f.configurations?.map(c => `${c.subsystem || ''}:${c.component_id || ''}:${c.config_id || ''}`).join(' '),
        geofence:       f.geofence && JSON.stringify(f.geofence),
        path_home:      f.path?.home && `[${f.path.home.lat || ''}, ${f.path.home.lng || ''}]`,
        path_arm:       f.path?.arm     && poseToString(f.path.arm),
        path_takeoff:   f.path?.takeoff && poseToString(f.path.takeoff),
        path_land:      f.path?.land    && poseToString(f.path.land),
        path_disarm:    f.path?.disarm  && poseToString(f.path.disarm),
        permit_docs:    f.permits?.map(p => p.docs?.join(' ')),
        policies_docs:  f.policies?.map(p => p.docs?.join(' ')),
        created_by:       f.created_by,
        created_on:       f.created_on,
        last_modified_by: f.last_modified_by,
        last_modified_on: f.last_modified_on,
      })
    })
    const keys = Object.keys(blob[0])
    const string = keys.join(',') + '\n' +
      blob.map(b => keys.map(k => b[k] ? escape(b[k].toString()) : '').join(',')).join('\n')

    downloadCsv(`Flights by ${name} exported ${new Date().toLocaleString()}.csv`, [string])
  }

  return (
    <div style={{
      position: 'absolute',
      top: '16px',
      right: '64px',
    }}>
      <Button variant='outlined' onClick={handleClick}>
        Download CSV
      </Button>
    </div>
  )
}

function StatusIcon({ status }) {

  const icon = {
    preflight:  <FlightTakeoff />,
    flying:     <LiveTv />,
    postflight: <FlightLand />,
    archived:   <Archive />,
  }
  return (
    <Button size='large' startIcon={icon[status]}
      color={ status === 'flying' ? 'secondary' : 'inherit' }
      variant={ status === 'flying'? 'contained' : 'outlined' }>
      {status}
    </Button>
  )
}

export default withStyles(styles)(DroneFlights)
