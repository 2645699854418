import { useEffect, useRef } from 'react'
import {
  useMap,
  FeatureGroup,
} from 'react-leaflet'
import FrzPolygon from '../../components/Airspace/components/FrzPolygon'
import { frzColour } from '../../utils'

function FrzFeatureGroup({
  showFrz,
  frzs,
  onClick,
  fitBoundsOnChange
}) {
  const map = useMap()
  const frzsRef = useRef()

  useEffect(() => {
    if (frzsRef.current && frzs?.length > 0 && showFrz) {
      if(fitBoundsOnChange){
        const frzBounds = frzsRef.current.getBounds()
        map.fitBounds(frzBounds)
      }
      
    }
  }, [map, frzs, showFrz, fitBoundsOnChange])

  return (
    <FeatureGroup ref={frzsRef}>
      { showFrz &&
        frzs.map(frz => (
          <FrzPolygon key={frz.frz_id}
           frz={frz}
           colour={frzColour(frz.status)}
           onClick={onClick}
          />
        )) 
      }
    </FeatureGroup>
  )
}

export default FrzFeatureGroup
