import { useEffect, useMemo } from 'react'
import { useMap } from 'react-leaflet'

import { useGetPublicNFZsQuery } from '../../api/airspace'
import rulesToPolygonProps from '../../utils/rulesToPolygonProps'

import useNfzValidity   from '../../components/useNfzValidity'
import PublicNfzPolygon from './components/PublicNfzPolygon'
import NfzPoint         from './components/NfzPoint'
import VesselMarker     from './components/VesselMarker'

function PublicAirspace({
  showStaticNfz  = false,
  showTempNfz    = false,
  showVessels    = false,
  showTestEstate = false,
  showCfmsNfz    = false, // only for HTX plugin
  selectedNfzId, // only for NFZs that are cached by Airspace module
  isLocked,
  onClickLayer
}) {
  const [ nfzValidity ] = useNfzValidity('Airspace')
  const { data: nfzData, error, isLoading } = useGetPublicNFZsQuery({
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
  })
  if (!isLoading && error)
    console.log('Error loading NFZs', error)

  const map = useMap()
  useEffect(() => {
    if (map && nfzData && selectedNfzId) {
      if (!nfzData.entities[selectedNfzId])
        return console.log('unknown airspace id', selectedNfzId)

      const coords = nfzData.entities[selectedNfzId].geometry.coordinates
      const latLngBounds = coords.map(coord => coord.map(c => [ c[1], c[0] ]))
      map.flyToBounds(latLngBounds)
    }
  }, [map, nfzData, selectedNfzId])

  const geoJsonArray = useMemo(() => {
    if (!showStaticNfz && !showTempNfz && !showCfmsNfz && !showVessels && !showTestEstate)
      return null

    return nfzData?.entities ? Object.values(nfzData.entities).map(nfz => {
      // FIXME: revise this convoluted logic, should be just type...
      if (nfz?.status === 'active' && (
          (showStaticNfz &&
            nfz.type === 'static' && !(nfz.owner?.company_id) && nfz.restriction !== 'test-estate') ||
          (showTempNfz &&
            nfz.type === 'temp' && !(nfz.owner?.company_id) && nfz.restriction !== 'test-estate') ||
          (showVessels &&
            nfz.geometry.type !== 'Point' && nfz.restriction   === 'operation-restriction') ||
          (showTestEstate &&
            !(nfz.owner?.company_id) && nfz.restriction === 'test-estate') ||
          (showCfmsNfz &&
            nfz.owner?.company_id === process.env.REACT_APP_CAAS_COMPANY_ID)
        )) {
        const newNfz = { ...nfz }
        newNfz.nfz_type = nfz.type

        // roundabout way to differentiate Vessel info from AIS. FIXME
        if (nfz.geometry.type === 'Point' && nfz.restriction === 'operation-restriction') {
          newNfz.type = 'vessel'
          return newNfz
        } else {
          newNfz.type = 'Feature'
          newNfz.properties = rulesToPolygonProps(nfz.restriction) // database is a mess, to clean up
          return newNfz
        }
      } else {
        // console.log('Reject nfz', nfz)
        return null
      }
    }).filter(g => g) : null
  }, [
    nfzData,
    showStaticNfz,
    showTempNfz,
    showVessels,
    showTestEstate,
    showCfmsNfz,
  ])

  //
  // Performance: (1) useMemo on dependencies, and (2) data update cached in /api
  // (ie. debounce one side enough). Every time we re-render, we remove all previous
  // NFZs from the map, else it will double / triple render. By keeping the key the
  // same, leaflet won't rerender (3).
  //
  // Uncomment to sanity check:
  // console.log(new Date().toLocaleTimeString(), 'filtered public airspace', geoJsonArray)
  //
  return (geoJsonArray ? <>
    { geoJsonArray.map(geojson => {
      if (!geojson)
        return null

      if (geojson.type === 'vessel') {
        if (showVessels)
          return <VesselMarker
            key={geojson.nfz_id}
            vesselGeoJson={geojson}
          />
        else
          return null
      }
      if (geojson.geometry.type === 'Point') {
        return <NfzPoint
          key={geojson.nfz_id}
          nfzGeoJson={geojson}
        />
      }
      return <PublicNfzPolygon // handles MultiPolygon as well
        key={geojson.nfz_id}
        isLocked={isLocked}
        nfzGeoJson={geojson}
        geoJsonArray={geoJsonArray}
        onClick={onClickLayer}
      />
    })}
  </> : null)
}

export default PublicAirspace
