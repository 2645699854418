import { useGetCompanyByIdQuery } from '../../api/accounts'

function Company({ id, name, email, ...rest }) {

  const { data, error, isLoading } = useGetCompanyByIdQuery(id, {
    skip: !id
  })

  if (!id || isLoading)
    return ''
  if (error)
    return '(Unavailable Company)'
  if (data?.status !== 'success')
    return '(Invalid Company)'

  const company = data?.data?.company || {}
  return <span {...rest}>
    { name  && company.name }
    { email && ` (${company.email || '-'})` }
  </span>
}

export default Company
