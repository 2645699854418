import { useState } from 'react'
import { toast } from 'react-toastify'
import { useUploadDroneAvatarMutation } from '../../../api/aircraft'
import { imageMimeTypes } from '../../../api/media'
import usePermissions from '../../../components/usePermissions'

import Media from '../../../components/Media'
import {
  CircularProgress,
  Grid,
  IconButton,
} from '@material-ui/core'
import {
  AddAPhoto,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  avatarStack: {
    height: '100%',
  },
  gridCenter: {
    display: 'flex',
    flexGrow: 1,
  },
  gridBottom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse'
  }
})

function DroneAvatarPicker({ classes, droneId, mediaId, onUpload }) {

  const [ uploadAvatar  ] = useUploadDroneAvatarMutation()
  const [ wait, setWait ] = useState(false)
  const canModifyDrones = usePermissions('drone-operation-write')

  const handleUpload = (e) => {
    const file = e.target.files[0]
    // if (!file.name.endsWith('.png') && !file.name.endsWith('.jpg'))
    if (!file)
      return
    if (!imageMimeTypes.includes(file.type))
      return toast.warning('Only JPG, PNG, and GIF files are supported')

    setWait(true)
    uploadAvatar({ drone_id: droneId, file })
    .unwrap()
    .then(fulfilled => {
      setWait(false)
      if (fulfilled.status !== 'success') {
        console.log('Failed to upload avatar', fulfilled)
        toast.warning('Failed to upload avatar')
      } else {
        toast.success('Avatar uploaded')
        // update drone with new avatar
        onUpload(fulfilled.data.drone.avatar)
      }
    })
    .catch(err => {
      setWait(false)
      console.log('Error uploading avatar', err)
      toast.error('Error uploading avatar')
    })
  }

  return (
    <Grid
      className={classes.avatarStack}
      container
      direction='column'
      alignItems='center'>
      <Grid item className={classes.gridCenter}>
        <Media size='preview' mediaId={mediaId} />
      </Grid>
      {canModifyDrones &&
      <Grid item className={classes.gridBottom}>
        <input
          style={{ display: 'none' }}
          id='contained-button-file'
          type='file'
          onChange={handleUpload}
        />
        <label htmlFor='contained-button-file'>
          { wait && <CircularProgress size={24} /> }
          <IconButton color='inherit' component='span' disabled={wait}>
            <AddAPhoto />
          </IconButton>
        </label>
      </Grid>}
    </Grid>
  )
}

export default withStyles(styles)(DroneAvatarPicker)