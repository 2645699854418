import { useEffect } from 'react'
import EditFeatureGroupGeoman from '../../Zones/Editor/components/EditFeatureGroupGeoman'
import { useUpdateFlightMutation } from '../../api/aircraft'
import { useMap, GeoJSON } from 'react-leaflet'
import { toast } from 'react-toastify'
import * as turf from '@turf/turf'

function EditableFlightGeofence({ flight, editable }) {
    const [updateFlight] = useUpdateFlightMutation()

    const onSave = (geofence)=>{
        updateFlight({
            ...flight,
            geofence: geofence
        }).unwrap().then(()=>{
            toast.info("Updated flight geofence")
        })
    }

    const map = useMap()
    useEffect(() => {
      if (flight?.geofence) {
        const centroid = turf.centroid(flight.geofence).geometry.coordinates
        const centerLatLng = { lat: centroid[1], lng: centroid[0] }
        map.flyTo(centerLatLng)
      }
    }, [map, flight])

    if (!(flight?.geofence))
        return null
    return <>{ editable ? <EditFeatureGroupGeoman
        geometry={flight.geofence}
        onSave={onSave}
    /> : <GeoJSON data={flight.geofence}/>}</>
}

export default EditableFlightGeofence
