import { format } from 'date-fns'
import { meterToFeet2dp } from '../../utils'
import { convertSecondsToHMS } from '../../utils/track-utils'
import Row from './Row.js'
import {
  Grid,
  Typography,
} from '@material-ui/core'

function Track({ telem, droneId, trackerId }) {
  if (!telem)
    return (<div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '16px', }}>
      <Typography variant='body1' style={{ textAlign: 'center' }}>
        No Recent Tracks
      </Typography>
    </div>)
  return (<>
    <Typography variant='body1'>Latest Track</Typography>
    <Grid container>
      <Row l='Time (UTC)'   v={telem.t} />
      <Row l='Time (Local)' v={telem.t ? format(new Date(telem.t), 'eee dd MMM yyyy HH:mm:ss') : '-'} />
      <Row l='Latitude'     v={telem.lat} />
      <Row l='Longitude'    v={telem.lng} />
      <Row l='Altitude (HAE WGS84)'
        v={Number.isFinite(telem.alt) ? `${(telem.alt / 100).toFixed(2)} meters, ${meterToFeet2dp(telem.alt / 100)} feet` : 'unspecified or invalid'}
      />
      <Row l='Speed'
        v={Number.isFinite(telem.v) ? `${telem.v} m/s` : 'unspecified or invalid'}
      />
      <Row l='Acceleration'
        v={Number.isFinite(telem.a) ? <>{telem.a} m/s<sup>2</sup></> : 'unspecified or invalid'}
      />
      <Row l='Heading'
        v={Number.isFinite(telem.head) ? `${telem.head} deg (magnetic north)` : 'unspecified or invalid'}
      />
      <Row l='Operational Status'
        v={Number.isFinite(telem.status) ? ['', 'Grounded', 'Airborne'][telem.status] || `unknown status ${telem.status}` : 'invalid'}
      />
      <Row l='Vertical Accuracy'
        v={Number.isFinite(telem.v_acc) && telem.v_acc !== -1 ? `${telem.v_acc.toFixed(2)} meters` : 'unspecified or invalid'}
      />
      <Row l='Horizontal Accuracy'
        v={Number.isFinite(telem.h_acc) && telem.h_acc !== -1 ? `${telem.h_acc.toFixed(2)} meters` : 'unspecified or invalid'}
      />
      <Row l='VDOP'
        v={Number.isFinite(telem.vdop) && telem.vdop !== -1 ? telem.vdop.toFixed(2) : 'unspecified or invalid'}
      />
      <Row l='HDOP'
        v={Number.isFinite(telem.hdop) && telem.hdop !== -1 ? telem.hdop.toFixed(2) : 'unspecified or invalid'}
      />
      <Row l='Roll'  v={Number.isFinite(telem.roll)  ? `${telem.roll.toFixed(3)} °`  : '-'} />
      <Row l='Pitch' v={Number.isFinite(telem.pitch) ? `${telem.pitch.toFixed(3)} °` : '-'} />
      <Row l='Yaw'   v={Number.isFinite(telem.yaw)   ? `${telem.yaw.toFixed(3)} °`   : '-'} />
      <Row l='Battery Remaining'
        v={Number.isFinite(telem.batt_duration) ? convertSecondsToHMS(telem.batt_duration) : '-' }
      />
      <Row l='Battery Voltage'
           v={Number.isFinite(telem.batt_voltage) ? `${telem.batt_voltage.toFixed(2)} mV` : 'unspecified or invalid'}
      />
      <Row l='Temperature'   v={Number.isFinite(telem.temp)   ? `${telem.temp.toFixed(3)} °C`   : '-'} />
      <Row l='Flight Time Remaining'
           v={Number.isFinite(telem.flight_time_remaining) ? convertSecondsToHMS(telem.flight_time_remaining) : 'unspecified or invalid'}
      />
      <Row l='Flight Time Capacity'
           v={Number.isFinite(telem.flight_time_capacity) ? convertSecondsToHMS(telem.flight_time_capacity) : 'unspecified or invalid'}
      />
      <Row l='RSSI' v={telem.rssi?.length ? telem.rssi : '-'} />
      <Row l='IMSI' v={telem.imsi?.length ? telem.imsi : '-'} />
      <Row l='IMEI' v={telem.imei?.length ? telem.imei : '-'} />
      <Row l='Log'  v={telem.log?.length  ? telem.log  : '-'} />
    </Grid>
  </>)
}

export default Track
