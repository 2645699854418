import { useState } from 'react'
import { toast } from 'react-toastify'

import { useGetFlightByIdQuery } from '../../api/aircraft'
  // useCreateFlightMutation,
  // useUpdateFlightMutation,
  // useDeleteFlightMutation,
import ActionButton     from '../../components/Buttons/ActionButton'
import WaitDialog       from '../../components/WaitDialog'
import AreYouSureDialog from '../../components/AreYouSureDialog'
import FlightTitle      from './components/FlightTitle'
import FlightMap        from './components/FlightMap'
import FlightMeta       from './components/FlightMeta'
import PreFlight  from './components/PreFlight'
import FlightFlying     from './components/FlightFlying'
import PostFlight from './components/PostFlight'
import {
  Grid,
} from '@material-ui/core'
import {
  Delete,
  Save,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  top: {
    height: theme.spacing(40),
  },
  bottom: {
    height: theme.spacing(40),
  },
})

function SingleFlight({ classes, flightId }) {
  const [ aysOpen, setAysOpen ] = useState(false)
  const { data, error, isLoading } = useGetFlightByIdQuery(flightId, { skip: !flightId })

  if (flightId) {
    if (isLoading)
      return <WaitDialog msg={`Loading Flight...`} />
    else if (error) {
      console.log('Error loading flight', error)
      toast.error(`Error loading flight ${flightId}`)
      return null
    } else if (data?.status !== 'success') {
      console.log('Failed to load flight', data)
      toast.warning(`Failed to load flight ${flightId}`)
      return null
    }
  }
  const flight = flightId === '' ? null : (data?.data?.flight || {})

  // const [ createFlight ] = useCreateFlightMutation()
  // const [ updateFlight ] = useUpdateFlightMutation()
  // const [ deleteFlight ] = useDeleteFlightMutation()
  function handleDelete() {
    setAysOpen(false)
    toast.info('Coming Soon')
  }
  function handleCreate() {
    toast.info('Coming Soon')
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={10}>
          <FlightTitle flight={flight} />
        </Grid>
        <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
          { flight ?
            <ActionButton label='Delete' startIcon={<Delete />}
              onClick={e => { setAysOpen(true) }} />
          : <ActionButton label='Save' startIcon={<Save />}
              onClick={handleCreate} />
          }
        </Grid>
        <Grid item xs={12} lg={4} className={classes.top}>
          <FlightMap flight={flight} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} className={classes.top}>
          <FlightMeta flight={flight} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} className={classes.top}>
          <FlightFlying flight={flight} />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.bottom}>
          <PreFlight flight={flight} />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.bottom}>
          <PostFlight flight={flight} />
        </Grid>
      </Grid>
      { aysOpen &&
        <AreYouSureDialog
          open={aysOpen}
          onClose={e => { setAysOpen(false) }}
          title='Delete Flight'
          message='Are you sure you want to delete this flight?'
          action='Delete'
          onOK={handleDelete}
        />
      }
    </div>
  )
}

export default withStyles(styles)(SingleFlight)
