import { useMemo, useState } from 'react'
import { 
  Box, 
  IconButton,
  Typography, 
  TextField,
  List,
  ListItem,
  withStyles, 
  ListItemText,
  ListItemSecondaryAction,
  Collapse
} from '@material-ui/core'
import { FilterList, Edit, Delete, Add, ExpandLess, ExpandMore } from '@material-ui/icons'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  list: {
    backgroundColor: theme.palette.background.paper
  },
  iconBtn: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    marginLeft: theme.spacing(1),
  }
})

function AirspaceListPanel({ classes, publicNfzs, privateNfzs, selectedNfzId, onSelectNfz, onEditNfz, onDeleteNfz, onAddNfz }) {
  const [ search, setSearch ] = useState('')
  const [ collapsePrivate, setCollapsePrivate ] = useState(false)
  const [ collapsePublic, setCollapsePublic ] = useState(true)

  const readyToUseAirspace = useMemo(() => privateNfzs?.filter(n => n.restriction === 'ready-to-use' && (!search || n.name.toLowerCase().includes(search.toLowerCase()))), [privateNfzs, search])
  const publicAirspace = useMemo(() => publicNfzs?.filter(n => n.geometry.type !== 'Point' && (!search || n.name.toLowerCase().includes(search.toLowerCase()))), [publicNfzs, search])

  return (
    <div className={classes.root}>
      <Box 
        paddingTop={2}
        paddingX={2}
      >
        <Box
          display='flex'
          alignItems='center'
          flexDirection='row'
          justifyContent='space-between'
          padding={0.5}
        >
          <Typography variant='h5'>Airspaces</Typography>
          <IconButton onClick={onAddNfz}>
            <Add />
          </IconButton>
        </Box>
        <Box
          mt={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <TextField
            label='Search an airspace...'
            fullWidth
            variant='filled'
            size='small'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <IconButton className={classes.iconBtn}>  {/* to be discussed */}
            <FilterList />
          </IconButton>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        overflow='auto'
      >
        <List className={classes.list}>
          <Box position='sticky' top={0} bgcolor='background.paper' zIndex={1}>
            <ListItem button onClick={() => setCollapsePrivate(c => !c)}>
              <ListItemText secondary='Private Ready To Use' />
              {collapsePrivate ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
          </Box>
          <Collapse in={!collapsePrivate}>
          { readyToUseAirspace?.map(nfz =>
            <ListItem button
              key={nfz.nfz_id}
              autoFocus={selectedNfzId === nfz.nfz_id}
              selected={selectedNfzId === nfz.nfz_id}
              onClick={() => onSelectNfz(nfz)}>
              <ListItemText primary={nfz.name}/>
              <div> {/* Autofocus does not work w/o nesting ListItemSecondaryAction*/}
              { selectedNfzId === nfz.nfz_id &&
                <ListItemSecondaryAction>
                  <IconButton edge='end' size='small' style={{paddingRight: 8}} onClick={() => onEditNfz(nfz)}>
                    <Edit fontSize='small'/>
                  </IconButton>
                  <IconButton edge='end' size='small' onClick={() => onDeleteNfz(nfz)}>
                    <Delete fontSize='small'/>
                  </IconButton>
                </ListItemSecondaryAction>
              }
              </div>
            </ListItem>
          )}
          { readyToUseAirspace?.length === 0 && <ListItem>
              <ListItemText secondary={search.length > 0
                ? `No entries found matching "${search}"`
                : `No private airspace created yet. Click on the plus icon to draw one.`
              }/>
            </ListItem>
          }
          </Collapse>
          <Box position='sticky' top={0} bgcolor='background.paper' zIndex={1}>
            <ListItem button onClick={() => setCollapsePublic(c => !c)}>
              <ListItemText secondary='Public No Fly Zones' />
              {collapsePublic ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
          </Box>
          <Collapse in={!collapsePublic}>
          { publicAirspace?.map(nfz =>
            <ListItem button
              key={nfz.nfz_id}
              autoFocus={selectedNfzId === nfz.nfz_id}
              selected={selectedNfzId === nfz.nfz_id}
              onClick={() => onSelectNfz(nfz)}>
              <ListItemText primary={nfz.name}/>
            </ListItem>
          )}
          { publicAirspace?.length === 0 && <ListItem>
              <ListItemText secondary={search.length > 0
                ? `No entries found matching "${search}"`
                : `No public airspace found.`
              }/>
            </ListItem>
          }
          </Collapse>
        </List>
      </Box>
    </div>
  )
}

export default withStyles(styles)(AirspaceListPanel)