import { useMemo }      from 'react'
import { getDecodedAccessToken, isController }  from '../../../api/accounts'
import { useGetPrivateNFZsQuery } from '../../../api/airspace'
import { withinValidity }         from '../../../utils'
import useNfzValidity from '../../../components/useNfzValidity'
import Pill           from '../../../components/Pill'
import GeofenceList   from './components/GeofenceList'
import {
  Tooltip,
} from '@material-ui/core'
import {
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  header: {
    display: 'flex',
  },
  gfid: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(24)
  },
  amsl: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(10)
  },
})

export function geofenceSortOrder(a, b) {
  if (a.status === 'active' && b.status !== 'active')
    return -1
  if (a.status !== 'active' && b.status === 'active')
    return 1
  if (withinValidity(a.validity) && !withinValidity(b.validity))
    return -1
  if (!withinValidity(a.validity) && withinValidity(b.validity))
    return 1
  return (a.validity[0].start < b.validity[0].start) ? -1 : 1
}

function ActiveGeofences({ classes, selectedId, onClickGeofence, isRsaf }) {
  const company_id = useMemo(() => getDecodedAccessToken().company_id, [])

  const [ nfzValidity ] = useNfzValidity('ActiveGeofences')
  const { data: nfzData } = useGetPrivateNFZsQuery({
    company_ids:    company_id,
    validity_start: nfzValidity.start,
    validity_end:   nfzValidity.end,
    restriction:    ['operation-restriction', 'keepin-area'],
  })

  // TODO: consider a global (combine with RSAF) sort order, then move into sortComparer
  //       https://redux-toolkit.js.org/api/createEntityAdapter
  const geofences = nfzData?.entities ?
    Object.values(nfzData?.entities).sort(geofenceSortOrder) : []

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.gfid}>
          Geofence ID
          {isRsaf && isController() && <Tooltip title='To create a Geofence, use the Polygon
            or Circle tool on the left side of the screen. Geofences here are
            applicable to all drones and trackers. For flight specidic geofences,
            click on a Flight instead.'>
            <Info style={{ width: '16px', height: '16px' }} />
          </Tooltip>}
        </div>
        <div className={classes.amsl}>AMSL (m)</div>
      </div>
      { geofences.length === 0 ?
        <div>
          <Pill text='No Active Geofence' />
        </div>
      : <GeofenceList
          geofences={geofences}
          selectedId={selectedId}
          onClickGeofence={onClickGeofence}
          isRsaf={isRsaf}
        />
      }
    </div>
  )
}

export default withStyles(styles)(ActiveGeofences)
