import { withRouter } from 'react-router'
import { toast } from 'react-toastify'

import {
  Button,
  Box
} from '@material-ui/core'
import {
  Call,
  ChevronLeft,
  Today,
} from '@material-ui/icons'

function MainControls({ classes, history }) {
  return (
    <Box display='flex' alignItems='center'>
      <Button
        variant='outlined'
        color='inherit'
        startIcon={<ChevronLeft />}
        onClick={() => { history.push('/zones') }}>
        Back
      </Button>
      <Box mr='auto'/>
      <Button
        variant='outlined'
        color='inherit'
        endIcon={<Today />}
        onClick={() => { toast.info('Coming Soon') }}>
        Check Dates
      </Button>
      <Box width={15}/>
      <Button
        variant='outlined'
        color='inherit'
        endIcon={<Call />}
        onClick={() => { toast.info('Coming Soon') }}>
        Contact Applicant
      </Button>
    </Box>
  )
}

export default withRouter(MainControls)
