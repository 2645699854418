import { Scatter } from 'react-chartjs-2'
import wheel from '../../utils/colour-wheel'
import ChartOptions from './ChartOptions'

export default function BatteryChart({ trackers }) {

  if (!trackers)
    return null

  const battDataset = []

  for (const imei in trackers) {
    let col = wheel.nextColour()
    battDataset.push({
      label: 'Tracker ' + imei,
      data: trackers[imei].map(t => [t.serverTime, t.bat]),
      backgroundColor: col,
      borderColor: col
    })
  }
  const battOptions = ChartOptions('Battery Seconds Remaining')

  return (
    <Scatter
      type='scatter'
      width={1000}
      height={625}
      data={{datasets: battDataset}}
      options={battOptions}
    />
  )
}
