import { useGetUserByIdQuery } from '../../api/accounts'

function User({ id, name, email, designation, ...rest }) {

  const { data, error, isLoading } = useGetUserByIdQuery(id, {
    skip: !id
  })

  if (!id || isLoading)
    return ''
  if (error)
    return '(Unavailable User)'
  if (data?.status !== 'success')
    return '(Invalid User)'

  const user = data?.data?.user || {}
  return <span {...rest}>
    { name        && user.name }
    { email       && ` (${user.email || 'No email'})` }
    { designation && ` [${user.designation || 'No designation'}]` }
  </span>
}

export default User
