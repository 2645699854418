import moment from 'moment'
import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    height:      theme.spacing(6),
  },
  timeScaleContainer: {
    height:      theme.spacing(3),
    padding:     theme.spacing(1, 0),
    borderLeft:  '2px solid white',
    borderRight: '2px solid white',
    borderColor: theme.palette.common.white,
  },
  timeScale: {
    height:  theme.spacing(1),
    width:   '100%',
    backgroundColor: theme.palette.common.black,
  },
  indicator: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
    width: ({ track, startTime, totalTime }) => {
      if (track?.t) {
        const currentTime = moment(track.t).valueOf()
        const width = parseInt(((currentTime - startTime) / totalTime) * 100)
        return `${width}%`
      }
      return '0%'
    },
  },
})

function TimeScale({
  classes,
  trackStart,
  trackEnd,
}) {
  if (!(trackStart && trackEnd))
    return null
  return (
    <div className={classes.container}>
      <div className={classes.timeScaleContainer}>
        <div className={classes.timeScale}>
          <div className={classes.indicator} />
        </div>
      </div>
      <div className={classes.timeMarkers}>
        <Typography variant='caption' style={{ float: 'left' }}>
          { trackStart.toLocaleTimeString() }
        </Typography>
        <Typography variant='caption' style={{ float: 'right' }}>
          { trackEnd.toLocaleTimeString() }
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(TimeScale)
