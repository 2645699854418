import {
  Button,
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'absolute',
    bottom: 0,
    width: `calc(100% - ${theme.spacing(8)}px)`,
    maxWidth: 'fill-available',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  saveDraft: {
    marginRight: theme.spacing(1),
  },
})

function StepperActions({
  classes,
  frzOk,
  activeStep,
  onNext,
  onBack,
  onSaveDraft,
  isApprover
}) {
  const actionButtons = !isApprover ? <>
    <Button
      className={classes.saveDraft}
      variant='outlined'
      disabled={!frzOk}
      color='inherit'
      onClick={onSaveDraft}>
      Save Draft
    </Button>
    <Button
      variant='contained'
      disabled={!frzOk}
      endIcon={<ChevronRight />}
      onClick={onNext}>
      Next
    </Button>
  </> :  <Button
      className={classes.saveDraft}
      variant='outlined'
      disabled={!frzOk}
      color='inherit'
      onClick={onSaveDraft}>
      Save Changes to FRZ Request
    </Button>
  return (
    <div className={classes.root}>
      {activeStep < 3 ?
        <Button
          variant='outlined'
          color='inherit'
          startIcon={<ChevronLeft />}
          onClick={onBack}>
          Back
        </Button>
        : <>&nbsp;</>
      }
      <div>
        {activeStep === 1 ?
          <Button
            variant='contained'
            disabled={!frzOk}
            endIcon={<ChevronRight />}
            onClick={onNext}>
            Next
          </Button>
          : activeStep === 2 ?
            <Button
              variant='contained'
              color='primary'
              disabled={!frzOk}
              onClick={onNext}>
              Submit for Approval
            </Button>
            : activeStep === 3 ?
              <Button
                variant='outlined'
                onClick={onNext}>
                Close
              </Button>
              : actionButtons
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(StepperActions)
